import React, { useEffect, useState } from "react";
import BudgetSheetNavbar from "../../components/BudgetSheetNavbar";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import YearSheet from "./YearSheet";
import Goals from "./Goals/Goals";
import Header from "../../components/Header";
import SummaryReport from "./SummaryReport/SummaryReport";
import FinalReport from "./finalReport/FinalReport";
import { getUniqueMonthsApi } from "../../services/Collections";
import { updateYearSheetFilters } from "../exceltable/ExcelStore";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const DynamicBudgetSheet = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginAuth = useSelector((state) => state?.loginAuth);
  const goalFilters = useSelector(
    ({ dynamicBudgetSheet }) => dynamicBudgetSheet?.goalFilters
  );
  const selectedHistoryData = useSelector(
    ({ dynamicBudgetSheet }) => dynamicBudgetSheet?.selectedHistoryData
  );
  const { yearSheetFilters, yearData } = useSelector(
    (state) => state?.dynamicBudgetSheet
  );
  const [active, setActive] = useState(loginAuth?.dynamicSheetTab || "goals");
  const [yearsheetData, setYearsheetData] = useState(yearData?.current || []);
  const [year, setYear] = useState(goalFilters?.year || null);
  const [yearSheetFilter, setYearSheetFilter] = useState(true);
  const [openPop, setOpenPop] = useState(false);
  const { data, prefilledFilters, summarySectionFilter } = loginAuth;
  const activeClient =
    summarySectionFilter?.selectedClient || prefilledFilters?.selectedClient;

  const handleUnique = async () => {
    try {
      let params = new URLSearchParams();
      params.append("user_id", data?.id);
      params.append("year", dayjs().year() + 1);
      // params.append("month", date || 0);

      let res = await getUniqueMonthsApi(activeClient?.key, params);

      if (res?.status === 200) {
        dispatch(
          updateYearSheetFilters({
            ...yearSheetFilters,
            uniqueMonths: res?.data,
          })
        );
      }
    } catch (error) {
      setLoader(false);
      console.log("error in finalreport", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    handleUnique();
  }, [activeClient?.key]);

  return (
    <>
      <Header
        isSetting={true}
        openSetting={() => setOpenPop(true)}
        isNotification={true}
        title="Dynamic Budget Sheet"
      />

      <SummaryWrapper style={{ background: active == "summary" && "#f3f3f3" }}>
        <BudgetSheetNavbar
          active={active}
          setActive={setActive}
          setYearSheetFilter={setYearSheetFilter}
          yearSheetFilter={yearSheetFilter}
          openPop={openPop}
          setOpenPop={setOpenPop}
        />
        {active == "goals" ? (
          <Goals
            selectedHistory={goalFilters || {}}
            year={year}
            setYear={setYear}
          />
        ) : active == "yearsheet" ? (
          <YearSheet
            handleUnique={handleUnique}
            selectedHistory={selectedHistoryData || []}
            yearsheetData={yearsheetData}
            setYearsheetData={setYearsheetData}
            yearSheetFilter={yearSheetFilter}
          />
        ) : active == "summary" ? (
          <SummaryReport />
        ) : (
          <FinalReport filter={yearSheetFilter} />
        )}
      </SummaryWrapper>
    </>
  );
};

export default DynamicBudgetSheet;

const SummaryWrapper = styled.div`
  padding: 10px 10px 0 10px;
  background: #fff;
  .main-heading {
    margin-bottom: 20px;
    font-size: 16px;
    font-family: "Poppins";
  }
`;
