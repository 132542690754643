import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

export const ModalLoader = ({ size, color }) => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: size,
        color: color,
      }}
      spin
    />
  );
  return <Spin style={{ margin: "0px" }} indicator={antIcon} />;
};
