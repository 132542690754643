export let categoryArray = [
  "Category",
  "Budget",
  "Sales",
  "ROAS",
  "Leads",
  "CPL",
  "Appts",
  "CPA",
  "Conv %",
  "Jobs",
  "CPJ",
  "Close %",
  "Average Ticket",
];

export function transformData(data) {
  let totals = {
    category_name: "Total",
    budget: 0,
    sales: 0,
    roas: 0,
    leads: 0,
    cpl: 0,
    appts: 0,
    cpa: 0,
    con_per: 0,
    jobs: 0,
    cpj: 0,
    close_per: 0,
    avg_ticket: 0,
  };

  let goals = {
    ...totals,
    category_name: "Goals",
  };

  let difference = {
    ...totals,
    category_name: "Difference",
  };

  let budgetPercentage = {
    ...totals,
    category_name: "Budget %",
  };

  const transformedData = data?.map((item) => {
    const budget = Number(item?.budget);
    const sales = Number(item?.sales);
    const roas = Number(item?.roas);
    const leads = Number(item?.leads);
    const cpl = Number(item?.cpl);
    const appts = Number(item?.appts);
    const cpa = Number(item?.cpa);
    const con_per = Number(item?.con_per);
    const jobs = Number(item?.jobs);
    const cpj = Number(item?.cpj);
    const closePer = Number(item?.close_per);
    const avgTicket = Number(item?.avg_ticket);

    // Accumulate totals for each field
    totals.budget += budget;
    totals.sales += sales;
    totals.roas += roas;
    totals.leads += leads;
    totals.cpl += cpl;
    totals.appts += appts;
    totals.cpa += cpa;
    totals.con_per += con_per;
    totals.jobs += jobs;
    totals.cpj += cpj;
    totals.close_per += closePer;
    totals.avg_ticket += avgTicket;

    return {
      category_name: item.category_name,
      budget: budget,
      sales: sales,
      roas: roas,
      leads: leads,
      cpl: cpl,
      appts: appts,
      cpa: cpa,
      con_per: con_per,
      jobs: jobs,
      cpj: cpj,
      close_per: closePer,
      avg_ticket: avgTicket,
    };
  });

  const formatValue = (value) => {
    return value % 1 === 0 ? value : Math.round(value * 100) / 100;
  };

  // Format totals values
  totals.budget = formatValue(totals.budget);
  totals.sales = formatValue(totals.sales);
  totals.roas = formatValue(totals.roas);
  totals.leads = formatValue(totals.leads);
  totals.cpl = formatValue(totals.cpl);
  totals.appts = formatValue(totals.appts);
  totals.cpa = formatValue(totals.cpa);
  totals.con_per = formatValue(totals.con_per);
  totals.jobs = formatValue(totals.jobs);
  totals.cpj = formatValue(totals.cpj);
  totals.close_per = formatValue(totals.close_per);
  totals.avg_ticket = formatValue(totals.avg_ticket);

  // Add totals object at the end of the transformed data
  transformedData.push(totals);
  transformedData.push(goals);
  transformedData.push(difference);
  transformedData.push(budgetPercentage);

  return transformedData;
}

export function formatToCurrency(value) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0, // Removes decimal values
  }).format(value);
}

export function formatToCurrencyWithDecimal(value, roundOff = true) {
  // If roundOff is true, round the value first
  let finalValue = roundOff ? Math.round(value) : value;
  
  // Format the value as currency
  let formattedValue = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2, // Ensure two decimal places are always shown
    maximumFractionDigits: 2  // Limit to two decimal places
  }).format(finalValue);

  return formattedValue;
}

export function formatNumber(
  value,
  minimumFractionDigits,
  maximumFractionDigits,
  roundOff = true
) {
  let formattedValue = value.toLocaleString("en-US", {
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  });

  return roundOff ? Math.round(value) : formattedValue;
}

export function sourceNameValue(r, c, value, fieldName) {
  let bg = getTheme(r);
  let fc = "rgb(36, 36, 36)";

  if (fieldName == "Total") {
    bg = "#000000";
    fc = "#ffffff";
  }
  if (
    fieldName == "Goals" ||
    fieldName == "Difference" ||
    fieldName == "Budget %"
  ) {
    bg = "#000000";
    fc = "#ffffff";
  }
  return {
    r: r,
    c: c,
    v: {
      lo: 1,
      v: value,
      f: "",
      ff: "Poppins",
      bg: bg,
      fc: fc,
      fs: "12",
      vt: 0,
      ht: 0,
    },
  };
}

export function numberToAlphaWord(num) {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"; // Alphabet string
  let result = "";

  // Loop to handle numbers beyond 25 (for multiple letter combinations)
  while (num >= 0) {
    let remainder = num % 26; // Find the current letter
    result = alphabet[remainder] + result; // Prepend the corresponding letter
    num = Math.floor(num / 26) - 1; // Move to the next "digit" in base 26
    if (num < 0) break;
  }

  return result;
}

export function getIndex(arr, value) {
  const index = arr.findIndex(
    (item) => item.toLowerCase() === value.toLowerCase()
  );
  if (index === -1) {
    return "Value not found";
  }
  return index;
}

function getCellFormat(name) {
  // budget sales cpl cpa cpj avg ticket
  let dollorFormat = [
    "budget",
    "sales",
    "cpl",
    "cpa",
    "cpj",
    "avg_ticket",
    "goals",
    "difference",
  ];
  let intFormat = ["leads", "appts", "jobs"];
  let percentageFormat = ["con_per", "close_per"];
  let doubleFormat = ["roas"];

  if (dollorFormat?.includes(name)) {
    return {
      fa: "$#,##0_);($#,##0)",
      t: "n",
    };
  } else if (intFormat?.includes(name)) {
    return {
      fa: "#,##0",
      t: "n",
    };
  } else if (percentageFormat?.includes(name)) {
    return {
      fa: "#,##0",
      // fa: '0.00%',
      t: "n",
    };
  } else if (doubleFormat?.includes(name)) {
    return {
      fa: "0.00",
      t: "n",
    };
  }
}

function getTheme(r) {
  return r % 2 === 0 ? "#e9e9e96e" : "#ffffff";
}

export function cellFormattedValues(name, r, c, value, formula, mainArray) {
  let row = r + 1;
  let f = "";
  let lock = 1;
  let backgroundColor = getTheme(r);
  let textColor = "#2f2e2ed9";
  let formatted = formatToCurrency(value);

  if (formula) {
    if (name === "jobs") {
      let sales = numberToAlphaWord(getIndex(categoryArray, "sales"));
      let avg_ticket = numberToAlphaWord(
        getIndex(categoryArray, "Average Ticket")
      );
      f = `=IF(${avg_ticket}${row}=0, 0, IFERROR(INT(${sales}${row}/${avg_ticket}${row}), 0))`;
      formatted = value?.toFixed(0);
    }

    if (name === "appts") {
      let jobs = numberToAlphaWord(getIndex(categoryArray, "Jobs"));
      let close_per = numberToAlphaWord(getIndex(categoryArray, "Close %"));
      f = `=IF(${close_per}${row}=0, 0, IFERROR(INT(${jobs}${row}/${close_per}${row}*100), 0))`;
      formatted = value?.toFixed(0);
    }

    if (name === "leads") {
      let appts = numberToAlphaWord(getIndex(categoryArray, "Appts"));
      let conv = numberToAlphaWord(getIndex(categoryArray, "Conv %"));
      f = `=IF(${conv}${row}=0, 0, IFERROR(INT(${appts}${row}/${conv}${row}*100), 0))`;
      formatted = value?.toFixed(0);
    }

    if (name === "budget") {
      let cpl = numberToAlphaWord(getIndex(categoryArray, "cpl"));
      let leads = numberToAlphaWord(getIndex(categoryArray, "leads"));
      f = `=IF(${leads}${row}=0, 0, IFERROR(${cpl}${row}*${leads}${row}, 0))`;
    }

    if (name === "roas") {
      let sales = numberToAlphaWord(getIndex(categoryArray, "sales"));
      let budget = numberToAlphaWord(getIndex(categoryArray, "budget"));
      f = `=IF(${budget}${row}=0, 0, IFERROR(ROUND(${sales}${row}/${budget}${row},2), 0))`;
      formatted = value?.toFixed(2);
    }

    if (name === "cpa") {
      let budget = numberToAlphaWord(getIndex(categoryArray, "budget"));
      let appts = numberToAlphaWord(getIndex(categoryArray, "appts"));
      f = `=IF(${appts}${row}=0, 0, IFERROR(${budget}${row}/${appts}${row}, 0))`;
    }

    if (name === "cpj") {
      let budget = numberToAlphaWord(getIndex(categoryArray, "budget"));
      let jobs = numberToAlphaWord(getIndex(categoryArray, "jobs"));
      f = `=IF(${jobs}${row}=0, 0, IFERROR(${budget}${row}/${jobs}${row}, 0))`;
    }
  } else {
    lock = 0;
    textColor = "#0000FF";

    if (name === "con_per" || name === "close_per") {
      formatted = `${value?.toLocaleString()}`;
    }
  }

  return {
    r: r,
    c: c,
    v: {
      lo: lock,
      v: value,
      f: f,
      ff: "Poppins",
      bg: backgroundColor,
      fc: textColor,
      fs: "12",
      ct: getCellFormat(name),
      m: formatted,
      vt: 0,
      ht: 0,
    },
  };
}

export function totalCellValues(name, rowEndIndex, col, value) {
  let f = "";
  let lock = 1;
  let bg = "#000000";
  let fc = "#ffffff";
  let formatted = formatToCurrency(value);

  if (name === "budget") {
    let colName = numberToAlphaWord(getIndex(categoryArray, "budget"));
    f = `=SUM(${colName}${2}:${colName}${rowEndIndex}, 2)`;
  }

  if (name === "sales") {
    let colName = numberToAlphaWord(getIndex(categoryArray, "sales"));
    f = `=SUM(${colName}${2}:${colName}${rowEndIndex}, 2)`;
  }

  if (name === "roas") {
    let colName = numberToAlphaWord(getIndex(categoryArray, "roas"));
    f = `=SUM(${colName}${2}:${colName}${rowEndIndex}, 2)`;
  }

  if (name === "leads") {
    let colName = numberToAlphaWord(getIndex(categoryArray, "leads"));
    f = `=SUM(${colName}${2}:${colName}${rowEndIndex}, 2)`;
  }

  if (name === "cpl") {
    let colName = numberToAlphaWord(getIndex(categoryArray, "cpl"));
    f = `=SUM(${colName}${2}:${colName}${rowEndIndex}, 2)`;
  }

  if (name === "appts") {
    let colName = numberToAlphaWord(getIndex(categoryArray, "appts"));
    f = `=SUM(${colName}${2}:${colName}${rowEndIndex}, 2)`;
  }
  if (name === "cpa") {
    let colName = numberToAlphaWord(getIndex(categoryArray, "cpa"));
    f = `=SUM(${colName}${2}:${colName}${rowEndIndex}, 2)`;
  }

  if (name === "con_per") {
    let colName = numberToAlphaWord(getIndex(categoryArray, "Conv %"));
    f = `=SUM(${colName}${2}:${colName}${rowEndIndex}, 2)`;
    formatted = `${value?.toString()}%`;
  }

  if (name === "jobs") {
    let colName = numberToAlphaWord(getIndex(categoryArray, "jobs"));
    f = `=SUM(${colName}${2}:${colName}${rowEndIndex}, 2)`;
  }

  if (name === "cpj") {
    let colName = numberToAlphaWord(getIndex(categoryArray, "cpj"));
    f = `=SUM(${colName}${2}:${colName}${rowEndIndex}, 2)`;
  }

  if (name === "close_per") {
    let colName = numberToAlphaWord(getIndex(categoryArray, "Close %"));
    f = `=SUM(${colName}${2}:${colName}${rowEndIndex}, 2)`;
    formatted = `${value?.toString()}%`;
  }

  if (name === "avg_ticket") {
    let colName = numberToAlphaWord(getIndex(categoryArray, "Average Ticket"));
    f = `=SUM(${colName}${2}:${colName}${rowEndIndex}, 2)`;
  }

  return {
    r: rowEndIndex,
    c: col,
    v: {
      lo: lock,
      v: value,
      f: f,
      ff: "Poppins",
      bg: bg,
      fc: fc,
      fs: "12",
      ct: getCellFormat(name),
      m: formatted,
      vt: 0,
      ht: 0,
    },
  };
}

function emptyCell(r, c) {
  let bg = "";
  let fc = "";
  return {
    r: r,
    c: c,
    v: {
      lo: 1,
      v: "",
      f: "",
      ff: "Poppins",
      bg: bg,
      fc: fc,
      fs: "12",
      vt: 0,
      ht: 0,
    },
  };
}

function footerCellValue(name, r, c, value, mainArrayLen) {
  let f = "";
  let lock = 0;
  let backgroundColor = "#ffffff";
  let formatted = formatToCurrency(value);

  if (name === "difference") {
    lock = 1;
    let colAlpha = numberToAlphaWord(c);
    f = `=${colAlpha}${mainArrayLen - 2}-${colAlpha}${mainArrayLen - 1}`;
  }
  if (name === "budget %") {
    lock = 1;
    let totalBudget = numberToAlphaWord(getIndex(categoryArray, "budget"));
    let totalsales = numberToAlphaWord(getIndex(categoryArray, "sales"));
    f = `=${totalBudget}${mainArrayLen - 2}/${totalsales}${
      mainArrayLen - 2
    }*100`;
  }

  return {
    r: r,
    c: c,
    v: {
      lo: lock,
      v: value,
      f: f,
      ff: "Poppins",
      bg: backgroundColor,
      fc: "rgb(36, 36, 36)",
      fs: "12",
      ct: getCellFormat(name),
      m: formatted,
      vt: 0,
      ht: 0,
    },
  };
}

export function findCellValue(transformArray) {
  let formula = true;
  let formulaNotrequired = false;
  let calcChain = [];
  let tobj = {
    r: 0,
    c: 0,
    id: "1",
    color: "w",
    parent: null,
    chidren: {},
    times: 0,
  };
  let celldata = transformArray?.flatMap((el, i) => {
    console.log(transformArray, "transformArray");
    return Object.entries(el).map(([key, value], c) => {
      calcChain?.push({ ...tobj, r: i + 1, c: c });
      if (key === "category_name") {
        return sourceNameValue(i + 1, c, value, el?.category_name);
      }
      if (el?.category_name === "Total") {
        return totalCellValues(key, transformArray?.length - 3, c, value);
      }

      if (
        el?.category_name === "Goals" ||
        el?.category_name === "Difference" ||
        el?.category_name === "Budget %"
      ) {
        let flag = 2;
        if (el?.category_name === "Budget %") flag = 1;
        if (key && c <= flag) {
          return footerCellValue(
            el?.category_name?.toLowerCase(),
            i + 1,
            c,
            value,
            transformArray?.length
          );
        } else {
          return emptyCell(i + 1, c);
        }
      }

      if (key === "budget") {
        return cellFormattedValues("budget", i + 1, c, value, formula);
      }
      if (key === "sales") {
        return cellFormattedValues(
          "sales",
          i + 1,
          c,
          value,
          formulaNotrequired
        );
      }
      if (key === "roas") {
        return cellFormattedValues("roas", i + 1, c, value, formula);
      }
      if (key === "leads") {
        return cellFormattedValues("leads", i + 1, c, value, formula);
      }
      if (key === "cpl") {
        return cellFormattedValues("cpl", i + 1, c, value, formulaNotrequired);
      }
      if (key === "appts") {
        return cellFormattedValues("appts", i + 1, c, value, formula);
      }
      if (key === "cpa") {
        return cellFormattedValues("cpa", i + 1, c, value, formula);
      }
      if (key === "con_per") {
        return cellFormattedValues(
          "con_per",
          i + 1,
          c,
          value,
          formulaNotrequired
        );
      }
      if (key === "jobs") {
        return cellFormattedValues("jobs", i + 1, c, value, formula);
      }
      if (key === "cpj") {
        return cellFormattedValues("cpj", i + 1, c, value, formula);
      }
      if (key === "close_per") {
        return cellFormattedValues(
          "close_per",
          i + 1,
          c,
          value,
          formulaNotrequired
        );
      }
      if (key === "avg_ticket") {
        return cellFormattedValues(
          "avg_ticket",
          i + 1,
          c,
          value,
          formulaNotrequired
        );
      }
      if (key === "avg_ticket") {
        return cellFormattedValues(
          "avg_ticket",
          i + 1,
          c,
          value,
          formulaNotrequired
        );
      }

      if (el?.category_name === "Difference") {
        if (key) {
          return totalCellValues(key, transformArray?.length - 2, c, value);
        }
      }

      return null;
    });
  });
  return [celldata, calcChain];
}
