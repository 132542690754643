import { ConfigProvider, DatePicker } from "antd";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import styled from "styled-components";
const { RangePicker } = DatePicker;

const theme = {
  backgroundGray: "black",
  lightWhite: "#000",
  primaryColor: "#fff",
  lightText: "#000",
  greyText: "#a4a4a4",
};

const RangeSelector = ({
  defaultDate = [],
  handleChange,
  disabled = false,
  bgColor,
}) => {
  const dateFormat = "YYYY-MM-DD";
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );

  const handleDateChange = (e, value) => handleChange(value);
  return (
    <ConfigProvider
      selectedThemeColors={selectedThemeColors}
      theme={{
        token: {
          colorBgElevated: "#fff", // container background
          colorSplit: theme.lightWhite,
          colorTextQuaternary: theme.lightText, // previous/next month date color
        },
        components: {
          DatePicker: {
            colorBgContainer: bgColor ?? theme.primaryColor, // used to change the bg color of datepicker-field
            // colorBgContainerDisabled: bgColor ?? theme.primaryColor, // used to change the bg color of datepicker-field
            colorText: theme.lightWhite,
            colorTextPlaceholder: theme.lightText,
            colorBorder: theme.primaryColor,
            cellActiveWithRangeBg: theme.primaryColor, //"#ebf4f8", // used to show the selected range background
            colorTextHeading: theme.greyText, // used to change the heading color in calender
            colorPrimary: "#000",
            colorPrimaryHover: "#000",
            colorIcon: theme.lightWhite,
            colorIconHover: theme.lightWhite,
          },
        },
      }}
    >
      <DatePickerWrapper
        selectedThemeColors={selectedThemeColors}
        format={dateFormat}
        defaultValue={[
          defaultDate[0] ? dayjs(defaultDate[0], dateFormat) : null,
          defaultDate[1] ? dayjs(defaultDate[1], dateFormat) : null,
        ]}
        allowClear={false}
        suffixIcon={null}
        onChange={handleDateChange}
        disabled={disabled}
        popupClassName="range-picker-popup"
      />
    </ConfigProvider>
  );
};

export default RangeSelector;

const DatePickerWrapper = styled(RangePicker)`
  width: 100%;
  height: 48px;
  padding: 8px !important;
  background: #fff !important;
  border: 1px solid rgba(206, 206, 206, 1) !important;
  ${
    "" /* display: flex !important;
  justify-content: space-evenly !important; */
  }
  .ant-empty-description {
    color: #000 !important;
  }
  .ant-picker-input {
    width: 40%;
    margin-bottom: 0px !important;
  }
  .ant-picker-separator {
    color: #fff !important;
  }
  .ant-picker-range-separator {
    width: 20%;
    display: flex;
    justify-content: center;
    filter: invert(1) !important;
  }
  .ant-picker-input > input {
    color: #0f1011 !important;

    text-align: center;
  }
  .ant-picker .ant-picker-input > input-disabled:hover {
    border: none !important;
    background-color: none !important;
  }
  .ant-picker .ant-picker-input > input[disabled]:hover {
    border: none !important;
    background-color: none !important;
  }
`;
