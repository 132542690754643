import { Modal, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
// import { updateSelectedChat } from "../store/MessageSlice";
import { DropDownIcon } from "../../utils/Icons/SvgIcons";
import ErrorMessage from "../../utils/ErrorMessage";
import { aiCompanyList } from "../../services/Collections";
import styled from "styled-components";

function YelpFilterModal({
  open,
  handleClose,
  editDetails,
  setFilterData,
  setFilterPayload,
  setCurrentPage,
}) {
  const [loading, setLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [scrollPage, setScrollPage] = useState(1);
  const dispatch = useDispatch();

  const initialValues = editDetails || {};

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight) {
      setScrollPage(scrollPage + 1);
    }
  };

  const handleCompanyList = async () => {
    setLoading(true);
    const params = new URLSearchParams();
    params.append("page", scrollPage);
    params.append("page_size", 100);

    const res = await aiCompanyList(params);
    if (res?.data?.length > 0) {
      const array = res?.data?.map((el) => {
        const obj = {
          label: el?.name,
          value: el?.id,
        };
        return obj;
      });
      if (scrollPage === 1) {
        setCompanyList(array);
      } else {
        setCompanyList([...companyList, ...array]);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleSubmit = (values) => {
    setFilterData(values);
    // dispatch(updateSelectedChat(null));
    const filterArr = [];
    for (const key in values) {
      // eslint-disable-next-line no-prototype-builtins
      if (values.hasOwnProperty(key)) {
        const requestObject = {
          field: key,
          value: values[key]?.value,
        };
        if (values[key]) {
          filterArr.push(requestObject);
        }
      }
    }
    setFilterPayload(filterArr);
    handleClose();
    setCurrentPage(1);
  };

  useEffect(() => {
    handleCompanyList();
  }, [scrollPage]);

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      width={520}
      footer={false}
      closeIcon={false}
      prefixCls="add-company"
    >
      <AddCompanyWrapper>
        <div className="title">
          <h3>Filters</h3>
        </div>
        <div>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            render={({ values, setFieldValue, errors, touched }) => (
              <Form>
                <div className="content">
                  <div className="fields">
                    <label>
                      Select Company name{" "}
                      <span style={{ color: "red", fontSize: "15px" }}>*</span>
                    </label>
                    <Select
                      prefixCls="inner-select2"
                      // className="inner-select2"
                      value={values?.company_id}
                      loading={loading}
                      onPopupScroll={handleScroll}
                      showSearch
                      allowClear
                      suffixIcon={false}
                      placeholder="Select Company"
                      onChange={(e, data) => setFieldValue("company_id", data)}
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      options={companyList}
                    />
                    {loading ? (
                      <div className="img-icon">
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 14,
                              }}
                              spin
                              color="red"
                            />
                          }
                        />
                      </div>
                    ) : (
                      <div className="img-icon">
                        <DropDownIcon />
                      </div>
                    )}
                    {errors?.type && touched?.type && (
                      <ErrorMessage message={errors?.type} />
                    )}
                  </div>
                </div>
                <div className="btn-wrapper">
                  <button onClick={handleClose}>CANCEL</button>

                  <button className="btn-save" type="submit">
                    APPLY
                  </button>
                </div>
              </Form>
            )}
          />
        </div>
      </AddCompanyWrapper>
    </Modal>
  );
}

export default YelpFilterModal;

const AddCompanyWrapper = styled.div`
  width: 100%;
  padding: 24px;
  max-height: 96vh;
  padding-left: 20px;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #fff;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #00000045;
    border-radius: 10px;
  }

  .title {
    h3 {
      color: #2c2c2c;
      font-family: "Poppins";
      font-size: 23px;
    }
  }

  .content {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 14px;

    .fields {
      display: flex;
      flex-direction: column;
      gap: 6px;
      position: relative;

      .img-icon {
        position: absolute;
        right: 20px;
        top: 45px;
        cursor: pointer;
        ${
          "" /* svg {
          filter: ${({ themeValue }) =>
            themeValue === "day" ? "invert(0)" : "invert(1)"};
        } */
        }
      }

      label {
        color: #2c2c2c;
        font-family: "Poppins";
        font-size: 13px;
      }
      .input {
        background: transparent;
        border: 1px solid #cecece;
        border-radius: 10px;
        color: #000;
        &:focus {
          outline: none;
        }
      }

      .textarea {
        background: #000;
        border: 1px solid #cecece;
        border-radius: 10px;
        width: 100% !important;
        height: 64px !important;
        color: #fff;
        resize: none;
        padding: 10px;
        font-family: "Poppins" !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        &::placeholder {
          color: #535e6d;
          font-family: "Poppins" !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
  .inner-select2 {
    color: #535e6d;
    font-family: "Poppins" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    appearance: none;
    height: 50px;
    width: 100%;
    padding: 0px 0px;
    border-radius: 10px;
    ${"" /* border: 1px solid #cecece !important; */}

    .inner-select2-selection-overflow {
      margin-top: -4px !important;
      margin-left: 11px !important;
    }
  }

  .time-picker {
    color: #535e6d;
    font-family: "Poppins" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    height: 50px;
    width: 100%;
    padding: 0px 0px;
    border-radius: 10px;
    border: 1px solid #cecece !important;
    display: flex !important;
    justify-content: space-around !important;
    background-color: #000;
  }
  .btn-wrapper {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 26px;

    @media (max-width: 480px) {
      flex-direction: column-reverse;
      gap: 10px;
    }

    button {
      width: 100%;
      display: flex;
      height: 48px;
      padding: 21px 24px;
      justify-content: center;
      align-items: center;
      background: transparent;
      gap: 8px;
      border-radius: 10px;
      border: 1px solid #2c2c2c;
      color: #2c2c2c;
      text-align: center;
      cursor: pointer;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      letter-spacing: 0.2px;
    }

    .btn-save {
      background: rgb(36, 36, 36);
      color: #fff;
      border: none;
    }
  }
  .switch-wrap {
    display: flex;
  }
`;
