import { Input, message, Select } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { add_categaries_api } from "../../services/Collections";
import { useSelector } from "react-redux";

function PaidAddCategoryModal({
  handleCancel,
  handleOk,
  isModalOpen,
  handleGetCategory,
}) {
  const [catName, setCatName] = useState(null);
  const [sourceType, setSourceType] = useState([]);
  const [loading, setLoading] = useState(false);

  const prefilledFilters = useSelector((state) => state?.lsaPerformance);
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );

  const socialOptions = [
    { label: "Paid Search", value: "paid" },
    { label: "LSA Performance", value: "lsa" },
    { label: "Paid Social", value: "social" },
    { label: "Yelp", value: "yelp" },
  ];

  const handleAddCategory = async () => {
    if (catName == null || catName?.trim() == "") {
      message.warning("Please enter the categories name");
      return;
    }
    if (sourceType?.length <= 0) {
      message.warning("Please select the sources");
      return;
    }
    setLoading(true);
    const activeClient =
      prefilledFilters?.selectedClient == null
        ? prefilledData?.selectedClient?.key
        : prefilledFilters?.selectedClient?.key;

    let payload = {
      paid_search_name: catName,
      source_types: sourceType,
    };

    const res = await add_categaries_api(activeClient, payload);
    if (res?.status == 201 || res?.status == 200) {
      message.success(res?.message || "Category Added successfully");
      handleCancel();
      handleGetCategory();
      setLoading(false);
    } else {
      message.error("Something went wrong!");
      setLoading(false);
    }
    setCatName(null);
    setSourceType([]);
  };

  return (
    <Wrapperpaidcategorymodal>
      <p className="Add-Category">Add Category</p>

      <div className="select-wrap">
        <label className="label-hadding">Category Name</label>
        <br />
        <Input
          onChange={(e) => setCatName(e?.target?.value)}
          prefixCls="psdadad"
          style={{
            width: "100%",
            height: "42px",
            marginTop: "6px",
          }}
          placeholder="Enter Name"
        />
      </div>

      <div className="select-wrap">
        <label className="label-hadding">Sources</label>
        <br />
        <Select
          prefixCls="inner-select2"
          onChange={(_, data) => setSourceType(data?.map((ele) => ele?.value))}
          style={{
            width: "100%",
            height: "42px",
            marginTop: "6px",
          }}
          maxTagCount={1}
          placeholder="select Sources"
          options={socialOptions}
          mode="multiple"
        />
      </div>

      <button onClick={handleAddCategory} className="add-button">
        {loading ? "Loading..." : "Add"}
      </button>
    </Wrapperpaidcategorymodal>
  );
}

export default PaidAddCategoryModal;

const Wrapperpaidcategorymodal = styled.div`
  .Add-Category {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }
  .label-hadding {
    font-size: 16px;
    font-weight: 400;
  }

  .add-button {
    width: 100%;
    height: 42px;
    padding: 10.5px 12px 10.5px 12px;
    gap: 10px;
    border-radius: 10px;
    background: #ed0101;
    border-style: none;
    color: white;
    margin-top: 16px;
  }
    .select-wrap{
     margin-top: 24px;
    }

  input {
    &::placeholder {
      color: #d1d1d1 !important;
      opacity: 1;
    }
  }
`;
