import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedClient: null,
};

export const BudgetTrckerSlice = createSlice({
  name: "BudgetTrckerSlice",
  initialState,

  reducers: {
    updateBudgetTrckerClient: (state, action) => {
      if (action) {
        state.selectedClient = action.payload;
      }
    },
    resetBudgetTrckerSlice: () => initialState,
  },
});

export const { updateBudgetTrckerClient, resetBudgetTrckerSlice } =
  BudgetTrckerSlice.actions;
export default BudgetTrckerSlice.reducer;
