import { InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { formatToCurrencyWithDecimal } from "../../exceltable/commonFun";
import {
  calculateTotals,
  checkForInfinity,
  formatInteger,
  formatNumberDecimal,
} from "./commonFuc";

export default function TotalComponent({
  data,
  activeTab,
  setActiveTab,
  name,
}) {
  const [totalData, setTotalData] = useState({
    current: null,
    duplicate: null,
  });
  const { current, duplicate } = totalData;
  useEffect(() => {
    if (data && data?.length > 0) {
      const parentArray = data?.filter((cat) => cat?.isParent);
      const parentTotal = calculateTotals(parentArray);
      // const duplicateArray = data?.reverse()?.filter((cat) => cat?.isChecked);
      let category = null;
      let duplicateArray = [];

      data?.forEach((cat, index) => {
        if (cat?.isChecked || cat?.isParent) {
          if (category && cat?.category_name !== category?.category_name) {
            duplicateArray.push(category);
          }
          category = cat;
        }
        if (index === data.length - 1 && category) {
          duplicateArray.push(category);
        }
      });
      const duplicateTotal = calculateTotals(duplicateArray);
      setTotalData({ current: parentTotal, duplicate: duplicateTotal });
    }
  }, [data]);

  return (
    <TotalWrapper>
      <div className="all-tabs">
        <button
          type="button"
          className="active"
        // className={activeTab == "current" && "active"}
        // onClick={() => setActiveTab("current")}
        >
          Current Total
        </button>
        <button
          type="button"
              className="active"
          // className={activeTab == "duplicate" && "active"}
          // onClick={() => setActiveTab("duplicate")}
        >
          Projection Total
        </button>
      </div>

      {/* {current && activeTab === "current" && ( */}
      <Container>
        <AdditionalMetrics>
          <Metric>
            <MetricLabel>Budget</MetricLabel>
            <MetricValue>${formatInteger(current?.budget)}</MetricValue>
          </Metric>
          <Metric>
            <MetricLabel>Revenue</MetricLabel>
            <MetricValue>${formatInteger(current?.sales)}</MetricValue>
          </Metric>
          <Metric>
            <MetricLabel>ROAS</MetricLabel>
            <MetricValue>
              {formatNumberDecimal(
                checkForInfinity(current?.sales / current?.budget)
              )}
            </MetricValue>
          </Metric>
          <Metric>
            <MetricLabel>Leads</MetricLabel>
            <MetricValue>{formatInteger(current?.leads)}</MetricValue>
          </Metric>
          <Metric>
            <MetricLabel>Appts</MetricLabel>
            <MetricValue>{formatInteger(current?.appts)}</MetricValue>
          </Metric>
          <Metric>
            <MetricLabel>CPA</MetricLabel>
            <MetricValue>
              $
              {formatInteger(
                checkForInfinity(current?.budget / current?.appts)
              )}
            </MetricValue>
          </Metric>
          <Metric>
            <MetricLabel>Jobs</MetricLabel>
            <MetricValue>{formatInteger(current?.jobs)}</MetricValue>
          </Metric>
          <Metric>
            <MetricLabel>CPJ</MetricLabel>
            <MetricValue>
              $
              {formatInteger(checkForInfinity(current?.budget / current?.jobs))}
            </MetricValue>
          </Metric>

          <Metric>
            <MetricLabel>CPL</MetricLabel>
            <MetricValue>
              $
              {formatInteger(
                checkForInfinity(current?.budget / current?.leads)
              )}
            </MetricValue>
          </Metric>

          <Metric>
            <MetricLabel>Conv%</MetricLabel>
            <MetricValue>
              {formatInteger(
                checkForInfinity(current?.appts / current?.leads) * 100
              )}
              %
            </MetricValue>
          </Metric>

          <Metric>
            <MetricLabel>Close%</MetricLabel>
            <MetricValue>
              {formatInteger(
                checkForInfinity(current?.jobs / current?.appts) * 100
              )}
              %
            </MetricValue>
          </Metric>

          <Metric>
            <MetricLabel>Avg. Ticket</MetricLabel>
            <MetricValue>
              ${formatInteger(checkForInfinity(current?.sales / current?.jobs))}
            </MetricValue>
          </Metric>
        </AdditionalMetrics>

        <AdditionalMetrics>
          <Metric>
            <MetricLabel>Budget</MetricLabel>
            <MetricValue>${formatInteger(duplicate?.budget)}</MetricValue>
          </Metric>
          <Metric>
            <MetricLabel>Revenue</MetricLabel>
            <MetricValue>${formatInteger(duplicate?.sales)}</MetricValue>
          </Metric>
          <Metric>
            <MetricLabel>ROAS</MetricLabel>
            <MetricValue>
              {formatNumberDecimal(
                checkForInfinity(duplicate?.sales / duplicate?.budget)
              )}
            </MetricValue>
          </Metric>
          <Metric>
            <MetricLabel>Leads</MetricLabel>
            <MetricValue>{formatInteger(duplicate?.leads)}</MetricValue>
          </Metric>
          <Metric>
            <MetricLabel>Appts</MetricLabel>
            <MetricValue>{formatInteger(duplicate?.appts)}</MetricValue>
          </Metric>
          <Metric>
            <MetricLabel>CPA</MetricLabel>
            <MetricValue>
              $
              {formatInteger(
                checkForInfinity(duplicate?.budget / duplicate?.appts)
              )}
            </MetricValue>
          </Metric>
          <Metric>
            <MetricLabel>Jobs</MetricLabel>
            <MetricValue>{formatInteger(duplicate?.jobs)}</MetricValue>
          </Metric>
          <Metric>
            <MetricLabel>CPJ</MetricLabel>
            <MetricValue>
              $
              {formatInteger(
                checkForInfinity(duplicate?.budget / duplicate?.jobs)
              )}
            </MetricValue>
          </Metric>

          <Metric>
            <MetricLabel>CPL</MetricLabel>
            <MetricValue>
              $
              {formatInteger(
                checkForInfinity(duplicate?.budget / duplicate?.leads)
              )}
            </MetricValue>
          </Metric>

          <Metric>
            <MetricLabel>Conv%</MetricLabel>
            <MetricValue>
              {formatInteger(
                checkForInfinity(duplicate?.appts / duplicate?.leads) * 100
              )}
              %
            </MetricValue>
          </Metric>

          <Metric>
            <MetricLabel>Close%</MetricLabel>
            <MetricValue>
              {formatInteger(
                checkForInfinity(duplicate?.jobs / duplicate?.appts) * 100
              )}
              %
            </MetricValue>
          </Metric>

          <Metric>
            <MetricLabel>Avg. Ticket</MetricLabel>
            <MetricValue>
              $
              {formatInteger(
                checkForInfinity(duplicate?.sales / duplicate?.jobs)
              )}
            </MetricValue>
          </Metric>
        </AdditionalMetrics>
      </Container>
      {/* )} */}

      {/* {duplicate && activeTab === "duplicate" && ( */}
      <Container></Container>
      {/* )} */}
     
    </TotalWrapper>
  );
}

const TotalWrapper = styled.div`
  background-color: #373737;
  padding: 10px 12px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-left: 10px;
      margin-bottom:10px;

  .all-tabs {
    border: 1px solid #d2d4de;
    border-radius: 6px;
    padding: 2px;
    display: flex;
    gap: 5px;
    background: white;
    margin-bottom: 10px;

    button {
      background: #f6f9fc;
      width: 100%;
      text-align: center;
      padding: 5px 20px;
      color: #000;
      border: none;
      border-left: 2px solid white;
      height: 33px;
      font-size: 14px;
      font-family: Poppins;
      font-style: normal;
      border-radius: 6px;
      transition: background 0.3s ease, color 0.3s ease, transform 0.2s ease;
      text-transform: capitalize;
      // &:hover {
      //   transform: scale(1.05);
      // }
    }

    .active {
      background: #979797;
      color: #fff;
      transition: background 0.3s ease, color 0.3s ease;
    }
  }
`;

const Container = styled.div`
  display: flex;
  // flex-direction: column;
  gap: 10px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CategoryName = styled.h2`
  color: #fff;
  margin: 0;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
`;

const MetricsContainer = styled.div`
  display: flex;
  gap: 16px;
  height: 46px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 9px;
  background-color: #f4f4f41a;
`;

const MetricInput = styled.input`
  border: none;
  background-color: #ffffff1f;
  color: #fff;
  width: 150px;

  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  padding: 9px 10px;
  border-radius: 6px;
`;

const Label = styled.span`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #fff;
  padding-right: 4px;
`;

const AdditionalMetrics = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  background-color: transparent;
  border-radius: 8px;
`;

const Metric = styled.div`
  width: 100%;
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  gap: 0px;
  border-radius: 12px;
  background: #ffffff1f;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.3s ease;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  @media (min-height: 1080px) {
    padding: 12px 10px;
  }
`;

const MetricLabel = styled.span`
  color: #fff;
  // margin-bottom: 5px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
`;

const MetricValue = styled.span`
  color: #fff;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  text-align: center;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledInputNumber = styled(InputNumber)`
  margin-bottom: 0;
  .ant-input-number {
    border: none;
    background-color: red;
    width: 100px;
    .ant-input-number-input {
      color: #2863e3;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      padding: 9px 10px;
      border: 1px solid #d2d4de;
      border-radius: 6px;
    }
  }
`;
