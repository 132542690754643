import { Checkbox, DatePicker, Select } from "antd";
import dayjs from "dayjs";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as yup from "yup";
import { reportTableFilter } from "../../app/Auth/signIn/LogInSlice";
import {
  filterClientsBySection,
  thirtyDayAgoDate,
} from "../../utils/common/commonFunction";
import RangeSelector from "../../components/RangePicker";

const customerTypes = [
  {
    label: "All Customers",
    value: "all",
  },
  {
    label: "New Customer",
    value: "new",
  },
];

const defaultInit = {
  date_start: "",
  date_end: "",
  date_new_cus_start: "",
  customer_type: "",
  selectedClient: {},
};

const validationSchema = yup.object().shape({
  date_end: yup.date().when("date_start", (date_start, schema) => {
    if (date_start && date_start[0]) {
      return (
        date_start[0] &&
        schema.min(date_start[0], "End date must be greater than start date")
      );
    }
    return schema.required("End Date is required");
  }),
});

function FilterForm({
  handleFilter,
  initValues = defaultInit,
  filterLoading,
  showMarketing,
  setFilterDrawer,
}) {
  const [clientOptions, setclientOptions] = useState([]);
  const clientOptionsData = useSelector(
    (state) => state?.loginAuth?.clientsList
  );
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );
  const updatedData = useSelector(
    (state) => state?.loginAuth?.reportTableSectionFilters
  );
  const [startDate, setStartDate] = useState(
    updatedData?.date_start || prefilledData?.date_start
  );
  const [endDate, setEndDate] = useState(
    updatedData?.date_end || prefilledData?.date_end
  );
  const [newCustomerDate, setNewCustomerDate] = useState(
    updatedData?.date_new_cus_start ||
      thirtyDayAgoDate(prefilledData?.date_start)
  );
  const [customerOnlyMarketing, setCustomerOnlyMarketing] = useState(
    updatedData?.customer_type || "all"
  );
  let initialJobCompleted =
    updatedData?.only_completed_jobs == undefined
      ? true
      : updatedData?.only_completed_jobs;
  const [isCompletedJobs, setIsCompletedJobs] = useState(initialJobCompleted);
  const [selectedClient, setSelectedClient] = useState(
    updatedData?.selectedClient || prefilledData?.selectedClient
  );
  const [checkboxStatus, setCheckboxStatus] = useState(
    updatedData?.checkboxVal || showMarketing
  );
  const dispatch = useDispatch();
  const [dateError, setDateError] = useState(false);

  const getClientOption = () => {
    let arr = [];
    filterClientsBySection(clientOptionsData?.clients, "report-table")?.length >
      0 &&
      filterClientsBySection(clientOptionsData?.clients, "report-table")?.map(
        (ele) => {
          let obj = {
            ...ele,
            label: ele?.name,
            value: ele?.name,
          };
          arr.push(obj);
        }
      );
    setclientOptions(arr);
  };

  useEffect(() => {
    getClientOption();
  }, []);

  const DateValidation = (start, end) => {
    if (dateError) {
      if (moment(start) > moment(end)) {
        setDateError(true);
      } else {
        setDateError(false);
      }
    }
  };

  const handleForm = () => {
    if (moment(startDate) > moment(endDate)) {
      setDateError(true);
      return;
    }
    if (
      Array.isArray(selectedClient)
        ? selectedClient?.length > 0
        : selectedClient
    ) {
      if (
        endDate &&
        startDate &&
        selectedClient &&
        newCustomerDate &&
        customerOnlyMarketing
      ) {
        let reauestedPayload = {
          date_end: endDate,
          date_start: startDate,
          selectedClient: Array.isArray(selectedClient)
            ? selectedClient
            : [selectedClient],
          date_new_cus_start: newCustomerDate,
          customer_type: customerOnlyMarketing,
          checkboxVal: checkboxStatus,
          only_completed_jobs: isCompletedJobs,
        };
        dispatch(reportTableFilter(reauestedPayload));
        handleFilter(reauestedPayload, checkboxStatus);
        // getDataTable(reauestedPayload);
        setFilterDrawer(false);
      } else {
        toast.warning("Please fill required fields");
      }
    } else {
      toast.warning("Please fill required fields");
    }
  };

  return (
    <FilterWrapper>
      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={handleForm}
      >
        <Form>
          <div className="campaign-filters">
            <div className="title">
              <h4 style={{ color: "rgba(0, 0, 0, 1)" }}>Filters</h4>
            </div>

            <div className="content">
              <div className="datepicker-box">
                <div className="input-box">
                  <label>
                    <strong>Client</strong>
                  </label>
                  <br />
                  <Select
                    size="large"
                    mode="multiple"
                    prefixCls="inner-select2"
                    onChange={(value, data) => {
                      if (value.length <= 12) {
                        setSelectedClient(data);
                      } else {
                        toast.warning("You can only select up to 12 options.");
                      }
                    }}
                    style={{
                      width: "100%",
                      height: "48px",
                      marginTop: "6px",
                    }}
                    maxTagCount={1}
                    placeholder="Please select"
                    options={clientOptions}
                    value={selectedClient}
                    allowClear={false}
                  />
                </div>

                <div style={{ marginTop: "20px" }} className="input-box">
                  <label>
                    <strong>Select Date</strong>
                  </label>{" "}
                  <RangeSelector
                    defaultDate={[
                      startDate
                        ? moment(startDate, "yyyy-MM-DD").format("yyyy-MM-DD")
                        : null,
                      endDate
                        ? moment(endDate, "yyyy-MM-DD").format("yyyy-MM-DD")
                        : null,
                    ]}
                    handleChange={(d) => {
                      const start = d[0];
                      const end = d[1];
                      setStartDate(start);
                      setNewCustomerDate(thirtyDayAgoDate(start));
                      setEndDate(end);
                    }}
                  />
                </div>

                {/* <div style={{ marginTop: "20px" }} className="input-box">
                  <label>
                    <strong>Start Date</strong>
                  </label>
                  <DatePicker
                    value={startDate ? dayjs(startDate) : ""}
                    prefixCls="customDatepicker"
                    allowClear={false}
                    onChange={(_, date) => {
                      setStartDate(date);
                      setNewCustomerDate(thirtyDayAgoDate(date));
                      DateValidation(date, endDate);
                    }}
                  />
                </div>

                <div style={{ marginTop: "20px" }} className="input-box">
                  <label>
                    <strong>End Date</strong>
                  </label>
                  <DatePicker
                    value={endDate ? dayjs(endDate) : ""}
                    prefixCls="customDatepicker"
                    allowClear={false}
                    onChange={(_, date) => {
                      DateValidation(startDate, date);
                      setEndDate(date);
                    }}
                  />
                  {dateError && (
                    <span className="error-msg">
                      End date must be greater than start date
                    </span>
                  )}
                </div> */}

                <div style={{ marginTop: "20px" }} className="input-box">
                  <label>
                    <strong>New Customer Start Date</strong>
                  </label>
                  <DatePicker
                    value={newCustomerDate ? dayjs(newCustomerDate) : ""}
                    prefixCls="customDatepicker"
                    onChange={(_, date) => {
                      setNewCustomerDate(date);
                    }}
                  />
                </div>

                <div style={{ marginTop: "20px" }} className="input-box">
                  <label>
                    <strong>Customer (Only for Marketing Sources)</strong>
                  </label>
                  <br />
                  <Select
                    size="large"
                    prefixCls="inner-select2"
                    onChange={(value) => {
                      setCustomerOnlyMarketing(value);
                    }}
                    defaultValue={customerOnlyMarketing}
                    style={{
                      width: "100%",
                      height: "48px",
                      marginTop: "6px",
                    }}
                    maxTagCount={1}
                    placeholder="Please Select"
                    options={customerTypes}
                  />
                </div>

                <div style={{ marginTop: "20px" }} className="input-box">
                  <Checkbox
                    style={{ color: "white" }}
                    onChange={(e) => setCheckboxStatus(e.target.checked)}
                    checked={checkboxStatus}
                  >
                    <strong style={{ color: "rgba(0, 0, 0, 1)" }}>
                      Marketing Roas
                    </strong>
                  </Checkbox>
                </div>
                <div style={{ marginTop: "20px" }} className="input-box">
                  <Checkbox
                    style={{ color: "white" }}
                    onChange={(e) => setIsCompletedJobs(e.target.checked)}
                    checked={isCompletedJobs}
                  >
                    <strong style={{ color: "rgba(0, 0, 0, 1)" }}>
                      Only Completed Jobs
                    </strong>
                  </Checkbox>
                </div>

                <div className="button-wrap">
                  {filterLoading ? (
                    <button type="button">
                      <b>Loading...</b>
                    </button>
                  ) : (
                    <button type="submit">
                      <b>Apply</b>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </FilterWrapper>
  );
}

export default FilterForm;

const FilterWrapper = styled.div`
  .error-msg {
    color: red;
    font-size: 12px;
    padding: 3px 8px;
  }
`;
