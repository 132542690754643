import { ConfigProvider, Modal, Select, Spin, Switch } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import InputField from "../../validation/inputField";
import { DropDownIcon } from "../../utils/Icons/SvgIcons";
import {
  aiCompanyList,
  createAdminUser,
  getAllClients,
  updateAdminUser,
} from "../../services/Collections";
import { toast } from "react-toastify";
import ErrorMessage from "../../utils/ErrorMessage";
import { NoDataDropdown } from "../../components/common/NoDataDropdown";

const permissonOptions = [
  {
    label: "Dashboard",
    value: "dashboard",
  },
  {
    label: "Marketing Tracker",
    value: "data-table",
  },

  {
    label: "Attribution Analyzer",
    value: "campaign-table",
  },
  {
    label: "Performance Metrics",
    value: "report-table",
  },
  {
    label: "Visualization",
    value: "visualization",
  },
  {
    label: "Call classification",
    value: "call-classification",
  },
  {
    label: "Dynamic Budget Sheet",
    value: "dynamic-budget-sheet",
  },
  {
    label: "Paid + LSA Perf.",
    value: "paid-search",
  },
  {
    label: "Get Leverage",
    value: "ai-booking-stats",
  },
  // {
  //   label: "Yelp Users",
  //   value: "yelp-users",
  // },
  {
    label: "Admin Users",
    value: "admin-users",
  },
  {
    label: "Settings",
    value: "settings",
  },
  {
    label: "Quality Assurance",
    value: "quality-assurance",
  },
  {
    label: "Capacity Marketing",
    value: "capacity-marketing",
  },
  // {
  //   label: "Notification Center",
  //   value: "notification-center",
  // },
  {
    label: "Budget Tracker",
    value: "budget-tracker",
  },
];

function CreateAdminUser({
  open,
  handleClose,
  modalType,
  editDetails,
  listApi,
  handleLogoutUsers,
}) {
  const [loading, setLoading] = useState(false);

  const userDetails = useSelector((state) => state?.LoginSlice?.data);
  const dispatch = useDispatch();
  const clientOptionsData = useSelector((state) => state?.loginAuth?.data);
  const [clientOptions, setclientOptions] = useState([]);
  const [companyloading, setCompanyLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(false);

  console.log(clientOptions, "clientOptions");

  const initialValues = {
    email: editDetails?.email ? editDetails?.email : "",
    password: editDetails?.password ? "******" : "",
    active: editDetails?.active ? editDetails?.active : 0,
    allocated_db: editDetails?.allocated_db ? editDetails?.allocated_db : [],
    allocated_boards: editDetails?.allocated_boards
      ? editDetails?.allocated_boards
      : [],
    role: editDetails?.role ? editDetails?.role : "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is required"),
    password: yup.string().required("Password is required").min(3),
    allocated_db: yup
      .array()
      .of(yup.string())
      .required("At least one client must be selected")
      .min(1, "At least one client must be selected"),
    allocated_boards: yup
      .array()
      .of(yup.string())
      .required("At least one permission tab must be selected")
      .min(1, "At least one permission tab must be selected"),
    // role: yup.string().required("Role is required"),
  });

  const editValidationSchema = yup.object().shape({
    allocated_db: yup
      .array()
      .of(yup.string())
      .required("At least one client must be selected")
      .min(1, "At least one client must be selected"),
    allocated_boards: yup
      .array()
      .of(yup.string())
      .required("At least one permission tab must be selected")
      .min(1, "At least one permission tab must be selected"),
  });

  const handleClientOptions = async () => {
    setOptionsLoading(true);
    let res = await getAllClients();
    if (res?.status === 200) {
      let arr = [];
      res?.data?.length > 0 &&
        // eslint-disable-next-line array-callback-return
        res?.data?.map((ele) => {
          let obj = {
            ...ele,
            label: ele?.name,
            value: ele?.db_name,
          };
          arr.push(obj);
        });
      setclientOptions(arr);
      setOptionsLoading(false);
    } else {
      console.log(res);
      setOptionsLoading(false);
    }
  };


  const handleSubmit = async (values) => {
    setLoading(true);
    const requestPayload = {
      ...values,
      role: "user",
    };
    const res = await createAdminUser(requestPayload);
    if (res?.status === 200 || res?.status === 201) {
      handleClose();
      listApi();
      setLoading(false);
      toast.success("User Created Successfully");
    } else {
      console.log(res, "resres");

      const message =
        res?.response?.data?.message ||
        res?.message ||
        res?.error ||
        "Something went wrong";
      if (res?.response?.status != 401) {
        toast.error(message);
      }
      setLoading(false);
    }
  };

  const handleUpdateData = async (values) => {
    setLoading(true);
    const requestPayload = {
      active: values?.active,
      allocated_db: values?.allocated_db,
      allocated_boards: values?.allocated_boards,
    };
    const res = await updateAdminUser(editDetails?.id, requestPayload);
    if (res?.status === 200 || res?.status === 201) {
      handleClose();
      listApi();
      setLoading(false);
      handleLogoutUsers(true);
      toast.success("User updated successfully");
    } else {
      const message =
        res?.response?.data?.message ||
        res?.message ||
        res?.error ||
        "Something went wrong";
      if (res?.response?.status != 401) {
        toast.error(message);
      }
      setLoading(false);
    }
  };


  useEffect(() => {
    handleClientOptions();
  }, []);

  return (
    <Modal
      open={open}
      onCancel={loading ? null : handleClose}
      centered
      footer={false}
      closeIcon={<span />}
      prefixCls="add-company"
    >
      <AddCompanyWrapper>
        <div className="title">
          <h3>{modalType == "edit" ? "Edit" : "Create"} Admin User</h3>
        </div>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={
              modalType === "edit" ? editValidationSchema : validationSchema
            }
            onSubmit={modalType === "edit" ? handleUpdateData : handleSubmit}
            render={({ setFieldValue, values, errors, touched }) => (
              <Form>
                <div className="content">
                  {modalType === "add" && (
                    <div className="fields">
                      <label>
                        Email{" "}
                        <span style={{ color: "red", fontSize: "15px" }}>
                          *
                        </span>
                      </label>
                      <Field
                        className="input"
                        name="email"
                        type="text"
                        placeholder="Enter Email"
                        component={InputField}
                      />
                    </div>
                  )}
                  {modalType === "add" && (
                    <div className="fields">
                      <label>
                        Password{" "}
                        <span style={{ color: "red", fontSize: "15px" }}>
                          *
                        </span>
                      </label>
                      <Field
                        className="input"
                        name="password"
                        type="text"
                        placeholder="Enter password"
                        component={InputField}
                      />
                    </div>
                  )}
                
                  <div className="fields">
                    <label>
                      Select Client{" "}
                      <span style={{ color: "red", fontSize: "15px" }}>*</span>
                    </label>
                    <Select
                      size={"large"}
                      mode="multiple"
                      value={optionsLoading ? [] : values.allocated_db}
                      prefixCls="inner-select2"
                      loading={optionsLoading}
                      maxTagCount={1}
                      style={{
                        width: "100%",
                      }}
                      showSearch
                      allowClear
                      placeholder="Select Client"
                      onChange={(data) => setFieldValue("allocated_db", data)}
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      options={clientOptions}
                      notFoundContent={
                        optionsLoading ? (
                          <NoDataDropdown text={"Processing..."} />
                        ) : (
                          <NoDataDropdown text={"No data"} />
                        )
                      }
                    />

                    {errors?.allocated_db && touched?.allocated_db && (
                      <ErrorMessage message={errors?.allocated_db} />
                    )}
                  </div>
                  <div className="fields">
                    <label>
                      Select Permissions{" "}
                      <span style={{ color: "red", fontSize: "15px" }}>*</span>
                    </label>
                    <Select
                      size={"large"}
                      mode="multiple"
                      prefixCls="inner-select2"
                      value={values.allocated_boards}
                      maxTagCount={1}
                      style={{
                        width: "100%",
                      }}
                      showSearch
                      allowClear
                      placeholder="Select Permissions"
                      onChange={(data) =>
                        setFieldValue("allocated_boards", data)
                      }
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      options={permissonOptions}
                    />

                    {errors?.allocated_boards && touched?.allocated_boards && (
                      <ErrorMessage message={errors?.allocated_boards} />
                    )}
                  </div>

                  <div className="switch-wrap">
                    <div style={{ width: "100%" }} className="fields">
                      <label>Is Active</label>
                      <ConfigProvider
                        theme={{
                          components: {
                            Switch: {
                              colorPrimary: "#296eff",
                              handleBg: "#fff",
                              colorPrimaryHover: "#296eff",
                              colorTextQuaternary: "#2c2c2c",
                              colorTextTertiary: "#2c2c2c",
                            },
                          },
                        }}
                      >
                        <Switch
                          prefixCls="custom-switch"
                          style={{ width: "50px" }}
                          value={values?.active === 1 ? true : false}
                          checked={values?.active === 1 ? true : false}
                          onChange={(e) =>
                            setFieldValue("active", e === true ? 1 : 0)
                          }
                        />
                      </ConfigProvider>
                    </div>
                  </div>
                </div>
                <div className="btn-wrapper">
                  <button onClick={loading ? null : handleClose}>CANCEL</button>

                  {loading ? (
                    <button className="btn-save">Loading...</button>
                  ) : (
                    <button className="btn-save" type="submit">
                      {modalType == "edit" ? "SAVE" : "CREATE"}
                    </button>
                  )}
                </div>
              </Form>
            )}
          />
        </div>
      </AddCompanyWrapper>
    </Modal>
  );
}

export default CreateAdminUser;

const AddCompanyWrapper = styled.div`
  width: 100%;
  padding: 24px;
  max-height: 95vh;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #fff;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #00000045;
    border-radius: 10px;
  }

  .title {
    h3 {
      color: #2c2c2c;
      font-family: "Poppins";
      font-size: 23px;
    }
  }

  .content {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 14px;

    .fields {
      display: flex;
      flex-direction: column;
      gap: 6px;
      position: relative;

      .img-icon {
        position: absolute;
        right: 20px;
        top: 43px;
        cursor: pointer;
      }

      label {
        color: #2c2c2c;
        font-family: "Poppins";
        font-size: 13px;
      }
      .input {
        background: #fff;
        border: 1px solid #cecece;
        border-radius: 10px;
        color: #000;
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: #cecece !important;
        }
      }

      .textarea {
        background: #000;
        border: 1px solid #cecece;
        border-radius: 10px;
        width: 100% !important;
        height: 64px !important;
        color: #fff;
        resize: none;
        padding: 10px;
        font-family: "Poppins" !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        &::placeholder {
          color: #535e6d;
          font-family: "Poppins" !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
  .inner-select2 {
    color: #535e6d;
    font-family: "Poppins" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    appearance: none;
    height: 50px;
    width: 100%;
    padding: 0px 0px;
    border-radius: 10px;
  }

  .time-picker {
    color: #535e6d;
    font-family: "Poppins" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    height: 50px;
    width: 100%;
    padding: 0px 0px;
    border-radius: 10px;
    border: 1px solid #cecece !important;
    display: flex !important;
    justify-content: space-around !important;
    background-color: #000;
  }
  .btn-wrapper {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 26px;

    @media (max-width: 480px) {
      flex-direction: column-reverse;
      gap: 10px;
    }

    button {
      width: 100%;
      display: flex;
      height: 48px;
      padding: 21px 24px;
      justify-content: center;
      align-items: center;
      background: transparent;
      gap: 8px;
      border-radius: 10px;
      border: 1px solid #2c2c2c;
      color: #2c2c2c;
      text-align: center;
      cursor: pointer;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      letter-spacing: 0.2px;
    }

    .btn-save {
      background: #242424;
      color: #fff;
      border: none;
    }
  }
  .switch-wrap {
    display: flex;
  }
`;
