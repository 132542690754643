import { Collapse, Drawer, Table } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";
import FilterForm from "./FilterForm";
import {
  getDataRoasPerformance,
  getPerformanceList,
} from "../../services/Collections";
import { useDispatch, useSelector } from "react-redux";
import { updateReportData } from "../../app/Auth/signIn/LogInSlice";
import moment from "moment";
import { thirtyDayAgoDate } from "../../utils/common/commonFunction";
import CollapseTable from "./CollapseTable";
import { DropDownIcon } from "../../utils/Icons/SvgIcons";
import Header from "../../components/Header";
import LoadingBlock from "../../components/LoadingBlock";
import Watermark from "../../components/Watermark";

const { Panel } = Collapse;

const TextTag = styled.p`
  text-align: center;
  width: 100%;
  margin: 0;
`;

const valueFormatter = (num, digits) => {
  digits = digits ?? 2;
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let item = lookup
    ?.slice()
    ?.reverse()
    ?.find((item) => num >= item.value);

  // Handle numbers less than 1 separately
  if (num < 1 && num > 0) {
    return num && num?.toFixed(digits)?.replace(rx, "$1");
  }

  return item
    ? (num / item?.value)?.toFixed(digits)?.replace(rx, "$1") + item?.symbol
    : "0";
};

const columns = [
  {
    title: "Business Unit",
    dataIndex: "bunit_name",
    width: 150,
    fixed: "left",
  },
  {
    title: "Completed Jobs",
    dataIndex: "completed_jobs",
    width: 150,
    render: (_, record) => <FlexCenter>{record?.completed_jobs}</FlexCenter>,
  },

  {
    title: (
      <p style={{ margin: 0 }}>
        {" "}
        Existing Customer <br /> (Rev./ Jobs/ Avg Rev.){" "}
      </p>
    ),
    dataIndex: "existing_customer_rev",
    width: 180,
    render: (_, record) => (
      <FlexCenter>
        {"$" +
          valueFormatter(record?.existing_customer_rev, 2) +
          " / " +
          valueFormatter(record?.existing_customer_jobs, 2) +
          " / " +
          "$" +
          valueFormatter(record?.existing_customer_avg_rev, 2)}
      </FlexCenter>
    ),
  },

  {
    title: (
      <p style={{ margin: 0 }}>
        {" "}
        New Customer <br /> (Rev./ Jobs/ Avg Rev.){" "}
      </p>
    ),
    dataIndex: "new_customer_rev",
    key: "9",
    width: 180,
    render: (_, record) => (
      <FlexCenter>
        ${valueFormatter(record?.new_customer_rev, 2) ?? 0} /{" "}
        {valueFormatter(record?.new_customer_jobs, 2)} / $
        {valueFormatter(record?.new_customer_avg_rev, 2) ?? 0}
      </FlexCenter>
    ),
  },

  {
    title: (
      <p style={{ margin: 0 }}>
        {" "}
        Scheduled <br /> Jobs{" "}
      </p>
    ),
    dataIndex: "scheduled_jobs",
    key: "15",
    width: 120,
    render: (_, record) => (
      <FlexCenter>{valueFormatter(record?.scheduled_jobs, 2) ?? 0}</FlexCenter>
    ),
  },
  {
    title: (
      <p style={{ margin: 0 }}>
        {" "}
        Canceled <br /> Jobs{" "}
      </p>
    ),
    dataIndex: "canceled_jobs",
    key: "16",
    width: 120,
    render: (_, record) => (
      <FlexCenter>{valueFormatter(record?.canceled_jobs, 2) ?? 0}</FlexCenter>
    ),
  },
  {
    title: (
      <p style={{ margin: 0 }}>
        {" "}
        Canceled <br /> Jobs %
      </p>
    ),
    dataIndex: "canceled_job_percentage",
    key: "17",
    width: 120,
    render: (_, record) => (
      <FlexCenter>
        {valueFormatter(record?.canceled_job_percentage * 100, 2) ?? 0}%
      </FlexCenter>
    ),
  },
  {
    title: (
      <p style={{ margin: 0 }}>
        {" "}
        Total Generated <br /> Revenue
      </p>
    ),
    dataIndex: "generated_rev",
    key: "18",
    width: 150,
    render: (_, record) => (
      <FlexCenter>${valueFormatter(record?.generated_rev, 2) ?? 0}</FlexCenter>
    ),
  },
  {
    title: "Total Sales",
    dataIndex: "total_sales",
    key: "19",
    width: 110,
    render: (_, record) => (
      <FlexCenter>${valueFormatter(record?.total_sales, 2) ?? 0}</FlexCenter>
    ),
  },
  {
    title: (
      <p style={{ margin: 0 }}>
        {" "}
        Total Completed <br /> Revenue
      </p>
    ),
    dataIndex: "completed_rev",
    key: "20",
    width: 150,
    render: (_, record) => (
      <FlexCenter>${valueFormatter(record?.completed_rev, 2) ?? 0}</FlexCenter>
    ),
  },
  {
    title: "Avg Rev / Customer",
    dataIndex: "gba_per_customer",
    key: "21",
    width: 180,
    render: (_, record) => (
      <FlexCenter>
        ${valueFormatter(record?.gba_per_customer, 2) ?? 0}
      </FlexCenter>
    ),
  },
  {
    title: "Avg Rev / Job",
    dataIndex: "gba_per_job",
    key: "21",
    width: 150,
    render: (_, record) => (
      <FlexCenter>${valueFormatter(record?.gba_per_job, 2) ?? 0}</FlexCenter>
    ),
  },
];

const columns2 = [
  {
    title: "Business Unit",
    dataIndex: "bunit_name",
    // className: "google-group-start"
    style: {
      borderRight: "1px solid #fff",
    },
    width: 150,
    fixed: "left",
  },
  {
    title: "Total",
    className: "google-group columnGroup ",
    children: [
      {
        title: <TextTag>ROAS</TextTag>,
        dataIndex: "marketing_roas",
        key: "24",
        className: "group-child",
        width: 100,
        render: (_, record) => (
          <FlexCenter>
            {valueFormatter(record?.marketing_roas, 2) ?? 0}
          </FlexCenter>
        ),
      },
      {
        title: <TextTag>Cost per Job</TextTag>,
        dataIndex: "marketing_cost_per_job",
        key: "25",
        width: 130,
        render: (_, record) => (
          <FlexCenter>
            ${valueFormatter(record?.marketing_cost_per_job, 2) ?? 0}
          </FlexCenter>
        ),
      },
    ],
  },
  {
    title: "Google",
    className: "google-group columnGroup ",
    children: [
      {
        title: <TextTag>Revenue</TextTag>,
        dataIndex: "google_revenue",
        key: "24",
        className: "group-child",
        width: 100,
        render: (_, record) => (
          <FlexCenter>
            ${valueFormatter(record?.google_revenue, 2) ?? 0}
          </FlexCenter>
        ),
      },
      {
        title: <TextTag>Cost per Job</TextTag>,
        dataIndex: "google_cost_per_job",
        key: "25",
        width: 100,
        render: (_, record) => (
          <FlexCenter>
            ${valueFormatter(record?.google_cost_per_job, 2) ?? 0}
          </FlexCenter>
        ),
      },
      // {
      //   title: <span style={{ whiteSpace: "nowrap" }}>Cost per Lead</span>,
      //   dataIndex: "google_cost_per_lead",
      //   key: "26",
      //   width: 100,
      //   render: (_, record) => (
      //     <FlexCenter>
      //       ${valueFormatter(record?.google_cost_per_lead, 2) ?? 0}
      //     </FlexCenter>
      //   ),
      // },
      {
        title: <TextTag>ROAS</TextTag>,
        dataIndex: "google_roas",
        key: "27",
        width: 100,
        render: (_, record) => (
          <FlexCenter>{valueFormatter(record?.google_roas, 2) ?? 0}</FlexCenter>
        ),
      },
      {
        title: "Total Spend",
        dataIndex: "google_spent",
        key: "28",
        // className: "group-child",
        width: 130,
        render: (_, record) => (
          <FlexCenter>
            ${valueFormatter(record?.google_spent, 2) ?? 0}
          </FlexCenter>
        ),
      },
    ],
  },
  {
    title: "LSA",
    className: "google-group columnGroup",
    children: [
      {
        title: <TextTag>Revenue</TextTag>,
        dataIndex: "lsa_revenue",
        key: "29",
        className: "group-child",
        width: 100,
        render: (_, record) => (
          <FlexCenter>
            ${valueFormatter(record?.lsa_revenue, 2) ?? 0}
          </FlexCenter>
        ),
      },
      {
        title: "Cost per Job",
        dataIndex: "lsa_cost_per_job",
        key: "30",
        width: 150,
        render: (_, record) => (
          <FlexCenter>
            ${valueFormatter(record?.lsa_cost_per_job, 2) ?? 0}
          </FlexCenter>
        ),
      },
      // {
      //   title: <span style={{ whiteSpace: "nowrap" }}>Cost per Lead</span>,
      //   dataIndex: "lsa_cost_per_lead",
      //   key: "31",
      //   width: 100,
      //   render: (_, record) => (
      //     <FlexCenter>
      //       ${valueFormatter(record?.lsa_cost_per_lead, 2) ?? 0}
      //     </FlexCenter>
      //   ),
      // },
      {
        title: <TextTag>ROAS</TextTag>,
        dataIndex: "lsa_roas",
        key: "32",
        width: 100,
        render: (_, record) => (
          <FlexCenter>{valueFormatter(record?.lsa_roas, 2) ?? 0}</FlexCenter>
        ),
      },
      {
        title: "Total Spend",
        dataIndex: "lsa_spent",
        key: "33",
        // className: "group-child",
        width: 130,
        render: (_, record) => (
          <FlexCenter>${valueFormatter(record?.lsa_spent, 2) ?? 0}</FlexCenter>
        ),
      },
    ],
  },
  {
    title: "Facebook",
    className: "google-group columnGroup",
    children: [
      {
        title: <TextTag>Revenue</TextTag>,
        dataIndex: "facebook_revenue",
        key: "34",
        className: "group-child",
        width: 100,
        render: (_, record) => (
          <FlexCenter>
            ${valueFormatter(record?.facebook_revenue, 2) ?? 0}
          </FlexCenter>
        ),
      },
      {
        title: "Cost per Job",
        dataIndex: "facebook_cost_per_job",
        key: "35",
        width: 100,
        render: (_, record) => (
          <FlexCenter>
            ${valueFormatter(record?.facebook_cost_per_job, 2) ?? 0}
          </FlexCenter>
        ),
      },
      // {
      //   title: <span style={{ whiteSpace: "nowrap" }}>Cost per Lead</span>,
      //   dataIndex: "facebook_cost_per_lead",
      //   key: "36",
      //   width: 100,
      //   render: (_, record) => (
      //     <FlexCenter>
      //       ${valueFormatter(record?.facebook_cost_per_lead, 2) ?? 0}
      //     </FlexCenter>
      //   ),
      // },
      {
        title: <TextTag>ROAS</TextTag>,
        dataIndex: "facebook_roas",
        key: "37",
        width: 100,
        render: (_, record) => (
          <FlexCenter>
            {valueFormatter(record?.facebook_roas, 2) ?? 0}
          </FlexCenter>
        ),
      },
      {
        title: "Total Spend",
        dataIndex: "facebook_spent",
        key: "38",
        // className: "group-child",
        width: 130,
        render: (_, record) => (
          <FlexCenter>
            ${valueFormatter(record?.facebook_spent, 2) ?? 0}
          </FlexCenter>
        ),
      },
    ],
  },
  {
    title: "Yelp",
    className: "google-group columnGroup",
    children: [
      {
        title: <TextTag>Revenue</TextTag>,
        dataIndex: "yelp_revenue",
        key: "39",
        className: "group-child",
        width: 100,
        render: (_, record) => (
          <FlexCenter>
            ${valueFormatter(record?.yelp_revenue, 2) ?? 0}
          </FlexCenter>
        ),
      },
      {
        title: "Cost per Job",
        dataIndex: "yelp_cost_per_job",
        key: "40",
        width: 100,
        render: (_, record) => (
          <FlexCenter>
            ${valueFormatter(record?.yelp_cost_per_job, 2) ?? 0}
          </FlexCenter>
        ),
      },
      // {
      //   title: <span style={{ whiteSpace: "nowrap" }}>Cost per Lead</span>,
      //   dataIndex: "yelp_cost_per_lead",
      //   key: "41",
      //   width: 100,
      //   render: (_, record) => (
      //     <FlexCenter>
      //       ${valueFormatter(record?.yelp_cost_per_lead, 2) ?? 0}
      //     </FlexCenter>
      //   ),
      // },
      {
        title: <TextTag>ROAS</TextTag>,
        dataIndex: "yelp_roas",
        key: "42",
        width: 100,
        render: (_, record) => (
          <FlexCenter>{valueFormatter(record?.yelp_roas, 2) ?? 0}</FlexCenter>
        ),
      },
      {
        title: "Total Spend",
        dataIndex: "yelp_spent",
        key: "43",
        // className: "group-child",
        width: 130,
        render: (_, record) => (
          <FlexCenter>${valueFormatter(record?.yelp_spent, 2) ?? 0}</FlexCenter>
        ),
      },
    ],
  },
  {
    title: "Networx",
    className: "google-group columnGroup",
    children: [
      {
        title: <TextTag>Revenue</TextTag>,
        dataIndex: "netwrox_revenue",
        key: "44",
        className: "group-child",
        width: 100,
        render: (_, record) => (
          <FlexCenter>
            ${valueFormatter(record?.netwrox_revenue, 2) ?? 0}
          </FlexCenter>
        ),
      },
      {
        title: "Cost per Job",
        dataIndex: "netwrox_cost_per_job",
        key: "45",
        width: 100,
        render: (_, record) => (
          <FlexCenter>
            ${valueFormatter(record?.netwrox_cost_per_job, 2) ?? 0}
          </FlexCenter>
        ),
      },
      // {
      //   title: <span style={{ whiteSpace: "nowrap" }}>Cost per Lead</span>,
      //   dataIndex: "netwrox_cost_per_lead",
      //   key: "46",
      //   width: 100,
      //   render: (_, record) => (
      //     <FlexCenter>
      //       ${valueFormatter(record?.netwrox_cost_per_lead, 2) ?? 0}
      //     </FlexCenter>
      //   ),
      // },
      {
        title: <TextTag>ROAS</TextTag>,
        dataIndex: "netwrox_roas",
        key: "47",
        width: 100,
        render: (_, record) => (
          <FlexCenter>
            {valueFormatter(record?.netwrox_roas, 2) ?? 0}
          </FlexCenter>
        ),
      },
      {
        title: "Total Spend",
        dataIndex: "netwrox_spent",
        key: "48",
        // className: "group-child",
        width: 130,
        render: (_, record) => (
          <FlexCenter>
            ${valueFormatter(record?.netwrox_spent, 2) ?? 0}
          </FlexCenter>
        ),
      },
    ],
  },
  {
    title: "Bing",
    className: "google-group columnGroup",
    children: [
      {
        title: <TextTag>Revenue</TextTag>,
        dataIndex: "bing_revenue",
        key: "49",
        className: "group-child",
        width: 100,
        render: (_, record) => (
          <FlexCenter>
            ${valueFormatter(record?.bing_revenue, 2) ?? 0}
          </FlexCenter>
        ),
      },
      {
        title: "Cost per Job",
        dataIndex: "bing_cost_per_job",
        key: "50",
        width: 100,
        render: (_, record) => (
          <FlexCenter>
            ${valueFormatter(record?.bing_cost_per_job, 2) ?? 0}
          </FlexCenter>
        ),
      },
      // {
      //   title: <span style={{ whiteSpace: "nowrap" }}>Cost per Lead</span>,
      //   dataIndex: "bing_cost_per_lead",
      //   key: "51",
      //   width: 100,
      //   render: (_, record) => (
      //     <FlexCenter>
      //       ${valueFormatter(record?.bing_cost_per_lead, 2) ?? 0}
      //     </FlexCenter>
      //   ),
      // },
      {
        title: <TextTag>ROAS</TextTag>,
        dataIndex: "bing_roas",
        key: "52",
        width: 100,
        render: (_, record) => (
          <FlexCenter>{valueFormatter(record?.bing_roas, 2) ?? 0}</FlexCenter>
        ),
      },
      {
        title: "Total Spend",
        dataIndex: "bing_spent",
        key: "53",
        // className: "group-child",
        width: 130,
        render: (_, record) => (
          <FlexCenter>${valueFormatter(record?.bing_spent, 2) ?? 0}</FlexCenter>
        ),
      },
    ],
  },
  {
    title: "Service Direct",
    className: "google-group columnGroup",
    children: [
      {
        title: <TextTag>Revenue</TextTag>,
        dataIndex: "service_direct_revenue",
        key: "54",
        className: "group-child",
        width: 100,
        render: (_, record) => (
          <FlexCenter>
            ${valueFormatter(record?.service_direct_revenue, 2) ?? 0}
          </FlexCenter>
        ),
      },
      {
        title: "Cost per Job",
        dataIndex: "service_direct_cost_per_job",
        key: "55",
        width: 100,
        render: (_, record) => (
          <FlexCenter>
            ${valueFormatter(record?.service_direct_cost_per_job, 2) ?? 0}
          </FlexCenter>
        ),
      },
      // {
      //   title: <span style={{ whiteSpace: "nowrap" }}>Cost per Lead</span>,
      //   dataIndex: "service_direct_cost_per_lead",
      //   key: "56",
      //   width: 100,
      //   render: (_, record) => (
      //     <FlexCenter>
      //       ${valueFormatter(record?.service_direct_cost_per_lead, 2) ?? 0}
      //     </FlexCenter>
      //   ),
      // },
      {
        title: <TextTag>ROAS</TextTag>,
        dataIndex: "service_direct_roas",
        key: "57",
        width: 100,
        render: (_, record) => (
          <FlexCenter>
            {valueFormatter(record?.service_direct_roas, 2) ?? 0}
          </FlexCenter>
        ),
      },
      {
        title: "Total Spend",
        dataIndex: "service_direct_spent",
        key: "58",
        // className: "group-child",
        width: 130,
        render: (_, record) => (
          <FlexCenter>
            ${valueFormatter(record?.service_direct_spent, 2) ?? 0}
          </FlexCenter>
        ),
      },
    ],
  },
];

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 40,
    }}
    spin
  />
);

function DataTable() {
  const [loading, setLoading] = useState(true);
  const [pagesize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [extraData, setExtraData] = useState(0);
  const [openDrawer, setFilterDrawer] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [clientsData, setClientsData] = useState([]);
  const [active, setActiveCollapse] = useState([]);
  const [roasTableData, setRoasTableData] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );
  const AllClients = useSelector(
    (state) => state?.loginAuth?.clientsList?.clients
  );

  const updatedData = useSelector(
    (state) => state?.loginAuth?.reportTableSectionFilters
  );
  const { reportData } = useSelector((e) => e.loginAuth);
  const [defaultValue, setDefaultValue] = useState({});
  const [showMarketing, setShowMarketing] = useState(
    updatedData?.checkboxVal == false ? false : true
  );
  const dispatch = useDispatch();
  const todayDate = moment().format("yyyy-MM-DD");
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );
  const activeClient = reportData?.selectedClient
    ? reportData?.selectedClient?.[0]
    : prefilledData?.selectedClient;

  const getInitValue = () => {
    const init = reportData
      ? reportData
      : {
          date_start: prefilledData?.date_start,
          date_end: prefilledData?.date_end,
          date_new_cus_start: thirtyDayAgoDate(prefilledData?.date_start),
          selectedClient: [prefilledData?.selectedClient],
          customer_type: "all",
          only_completed_jobs:
            updatedData?.only_completed_jobs != null ||
            updatedData?.only_completed_jobs != undefined
              ? updatedData?.only_completed_jobs
              : true,
        };
    setDefaultValue(init);
    getDataTable(init);
  };

  const onChangePagination = (e, limit) => {
    setCurrentPage(e - 1);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setPageSize(limit);
  };

  const getDataTable = async (data) => {
    setLoading(true);
    const {
      selectedClient,
      date_start,
      date_end,
      customer_type,
      date_new_cus_start,
      only_completed_jobs,
      include_roas,
    } = data;

    let selectedKeys = selectedClient?.map((el) => el?.key);

    let params = new URLSearchParams();
    params.append("date_start", date_start);
    params.append("date_end", date_end);
    params.append("customer_type", customer_type);
    // params.append("state", "NY");
    params.append(
      "date_new_cus_start",
      moment(date_new_cus_start).format("YYYY-MM-DD")
    );
    params.append("include_roas", showMarketing ? showMarketing : false);
    params.append("only_completed_jobs", only_completed_jobs);
    const res = await getPerformanceList(params.toString(), {
      client_keys: selectedKeys,
    });
    if (res?.status === 200) {
      setTableData(res?.data);
      let activeFirstCollapse = res?.data?.clients?.[0]?.client?.name;
      // setActiveCollapse([activeFirstCollapse]);
    } else {
      // toast.error(res?.message || res?.error || "Something went wrong");
    }
    setLoading(false);
  };
  const getRoasDataTable = async (data) => {
    setLoading(true);
    const { selectedClient, date_start, date_end } = data;
    let params = new URLSearchParams();
    params.append("date_start", date_start);
    params.append("date_end", date_end);
    const res = await getDataRoasPerformance(
      selectedClient?.key,
      params.toString()
    );
    if (res?.status === 200) {
      const { roas } = res?.data || {};
      setRoasTableData(roas);
    }
    setLoading(false);
  };

  const handleFilter = async (listing, checkboxValue) => {
    setFilterLoading(true);
    const {
      selectedClient,
      date_start,
      date_end,
      customer_type,
      date_new_cus_start,
      only_completed_jobs,
    } = listing;

    let selectedKeys = selectedClient?.map((el) => el?.key);
    const cust_type = customer_type ?? "all";

    let params = new URLSearchParams();
    params.append("date_start", date_start);
    params.append("date_end", date_end);
    params.append("customer_type", cust_type);
    params.append("state", "NY");
    params.append(
      "date_new_cus_start",
      moment(date_new_cus_start).format("YYYY-MM-DD")
    );
    params.append("only_completed_jobs", only_completed_jobs);
    params.append("include_roas", checkboxValue);

    const res = await getPerformanceList(params.toString(), {
      client_keys: selectedKeys,
    });
    if (res?.status === 200) {
      setShowMarketing(checkboxValue);
      setTableData(res?.data);
      let activeFirstCollapse = res?.data?.clients?.[0]?.client?.name;
      // setActiveCollapse([activeFirstCollapse]);
      dispatch(updateReportData({ ...listing, customer_type: cust_type }));
      setDefaultValue({ ...listing, customer_type: cust_type });
      setFilterDrawer(false);
    } else {
      // toast.error(res?.message || res?.error || "Something went wrong");
    }
    setFilterLoading(false);
  };

  const handleOpened = (ele) => {
    active?.[0] === ele?.client?.name
      ? setActiveCollapse([])
      : setActiveCollapse([ele?.client?.name]);
  };

  useEffect(() => {
    getInitValue();
  }, []);

  const TableContent = ({ ele }) => {
    return (
      <div>
        <div className="Table-second-heading"></div>
        <Table
          prefixCls="custom-ui-table"
          columns={columns}
          dataSource={ele?.metric}
          pagination={false}
          scroll={{
            x: "100%",
            y: "100%",
          }}
        />
      </div>
    );
  };

  return (
    <>
      <Header
        title="Performance Metrics"
        clientInfo={activeClient}
        isFilter={true}
        openFilter={() => setFilterDrawer(true)}
        isNotification={true}
      />
      {openDrawer && (
        <Drawer
          className="filter-drawer"
          placement="right"
          closable={false}
          onClose={() => setFilterDrawer(false)}
          open={openDrawer}
          width={"400px"}
          key="bottom"
        >
          <FilterForm
            handleFilter={handleFilter}
            initValues={defaultValue}
            filterLoading={filterLoading}
            showMarketing={showMarketing}
            setFilterDrawer={setFilterDrawer}
            getDataTable={getDataTable}
          />
        </Drawer>
      )}

      <DataTableWrapper selectedThemeColors={selectedThemeColors}>
        {filterLoading || loading ? (
          <LoadingBlock height={"calc(100vh - 121px)"} size={28} />
        ) : (
          <>
            <HeaderSection>
              {" "}
              <h2>Total</h2>{" "}
            </HeaderSection>

            <Table
              prefixCls="custom-ui-table"
              columns={columns}
              dataSource={tableData?.total}
              pagination={false}
              scroll={{
                x: "100%",
                y: "47vh",
              }}
            />

            {tableData && showMarketing && (
              <div style={{ marginTop: "24px" }}>
                <HeaderSection>
                  {" "}
                  <h2>Total ROAS</h2>{" "}
                </HeaderSection>
                <Table
                  prefixCls="custom-double-quadruple-ui-table"
                  columns={columns2}
                  dataSource={tableData?.total_roas}
                  pagination={false}
                  scroll={{
                    x: "100%",
                    y: "100%",
                  }}
                />
              </div>
            )}

            {tableData?.clients?.length > 0 &&
              tableData?.clients?.map((ele) => (
                <>
                  <div style={{ marginTop: "46px" }}>
                    <HeaderSection onClick={() => handleOpened(ele)}>
                      <div
                        className={
                          showMarketing
                            ? "section-wrapper-hidden-style"
                            : active &&
                              active.length > 0 &&
                              active?.includes(ele?.client?.name)
                            ? "section-wrapper-hidden-style"
                            : "section-wrapper"
                        }
                      >
                        <div className="left-section">
                          <img src={ele?.client?.logo} alt="" />
                          <h3> {ele?.client?.name} </h3>
                        </div>
                        <span
                          style={{
                            rotate:
                              active?.[0] !== ele?.client?.name && "-90deg",
                          }}
                          onClick={() => handleOpened(ele)}
                        >
                          {" "}
                          <DropDownIcon />{" "}
                        </span>
                      </div>
                    </HeaderSection>

                    {showMarketing && (
                      <div style={{ marginTop: "12px" }}>
                        <Table
                          prefixCls="custom-double-quadruple-ui-table"
                          columns={columns2}
                          dataSource={ele?.roas}
                          pagination={false}
                          scroll={{
                            x: "100%",
                            y: "100%",
                          }}
                        />
                      </div>
                    )}

                    <CollapseTable
                      activeKey={active}
                      accordion={true}
                      clientName={ele?.client?.name}
                      setActiveCollapse={setActiveCollapse}
                      header=""
                      tableContent={<TableContent ele={ele} />}
                    />
                  </div>
                </>
              ))}
          </>
        )}
      </DataTableWrapper>
      <Watermark style={{padding: "0 10px 6px"}} background={"rgb(243 243 243)"}/>
    </>
  );
}

export default DataTable;

const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    letter-spacing: 0.005em;
    text-align: left;
    margin-bottom: 16px;
  }

  .left-section {
    width: 100%;
    display: flex;
    gap: 7px;
    align-items: center;

    img {
      height: 46px;
    }

    h3 {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      margin: 0;
      color: #111111;
    }
  }

  span {
    width: fit-content;
    height: fit-content;
    cursor: pointer;
    transition: 0.3s;
  }

  .section-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #dfdcdc;
    border-radius: 12px;
    padding: 4px 6px;
  }
  .section-wrapper-hidden-style {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 12px;
  }
`;

// const HeaderSection = styled.div`
//   display: flex;
//   justify-content: space-between;
//   padding: 0;
//   align-items: center;

//   color: white;
//   .left-section {
//     width: 100%;
//     display: flex;
//     flex-direction: column;

//     h3 {
//       color: ${({ selectedThemeColors }) =>
//     selectedThemeColors?.textPrimaryColor};
//       font-size: 24px;
//     }
//     .table-heading {
//       color: ${({ selectedThemeColors }) =>
//     selectedThemeColors?.textPrimaryColor};
//       font-size: 15px;
//     }
//   }
//   .filterBtn-Box {
//     button {
//       background: ${({ selectedThemeColors }) =>
//     selectedThemeColors?.headerColor};
//       height: 33px;
//       font-size: 13px;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       padding-top: 0 !important;
//       padding-bottom: 0 !important;
//     }
//   }
// `;

const DataTableWrapper = styled.div`
  background: ${({ selectedThemeColors }) => selectedThemeColors?.bgMainColor};
  width: 100%;
  height: 100%;
  padding: 20px;

  .multiple-tables {
    border-radius: 9px;
    box-shadow: 0px 2px 8px #8b94a3cf;
    margin-top: 20px;
  }

  .Table-second-heading {
    margin: 0px 0 20px 0px;
    color: rgba(36, 36, 36, 1);
  }
`;

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
`;

const ColHeadStyle = styled.p`
  width: 100%;
  margin: 0;
`;
