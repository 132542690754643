import { InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  calculateTotals,
  checkForInfinity,
  debounce,
  formatInteger,
  formatNumberDecimal,
} from "./commonFuc";
import {
  formatNumber,
  formatToCurrencyWithDecimal,
} from "../../exceltable/commonFun";
import { useSelector } from "react-redux";

const formatterRegex = /\B(?=(\d{3})+(?!\d))/g;
const parseRegex = /\$\s?|(,*)/g;

export default function DifferenceComponent({ data, activeTab }) {
  const goalPrediction = useSelector(
    (state) => state?.dynamicBudgetSheet?.GoalPrediction
  );
  const [goalsCal, setGoalsCal] = useState({ budget: 0, sales: 0 });

  const updateDifference = debounce((name, value) => {
    if (name === "budget") {
      setGoalsCal((prevGoalsCal) => ({ ...prevGoalsCal, budget: value }));
    }
    if (name === "sales") {
      setGoalsCal((prevGoalsCal) => ({ ...prevGoalsCal, sales: value }));
    }
  }, 50);

  const [totalData, setTotalData] = useState({});

  useEffect(() => {
    if (data && data?.length > 0) {
      const parentArray = data?.filter((cat) => cat?.isParent);
      const parentTotal = calculateTotals(parentArray);
      const duplicateArray = data?.filter((cat) => cat?.isChecked);
      const duplicateTotal = calculateTotals(duplicateArray);

      if (activeTab === "current") {
        setTotalData(parentTotal);
        setGoalsCal({ budget: parentTotal?.budget, sales: parentTotal?.sales });
      } else {
        setTotalData(duplicateTotal);
        setGoalsCal({
          budget: duplicateTotal?.budget,
          sales: duplicateTotal?.sales,
        });
      }
    }
  }, [data, activeTab]);

  return (
    <>
      <Container>
        <Header>
          <CategoryName>Goal</CategoryName>

          <MetricsContainer>
            <div>
              <Label>Budget</Label>
              <MetricInput
                value={`$${formatInteger(goalPrediction?.budget || 0)}`}
                disabled
              />
            </div>
            <div>
              <Label>Revenue</Label>
              <MetricInput
                value={`$${formatInteger(goalPrediction?.sales || 0)}`}
                disabled
              />
            </div>
          </MetricsContainer>
        </Header>
        {/* <Line /> */}

        {/* 
        <Header>
          <CategoryName>Difference</CategoryName>
          <MetricsContainer>
            <div>
              <Label>Budget</Label>
              <MetricInput
                value={`${formatInteger(
                  totalData?.budget - goalPrediction?.budget
                )}`}
                disabled
              />
            </div>
            <div>
              <Label>Revenue</Label>
              <MetricInput
                value={`${formatInteger(
                  totalData?.sales - goalPrediction?.sales
                )}`}
                disabled
              />
            </div>
          </MetricsContainer>
        </Header>


        <Line />
        <Header>
          <CategoryName>Budget%</CategoryName>
          <MetricsContainer>
            <div>
              <Label>Budget</Label>
              <MetricInput
                value={`${formatInteger(
                  checkForInfinity(totalData?.budget / totalData?.sales) * 100
                )}%`}
                disabled
              />
            </div>
          </MetricsContainer>
        </Header> */}
      </Container>
    </>
  );
}

const Line = styled.div`
  border-top: 1px solid #5b5b5b;
`;

const Container = styled.div`
  background-color: #373737;
  padding: 10px 12px;
  border-radius: 12px;
  margin-left: 10px;
  margin-bottom: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: margin 0.2s ease, box-shadow 0.3s ease;
  &:hover {
    margin-bottom: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1835px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const CategoryName = styled.h2`
  color: #fff;
  margin: 0;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  padding-right: 6px;
`;

const MetricsContainer = styled.div`
  display: flex;
  gap: 16px;
  // height: 46px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 9px;
  background-color: #f4f4f41a;
`;

const Label = styled.span`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #fff;
  padding-right: 4px;
`;

const MetricInput = styled.input`
  border: none;
  background-color: #ffffff1f;
  color: #fff;
  width: 110px;

  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  padding: 9px 10px;
  border-radius: 6px;
`;

const StyledInputNumber = styled(InputNumber)`
  margin-bottom: 0;
  .ant-input-number {
    border: none;
    background-color: #fff;
    width: 100px;
    .ant-input-number-input {
      color: #2863e3;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      padding: 9px 10px;
      border: 1px solid #d2d4de;
      border-radius: 6px;
    }
  }
`;
