import React, { useEffect, useState } from "react";
import ComposedGraphComponent from "../../components/ComposedGraph";
import { Skeleton } from "antd";
import { useSelector } from "react-redux";
import { getVisualizationData } from "../../services/Collections";
import { toast } from "react-toastify";
import { GraphLoader } from "../../utils/Icons/SvgIcons";
import { graphListing, SubHeader } from "./Visualization";
import styled from "styled-components";

const VisualizationBusinessUnit = ({ businessunit, init }) => {
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );
  const [businessUnit, setBusinessUnit] = useState([]);
  const [loading, setLoading] = useState(false);

  const getVisualizationDataListing = async (init) => {
    setLoading(true);
    const {
      selectedClient,
      date_start,
      date_end,
      customer_type = "all",
      job_types = [],
    } = init;
    let params = new URLSearchParams();
    params.append("date_start", date_start);
    params.append("date_end", date_end);
    params.append("customer_type", customer_type);
    params.append("state", "NY");
    job_types?.map((list) => params.append("job_types", list));
    params.append("business_units", businessunit?.id);

    const res = await getVisualizationData(selectedClient?.key, params);
    if (res?.status === 200) {
      const { categories, existing_cus, existing_cus_mkt, new_cus } =
        res?.data || {};
      let customize_data = categories?.map((element, index) => ({
        name: element,
        "Existing Customer Rev": existing_cus?.rev[index],
        "Existing Customer Job": existing_cus?.job[index],
        "Existing Customer Avg Rev": existing_cus?.avg_rev[index],

        "Existing Customer with Marketing Rev": existing_cus_mkt?.rev[index],
        "Existing Customer with Marketing Job": existing_cus_mkt?.job[index],
        "Existing Customer with Marketing Avg Rev":
          existing_cus_mkt?.avg_rev[index],

        "New Customer Rev": new_cus?.rev[index],
        "New Customer Job": new_cus?.job[index],
        "New Customer Avg Rev": new_cus?.avg_rev[index],
      }));

      setBusinessUnit(customize_data);
      setLoading(false);
    } else {
      // toast.error(res?.message || res?.error || "Something went wrong");
      setLoading(false);
    }
  };

  useEffect(() => {
    getVisualizationDataListing(init);
  }, []);

  return (
    <>
      {loading ? (
        <MapsLoaderWrapper>
          <Skeleton.Node
            active={true}
            style={{ width: "100%", height: "450px", margin: "auto" }}
          >
            <GraphLoader />
          </Skeleton.Node>
        </MapsLoaderWrapper>
      ) : (
        <>
          <SubHeader selectedThemeColors={selectedThemeColors}>
            <div className="filterBtn-Box">
              <h4>Business Unit : {businessunit?.name}</h4>
            </div>
          </SubHeader>
          <ComposedGraphComponent
            data={businessUnit}
            graphListing={graphListing}
            yAxis_left_label="REV"
            yAxis_right_label="AVG REV"
            label_prefix="$"
          />
        </>
      )}
    </>
  );
};

export default VisualizationBusinessUnit;

const MapsLoaderWrapper = styled.div`
  /* width: 100%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 40px;
`;
