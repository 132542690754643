import React, { useCallback, useEffect, useState, useRef } from "react";
import { DatePicker, Drawer, Pagination, Select, Spin, Table } from "antd";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { Sort, SortAsc, SortDesc } from "../../utils/Images/Images";
import {
  getBookingList,
  getBookingListWithCancelToken,
} from "../../services/Collections";
import { BackSVGIcon } from "../../utils/Icons/SvgIcons";
import { debounce } from "../../utils/common/commonFunction";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import BookingModal from "../../components/BookingModal";
import { ChatComponent } from "./ChatComponent";
import {
  updateBookingDateFilter,
  updateBookingDateType,
} from "./AiBookingSlice";
import axios from "axios";
import dayjs from "dayjs";
import Header from "../../components/Header";
import LoadingBlock from "../../components/LoadingBlock";
import Watermark from "../../components/Watermark";

function BookingList() {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const [pagesize, setPageSize] = useState(10);
  const [extraData, setExtraData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const [sortParam, setSortParam] = useState({
    sortType: "",
    sortBasis: "",
  });
  const location = useLocation();
  const dispatch = useDispatch();
  const [openChatDrawer, setOpenChatDrawer] = useState(false);
  const [openSmsDrawer, setOpenSmsDrawer] = useState(false);
  const [editDetails, setEditDetails] = useState(null);
  const [detailModal, setDetailModal] = useState(false);
  const [currentDate, setCurrentDate] = useState(null);
  const selectedCompany = useSelector(
    (state) => state?.aiBooking?.selectedCompany
  );
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );
  const companyID = useSelector((state) => state?.loginAuth?.data?.company_id);
  const companyData = useSelector((state) => state?.loginAuth?.data);
  const selctedCompanyId = companyData?.company_id
    ? companyData
    : selectedCompany;
  const { bookingDateFilter, bookingDateType } = useSelector(
    (state) => state?.aiBooking
  );
  var enddate = new Date();
  var startdate = new Date(new Date().setDate(enddate.getDate() - 30));

  const getSortParam = (sortBasis) => {
    setCurrentPage(1);
    if (sortBasis === sortParam.sortBasis) {
      setSortParam({
        sortType: sortParam.sortType === "desc" ? "asc" : "desc",
        sortBasis,
      });
    } else {
      setSortParam({
        sortType: "desc",
        sortBasis,
      });
    }
  };

  const srcSortImage = (Basis) => {
    if (Basis === sortParam.sortBasis) {
      if (sortParam.sortType === "asc") {
        return SortAsc;
      }
      return SortDesc;
    }
    return Sort;
  };

  const onChangePagination = (e, limit) => {
    setCurrentPage(e);
    setPageSize(limit);
  };
  let cancelToken = null;

  const handleBookingList = async (search) => {
    if (cancelToken) {
      cancelToken.cancel("Req cancelled by user");
      cancelToken = null; // Remove the cancel token from the mapping
    }
    try {
      cancelToken = axios.CancelToken.source();

      setLoading(true);
      // const id = location?.state?.id;
      const id = companyID || selectedCompany?.value;
      const params = new URLSearchParams();
      params.append("page", Number(currentPage));
      params.append("page_size", Number(pagesize));
      params.append("company_id", id);
      bookingDateFilter && params.append("date_filter", bookingDateFilter);

      sortParam?.sortBasis && params.append("sort_by", sortParam?.sortBasis);
      sortParam?.sortType && params.append("order_by", sortParam?.sortType);
      if (search !== null || search !== "") {
        if (search === undefined) {
          if (searchWord) params.append("search", searchWord);
        } else {
          search && params.append("search", search);
        }
      }
      const res = await getBookingListWithCancelToken(id, params?.toString(), {
        cancelToken: cancelToken?.token,
      });
      console.log(res, "res");
      if (res?.status === 200) {
        cancelToken = null;
        setLoading(false);
        setExtraData(res?.data?.total_count);

        if (res?.data?.data?.length > 0) {
          const arr = [];
          // eslint-disable-next-line array-callback-return
          res?.data?.data?.map((el, index) => {
            const obj = {
              ...el,
              index: pagesize * (currentPage - 1) + (1 + index),
            };
            arr.push(obj);
          });
          setTableData(arr);
        } else {
          setTableData([]);
          setLoading(false);
          // setExtraData(0);
        }
      }
    } catch (error) {
      console.log(error, "error");
      cancelToken = null;
      if (error?.name != "CanceledError") {
        setLoading(false);
        setTableData([]);
      }
    }
  };

  const handleSearchListing = useCallback(debounce(handleBookingList, 400), [
    bookingDateFilter,
    bookingDateType,
  ]);

  useEffect(() => {
    handleSearchListing(searchWord);
  }, [searchWord]);

  const handleSearch = (value) => {
    setSearchWord(value);
  };

  const handleChatIds = (data) => {
    console.log(data, "datadata");

    if (data?.message_id != null) {
      return { message_id: data?.message_id, message_type: "chatwidget" };
    }
    if (data?.yelp_message_id != null) {
      return { message_id: data?.yelp_message_id, message_type: "yelp" };
    }
    if (data?.lsa_message_id != null) {
      return { message_id: data?.lsa_message_id, message_type: "lsa" };
    }
    return null;
  };

  const handleChatButtonStyling = (data) => {
    if (
      data?.message_id != null ||
      data?.yelp_message_id != null ||
      data?.lsa_message_id != null
    ) {
      return false;
    }
    return true;
  };

  const handleChatButton = (data) => {
    if (data?.message_id != null) {
      return "Chat";
    }
    if (data?.yelp_message_id != null) {
      return "Yelp Chat";
    }
    if (data?.lsa_message_id != null) {
      return "LSA Chat";
    }
    return "Chat";
  };

  useEffect(() => {
    handleBookingList();
  }, [currentPage, pagesize, sortParam, bookingDateFilter, bookingDateType]);

  const columns = [
    {
      title: "Sr.No",
      dataIndex: "index",
      render: (val) => <p>{val}.</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Email</span>
          <img
            style={{
              filter: sortParam?.sortBasis !== "email" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("email")}
            onClick={() => getSortParam("email")}
          />
        </TitleWrapper>
      ),
      dataIndex: "email",
    },
    {
      title: (
        <TitleWrapper>
          <span>First name</span>
          <img
            style={{
              filter: sortParam?.sortBasis !== "first_name" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("first_name")}
            onClick={() => getSortParam("first_name")}
          />
        </TitleWrapper>
      ),
      dataIndex: "first_name",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Last name</span>
          <img
            style={{
              filter: sortParam?.sortBasis !== "last_name" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("last_name")}
            onClick={() => getSortParam("last_name")}
          />
        </TitleWrapper>
      ),
      dataIndex: "last_name",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Phone</span>
          <img
            style={{
              filter: sortParam?.sortBasis !== "phone" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("phone")}
            onClick={() => getSortParam("phone")}
          />
        </TitleWrapper>
      ),
      dataIndex: "phone",
      render: (val) => <p>{val || "N/A"}</p>,
    },
    {
      title: "Chat",
      render: (_, data) => (
        <PodiumStatusWrapper>
          <button
            style={{
              border: handleChatButtonStyling(data) && "1px solid gray",
              color: handleChatButtonStyling(data) && "gray",
              cursor: handleChatButtonStyling(data) && "not-allowed",
            }}
            className="btn-connected"
            onClick={() => {
              if (!handleChatButtonStyling(data)) {
                setEditDetails(data);
                setOpenChatDrawer(true);
              }
            }}
          >
            {handleChatButton(data)}
          </button>
          <button
            style={{
              border: !data?.sms_message_id && "1px solid gray",
              color: !data?.sms_message_id && "gray",
              cursor: !data?.sms_message_id && "not-allowed",
            }}
            className="btn-connected"
            onClick={() => {
              if (data?.sms_message_id) {
                setEditDetails(data);
                setOpenSmsDrawer(true);
              }
            }}
          >
            SMS
          </button>
        </PodiumStatusWrapper>
      ),
    },
    {
      title: (
        <TitleWrapper>
          <span>Booking Status</span>
          {/* <img
            style={{
              filter: sortParam?.sortBasis !== "status" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("status")}
            onClick={() => getSortParam("status")}
          /> */}
        </TitleWrapper>
      ),
      dataIndex: "status",
      render: (val) => <p>{val ? "True" : "N/A"}</p>,
    },
    {
      title: "Action",
      render: (_, data) => (
        <PodiumStatusWrapper>
          <button
            style={{
              color: "#f98229",
              border: "1px solid #f98229",
              display: "flex",
              gap: "5px",
            }}
            className="btn-connected"
            onClick={() => {
              setEditDetails(data);
              setDetailModal(true);
            }}
          >
            Details
          </button>
        </PodiumStatusWrapper>
      ),
    },
  ];

  return (
    <>
      <Header
        title="Bookings"
        clientInfo={selctedCompanyId}
        // openFilter={() => setOpenDrawer(true)}
        isNotification={true}
      />
      <TableWrapper selectedThemeColors={selectedThemeColors}>
        {openChatDrawer && (
          <Drawer
            className="chat-drawer"
            placement="right"
            headerStyle={{ display: "none" }}
            onClose={() => {
              setOpenChatDrawer(false);
            }}
            closeIcon={false}
            title={null}
            open={openChatDrawer}
            width="50%"
            height="100%"
            key="bottom"
          >
            <ChatComponent
              messageId={handleChatIds(editDetails)?.message_id}
              messageType={handleChatIds(editDetails)?.message_type}
              companyId={companyID || selectedCompany?.value}
              onClose={() => setOpenChatDrawer(false)}
            />
          </Drawer>
        )}

        {openSmsDrawer && (
          <Drawer
            className="chat-drawer"
            placement="right"
            headerStyle={{ display: "none" }}
            onClose={() => {
              setOpenSmsDrawer(false);
            }}
            closeIcon={false}
            title={null}
            open={openSmsDrawer}
            width="50%"
            height="100%"
            key="bottom"
          >
            <ChatComponent
              messageId={editDetails?.sms_message_id}
              messageType={"sms"}
              companyId={companyID || selectedCompany?.value}
              onClose={() => setOpenSmsDrawer(false)}
            />
          </Drawer>
        )}

        {detailModal && (
          <BookingModal
            open={detailModal}
            handleClose={() => setDetailModal(false)}
            data={editDetails}
          />
        )}
        <div className="create-btn">
          <div className="backbtn-box">
            <span
              style={{
                cursor: "pointer",
                display: "flex",
                gap: "5px",
                fontFamily: "Poppins",
                color: "#000",
              }}
              onClick={() => navigate(-1)}
            >
              <BackSVGIcon /> Back
            </span>
          </div>

          <div style={{ display: "flex", gap: "5px" }}>
            <RangeWrapper>
              {/* <RangeSelectorConfirm
              allowClear={true}
              defaultDate={[
                bookingDateFilter?.startDate
                  ? moment(initialValues?.start_date, "yyyy-MM-DD").format(
                      "yyyy-MM-DD"
                    )
                  : null,
                bookingDateFilter?.endDate
                  ? moment(initialValues?.end_date, "yyyy-MM-DD").format(
                      "yyyy-MM-DD"
                    )
                  : null,
              ]}
              handleChange={(d) => {
                console.log(d, "dddddd");
                const start = moment(d[0]).format("yyyy-MM-DD");
                const end = moment(d[1]).format("yyyy-MM-DD");
                dispatch(
                  updateBookingDateFilter({ startDate: start, endDate: end })
                );
              }}
              handleClear={() => {
                dispatch(updateBookingDateFilter(null));
              }}
            /> */}
              <DatePicker
                style={{ width: "100%" }}
                value={bookingDateFilter ? dayjs(bookingDateFilter) : null}
                placeholder="Filter by Date"
                onChange={(_, val) => dispatch(updateBookingDateFilter(val))}
              />
            </RangeWrapper>

            <form autoComplete="off" onSubmit={(e) => e?.preventDefault()}>
              <input
                type="text"
                placeholder="Search"
                style={{ height: "33px" }}
                onChange={(e) => {
                  e?.preventDefault();
                  setCurrentPage(1);
                  handleSearch(e?.target?.value);
                }}
                autoComplete="off"
              />
            </form>
          </div>
        </div>
        <div style={{ position: "relative" }}>
          {loading ? (
            <LoadingBlock height={"calc(100vh - 168px)"} size={28} />
          ) : (
            <Table
              prefixCls="custom-ui-table"
              columns={columns}
              dataSource={tableData}
              pagination={false}
            />
          )}
        </div>
        {!loading && (
          <div
            style={{
              marginTop: "23px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            {/* <Pagination
            prefixCls="interaction-pagination-night"
            current={currentPage}
            onChange={onChangePagination}
            total={extraData}
            defaultPageSize={pagesize}
            showSizeChanger
          /> */}
            <Pagination
              hideOnSinglePage
              page={currentPage}
              limit={pagesize}
              current={currentPage}
              onChange={onChangePagination}
              total={extraData}
              defaultPageSize={pagesize}
              className="pagination"
              showSizeChanger={true}
            />
          </div>
        )}
      </TableWrapper>
      <Watermark style={{ padding: "0 10px" }} background="rgb(246 249 252)" />
    </>
  );
}

export default BookingList;

const TableWrapper = styled.div`
  * {
    margin: 0;
  }
  /* background: #f2f5fd; */
  width: calc(100% - 20px);
  min-height: calc(100vh - 82px);
  height: calc(100% - 82px);
  height: 100%;
  padding: 20px 0 20px 20px !important;
  padding-top: 0px;

  .create-btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 0px;
    gap: 7px;
    padding-top: 0px;

    .backbtn-box {
      display: flex;
      align-items: center;
    }

    input {
      height: 43px;
      width: 100%;
      background: #ffff;
      border-radius: 7px;
      border: 1px solid #cecece;
      padding: 0 10px;
      color: #000;
      font-family: "Poppins", Arial, sans-serif;

      ::-webkit-input-placeholder {
        color: #000 !important;
      }

      &::placeholder {
        color: #cecece !important;
      }

      &:focus {
        outline: none;
        border: 1px solid #000;
      }
    }

    .btn {
      button {
        background-color: #343434;
        color: #fff;
        font-family: "Poppins";
        border-radius: 7px;
        font-weight: 600;
        font-size: 13px;
        border: none;
        width: 100px;
        height: 43px;
        cursor: pointer;
      }
    }
  }
  .campaignTable {
    transition: all 0.3s ease 0s;
    .campaignTable-thead {
      .campaignTable-cell {
        background: ${({ selectedThemeColors }) =>
          selectedThemeColors?.headerColor} !important;
      }
    }
    .campaignTable-tbody {
      .campaignTable-cell {
        background: ${({ selectedThemeColors }) =>
          selectedThemeColors?.CardsColor};
        color: ${({ selectedThemeColors }) =>
          selectedThemeColors?.textPrimaryColor};
        border-bottom: 1px solid rgba(232, 232, 237, 1) !important;
      }
    }
    .campaignTable-container {
      max-height: calc(100vh - 210px) !important;
      overflow: auto !important;
    }
    .campaignTable-thead {
      position: sticky !important;
      top: 0 !important;
      z-index: 99 !important;
    }
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 10px;

  img {
    cursor: pointer;
    height: 20px;
  }
`;

const PodiumStatusWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;

  .btn-connect {
    border-radius: 12px;
    padding: 5px 10px;
    border-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    font-family: "Poppins", Arial, sans-serif;
    cursor: pointer;
  }
  .btn-connected {
    border-radius: 12px;
    padding: 5px 10px;
    border-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 1px solid #71b497;
    color: #71b497;
    width: 90px;
    font-family: "Poppins", Arial, sans-serif;

    cursor: pointer;
  }
`;

const LoadingBox = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RangeWrapper = styled.div`
  width: 200px;
  padding: 0 10px;

  .ant-picker-range-separator {
    filter: none !important;
  }
  input {
    width: 150px;
    height: unset !important;
    background: unset !important;
    border: unset !important;
    border-radius: unset !important;
  }
`;
