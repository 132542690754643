import { Drawer, Spin, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import PaidSearchSettings from "./PaidSearchSettings";
import moment from "moment";
import { getPerformancePaidSocialApi } from "../../services/Collections";
import { toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";
import { updateSearchSettings } from "../../app/Auth/signIn/LogInSlice";
import RangeSelectorConfirm from "../../components/PickerWithConfirm";
import {
  getSelectedClientLogo,
  numberWithCommas,
  valueFormatter,
} from "../../utils/common/commonFunction";
import PaidFilter from "./PaidFilter";
import NoDataUI from "../../components/NoDataUI";
import {
  HeaderSettingIcon,
  ManageSettingIcon,
  PaidLSAPerfTabs,
} from "../../utils/Icons/SvgIcons";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import TabComponent from "../../components/newComponent/TabComponent";
import { updateActiveTab } from "./LsaPerformanceStore";
import LoadingBlock from "../../components/LoadingBlock";
import Watermark from "../../components/Watermark";
import axios from "axios";
// import { updateActiveTab } from "./LsaPerformanceStore";

const PaidSearch = () => {
  const dispatch = useDispatch();
  const [settingDrawers, setSettingDrawers] = useState(false);
  const [filterDrawers, setFilterDrawers] = useState(false);

  const filterData = useSelector(
    (state) => state?.lsaPerformance?.selectedClient
  );
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );
  const prefillSettings = useSelector(
    (state) => state?.loginAuth?.paidSearchSettings
  );
  const intervalValue = useSelector((state) => state?.lsaPerformance?.interval);
  // const activeTab = useSelector((state) => state?.lsaPerformance?.activeTab);
  const [activeTab, setActiveTab] = useState("");

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 40,
      }}
      spin
    />
  );

  const [tableData, setTableData] = useState([]);
  const [reloadReqiured, setReloadReqiured] = useState(false);
  const [toggle, setToggle] = useState();
  const AllClients = useSelector(
    (state) => state?.loginAuth?.clientsList?.clients
  );

  const [paidLoading, setPaidLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState({
    start: moment(
      prefillSettings?.startDate || prefilledData?.date_start
    ).format("yyyy-MM-DD"),
    end: moment(prefillSettings?.endDate || prefilledData?.date_end).format(
      "yyyy-MM-DD"
    ),
  });
  const [headerPopover, setHeaderPopover] = useState(false);

  const navigate = useNavigate();

  const columns = [
    {
      title: "Campaign",
      dataIndex: "paidSearch",
      width: 150,
      fixed: "left",
    },
    {
      title: (
        <p style={{ margin: 0 }}>
          Gross <br /> Spend
        </p>
      ),
      dataIndex: "spend",
      width: 90,
      render: (val) =>
        activeTab === "yelp" ? (
          <p style={{ margin: "0px" }}>${Number(val)?.toFixed(2)}</p>
        ) : (
          <p style={{ margin: "0px" }}>${valueFormatter(val, 1)}</p>
        ),
    },
    {
      title: "% Spend",
      dataIndex: "percentSpend",
      width: 80,
      render: (val) => (
        <p style={{ margin: "0px" }}>{numberWithCommas(val?.toFixed(2))}%</p>
      ),
    },
    {
      title: "Calls",
      dataIndex: "totalCalls",
      width: 80,
      render: (val) => <p style={{ margin: "0px" }}>{val > 0 ? val : "---"}</p>,
    },
    {
      title: "Leads",
      dataIndex: "totalLeads",
      width: 80,
      render: (val, data) => <p style={{ margin: "0px" }}>{val}</p>,
    },
    {
      title: (
        <p style={{ margin: "0px" }}>
          Ran <br /> Jobs
        </p>
      ),
      dataIndex: "rainJobs",
      width: 80,
      render: (val) => (
        <p style={{ margin: "0px" }}>{numberWithCommas(val?.toFixed(0))}</p>
      ),
    },
    {
      title: "Sold",
      dataIndex: "sold",
      width: 80,
      render: (val) => (
        <p style={{ margin: "0px" }}>{numberWithCommas(val?.toFixed(0))}</p>
      ),
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      width: 90,
      render: (val) => (
        <p style={{ margin: "0px" }}>${valueFormatter(val, 1)}</p>
      ),
    },
    {
      title: "% Rev",
      dataIndex: "percentRev",
      width: 80,
      render: (val) => (
        <p style={{ margin: "0px" }}>{numberWithCommas(val?.toFixed(2))}%</p>
      ),
    },
    {
      title: "ROAS",
      dataIndex: "roas",
      width: 80,
      render: (val) => (
        <p style={{ margin: "0px" }}>{numberWithCommas(val?.toFixed(2))}</p>
      ),
    },
    {
      title: (
        <p style={{ margin: "0px" }}>
          NEW
          <br />
          CUSTOMER
          <br />
          CAC
        </p>
      ),
      dataIndex: "cac",
      width: 100,
      render: (val) => (
        <p style={{ margin: "0px" }}>
          ${val ? valueFormatter(parseInt(val), 1) : 0}
        </p>
      ),
    },
    {
      title: (
        <p style={{ margin: "0px" }}>
          NEW
          <br />
          CUSTOMER
          <br />
          (Avg Ticket)
        </p>
      ),
      dataIndex: "customerAvgT",
      width: 100,
      render: (val) => (
        <p style={{ margin: "0px" }}>
          ${val ? numberWithCommas(parseInt(val)) : 0}
        </p>
      ),
    },
    {
      title: (
        <p style={{ margin: "0px" }}>
          TOTAL
          <br />
          NEW
          <br />
          CUSTOMER
        </p>
      ),
      dataIndex: "new_customer",
      width: 100,
      render: (val) => (
        <p style={{ margin: "0px" }}>
          {val ? numberWithCommas(parseInt(val)) : 0}
        </p>
      ),
    },
    {
      title: (
        <p style={{ margin: "0px" }}>
          Rev Per
          <br /> Job
        </p>
      ),
      dataIndex: "revPerJob",
      width: 80,
      render: (val) => (
        <p style={{ margin: "0px" }}>${valueFormatter(val, 1)}</p>
      ),
    },
  ];

  const getTotalSpendByDate = (res) => {
    let obj = {
      startDate: currentDate?.start,
      endDate: currentDate?.end,
    };

    dispatch(updateSearchSettings(obj));
    return {
      paidSearch: res?.name,
      spend: res?.spend,
      dummySpend: res?.spend,
      rainJobs: res?.total_jobs,
      revenue: res?.total_revenue,
      dummyRevenue: res?.total_revenue,
      sold: res?.sold_jobs,
      roas: res?.roas,
      revPerJob: res?.rev_per_job,
      totalCalls: res?.total_calls,
      totalLeads: res?.total_leads,
      cac: res?.new_customer_cac,
      new_customer: res?.new_customer,
      customerAvgT: res?.new_customer_avg_ticket,
    };
  };

  const manupilateData = async (payload) => {
    let tempArray = [];
    let totalSpend = 0;
    let totalRanJob = 0;
    let totalSold = 0;
    let totalRevenue = 0;
    let totalRoas = 0;
    let totalRevPerJob = 0;
    let totalSpendPercentSum = 0;
    let totalRevenuePercentSum = 0;
    let totalCalls = 0;
    let totalLeads = 0;
    let totalCAC = 0;
    let newCustomer = 0;
    let totalCustomerAvgT = 0;

    for (let i = 0; i < payload.length; i++) {
      let result = getTotalSpendByDate(payload[i]);
      totalSpend = totalSpend + result?.dummySpend;
      totalRanJob = totalRanJob + result?.rainJobs;
      totalSold = totalSold + result?.sold;
      totalRevenue = totalRevenue + result?.revenue;
      totalRoas = totalRoas + result?.roas;
      totalRevPerJob = totalRevPerJob + result?.revPerJob;
      totalCalls = totalCalls + result?.totalCalls;
      totalLeads = totalLeads + result?.totalLeads;
      totalCAC = totalCAC + result?.cac;
      newCustomer = newCustomer + result?.new_customer;
      totalCustomerAvgT = totalCustomerAvgT + result?.customerAvgT;
      tempArray.push(result);
    }

    let updatedArray = [];
    for (let index = 0; index < tempArray.length; index++) {
      const element = tempArray[index];
      // Calculation of spend %
      let percentSpend =
        element?.dummySpend == 0 ? 0 : (element?.dummySpend / totalSpend) * 100;
      totalSpendPercentSum =
        Number(totalSpendPercentSum) + Number(percentSpend);

      // Calculation of Revenue %
      let percentRev =
        element?.revenue == 0 ? 0 : (element?.revenue / totalRevenue) * 100;
      totalRevenuePercentSum =
        Number(totalRevenuePercentSum) + Number(percentRev);

      console.log(totalRevenuePercentSum, "totalRevenuePercentSum", percentRev);

      updatedArray.push({
        ...element,
        percentSpend: percentSpend,
        percentRev: percentRev,
      });
    }

    let final = [
      ...updatedArray,
      {
        ...tableData[tableData?.length - 1],
        paidSearch: "Total",
        spend: totalSpend,
        rainJobs: totalRanJob,
        sold: totalSold,
        revenue: totalRevenue,
        roas: totalSpend == 0 ? 0 : totalRevenue / totalSpend,
        revPerJob: totalRanJob == 0 ? 0 : totalRevenue / totalRanJob,
        totalCalls: totalCalls,
        totalLeads: totalLeads,
        percentSpend:
          totalSpendPercentSum >= 100 ? 100 : Math.round(totalSpendPercentSum),
        percentRev:
          totalRevenuePercentSum >= 100
            ? 100
            : Math.round(totalRevenuePercentSum),
        cac: totalCAC,
        customerAvgT: totalCustomerAvgT,
        new_customer: newCustomer,
      },
    ];

    setTableData(final);
    setPaidLoading(false);
    setFilterDrawers(false);
  };

  const handleAction = (index) => {
    if (index === 0) {
      dispatch(updateActiveTab("paid"));
      setActiveTab("paid");
    } else if (index === 1) {
      dispatch(updateActiveTab("lsa"));
      setActiveTab("lsa");
    } else if (index === 2) {
      dispatch(updateActiveTab("social"));
      setActiveTab("social");
    } else if (index === 3) {
      dispatch(updateActiveTab("yelp"));
      setActiveTab("yelp");
    } else {
      setToggle(false);
    }
  };
  const cancelToken = useRef(null);

  const handlePerformancePaidSocial = async (client_key) => {
    try {
      if (cancelToken.current) {
        cancelToken.current.cancel("Request cancelled by user");
        console.log("old qweqwe");
      }
      cancelToken.current = axios.CancelToken.source();

      // The paramenter client_key is coming from paid filter
      let obj = {
        paid: "paid_search",
        lsa: "lsa",
        social: "paid_social",
        yelp: "yelp",
      };
      let key = client_key
        ? client_key?.key
        : filterData
        ? filterData?.key
        : prefilledData?.selectedClient?.key;

      setPaidLoading(true);

      let params = new URLSearchParams();
      params.append("client_key", key);
      params.append("target", obj?.[activeTab]);
      params.append("date_start", currentDate?.start);
      params.append("date_end", currentDate?.end);
      intervalValue && params.append("interval", intervalValue?.value);

      let res = await getPerformancePaidSocialApi(key, params.toString(), {
        cancelToken: cancelToken.current.token,
      });

      if (res?.status == 200) {
        cancelToken.current = null;
        manupilateData(res?.data);
      } else {
        setTableData([]);
        if (res?.name !== "CanceledError") {
          setPaidLoading(false);
        }
        setFilterDrawers(false);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request was canceled:", error.message);
      } else {
        console.error("An error occurred:", error);
      }
    }
  };

  useEffect(() => {
    if (activeTab) {
      handlePerformancePaidSocial();
    }
  }, [currentDate, activeTab]);

  const content = (
    <ContentStyle>
      <div>
        <HeaderSettingIcon color="black" />
        <p
          onClick={() => {
            setHeaderPopover(false);
            setSettingDrawers(true);
          }}
        >
          Manage Settings
        </p>
      </div>
      <div>
        <ManageSettingIcon />
        <p
          onClick={() => {
            setHeaderPopover(false);
            navigate("/private-layout/paid-category");
          }}
        >
          Manage Categories
        </p>
      </div>
    </ContentStyle>
  );

  return (
    <>
      <Header
        title="Paid + LSA Perf."
        clientInfo={filterData || prefilledData?.selectedClient}
        isFilter={true}
        openFilter={() => setFilterDrawers(true)}
        isSetting={true}
        popOverContent={content}
        openPopover={headerPopover}
        setPopOver={setHeaderPopover}
        isNotification={true}
      />
      <PaidSearchWrapper selectedThemeColors={selectedThemeColors}>
        {settingDrawers && (
          <Drawer
            className="filter-drawer"
            placement="right"
            closable={false}
            onClose={() => setSettingDrawers(false)}
            open={settingDrawers}
            width={"600px"}
            key="bottom"
          >
            <PaidSearchSettings
              paidLoading={paidLoading}
              setFilterDrawer={setSettingDrawers}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />
          </Drawer>
        )}

        {filterDrawers && (
          <Drawer
            className="filter-drawer"
            placement="right"
            closable={false}
            onClose={() => setFilterDrawers(false)}
            open={filterDrawers}
            width={"400px"}
            key="bottom"
          >
            <PaidFilter
              paidLoading={paidLoading}
              handleGetPaidSearch={handlePerformancePaidSocial}
            />
          </Drawer>
        )}
        {/* <div style={{ display: "flex", alignItems: "center" }}>
        <img
          className="logo-as-per-client"
          src={getSelectedClientLogo(
            AllClients,
            filterData ? filterData?.name : prefilledData?.selectedClient?.name
          )}
          alt=""
        />

        <h3 className="client-name">
          Client Name :{" "}
          {filterData ? filterData?.name : prefilledData?.selectedClient?.name}
        </h3>
      </div> */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
            flexWrap: "wrap",
          }}
        >
          <TabComponent
            tabs={[" Paid Search", "LSA Performance", "Paid Social", "Yelp"]}
            selectedIndex={handleAction}
            // disableBtnsOnLoading={paidLoading}
          />

          <div className="filterBtn-Box">
            {!paidLoading && (
              <RangeWrapper>
                <RangeSelectorConfirm
                  defaultDate={[
                    prefillSettings?.startDate
                      ? moment(prefillSettings?.startDate, "yyyy-MM-DD").format(
                          "yyyy-MM-DD"
                        )
                      : prefilledData?.date_start
                      ? moment(prefilledData?.date_start, "yyyy-MM-DD").format(
                          "yyyy-MM-DD"
                        )
                      : null,
                    prefillSettings?.endDate
                      ? moment(prefillSettings?.endDate, "yyyy-MM-DD").format(
                          "yyyy-MM-DD"
                        )
                      : prefilledData?.date_end
                      ? moment(prefilledData?.date_end, "yyyy-MM-DD").format(
                          "yyyy-MM-DD"
                        )
                      : null,
                  ]}
                  handleChange={(d) => {
                    const start = moment(d[0]).format("yyyy-MM-DD");
                    const end = moment(d[1]).format("yyyy-MM-DD");
                    setCurrentDate({ start, end });
                  }}
                />
              </RangeWrapper>
            )}

            {/* {activeTab && (
              <button onClick={() => setSettingDrawers(true)}>Settings</button>
            )} */}
            {/* <button onClick={() => setFilterDrawers(true)}>Filters</button> */}
            {/* <button onClick={() => navigate("/private-layout/paid-category")}>
              Manage Categories
            </button> */}
          </div>
        </div>

        {paidLoading ? (
          <LoadingBlock height={"calc(100vh - 157px)"} size={24} />
        ) : (
          <>
            {/* <RangeWrapper>
            <RangeSelectorConfirm
              defaultDate={[
                prefillSettings?.startDate
                  ? moment(prefillSettings?.startDate, "yyyy-MM-DD").format(
                      "yyyy-MM-DD"
                    )
                  : prefilledData?.date_start
                  ? moment(prefilledData?.date_start, "yyyy-MM-DD").format(
                      "yyyy-MM-DD"
                    )
                  : null,
                prefillSettings?.endDate
                  ? moment(prefillSettings?.endDate, "yyyy-MM-DD").format(
                      "yyyy-MM-DD"
                    )
                  : prefilledData?.date_end
                  ? moment(prefilledData?.date_end, "yyyy-MM-DD").format(
                      "yyyy-MM-DD"
                    )
                  : null,
              ]}
              handleChange={(d) => {
                const start = moment(d[0]).format("yyyy-MM-DD");
                const end = moment(d[1]).format("yyyy-MM-DD");
                setCurrentDate({ start, end });
              }}
            />
          </RangeWrapper> */}
            {activeTab ? (
              <div className="table">
                <Table
                  prefixCls="custom-ui-table"
                  columns={columns}
                  dataSource={reloadReqiured ? [] : tableData}
                  pagination={false}
                  scroll={{
                    x: "100%",
                    y: "500px",
                  }}
                />
              </div>
            ) : (
              <NoDataUI
                icon={<PaidLSAPerfTabs />}
                heading="Please select the tab"
                para="Use the tabs above to specify your desired data."
              />
            )}
          </>
        )}
      </PaidSearchWrapper>
      <Watermark background="#f6f9fc" style={{padding: "0 10px"}}/>
    </>
  );
};

export default PaidSearch;

const RangeWrapper = styled.div`
  width: 250px;
  padding: 0 10px;
  .ant-picker-range-separator {
    filter: none !important;
  }
`;

const PaidSearchWrapper = styled.div`
  padding: 20px;
  height: calc(100vh - 78px);

  .client-name {
    color: #000;
    font-size: 16px;
  }
  .filterBtn-Box {
    height: 35px;
    button {
      border-style: none;
      background: rgb(36, 36, 36) !important;
      color: #fff;
      padding: 7px 20px;
      font-size: 13px;
      border-radius: 7px;
      margin-bottom: 0px;
    }
    .btnColor {
      color: black;
      background: white !important;
      border: 1px solid;
      box-shadow: 5px 7px 7px 0px rgb(0 0 0 / 30%);
    }
    .disable {
      cursor: not-allowed;
      background: rgb(94 93 93) !important;
    }
  }
  .campaignTable {
    .campaignTable-thead {
      .campaignTable-cell {
        background: ${({ selectedThemeColors }) =>
          selectedThemeColors?.headerColor} !important;
      }
    }
    .campaignTable-tbody {
      .campaignTable-cell {
        background: ${({ selectedThemeColors }) =>
          selectedThemeColors?.CardsColor};
        color: ${({ selectedThemeColors }) =>
          selectedThemeColors?.textPrimaryColor};
        border-bottom: 1px solid rgba(232, 232, 237, 1) !important;
      }
    }
    .campaignTable-container {
      max-height: calc(100vh - 230px) !important;
      overflow: auto !important;
    }
    .campaignTable-thead {
      position: sticky !important;
      top: 0 !important;
      z-index: 99 !important;
    }
  }
`;

const ContentStyle = styled.div`
  > div {
    display: flex;
    gap: 10px;
    justify-content: start;
    align-items: center;
    height: 36px;
    padding: 8px 6px;
    border-radius: 6px;
    cursor: pointer;

    p {
      margin: 0;
    }

    :hover {
      background: #e4e4e4;
    }
  }
`;
