import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  selectedHistory: null,
  selectedGoalHistory: null,
  selectedHistoryData: null,
  yearSheetFilters: {},
  goalFilters: {},
  yearData: {
    current: [],
    prediction: [],
  },
  GoalPrediction: null,
  predicationLabelValue: [],
  finalReport: {
    filters: null,
    data: null,
  },
};

export const DynamicBudgetModuleSlice = createSlice({
  name: "DynamicBudgetModuleSlice",
  initialState,
  reducers: {
    updateExcelDataBudget: (state, action) => {
      state.data = action?.payload;
    },
    activeHistorySheet: (state, action) => {
      state.selectedHistory = action?.payload;
    },
    activeGoalHistory: (state, action) => {
      state.selectedGoalHistory = action?.payload;
    },
    activeHistorySheetData: (state, action) => {
      state.selectedHistoryData = action?.payload;
    },
    updateYearSheetFilters: (state, action) => {
      state.yearSheetFilters = action?.payload;
    },
    updateGoalFilters: (state, action) => {
      state.goalFilters = action?.payload;
    },
    updateYearData: (state, action) => {
      if (action?.payload) {
        if (action?.payload?.name === "current") {
          state.yearData.current = action?.payload?.categories;
        }
        if (action?.payload?.name === "prediction") {
          state.yearData.prediction = action?.payload?.categories;
        }
        if (action?.payload?.current && action?.payload?.prediction) {
          state.yearData = action?.payload;
        }
      } else {
        state.yearData = { current: [], prediction: [] };
      }
    },
    updateGoalPrediction: (state, action) => {
      if (action?.payload) {
        state.GoalPrediction = action.payload;
      } else {
        state.GoalPrediction = null;
      }
    },
    updatePredicationLabelValue: (state, action) => {
      if (action?.payload) {
        state.predicationLabelValue = action.payload;
      }
    },
    finalReportFilter: (state, action) => {
      if (action?.payload) {
        state.finalReport.filters = action?.payload;
      }
    },
    finalReportData: (state, action) => {
      if (action?.payload) {
        state.finalReport.data = action?.payload;
      }else{
        state.finalReport.data = null;
      }
    },
    resetDynamicBudgetSlice: () => initialState,
  },
});

export const {
  updateExcelDataBudget,
  activeHistorySheet,
  activeHistorySheetData,
  activeGoalHistory,
  updateYearSheetFilters,
  updateGoalFilters,
  updateYearData,
  updateGoalPrediction,
  updatePredicationLabelValue,
  resetDynamicBudgetSlice,
  finalReportData,
  finalReportFilter,
} = DynamicBudgetModuleSlice.actions;
export default DynamicBudgetModuleSlice.reducer;
