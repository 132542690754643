import { DatePicker, Input, Select } from "antd";
import dayjs from "dayjs";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import * as yup from "yup";
import {
  getBussinessUnitsListing,
  getJobTypesListing,
} from "../../services/Collections";
import { updateVisualizationData } from "../../app/Auth/signIn/LogInSlice";
import RangeSelector from "../../components/RangePicker";
import moment from "moment";
import { filterClientsBySection } from "../../utils/common/commonFunction";
import { NoDataDropdown } from "../../components/common/NoDataDropdown";

const customerTypes = [
  {
    label: "All Customers",
    value: "all",
  },
  {
    label: "New Customer",
    value: "new",
  },
];

const defaultInit = {
  date_start: "",
  date_end: "",
  date_new_cus_start: "",
  customer_type: "",
  selectedClient: {},
};

const validationSchema = yup.object().shape({
  // date_start: yup.date().required("Start Date is required"),
  date_end: yup.date().when("date_start", (date_start, schema) => {
    if (date_start && date_start[0]) {
      return (
        date_start[0] &&
        schema.min(date_start[0], "End date must be greater than start date")
      );
    }
    return schema.required("End Date is required");
  }),
});

function VisualizationForm({
  handleFilter,
  initValues = defaultInit,
  filterLoading,
  showFilter,
}) {
  const [clientOptions, setclientOptions] = useState([]);
  const clientOptionsData = useSelector(
    (state) => state?.loginAuth?.clientsList
  );
  const [jobTypes, setJobTypes] = useState([]);
  const [businesUnits, setBusinessUnits] = useState([]);
  const [jobLoading, setJobLoading] = useState(false);
  const [businessLoading, setBusinessLoading] = useState(false);
  const dispatch = useDispatch();

  const getJobTypes = async (clientkey) => {
    setJobLoading(true);
    const res = await getJobTypesListing(clientkey);
    const { data } = res || {};
    const updatedList = data?.map((list) => ({
      label: list?.name,
      value: list?.id,
    }));
    setJobTypes(updatedList);
    setJobLoading(false);
  };

  const getBussinessUnits = async (clientkey) => {
    setBusinessLoading(true);
    const res = await getBussinessUnitsListing(clientkey);
    const { data } = res || {};
    const updatedList = data?.map((list) => ({
      label: list?.name,
      value: list?.id,
    }));
    setBusinessUnits(updatedList);
    setBusinessLoading(false);
  };

  const getClientOption = () => {
    let arr = [];
    filterClientsBySection(clientOptionsData?.clients, "visualization")
      ?.length > 0 &&
      // eslint-disable-next-line array-callback-return
      filterClientsBySection(clientOptionsData?.clients, "visualization")?.map(
        (ele) => {
          let obj = {
            ...ele,
            label: ele?.name,
            value: ele?.name,
          };
          arr.push(obj);
        }
      );
    setclientOptions(arr);
  };

  useEffect(() => {
    getClientOption();
    getBussinessUnits(initValues?.selectedClient?.key);
    getJobTypes(initValues?.selectedClient?.key);
  }, []);

  const handleForm = (e) => {
    handleFilter(e);
  };

  return (
    <VisualFormWrapper>
      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={handleForm}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form>
            <div className="campaign-filters">
              <div className="title">
                <h4 style={{ color: "rgba(25, 25, 25, 1)" }}>Filters</h4>
              </div>
              <div className="content">
                <div className="datepicker-box">
                  <div className="input-box">
                    <label>
                      <strong>Client</strong>
                    </label>
                    <br />
                    <Field name="selectedClient" type="string">
                      {({ form: { setFieldValue }, field: { value } }) => (
                        <Select
                          size="large"
                          prefixCls="inner-select2"
                          onChange={(_, { db, key, label, name, value, logo }) => {
                            setFieldValue("selectedClient", {
                              db,
                              key,
                              label,
                              name,
                              value,
                              logo,
                            });
                            dispatch(
                              updateVisualizationData({
                                ...initValues,
                                job_types: [],
                                business_units: [],
                              })
                            );
                            setFieldValue("business_units", []);
                            setFieldValue("job_types", []);
                            setJobTypes([]);
                            setBusinessUnits([]);
                            getBussinessUnits(key);
                            getJobTypes(key);
                          }}
                          style={{
                            width: "100%",
                            height: "48px",
                            marginTop: "6px",
                          }}
                          maxTagCount={1}
                          placeholder="Please select"
                          options={clientOptions}
                          value={value}
                        />
                      )}
                    </Field>
                  </div>

                  {showFilter && (
                    <>
                      <div style={{ marginTop: "20px" }} className="input-box">
                        <label>
                          <strong>Select Date</strong>
                        </label>{" "}
                        <RangeSelector
                          defaultDate={[
                            values?.date_start
                              ? moment(values?.date_start, "yyyy-MM-DD").format(
                                "yyyy-MM-DD"
                              )
                              : null,
                            values?.date_end
                              ? moment(values?.date_end, "yyyy-MM-DD").format(
                                "yyyy-MM-DD"
                              )
                              : null,
                          ]}
                          handleChange={(d) => {
                            const start = d[0];
                            const end = d[1];
                            setFieldValue("date_start", start);
                            setFieldValue("date_end", end);
                          }}
                        />
                      </div>
                      <div style={{ marginTop: "20px" }} className="input-box">
                        <label>
                          <strong>New Customer Number of day</strong>
                        </label>{" "}
                        <Input
                          style={{ height: "42px" }}
                          value={values?.new_customer_nod}
                          onChange={(e) => setFieldValue("new_customer_nod", e.target.value)}
                        />
                        {/* <DatePicker
                          value={[
                            values?.date_new_cus_start
                              ? dayjs(values?.date_new_cus_start)
                              : null,
                          ]}
                          prefixCls="visualizationDatepicker"
                          onChange={(_, date) => {
                            setFieldValue("date_new_cus_start", date);
                          }}
                        /> */}
                      </div>
                      <div style={{ marginTop: "20px" }} className="input-box">
                        <label>
                          <strong>Customer (Only for Marketing Sources)</strong>
                        </label>
                        <br />
                        <Field name="customer_type" type="string">
                          {({ form: { setFieldValue }, field: { value } }) => (
                            <Select
                              size="large"
                              // className="inner-select custom-Select-Campaign"
                              prefixCls="inner-select2"
                              onChange={(value) => {
                                setFieldValue("customer_type", value);
                              }}
                              style={{
                                width: "100%",
                                height: "48px",
                                marginTop: "6px",
                              }}
                              maxTagCount={1}
                              placeholder="Please Select"
                              defaultValue={customerTypes?.filter(
                                (list) => list.value === value
                              )}
                              options={customerTypes}
                            />
                          )}
                        </Field>
                      </div>
                      <div style={{ marginTop: "20px" }} className="input-box">
                        <label>
                          <strong>Job Type</strong>
                        </label>
                        <br />
                        <Field name="job_types" mode="multiple" type="string">
                          {({ form: { setFieldValue }, field: { value } }) => (
                            <Select
                              size="large"
                              mode="multiple"
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              allowClear
                              // className="inner-select custom-Select-Campaign"
                              prefixCls="inner-select2"
                              onChange={(value) => {
                                setFieldValue("job_types", value);
                              }}
                              value={jobTypes?.length > 0 ? value : null}
                              loading={jobLoading}
                              style={{
                                width: "100%",
                                height: "48px",
                                marginTop: "6px",
                              }}
                              maxTagCount={1}
                              placeholder="Please Select"
                              options={jobTypes}
                              notFoundContent={
                                jobLoading ? (
                                  <NoDataDropdown text={"Processing..."} />
                                ) : (
                                  <NoDataDropdown text={"No data"} />
                                )
                              }
                            />
                          )}
                        </Field>
                      </div>
                      <div style={{ marginTop: "20px" }} className="input-box">
                        <label>
                          <strong>Business Unit</strong>
                        </label>
                        <br />
                        <Field name="business_units" type="string">
                          {({ form: { setFieldValue }, field: { value } }) => (
                            <Select
                              size="large"
                              mode="multiple"
                              allowClear
                              // className="inner-select custom-Select-Campaign"
                              prefixCls="inner-select2"
                              value={businesUnits?.length > 0 ? value : null}
                              onChange={(data) => {
                                setFieldValue("business_units", data);
                              }}
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              style={{
                                width: "100%",
                                height: "48px",
                                marginTop: "6px",
                              }}
                              loading={businessLoading}
                              maxTagCount={1}
                              placeholder="Please Select"
                              options={businesUnits}
                              notFoundContent={
                                businessLoading ? (
                                  <NoDataDropdown text={"Processing..."} />
                                ) : (
                                  <NoDataDropdown text={"No data"} />
                                )
                              }
                            />
                          )}
                        </Field>
                      </div>
                    </>
                  )}

                  <div className="button-wrap">
                    {filterLoading ? (
                      <button type="button">
                        <b>Loading...</b>
                      </button>
                    ) : (
                      <button type="submit">
                        <b>Apply</b>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </VisualFormWrapper>
  );
}

export default VisualizationForm;

const VisualFormWrapper = styled.div`
  .error-msg {
    color: red;
    font-size: 12px;
    padding: 3px 8px;
  }
`;
