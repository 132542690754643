import { useEffect, useState } from "react";

function useDayChangeEffect(callback) {
  const [currentDay, setCurrentDay] = useState(new Date().toDateString());

  useEffect(() => {
    const checkForDayChange = () => {
      const now = new Date().toDateString();
      if (now !== currentDay) {
        setCurrentDay(now);
        callback();
      }
    };

    // Check for day change every minute
    const intervalId = setInterval(checkForDayChange, 60000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [currentDay, callback]);

  return currentDay;
}

export default useDayChangeEffect;
