import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  campanyListing: [],
  selectedCompany: null,
  startDate: null,
  endDate: null,
  activeTab: "bookingStats",
  bookingDateFilter: null,
  bookingDateType: "created_at",
  companyList: [],
  lastSeen: {
    date: null,
    hasSeen: false,
  },
  messageType: "chatwidget",
  selectedChat: {},
};

export const AiBookingSlice = createSlice({
  name: "AiBookingSlice",
  initialState,
  reducers: {
    updateToken: (state, action) => {
      state.token = action?.payload;
    },
    updateCampanyListing: (state, action) => {
      state.interval = action?.payload;
    },
    updateDate: (state, action) => {
      state.startDate = action?.payload?.date_start;
      state.endDate = action?.payload?.date_end;
    },
    selectedAiCompany: (state, action) => {
      state.selectedCompany = action?.payload;
    },
    aiCompanyListing: (state, action) => {
      state.companyList = action?.payload;
    },
    willVanishToken: (state, action) => {
      state.token = null;
      state.selectedCompany = null;
      state.campanyListing = [];
    },
    updateActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    updateSeenStatus: (state, action) => {
      state.lastSeen = action.payload;
    },
    updateMessageType: (state, actions) => {
      if (actions) {
        state.messageType = actions.payload;
      }
    },
    updateSelectedChat: (state, actions) => {
      if (actions) {
        state.selectedChat = actions.payload;
      }
    },
    clearBookingDate: (state, action) => {
      state.startDate = null;
      state.endDate = null;
    },
    updateBookingDateFilter: (state, action) => {
      state.bookingDateFilter = action?.payload;
    },
    updateBookingDateType: (state, action) => {
      state.bookingDateType = action?.payload;
    },
    reset_AiBookingSlice: () => initialState,
  },
});

export const {
  updateToken,
  updateCampanyListing,
  selectedAiCompany,
  willVanishToken,
  updateDate,
  updateActiveTab,
  updateSeenStatus,
  updateMessageType,
  updateSelectedChat,
  aiCompanyListing,
  clearBookingDate,
  updateBookingDateFilter,
  updateBookingDateType,
  reset_AiBookingSlice,
} = AiBookingSlice.actions;
export default AiBookingSlice.reducer;
