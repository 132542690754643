import { useState, useEffect, useCallback } from 'react';

export function useOnlineStatus() {
  const [isOnline, setIsOnline] = useState(true);

  const checkOnlineStatus = useCallback(async () => {
    try {
      // Try to fetch a resource from a public CDN
      const response = await fetch('https://www.cloudflare.com/cdn-cgi/trace', {
        mode: 'no-cors',
        cache: 'no-store',
      });
      
      // If we can reach the CDN, we're online
      setIsOnline(true);
    } catch (error) {
      // If the fetch fails, we're offline
      setIsOnline(false);
    }
  }, []);

  useEffect(() => {
    const handleOnline = () => {
      console.log("Browser reports online");
      checkOnlineStatus();
    };
    
    const handleOffline = () => {
      console.log("Browser reports offline");
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Initial check
    checkOnlineStatus();

    // Set up interval for periodic checks
    const intervalId = setInterval(checkOnlineStatus, 10000); // Check every 10 seconds

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      clearInterval(intervalId);
    };
  }, [checkOnlineStatus]);

  return isOnline;
}