import { useLayoutEffect, useState } from "react";

export const useInfiniteScroll = ({ elementId = "main-2", height = 200 }) => {
  const [innerHeight, setInnerHeight] = useState(false);

  const updateInnerHeight = (e) => {
    setInnerHeight(
      Number(e.target.clientHeight) + Number(e.target.scrollTop) >=
        Number(e.target.scrollHeight) - height
    );
  }; // Adjust the debounce delay as needed

  useLayoutEffect(() => {
    const handleScroll = (e) => updateInnerHeight(e);

    const mainElement = document.getElementById(elementId);
    if (mainElement && mainElement.firstChild) {
      mainElement.firstChild.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (mainElement && mainElement.firstChild) {
        mainElement.firstChild.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return [innerHeight];
};
