import React from 'react'
import styled, { keyframes } from 'styled-components';
import { WifiOff } from 'lucide-react';

export default function NoInternetConnectionUI() {
    const handleRetry = () => {
        window.location.reload();
      };

  return (
    <Container>
    <Card>
      <IconWrapper>
        <WifiOff size={40} color="#ffffff" />
      </IconWrapper>
      <Title>No Internet Connection</Title>
      <Message>
        Oops! It looks like you're offline. Please check your internet connection and try again.
      </Message>
      <RetryButton onClick={handleRetry}>
        Retry Connection
      </RetryButton>
    </Card>
  </Container>
  )
}

const pulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(216, 12, 12, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(216, 12, 12, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(216, 12, 12, 0);
  }
`;
//    #242424, #ffffff1f, and #ffffff theme color - #d80c0c
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #242424, #d80c0c);
  color: #ffffff;
  font-family: 'Arial', sans-serif;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: 
      linear-gradient(to right, rgba(255,255,255,0.1) 1px, transparent 1px),
      linear-gradient(to bottom, rgba(255,255,255,0.1) 1px, transparent 1px);
    background-size: 40px 40px;
     mask-image: radial-gradient(circle, transparent 40%, black 100%);
  }
`;

const Card = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 2rem;
  text-align: center;
  max-width: 560px;
  width: 90%;
  z-index: 1;
`;

const IconWrapper = styled.div`
  background-color: #d80c0c;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.5rem;
  animation: ${pulse} 2s infinite;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const Message = styled.p`
  font-size: 1rem;
  margin-bottom: 1.5rem;
  line-height: 1.5;
`;

const RetryButton = styled.button`
  background-color: #d80c0c;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff1a1a;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(216, 12, 12, 0.5);
  }
`;