import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedClient: null,
  interval: {
    label: "90",
    value: 90,
  },
  activeTab: "paid",
};

export const LsaPerformanceSlice = createSlice({
  name: "LsaPerformanceSlice",
  initialState,
  reducers: {
    updateLsaClient: (state, action) => {
      state.selectedClient = action?.payload;
    },
    updateInterval: (state, action) => {
      state.interval = action?.payload;
    },
    updateActiveTab: (state, action) => {
      state.activeTab = action?.payload;
    },
    resetLsaPerformance: () => initialState,
  },
});

export const {
  updateLsaClient,
  updateInterval,
  updateActiveTab,
  resetLsaPerformance,
} = LsaPerformanceSlice.actions;
export default LsaPerformanceSlice.reducer;
