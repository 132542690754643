import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, Tooltip } from "antd";
import styled from "styled-components";
import { filterClientsBySection } from "../../utils/common/commonFunction";
import { BellIcon } from "../../utils/Icons/SvgIcons";
import LoadingBlock from "../../components/LoadingBlock";
import { getNotificationsListing } from "../../services/Collections";
import { toast } from "react-toastify";
import { updateNotificationClient } from "./NotificationSlice";

const NotificationFilter = () => {
  const dispatch = useDispatch();
  const { loginAuth, notificationCenter } = useSelector((state) => state);
  const clientOptionsData = loginAuth?.clientsList;
  const selectedThemeColors = loginAuth?.themeColors;
  const activeClient = notificationCenter?.selectedClient;
  const [clientOptions, setclientOptions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [notification, setNotification] = useState([]);

  const [selectedClient, setSelectedClient] = useState(
    activeClient?.length > 0 ? activeClient : null
  );

  const handleSelectedClient = (data) => {
    setSelectedClient(clientOptions);
    setSelectedClient(data);

    dispatch(updateNotificationClient(data));
  };

  const getClientOption = () => {
    let arr = [];
    filterClientsBySection(clientOptionsData?.clients, "notification-center")
      ?.length > 0 &&
      filterClientsBySection(
        clientOptionsData?.clients,
        "notification-center"
      )?.map((ele) => {
        let obj = {
          ...ele,
          label: ele?.name,
          value: ele?.name,
        };
        arr.push(obj);
      });
    setclientOptions(arr);
  };

  useEffect(() => {
    getClientOption();
  }, []);

  const notificationListing = async () => {
    setLoader(true);
    let clients = notificationCenter?.selectedClient;
    let keys = clients?.length > 0 ? clients?.map((ele) => ele?.key) : clients;
    let payload = {
      client_keys: clientOptions?.length == keys?.length ? [] : keys,
    };

    let res = await getNotificationsListing(payload);

    if (res?.status == 200) {
      let arr = [];
      res?.data?.forEach((element) => {
        element?.notifications?.length > 0 &&
          element?.notifications?.forEach((ele) =>
            arr?.push({ ...element?.client, ...ele })
          );
      });
      setNotification(arr);
      setLoader(false);
    } else {
      setLoader(false);
      toast.error("Something went wrong");
    }
  };



  useEffect(() => {
    notificationListing();
  }, [activeClient]);

  return (
    <FilterWrapper selectedThemeColors={selectedThemeColors}>
      <div className="inner-part">
        <div className="content-box">
          <div className="filters">
            <h4>Notifications</h4>

            <Select
              size={"large"}
              name="clientKey"
              prefixCls="inner-select2"
              value={selectedClient ?? undefined}
              onChange={(_, data) => handleSelectedClient(data)}
              style={{
                width: "100%",
                height: "48px",
              }}
              maxTagCount={1}
              placeholder="Please select"
              options={clientOptions}
              dropdownStyle={{
                maxHeight: "300px",
              }}
              mode="multiple"
            />

            {loader ? (
              <LoadingBlock height={"calc(100vh - 175px)"} size={28} />
            ) : (
              <NotificationCenterStyle>
                <div className="notiWrapper">
                  {notification?.length > 0 ? (
                    notification?.map((ele) => (
                      <>
                        <div className="notification">
                          <i className="icon">
                            <BellIcon />
                          </i>

                          <div className="message">
                            <div className="innerMessage">
                              <div className="title">{ele?.title}</div>
                              {/* <div className="date">Today 11:00 Am</div> */}
                            </div>
                            <Tooltip
                              placement="top"
                              title={ele?.description}
                              arrow={false}
                            >
                              <p className="para">{ele?.description}</p>
                            </Tooltip>
                            <div className="clientName">
                              <div className="clientIcon">
                                <img src={ele?.logo} />
                              </div>
                              <span>{ele?.display_name}</span>
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <div className="noData">No Data Found</div>
                  )}
                </div>
              </NotificationCenterStyle>
            )}
          </div>
        </div>
      </div>
    </FilterWrapper>
  );
};
export default NotificationFilter;

export const FilterWrapper = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .inner-select2 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    appearance: none;
    height: 50px;
    width: 100%;
    margin-top: 4px;
    padding: 0px 0px;
    border: 1px solid rgba(206, 206, 206, 1);
    border-radius: 10px;
    color: #000;
    background: #fff;

    .anticon-close {
      color: #000 !important;
    }

    .inner-select2-selector {
      border: none !important;
    }
    .inner-select2-selection-placeholder {
      color: ${({ selectedThemeColors }) =>
        selectedThemeColors?.inputPlaceHolder} !important;
    }
    .inner-select2-suffix {
      color: rgba(146, 146, 165, 1) !important;
    }
    .inner-select2-selection-wrap {
      height: 100% !important;
    }
  }

  .channel-check-div {
    width: 100%;
    height: 53px;
    border-radius: 8px;
    border: 1px solid rgba(206, 206, 206, 1);
    padding: 0px 10px;
    margin-bottom: 10px;
    position: relative;
    background-color: #fff;
    color: #0f1011 !important;
    display: flex;
    align-items: center;
    ${"" /* justify-content: space-between; */}
    cursor: pointer;
  }
  .inner-part {
    .content-box {
      .header {
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;

        img {
          width: 50px;
        }
        h4 {
          color: #fff;
        }
      }
      .filters {
        h4 {
          margin: 0px 0;
          margin-bottom: 26px;
          color: #0f1011;
        }
        > div {
          margin-bottom: 8px;

          label {
            color: ${({ selectedThemeColors }) =>
              selectedThemeColors?.lightText};

            display: block;
            margin-bottom: 0.25rem;
          }
          span {
            display: flex;
            justify-content: space-between;

            span {
              color: #fff;
            }

            .ant-checkbox-inner {
              margin-top: 3px;
            }
            label {
              color: #7e7e7e;

              display: block;
              margin-bottom: 0.25rem;
              ${"" /* width: 100%; */}
            }
            .input {
              width: max-content;
              height: 20px;
              border-radius: 8px;
              border: none;
              padding: 0px 10px;
              margin-bottom: 10px;
              background-color: #171033;
              color: #fff;
            }
          }
          .input {
            width: 100%;
            height: 53px;
            border-radius: 8px;
            border: none;
            padding: 0px 10px;
            margin-bottom: 10px;
            background-color: #171033;
            color: #fff;
            ${
              "" /* border-color: #2a2833 !important;
            color: #3c2ac1 !important; */
            }
          }
          .ant-select {
            width: 100%;
            height: 53px;
            border-radius: 8px;
            border: none;

            .ant-select-selector {
              height: 100%;
              background-color: #05031a;
              border: none;
              .ant-select-selection-overflow {
                height: 100%;
                .ant-select-selection-overflow-item {
                  .ant-select-selection-item {
                    margin-bottom: 29px;
                    .ant-select-selection-item-content {
                      color: #fff;
                      cursor: context-menu;
                      maxwidth: 150px;
                      textoverflow: ellipsis;
                      overflow: hidden;
                      whitespace: nowrap;
                    }
                  }
                }
              }
            }
          }
        }
        button {
          background-color: ${({ selectedThemeColors }) =>
            selectedThemeColors?.buttonColor};
          border-color: #0f1011;
          color: #fff;
          text-align: center;
          width: 100%;
          font-weight: 700;
          height: 40px;
          border-radius: 8px;
          border: none;
          margin-top: 10px;
          font-size: 13px;
        }
      }
    }
  }
  .source-checkbox-wrapper .ant-checkbox-wrapper {
    margin-bottom: 0px !important;
    display: flex !important;

    .ant-checkbox {
      .ant-checkbox-inner {
        background-color: rgba(249, 249, 249, 1) !important;
        border: 1px solid rgba(206, 206, 206, 1);
      }
    }

    .ant-checkbox-inner {
      background-color: red !important;
      border: 1px solid rgba(206, 206, 206, 1);
    }
  }
`;

const NotificationCenterStyle = styled.div`
  background: #ffffff;
  font-family: Poppins;

  .notiWrapper {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: calc(100vh - 187px);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #c4bdbd;
      border-radius: 10px;
    }
  }

  .notification {
    background: #f6f6f6;
    height: 99px;
    max-height: auto;
    padding: 16px;
    border-radius: 10px;
    display: flex;
    gap: 17px;
    align-items: center;

    .innerMessage {
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: space-between;
    }

    .clientName {
      display: flex;
      align-items: center;
      gap: 6px;
      span {
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        color: #7a7b7a;
      }
    }

    .icon {
      display: flex;
      flex-direction: column;
      border-radius: 20px;

      svg {
        width: 32px;
      }
    }

    .clientIcon {
      background: #e3e3e3;
      height: 24px;
      border-radius: 4px;
      background: rgb(227, 227, 227);
      padding: 2px 5px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    :hover {
      background: #eff8fc;
    }
  }

  .message {
    width: 100%;
    .title {
      font-size: 14px;
      font-weight: 700;
      line-height: 14px;
    }
    .para {
      width: 355px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      margin: 0 0 4px 0;
    }
    .date {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: #7a7b7a;
    }
  }

  .noData {
    height: calc(100vh - 190px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #9d9d9d;
  }
`;
