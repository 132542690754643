import React from "react";
import { Modal, List } from "antd";
import PropTypes from "prop-types";

export const PermissionModal = ({ isVisible, onClose, scheduleData, type }) => {
  const renderSchedule = () => {
    return scheduleData.map((el, index) => (
      <List.Item key={index}>
        <span>
          {index + 1}. {el}
        </span>
      </List.Item>
    ));
  };

  return (
    <Modal
      title={type}
      open={isVisible}
      onCancel={onClose}
      footer={null}
      centered
    >
      <List>{renderSchedule()}</List>
    </Modal>
  );
};
