// This file will store the login credentials.
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  token: null,
  clientsList: null,
  dashboardData: null,
  openFilterModal: false,
  prefilledFilters: null,
  // campaignData: null,
  settingData: null,
  isSelectAll: false,
  // isSelectAllCampaign: true,
  prefilledSelected: null,
  channelPerformance: false,
  adleverageUserId: null,
  campaignsTableFilter: null,
  classificationSectionFilters: null,
  summarySectionFilter: null,
  reportTableSectionFilters: null,
  prefilledApplyMargin: null,
  alreadyAppliedMargin: true,
  reportData: null,
  visualizationData: null,
  paidSearchSettings: null,
  dynamicSheetTab: "goals",
  dynamicSettingTab: "businessUnits",
  paidSearchClient: null,
  aiCallClassificationCellData: [],
  themeColors: {
    headerColor: "rgba(36, 36, 36, 1)",
    headerProfileText: "rgba(18, 18, 18, 1)",
    CardsColor: "rgba(255, 255, 255, 1)",
    bgMainColor: "rgba(243, 243, 243, 1)",
    buttonColor: "rgba(216, 12, 12, 1)",
    graphFirstStrokeColor: "rgba(37, 208, 82, 1)",
    graphSecondStrokeColor: "rgba(216, 12, 12, 1)",
    textPrimaryColor: "rgba(0, 0, 0, 1)",
    progressColor: "rgba(216, 12, 12, 1)",
    progressBgColor: "rgba(15, 15, 15, 1)",
    lightText: "rgba(146, 146, 165, 1)",
    inputBgColor: "rgba(249, 249, 249, 1)",
    inputPlaceHolder: "rgba(146, 146, 165, 1)",
    inputBorder: "rgba(206, 206, 206, 1)",
  },
  loginType: true,
  selectedModuleTabs: null,
};

export const loginSlice = createSlice({
  name: "loginAuth",
  initialState,
  //This is login reducers.
  reducers: {
    loginSuccess: (state, action) => {
      if (action.payload) {
        console.log(action.payload, "action.payload");

        state.data = action.payload;
        state.token = action.payload.token;
        // state.permissions = action.payload.permissions;
      } else {
        state.data = {};
        state.token = null;
      }
    },
    dashboardData: (state, action) => {
      if (action) {
        state.dashboardData = action.payload;
      }
    },
    filterModal: (state, action) => {
      state.openFilterModal = action.payload;
    },
    prefilledFilters: (state, action) => {
      if (action) {
        state.prefilledFilters = action?.payload;
      }
    },
    // updateCampaignData: (state, action) => {
    //   if (action) {
    //     state.campaignData = action.payload;
    //   }
    // },
    updateSettingData: (state, action) => {
      if (action) {
        state.settingData = action.payload;
      }
    },
    updateSelectAll: (state, action) => {
      if (action) {
        state.isSelectAll = action.payload;
      }
    },
    // updateSelectAllCampaigns: (state, action) => {
    //   if (action) {
    //     state.isSelectAllCampaign = action.payload;
    //   }
    // },
    alreadySelected: (state, action) => {
      if (action) {
        state.prefilledSelected = action.payload;
      }
    },
    prefilledChecksDashboard: (state, action) => {
      if (action) {
        state.channelPerformance = action.payload;
      }
    },
    loginUserDetail: (state, action) => {
      if (action) {
        state.adleverageUserId = action.payload;
      }
    },
    campaignFilterDetails: (state, action) => {
      if (action) {
        state.campaignsTableFilter = action.payload;
      }
    },
    classificationFilterDetails: (state, action) => {
      if (action) {
        state.classificationSectionFilters = action.payload;
      }
    },
    summaryFilterDetails: (state, action) => {
      if (action) {
        state.summarySectionFilter = action.payload;
      }
    },
    reportTableFilter: (state, action) => {
      if (action) {
        state.reportTableSectionFilters = action.payload;
      }
    },
    preFillApplyMargin: (state, action) => {
      if (action) {
        state.prefilledApplyMargin = action.payload;
      }
    },
    alrdyAppliedMargin: (state, action) => {
      if (action) {
        state.alreadyAppliedMargin = action.payload;
      }
    },
    updateReportData: (state, action) => {
      if (action) {
        state.reportData = action.payload;
      }
    },
    updateDynamicSheetTab: (state, action) => {
      if (action) {
        state.dynamicSheetTab = action.payload;
      }
    },
    updateDynamicSettingTab: (state, action) => {
      if (action) {
        state.dynamicSettingTab = action.payload;
      }
    },
    updateVisualizationData: (state, action) => {
      if (action) {
        state.visualizationData = action.payload;
      }
    },
    updateSearchSettings: (state, action) => {
      if (action) {
        state.paidSearchSettings = action.payload;
      }
    },
    updateThemeColors: (state, action) => {
      if (action) {
        state.themeColors = action.payload;
      }
    },
    updatepaidSearchClient: (state, action) => {
      if (action) {
        state.paidSearchClient = action?.payload;
      }
    },
    updateLoginType: (state, action) => {
      if (action) {
        state.loginType = action.payload;
      }
    },
    selectedModule: (state, action) => {
      if (action) {
        state.selectedModuleTabs = action.payload;
      }
    },
    updateClientList: (state, action) => {
      if (action) {
        state.clientsList = action.payload;
      }
    },
    updateAiCallClassificationCellData: (state, action) => {
      if (action) {
        if (action?.payload?.type === "append") {
          if (state?.aiCallClassificationCellData?.length > 0) {
            state.aiCallClassificationCellData = [
              ...state.aiCallClassificationCellData,
              action?.payload?.d,
            ];
          } else {
            state.aiCallClassificationCellData = [action?.payload?.d];
          }
        }

        if (action?.payload?.type === "clear") {
          state.aiCallClassificationCellData = [];
        }
      }
    },
    reset_loginAuth: () => initialState,
  },
});

//This is login actions.

export const {
  selectedModule,
  loginSuccess,
  dashboardData,
  filterModal,
  prefilledFilters,
  // updateCampaignData,
  updateSettingData,
  updateSelectAll,
  // updateSelectAllCampaigns,
  alreadySelected,
  prefilledChecksDashboard,
  loginUserDetail,
  campaignFilterDetails,
  classificationFilterDetails,
  summaryFilterDetails,
  reportTableFilter,
  preFillApplyMargin,
  alrdyAppliedMargin,
  updateReportData,
  updateDynamicSheetTab,
  updateDynamicSettingTab,
  updateVisualizationData,
  updateSearchSettings,
  updateThemeColors,
  updatepaidSearchClient,
  updateLoginType,
  updateAiCallClassificationCellData,
  updateClientList,
  reset_loginAuth,
} = loginSlice.actions;
export default loginSlice.reducer;
