import { Modal } from "antd";
import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { getExpiredCompanyList } from "../services/Collections";

export const ModalUI = ({ open, onOk }) => {
  const [loading, setLoading] = useState(true);
  const [companyList, setCompanyList] = useState([]);

  console.log(companyList, "companyList");

  const getCompanyListing = async () => {
    try {
      let res = await getExpiredCompanyList();
      if (res?.status === 200) {
        setLoading(false);
        let data = res?.data;
        console.log(data, "datadata");
        let array = [];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          if (element?.start_time) {
            const currentDate = new Date();
            const startDateObject = new Date(element?.start_time);
            const differenceInDays = Math.floor(
              (currentDate - startDateObject) / (1000 * 60 * 60 * 24)
            );
            console.log(differenceInDays, "differenceInDays");
            if (differenceInDays > 14) {
              array.push(element);
            }
          }
        }
        setCompanyList(array);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompanyListing();
  }, []);

  if (loading) return null;
  return (
    <Modal
      prefixCls="subscription-modal"
      closable={false}
      // onOk={onOk}
      open={!loading && open}
      footer={false}
      centered
    >
      <ModalBox>
        <h4 className="title">
          <b>
            <strong>Booking Buddy Trial Period Expired</strong>
          </b>
        </h4>

        <div className="content">
          {companyList?.map((el, idx) => (
            <div className="flex-box">
              <p>
                <b>{idx + 1}.</b>
              </p>
              <p className="companyName">
                <b>{el?.company}</b>
              </p>
            </div>
          ))}
        </div>

        <div className="btn-box">
          <button onClick={onOk}>OK</button>
        </div>
      </ModalBox>
    </Modal>
  );
};

const ModalBox = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 0;

  .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
    max-height: 400px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0px;
    }
    &::-webkit-scrollbar-thumb {
      background: #cecece;
      border-radius: 10px;
    }
  }

  .btn-box {
    width: 100%;

    button {
      font-weight: 500;
      height: 50px;
      cursor: pointer;
      border-radius: 0.75rem;
      width: 100%;
      background-color: rgb(216, 12, 12);
      border: 1px solid rgb(216, 12, 12);
      font-size: 14px;
      color: rgb(255, 255, 255);
      text-align: center;
    }
  }

  .flex-box {
    display: flex;
    gap: 10px;
    border-bottom: 1px solid #cecece52;
    ${"" /* justify-content: space-between; */}
    p {
      font-family: "Poppins";
      font-size: 15px;
    }
    .companyName {
      margin-right: 4px;
    }
  }

  .title {
    font-family: "Poppins";
    font-size: 22px;
  }
`;
