import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedClient: null,
};

export const QualityAssurance = createSlice({
  name: "qualityAssurance",
  initialState,

  reducers: {
    updateQualityAssuranceClient: (state, action) => {
      if (action) {
        state.selectedClient = action.payload;
      }
    },
    resetQualitySlice: () => initialState,
  },
});

export const { updateQualityAssuranceClient, resetQualitySlice } =
  QualityAssurance.actions;
export default QualityAssurance.reducer;
