import React, { forwardRef, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  Form,
  Button,
  InputNumber,
  message,
  Switch,
  Checkbox,
  Popover,
  Modal,
} from "antd";
import {
  formatNumber,
  formatToCurrencyWithDecimal,
} from "../../exceltable/commonFun";
import {
  calculateTotals,
  checkForInfinity,
  formatInteger,
  formatNumberDecimal,
  predictionCalculator,
} from "./commonFuc";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  budgetPredictedApi,
  proposalsHistoryApi,
} from "../../../services/Collections";
import { getRandomColor } from "../../../utils/common/commonFunction";
import {
  Arrowiconright,
  RemoveIcon,
  SheetPlusIcon,
} from "../../../utils/Icons/SvgIcons";
import TotalComponent from "./TotalComponent";
import DifferenceComponent from "./DifferenceComponent";
import BudgetSalesGraph from "../BudgetSalesGraph";
import {
  AdditionalMetrics,
  CategoryName,
  CategoryNameResponsive,
  CategoryWrap,
  Container,
  DynamicSheetWrapper,
  ExpendTag,
  FormButtonWrapper,
  Header,
  Label,
  ListContainer,
  Metric,
  MetricLabel,
  MetricsContainer,
  MetricValue,
  MinMetric,
  PopoverWrapper,
  StyledInputNumber,
  Tag,
} from "./styled";
import { updateYearData } from "../../exceltable/ExcelStore";
import { ModalWrapper } from "../Goals/Goals";
import { toast } from "react-toastify";
import Watermark from "../../../components/Watermark";

const formatterRegex = /\B(?=(\d{3})+(?!\d))/g;
const parseRegex = /\$\s?|(,*)/g;

const YearComponent = forwardRef(
  (
    {
      name,
      data,
      setYearTotal,
      category,
      subCategory,
      goalsCal,
      setGraphsData,
      graphsData,
      setLoading,
      yearSheetFilter,
      sortBy,
      handleUnique,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const persistYearData = useSelector(
      (state) => state?.dynamicBudgetSheet?.yearData
    );
    const goalPrediction = useSelector(
      (state) => state?.dynamicBudgetSheet?.GoalPrediction
    );
    const defaultValPrediction = useSelector(
      (state) => state?.dynamicBudgetSheet?.predicationLabelValue
    );
    const yearSheetFilters = useSelector(
      (state) => state?.dynamicBudgetSheet?.yearSheetFilters
    );
    const [userName, setUserName] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeTab, setActiveTab] = useState("current");
    const [loadBalancer, setLoadBalancer] = useState(true);
    const loginAuth = useSelector((state) => state?.loginAuth);
    const prefilledFilters = useSelector(
      (state) => state?.loginAuth?.summarySectionFilter
    );
    const prefilledData = useSelector(
      (state) => state?.loginAuth?.prefilledFilters
    );
    const userData = useSelector((state) => state?.loginAuth?.data);
    const activeClient =
      prefilledFilters == null
        ? prefilledData?.selectedClient?.key
        : prefilledFilters?.selectedClient?.key;

    const [form] = Form.useForm();

    const fieldForm = [
      {
        display: "Revenue",
        name: "sales",
        signType: 1, // 1 - Dollar and 2 - Percentage
      },
      {
        display: "CPL",
        name: "cpl",
        signType: 1,
      },
      {
        display: "Conv%",
        name: "con_per",
        signType: 2,
      },
      {
        display: "Close%",
        name: "close_per",
        signType: 2,
      },
      {
        display: "Avg. Ticket",
        name: "avg_ticket",
        signType: 1,
      },
    ];

    const pushHistory = () => {
      if (userName) {
        setIsModalOpen(false);
        onFinish(false);
      } else {
        toast.error("Please enter the history title", { theme: "colored" });
      }
    };

    const onFinish = async (isModalOpen = true) => {
      if (isModalOpen) {
        setIsModalOpen(true);
        return;
      }
      if (persistYearData?.current?.length === 0) {
        alert("Before submitting, Please check Prediction sheet once!");
        return;
      }
      try {
        onFinishPrediction();
        let payload = {
          save_date: moment().toISOString(),
          user_id: loginAuth?.data?.id,
          year: moment(new Date()).format("YYYY"),
          month: moment(new Date()).format("MM"),
          business_unit: category,
          service_type: subCategory,
          sheet: {
            historyTitle: userName,
            yearSheetFilters: yearSheetFilters,
            goalPrediction: goalPrediction,
            persistYearData: persistYearData,
          },
          total: {},
          budget_goal: 0,
          revenue_goal: 0,
          budget_per: 0,
          budget_goal_per: 0,
          budget_variance: 0,
        };
        let params = new URLSearchParams();
        params.append("client_key", activeClient);
        await proposalsHistoryApi(activeClient, params, payload);
      } catch (error) {
        // setLoading(false);
        console.log("error in businessUnitSubCategories", error);
      }
    };

    console.log(JSON.stringify(category?.toString()), "category");
    const onFinishPrediction = async () => {
      try {
        setLoading(true);

        let tempcategory = null;
        let duplicateArray = [];
        let AddCategories = {
          bu_category: category || [],
          bu_subcategory: subCategory || [],
        };
        let year = new Date()?.getFullYear() + 1;
        let month = yearSheetFilters?.month || 0;

        persistYearData?.current?.forEach((cat, index) => {
          if (cat?.isChecked || cat?.isParent) {
            if (
              tempcategory &&
              cat?.category_name !== tempcategory?.category_name
            ) {
              duplicateArray.push({ ...tempcategory, ...AddCategories });
            }
            tempcategory = {
              ...cat,
              user_id: userData?.id,
              year: year,
              month: month,
            };
          }
          if (index === data.length - 1 && tempcategory) {
            duplicateArray.push({ ...tempcategory, ...AddCategories });
          }
        });
        let payload = {
          title: userName,
          user_id: loginAuth?.data?.id,
          items: duplicateArray,
        };
        // category && category?.length > 0 && params.append("bu_category", category);
        // subCategory && subCategory?.length > 0 && params.append("bu_subcategory", subCategory);

        let res = await budgetPredictedApi(activeClient, payload);
        if (res?.status === 200) {
          message.success("Saved successfully");
          handleUnique();
          setLoading(false);
        } else {
          message.error("Something went wrong");
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log("error in businessUnitSubCategories", error);
      }
    };

    function updateCategoryData(index, value, field) {
      if (value === null) return;
      const values = form.getFieldsValue();
      const category = values.categories[index];
      // Update the field with the passed numeric value
      category[field] = value;

      if (category?.isDynaicBudget) {
        // ROAS IS NOT EDITABLE
        category.jobs = checkForInfinity(category.sales / category.avg_ticket);
        category.appts = checkForInfinity(
          (category.jobs / category.close_per) * 100
        );
        category.leads = checkForInfinity(
          (category.appts / category.con_per) * 100
        );
        category.budget = checkForInfinity(category.leads * category.cpl);
        category.roas = checkForInfinity(category.sales / category.budget);
        category.cpa = checkForInfinity(category.budget / category.appts);
        category.cpj = checkForInfinity(category.budget / category.jobs);
      } else {
        // BUDGET WILL NOT CALC AND CPL IS NOT EDITABLE
        if (field === "sales") {
          category.roas = checkForInfinity(category.sales / category.budget);
        } else if (field === "roas") {
          category.sales = checkForInfinity(category.budget * category.roas);
        } else {
          category.sales = checkForInfinity(category.budget * category.roas);
          category.roas = checkForInfinity(category.sales / category.budget);
        }
        category.jobs = checkForInfinity(category.sales / category.avg_ticket);
        category.appts = checkForInfinity(
          (category.jobs / category.close_per) * 100
        );
        category.leads = checkForInfinity(
          (category.appts / category.con_per) * 100
        );
        category.cpl = formatNumberDecimal(
          checkForInfinity(category.budget / category.leads)
        );
        category.cpa = checkForInfinity(category.budget / category.appts);
        category.cpj = checkForInfinity(category.budget / category.jobs);
      }

      // Calculate total for all categories
      dispatch(updateYearData({ categories: values?.categories, name }));
      form.setFieldsValue(values);

      let budgetArr = [];
      let revenueArr = [];

      values?.categories?.map((ele) => {
        let color = getRandomColor();
        budgetArr.push({
          name: ele?.category_name,
          value: ele?.budget,
          fill: color,
        });
        revenueArr.push({
          name: ele?.category_name,
          value: ele?.sales,
          fill: color,
        });
      });
      setGraphsData({ budget: budgetArr, revenue: revenueArr });
    }

    const handleCheckBox = (status, index, currentObj) => {
      let mainArr = form.getFieldValue().categories;
      mainArr.forEach((item) => {
        if (item.category_name === currentObj.category_name && item.isChecked) {
          item.isChecked = false;
        }
      });
      if (status) {
        mainArr[index] = { ...currentObj, isChecked: true };
      } else {
        const index = mainArr.findIndex(
          (item) =>
            item.category_name === currentObj.category_name && !item?.isParent
        );
        const object = mainArr.find(
          (item) =>
            item.category_name === currentObj.category_name && !item?.isParent
        );
        mainArr[index] = { ...object, isChecked: true };
      }
      dispatch(updateYearData({ categories: mainArr, name }));
      form.setFieldsValue({ categories: mainArr });
      setYearTotal(mainArr);
    };

    const updateExpend = (index, currentObj) => {
      let mainArr = form.getFieldValue().categories;
      const goalBudget = goalPrediction?.budget || 0;
      const goalSales = goalPrediction?.sales || 0;
      const categorySum = calculateTotals(
        mainArr?.filter((el) => el?.isParent)
      );
      const parantData = mainArr?.find(
        (el) => el?.category_name === currentObj.category_name && el?.isParent
      );

      mainArr.forEach((item) => {
        if (item.category_name === currentObj.category_name && item.isChecked) {
          let result = predictionCalculator(
            goalBudget,
            goalSales,
            categorySum,
            parantData
          );
          item.budget = result.budget;
          item.sales = result.sales;
          item.roas = result.roas;
          item.jobs = result.jobs;
          item.appts = result.appts;
          item.leads = result.leads;
          item.cpa = result.cpa;
          item.cpa = result.cpa;
          item.cpj = result.cpj;
          item.cpl = result.cpl;
          item.isChecked = false;
        }
      });
      console.log(mainArr, "mainArrmainArrmainArr");
      mainArr[index + 1].isChecked = true;
      setYearTotal(mainArr);
      dispatch(updateYearData({ categories: mainArr, name }));
      form.setFieldsValue({ categories: mainArr });
    };

    const removeExpend = () => {
      let mainArr = form.getFieldValue().categories;
      setYearTotal(mainArr);
      dispatch(updateYearData({ categories: mainArr, name }));
      // This state is only for the rending prepose!
      setLoadBalancer(!loadBalancer);
    };

    const handleMinimizeMaximize = (payload) => {
      let data = form.getFieldValue()?.categories;
      let updatedArray = data?.map((el) =>
        el?.category_name === payload?.category_name
          ? { ...el, showUi: !el?.showUi }
          : el
      );
      form.setFieldsValue({ categories: updatedArray });
    };

    function sortData(data, sortBy) {
      const isChildExist = data?.some((el) => !el?.isParent);
      const { name: key, type } = sortBy.option;

      const grouped = Object.values(
        data.reduce((acc, item) => {
          if (!acc[item.category_name]) acc[item.category_name] = [];
          acc[item.category_name].push(item);
          return acc;
        }, {})
      ).map((group) =>
        group.sort((a, b) => {
          if (a.isParent && !b.isParent) return -1;
          if (!a.isParent && b.isParent) return 1;
          return 0;
        })
      );

      grouped.sort((a, b) => {
        const parentA =
          a.find((item) => item.isChecked) ?? a.find((item) => item.isParent);
        const parentB =
          b.find((item) => item.isChecked) ?? b.find((item) => item.isParent);

        const valueA = parentA ? parentA[key] : 0;
        const valueB = parentB ? parentB[key] : 0;

        if (type === "asc") return valueA - valueB;
        if (type === "desc") return valueB - valueA;
        return 0;
      });
      return grouped.flat();
    }

    useEffect(() => {
      if (sortBy) {
        let data = form.getFieldValue()?.categories;
        const sorted = sortData(data, sortBy);
        form.setFieldsValue({ categories: sorted });
      } else {
        const initialPositions = [
          "Direct Mail",
          "Email Marketing",
          "Events",
          "Google LSA",
          "Misc Marketing",
          "Organic Social",
          "Outdoor",
          "Paid Search",
          "Paid Social",
          "Pay For Performance",
          "Print",
          "Production",
          "Radio",
          "Referral",
          "Repeat",
          "SEO",
          "Television",
          "Web",
          "Yelp",
        ];
        if (sortBy === undefined) {
          let data = form.getFieldValue()?.categories;
          const sorted = [];
          initialPositions?.forEach((val) =>
            data?.forEach(
              (ele) => ele?.category_name == val && sorted.push(ele)
            )
          );
          form.setFieldsValue({ categories: sorted });
        }
      }
    }, [sortBy]);

    useEffect(() => {
      if (data) {
        if (name === "current") {
          if (persistYearData?.current?.length) {
            form.setFieldsValue({ categories: persistYearData?.current });
          } else {
            form.setFieldsValue({ categories: data });
            dispatch(updateYearData({ categories: data, name: "current" }));
          }
        }
        // if (name === "prediction") {
        //   if (persistYearData?.prediction?.length) {
        //     form.setFieldsValue({ categories: persistYearData?.prediction });
        //   } else {
        //     const goalBudget = goalPrediction?.budget || 0;
        //     const salesBudget = goalPrediction?.sales || 0;
        //     let dataWithDefaultValues = data.map((item) => {
        //       let match = defaultValPrediction.find(
        //         (subItem) => subItem.category === item.category_name
        //       );
        //       if (match) {
        //         return {
        //           ...item,
        //           cpl: match.cpl,
        //           con_per: match.conv_percentage,
        //           close_per: match.close_percentage,
        //           avg_ticket: match.avg_ticket,
        //         };
        //       }
        //       return item;
        //     });
        //     const total = calculateTotals(dataWithDefaultValues);

        //     let predictionData = dataWithDefaultValues?.map((el) => {
        //       let newBudget =
        //         checkForInfinity(el?.budget / total?.budget) * goalBudget;
        //       let newSales =
        //         checkForInfinity(el?.sales / total?.sales) * salesBudget;
        //       let newRoas = checkForInfinity(newSales / newBudget);
        //       let newJobs = checkForInfinity(newSales / el?.avg_ticket);
        //       let newAppts = checkForInfinity((newJobs / el?.close_per) * 100);
        //       let newLeads = checkForInfinity((newAppts / el?.con_per) * 100);
        //       let newCpa = checkForInfinity(newBudget / newAppts);
        //       let newCpj = checkForInfinity(newBudget / newJobs);
        //       let newCpl = checkForInfinity(newBudget / newLeads);

        //       return {
        //         ...el,
        //         budget: newBudget,
        //         sales: newSales,
        //         roas: newRoas,
        //         jobs: newJobs,
        //         appts: newAppts,
        //         leads: newLeads,
        //         cpa: newCpa,
        //         cpj: newCpj,
        //         cpl: newCpl,
        //       };
        //     });
        //     form.setFieldsValue({ categories: predictionData });
        //     dispatch(
        //       updateYearData({ categories: predictionData, name: "prediction" })
        //     );
        //   }
        // }
        // This state is only for the rending prepose!
        setLoadBalancer(!loadBalancer);
      }
    }, [data, name, persistYearData]);

    return (
      <>
        <Modal
          open={isModalOpen}
          onOk={pushHistory}
          onCancel={() => setIsModalOpen(false)}
          prefixCls="goal"
        >
          <ModalWrapper>
            <p>Predicted Title</p>
            <input
              value={userName}
              type="text"
              placeholder="Please enter the predicted title"
              onChange={(e) => setUserName(e?.target?.value)}
            />
          </ModalWrapper>
        </Modal>

        <Form
          ref={ref}
          form={form}
          onFinish={onFinish}
          initialValues={{ categories: [] }}
        >
          <DynamicSheetWrapper active={yearSheetFilter}>
            <section className="right">
              <Form.List name="categories">
                {(fields, { add, remove }) => (
                  <ListContainer>
                    {fields.map((field, index) => {
                      let rowData = form.getFieldValue().categories[index];
                      return (
                        <>
                          {rowData?.showUi ? (
                            <Container
                              key={field.key}
                              style={{
                                margin: !rowData?.isParent ? "0 20px" : "0",
                                marginTop:
                                  index === 0
                                    ? "10px"
                                    : rowData?.isParent
                                    ? "20px"
                                    : "0",
                                padding: rowData?.isParent
                                  ? "10px 12px"
                                  : "20px 12px 10px 12px",
                              }}
                            >
                              {!rowData?.isParent && (
                                <Tag>
                                  {rowData?.isDynaicBudget
                                    ? "Dynamic"
                                    : "Static"}
                                </Tag>
                              )}

                              {rowData?.isParent && (
                                <ExpendTag
                                  rotation="90"
                                  onClick={() =>
                                    handleMinimizeMaximize(rowData)
                                  }
                                >
                                  <Arrowiconright />
                                </ExpendTag>
                              )}

                              <Header style={{ paddingRight: "42px" }}>
                                <CategoryWrap>
                                  {rowData?.isParent ? (
                                    <CategoryName>
                                      {rowData.category_name}
                                    </CategoryName>
                                  ) : (
                                    <Checkbox
                                      name="isChecked"
                                      checked={rowData?.isChecked}
                                      onChange={(e) =>
                                        handleCheckBox(
                                          e?.target?.checked,
                                          index,
                                          rowData
                                        )
                                      }
                                    >
                                      <CategoryName>
                                        {rowData.category_name}
                                      </CategoryName>
                                    </Checkbox>
                                  )}
                                  {rowData?.isParent && (
                                    <Popover
                                      content={
                                        <PopoverWrapper>
                                          <button
                                            onClick={() => {
                                              add(
                                                {
                                                  ...rowData,
                                                  isParent: false,
                                                  isChecked: true,
                                                  isDynaicBudget: true,
                                                },
                                                index + 1
                                              );
                                              updateExpend(index, rowData);
                                            }}
                                          >
                                            Predicted Dynamic Budget
                                          </button>
                                          <button
                                            onClick={() => {
                                              add(
                                                {
                                                  ...rowData,
                                                  isParent: false,
                                                  isChecked: true,
                                                  isDynaicBudget: false,
                                                },
                                                index + 1
                                              );
                                              updateExpend(index, rowData);
                                            }}
                                          >
                                            Predicted Static Budget
                                          </button>
                                        </PopoverWrapper>
                                      }
                                      trigger="hover"
                                    >
                                      <button
                                        type="button"
                                        style={{
                                          border: "none",
                                          background: "transparent",
                                        }}
                                      >
                                        <SheetPlusIcon />
                                      </button>
                                    </Popover>
                                  )}
                                  {!rowData?.isParent && (
                                    <i
                                      className="expend"
                                      onClick={() => {
                                        remove(index);
                                        removeExpend(index);
                                      }}
                                    >
                                      {" "}
                                      <RemoveIcon />{" "}
                                    </i>
                                  )}
                                </CategoryWrap>
                                <MetricsContainer>
                                  <>
                                    {fieldForm &&
                                      fieldForm?.map((el) => (
                                        <div>
                                          <Label>{el?.display}</Label>
                                          <StyledInputNumber
                                            prefixCls={
                                              rowData?.isParent
                                                ? "disabled-custom-number-input"
                                                : !rowData?.isDynaicBudget &&
                                                  el?.name === "cpl"
                                                ? "disabled-custom-number-input"
                                                : "custom-number-input"
                                            }
                                            name={[field.name, el?.name]}
                                            value={formatInteger(
                                              rowData[el?.name]
                                            )}
                                            formatter={(value) =>
                                              el?.signType === 1
                                                ? `$ ${value}`.replace(
                                                    formatterRegex,
                                                    ","
                                                  )
                                                : `${value}%`
                                            }
                                            parser={(value) =>
                                              el?.signType === 1
                                                ? value.replace(parseRegex, "")
                                                : value.replace("%", "")
                                            }
                                            onChange={(e) => {
                                              const regex = /^\d*\.?\d{0,2}$/;
                                              if (regex.test(e) || e === "") {
                                                updateCategoryData(
                                                  index,
                                                  e,
                                                  el?.name
                                                );
                                              }
                                            }}
                                            onKeyDown={(e) => {
                                              const currentValue =
                                                e.target.value;
                                              const decimalIndex =
                                                currentValue.indexOf(".");
                                              if (decimalIndex !== -1) {
                                                const decimals =
                                                  currentValue.slice(
                                                    decimalIndex + 1
                                                  );
                                                if (
                                                  decimals.length >= 2 &&
                                                  ![
                                                    "Backspace",
                                                    "Delete",
                                                  ].includes(e.key)
                                                ) {
                                                  e.preventDefault();
                                                }
                                              }
                                            }}
                                            disabled={
                                              rowData?.isParent
                                                ? true
                                                : !rowData?.isDynaicBudget &&
                                                  el?.name === "cpl"
                                                ? true
                                                : false
                                            }
                                          />
                                        </div>
                                      ))}
                                  </>
                                </MetricsContainer>
                              </Header>

                              <AdditionalMetrics>
                                <Form.Item noStyle shouldUpdate>
                                  {({ getFieldValue }) => {
                                    const budget = getFieldValue([
                                      "categories",
                                      index,
                                      "budget",
                                    ]);
                                    const roas = getFieldValue([
                                      "categories",
                                      index,
                                      "roas",
                                    ]);
                                    const leads = getFieldValue([
                                      "categories",
                                      index,
                                      "leads",
                                    ]);
                                    const appts = getFieldValue([
                                      "categories",
                                      index,
                                      "appts",
                                    ]);
                                    const cpa = getFieldValue([
                                      "categories",
                                      index,
                                      "cpa",
                                    ]);
                                    const jobs = getFieldValue([
                                      "categories",
                                      index,
                                      "jobs",
                                    ]);
                                    const cpj = getFieldValue([
                                      "categories",
                                      index,
                                      "cpj",
                                    ]);

                                    return (
                                      <>
                                        <Metric>
                                          <MetricLabel>Budget</MetricLabel>
                                          <MetricValue>
                                            ${formatInteger(budget)}
                                          </MetricValue>
                                        </Metric>
                                        <Metric>
                                          <MetricLabel>ROAS</MetricLabel>
                                          {rowData?.isParent ||
                                          rowData?.isDynaicBudget ? (
                                            <MetricValue>
                                              {formatNumberDecimal(roas)}
                                            </MetricValue>
                                          ) : (
                                            <MetricValue>
                                              <StyledInputNumber
                                                prefixCls={
                                                  "roas-custom-number-input"
                                                }
                                                value={Number(roas)}
                                                formatter={(value) =>
                                                  `${value}`.replace(
                                                    formatterRegex,
                                                    ","
                                                  )
                                                }
                                                parser={(value) =>
                                                  value.replace(parseRegex, "")
                                                }
                                                onChange={(e) => {
                                                  const regex =
                                                    /^\d*\.?\d{0,2}$/;
                                                  if (
                                                    regex.test(e) ||
                                                    e === ""
                                                  ) {
                                                    updateCategoryData(
                                                      index,
                                                      e,
                                                      "roas"
                                                    );
                                                  }
                                                }}
                                                onKeyDown={(e) => {
                                                  const currentValue =
                                                    e.target.value;
                                                  const decimalIndex =
                                                    currentValue.indexOf(".");
                                                  if (decimalIndex !== -1) {
                                                    const decimals =
                                                      currentValue.slice(
                                                        decimalIndex + 1
                                                      );
                                                    if (
                                                      decimals.length >= 2 &&
                                                      ![
                                                        "Backspace",
                                                        "Delete",
                                                      ].includes(e.key)
                                                    ) {
                                                      e.preventDefault();
                                                    }
                                                  }
                                                }}
                                              />
                                            </MetricValue>
                                          )}
                                        </Metric>
                                        <Metric>
                                          <MetricLabel>Leads</MetricLabel>
                                          <MetricValue>
                                            {formatInteger(leads)}
                                          </MetricValue>
                                        </Metric>
                                        <Metric>
                                          <MetricLabel>Appts</MetricLabel>
                                          <MetricValue>
                                            {formatInteger(appts)}
                                          </MetricValue>
                                        </Metric>
                                        <Metric>
                                          <MetricLabel>
                                            Cost per Appts
                                          </MetricLabel>
                                          <MetricValue>
                                            ${formatInteger(cpa)}
                                          </MetricValue>
                                        </Metric>
                                        <Metric>
                                          <MetricLabel>Sold Jobs</MetricLabel>
                                          <MetricValue>
                                            {formatInteger(jobs)}
                                          </MetricValue>
                                        </Metric>
                                        <Metric>
                                          <MetricLabel>
                                            Cost per Sold Jobs
                                          </MetricLabel>
                                          <MetricValue>
                                            ${formatInteger(cpj)}
                                          </MetricValue>
                                        </Metric>
                                      </>
                                    );
                                  }}
                                </Form.Item>
                              </AdditionalMetrics>
                            </Container>
                          ) : (
                            <>
                              {/* This is the minimize block */}
                              {rowData?.isParent && (
                                <Container
                                  style={{
                                    alignItems: "center",
                                    flexDirection: "row",
                                    margin: !rowData?.isParent ? "0 20px" : "0",
                                    marginTop:
                                      index === 0
                                        ? "10px"
                                        : rowData?.isParent
                                        ? "20px"
                                        : "0",
                                    padding: rowData?.isParent
                                      ? "10px 12px"
                                      : "20px 12px 10px 12px",
                                  }}
                                >
                                  <ExpendTag
                                    rotation="0"
                                    onClick={() =>
                                      handleMinimizeMaximize(rowData)
                                    }
                                  >
                                    <Arrowiconright />{" "}
                                  </ExpendTag>

                                  <CategoryNameResponsive>
                                    {rowData?.category_name}
                                  </CategoryNameResponsive>

                                  <Form.Item noStyle shouldUpdate>
                                    {({ getFieldValue }) => {
                                      const budget = getFieldValue([
                                        "categories",
                                        index,
                                        "budget",
                                      ]);
                                      const sales = getFieldValue([
                                        "categories",
                                        index,
                                        "sales",
                                      ]);
                                      const currentCell = getFieldValue([
                                        "categories",
                                        index,
                                      ]);
                                      const isCheckedCell = getFieldValue([
                                        "categories",
                                      ])?.find(
                                        (el) =>
                                          el?.category_name ===
                                            currentCell?.category_name &&
                                          el?.isChecked
                                      );

                                      console.log(
                                        currentCell,
                                        "roas",
                                        isCheckedCell
                                      );

                                      return (
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: "10px",
                                          }}
                                        >
                                          <MinMetric flexDirection="row">
                                            <MetricLabel
                                              style={{
                                                marginBottom: 0,
                                              }}
                                            >
                                              Budget
                                            </MetricLabel>
                                            <MetricValue>
                                              ${formatInteger(budget)}
                                            </MetricValue>
                                          </MinMetric>

                                          <MinMetric flexDirection="row">
                                            <MetricLabel
                                              style={{
                                                marginBottom: 0,
                                              }}
                                            >
                                              Revenue
                                            </MetricLabel>
                                            <MetricValue>
                                              ${formatInteger(sales)}
                                            </MetricValue>
                                          </MinMetric>

                                          <CategoryName style={{ alignSelf: "center", }} > New </CategoryName>

                                          <MinMetric flexDirection="row">
                                            <MetricLabel
                                              style={{
                                                marginBottom: 0,
                                              }}
                                            >
                                              Budget
                                            </MetricLabel>
                                            <MetricValue>
                                              $
                                              {formatInteger(
                                                isCheckedCell?.budget
                                              )}
                                            </MetricValue>
                                          </MinMetric>

                                          <MinMetric flexDirection="row">
                                            <MetricLabel
                                              style={{
                                                marginBottom: 0,
                                              }}
                                            >
                                              Revenue
                                            </MetricLabel>
                                            <MetricValue>
                                              $
                                              {formatInteger(
                                                isCheckedCell?.sales
                                              )}
                                            </MetricValue>
                                          </MinMetric>
                                        </div>
                                      );
                                    }}
                                  </Form.Item>
                                </Container>
                              )}
                            </>
                          )}
                        </>
                      );
                    })}
                  </ListContainer>
                )}
              </Form.List>

              {/* {graphsData && (
                <BudgetSalesGraph
                  budgetData={graphsData?.budget}
                  revenueData={graphsData?.revenue}
                />
              )} */}
            </section>
            <section className="left">
              <TotalComponent
                data={form.getFieldsValue()?.categories ?? data}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                name={name}
              />

              {name === "current" && (
                <DifferenceComponent
                  data={form.getFieldsValue()?.categories ?? data}
                  activeTab={activeTab}
                />
              )}

<Watermark />
            </section>
          </DynamicSheetWrapper>
        </Form>
      </>
    );
  }
);

export default YearComponent;
