import React, { useEffect, useState } from "react";
import {
  getCampaignTableData,
  getCampaignTableExportData,
  getSourceListForSetting,
  GetSourceTypeSources,
  postCampaignTableData,
} from "../../services/Collections";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  Checkbox,
  DatePicker,
  Drawer,
  Flex,
  Pagination,
  Select,
  Spin,
  Table,
  Tooltip,
} from "antd";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import { updateCampaignData, updateSelectAllCampaigns } from "./CampaignSlice";
import axios from "axios";
import RangeSelector from "../../components/RangePicker";
import {
  filterClientsBySection,
  getSelectedClientLogo,
} from "../../utils/common/commonFunction";
import { NoDataDropdown } from "../../components/common/NoDataDropdown";
import Header from "../../components/Header";
import LoadingBlock from "../../components/LoadingBlock";
import Watermark from "../../components/Watermark";

const source_type_options = [
  { label: "Google", value: "google" },
  { label: "Facebook", value: "facebook" },
  { label: "LSA", value: "lsa" },
  { label: "Yelp", value: "yelp" },
  { label: "Bing", value: "bing" },
];

const CampaignTable = () => {
  const dispatch = useDispatch();
  const campaignFilterFields = useSelector(
    (state) => state?.campaignSlice?.campaignData
  );
  const defaultIsSelectAll = useSelector(
    (state) => state?.campaignSlice?.isSelectAllCampaign
  );
  const clientOptionsData = useSelector(
    (state) => state?.loginAuth?.clientsList
  );
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );

  const [loading, setLoading] = useState(true);
  const [filterLoading, setFilterLoading] = useState(false);
  const [sourceLoader, setSourceLoader] = useState(false);
  const [openDrawer, setFilterDrawer] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [toatlRevenue, setTotalRevenue] = useState(0);
  const [clientOptions, setclientOptions] = useState([]);
  const [sourceOption, setSourceOption] = useState([]);
  const [pagesize, setPageSize] = useState(10);
  const [extraData, setExtraData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectAllData, setSelectAllData] = useState(
    campaignFilterFields?.selectAllData
  );
  const [scrollPage, setScrollPage] = useState(1);
  const [exportLoading, setExportLoading] = useState(false);
  const [sourceTypeLoading, setSourceTypeLoading] = useState(false);

  const initialValues = {
    start_date: campaignFilterFields?.date_start,
    end_date: campaignFilterFields?.date_end,
    selected_client: campaignFilterFields?.selectedClient,
    selected_values: campaignFilterFields?.selectSource || [],
    interval: campaignFilterFields?.interval,
    zero_dollar_job: campaignFilterFields?.zero_dollar_job,
    source_type: campaignFilterFields?.source_type,
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 40,
      }}
      spin
    />
  );

  const IntervalOption = [
    {
      label: "90",
      value: 90,
    },
  ];

  const columns = [
    {
      title: " Customer Id",
      dataIndex: "id",
      width: 150,
      fixed: "left",
    },
    {
      title: "Job No.",
      dataIndex: "jobNumber",
      width: 100,
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      width: 100,
      render: (val) => (
        <p style={{ marginBottom: "0px" }}>
          {valueFormatter(val, 2) || val == 0
            ? "$" + valueFormatter(val, 2)
            : "-"}
        </p>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      width: 100,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      width: 100,
      render: (val) => (
        <p style={{ margin: 0, whiteSpace: "nowrap" }}>{val ? val : "-"}</p>
      ),
    },
    {
      title: "CRM Campaign",
      dataIndex: "name",
      width: 100,
    },
    {
      title: "Business Unit",
      dataIndex: "businessUnit",
      width: 100,
    },
    {
      title: "Job Type",
      dataIndex: "jobType",
      width: 100,
    },
    {
      title: "Original Source",
      dataIndex: "source",
      width: 100,
    },
  ];

  const handleCompanignList = async (data, page, selected_client) => {
    setSourceLoader(true);
    let selectedClient;
    let search;

    if (data?.db) {
      selectedClient = data;
    } else {
      search = data;
    }

    let params = new URLSearchParams();
    params.append(
      "db",
      selected_client?.db ||
        selectedClient?.db ||
        campaignFilterFields?.selectedClient?.db
    );
    params.append("page_size", 5000);
    params.append("page", page ?? scrollPage);
    search && params.append("search", search);
    let res = await getSourceListForSetting(params?.toString());
    if (res?.status == 200) {
      let arr = [];
      res?.data?.items?.length > 0 &&
        res?.data?.items?.map((el) => {
          arr.push({
            label: el,
            value: el,
          });
        });
      if (page == 1 || scrollPage == 1) {
        setSourceOption(arr);
        setSourceLoader(false);
      } else {
        setSourceOption([...sourceOption, ...arr]);
        setSourceLoader(false);
      }
    } else {
      setSourceLoader(false);
    }
  };

  const onChangePagination = (e, limit) => {
    setCurrentPage(e);
    setPageSize(limit);
  };

  const valueFormatter = (num, digits) => {
    if (num > 999) {
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup
        .slice()
        .reverse()
        .find(function (item) {
          return num >= item.value;
        });
      return item
        ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
        : "0";
    } else {
      return Math.floor(num);
    }
  };

  const handleListing = async (value) => {
    if (campaignFilterFields?.selectSource?.length != sourceOption?.length) {
      setSelectAllData(false);
      dispatch(updateSelectAllCampaigns(false));
    }
    let filter = true;
    const checkFilter = filter && value?.start_date && value?.end_date;
    let params = new URLSearchParams();

    const startDate = value?.start_date || campaignFilterFields?.date_start;
    const endDate = value?.end_date || campaignFilterFields?.date_end;
    const client =
      value?.selected_client || campaignFilterFields?.selectedClient;
    const selectedSourceVal =
      value?.selected_values || campaignFilterFields?.selectSource || [];

    if (checkFilter) {
      const campaignObj = {
        date_start: moment(startDate).format("YYYY-MM-DD"),
        date_end: moment(endDate).format("YYYY-MM-DD"),
        selectedClient: client,
        interval: value?.interval || null,
        zero_dollar_job: value?.zero_dollar_job ?? 1,
        selectSource: selectedSourceVal,
        selectAllData: selectAllData,
        isCampaignShowTable: true,
        source_type: value?.source_type,
      };
      dispatch(updateCampaignData(campaignObj));
    }

    if (filter) {
      setFilterLoading(true);
    }
    setLoading(true);
    params.append("client_key", client?.key);

    let requestPayload = {
      start_date: startDate,
      end_date: endDate,
      page: checkFilter ? 1 : currentPage,
      page_size: pagesize,
      zero_dollar_job: value?.zero_dollar_job ?? 1,
    };
    if (value?.interval) requestPayload["interval"] = value?.interval;

    if (checkFilter) {
      if (selectedSourceVal?.length > 0) {
        let s = selectedSourceVal?.map((el) => el?.label);
        requestPayload["source"] = s;
      }
    } else {
      if (
        campaignFilterFields?.selectSource?.length > 0 &&
        (selectedSourceVal?.length !== sourceOption?.length ||
          selectAllData !== true)
      ) {
        let s = campaignFilterFields?.selectSource?.map((el) => el?.label);
        requestPayload["source"] = s;
      }
    }

    let res = await postCampaignTableData(params.toString(), requestPayload);
    if (res?.status === 200) {
      setLoading(false);
      setFilterLoading(false);
      if (checkFilter) {
        setFilterDrawer(false);
      }
      setExtraData(res?.total);

      let arr = [];
      let total_revenue = 0;

      if (res?.data && res?.data.length > 0) {
        res?.data?.map((el) => {
          let obj = {
            id: el?.cuustomerid,
            jobNumber: el?.jobnumber,
            createdAt: moment(el?.created_on).format("YYYY-MM-DD"),
            startDate: el?.start_date,
            name: el?.job_campaign,
            source: el?.source,
            revenue: el?.revenue,
            businessUnit: el?.business_unit,
            jobType: el?.job_type,
          };
          total_revenue = total_revenue + el?.revenue;
          arr.push(obj);
        });
        setTableData(arr);
        setTotalRevenue(res?.extra?.total_rev);
      } else {
        setTableData([]);
        setTotalRevenue(0);
      }
    } else {
      setTableData([]);
      setTotalRevenue(0);
      setLoading(false);
      setFilterLoading(false);
    }
  };

  const getClientOption = () => {
    let arr = [];
    filterClientsBySection(clientOptionsData?.clients, "campaign-table")
      ?.length > 0 &&
      // eslint-disable-next-line array-callback-return
      filterClientsBySection(clientOptionsData?.clients, "campaign-table")?.map(
        (ele) => {
          let obj = {
            ...ele,
            label: ele?.name,
            value: ele?.name,
          };
          arr.push(obj);
        }
      );
    setclientOptions(arr);
  };

  const getSourceTypeListing = async (data, setFieldValue, client) => {
    if (data === undefined) {
      return;
    }
    setSourceTypeLoading(true);
    try {
      let param = new URLSearchParams();
      param.append("type", "campaign");
      param.append("platform", data?.value);
      if (client) {
        param.append("db", client?.db);
      } else {
        param.append("db", campaignFilterFields?.selectedClient?.db);
      }

      let res = await GetSourceTypeSources(param);

      if (res?.status == 200) {
        let modifiedData = [];
        if (res?.data?.[0]?.campaign_source) {
          let arr = JSON.parse(res?.data?.[0]?.campaign_source);
          modifiedData = arr?.map((ele) => ({
            label: ele,
            value: ele,
            ele,
          }));
        }
        setFieldValue("selected_values", modifiedData);
      }
      setSourceTypeLoading(false);
    } catch (err) {
      console.log("error", err);
      setSourceTypeLoading(false);
    }
  };

  const filterComponent = () => {
    return (
      <div className="campaign-filters">
        <div className="title">
          <h4 style={{ color: "rgba(25, 25, 25, 1)" }}>Filters</h4>
        </div>

        <div className="content">
          <Formik
            initialValues={initialValues}
            onSubmit={(value) => {
              handleListing(value);
              setCurrentPage(1);
            }}
            render={({ setFieldValue, values }) => (
              <Form>
                <div className="datepicker-box">
                  <div className="input-box">
                    <label>
                      <strong>Select Date</strong>
                    </label>{" "}
                    <RangeSelector
                      defaultDate={[
                        values?.start_date
                          ? moment(values?.start_date, "yyyy-MM-DD").format(
                              "yyyy-MM-DD"
                            )
                          : null,
                        values?.end_date
                          ? moment(values?.end_date, "yyyy-MM-DD").format(
                              "yyyy-MM-DD"
                            )
                          : null,
                      ]}
                      handleChange={(d) => {
                        const start = d[0];
                        const end = d[1];
                        setFieldValue("start_date", start);
                        setFieldValue("end_date", end);
                      }}
                    />
                  </div>

                  <div style={{ marginTop: "20px" }} className="input-box">
                    <label>
                      <strong>Client</strong>
                    </label>
                    <br />
                    <Select
                      size="large"
                      prefixCls="inner-select2"
                      value={values?.selected_client}
                      onChange={(_, data) => {
                        if (!sourceTypeLoading) {
                          setFieldValue("selected_client", data);
                          handleCompanignList(data);
                          setSourceOption(() => []);
                          setFieldValue("selected_values", []);
                          setFieldValue("source_type", null);
                          dispatch(updateSelectAllCampaigns(false));
                        }
                      }}
                      style={{
                        width: "100%",
                        height: "48px",
                        marginTop: "6px",
                      }}
                      maxTagCount={1}
                      placeholder="Please select"
                      options={clientOptions}
                    />
                  </div>

                  <div style={{ marginTop: "20px" }} className="input-box">
                    <label>
                      <strong>Source Type</strong>
                    </label>
                    <br />
                    <Select
                      size="large"
                      allowClear
                      prefixCls="inner-select2"
                      loading={sourceTypeLoading}
                      value={values?.source_type}
                      onChange={(_, data) => {
                        if (!sourceTypeLoading) {
                          setFieldValue("source_type", data);
                          getSourceTypeListing(
                            data,
                            setFieldValue,
                            values?.selected_client
                          );
                          setFieldValue("selected_values", []);
                          dispatch(updateSelectAllCampaigns(false));
                        }
                      }}
                      style={{
                        width: "100%",
                        height: "48px",
                        marginTop: "6px",
                      }}
                      maxTagCount={1}
                      placeholder="Please select"
                      options={source_type_options}
                    />
                  </div>

                  <div className="input-box" style={{ marginTop: "20px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        background: "transparent",
                        height: "100%",
                        marginBottom: "0px",
                      }}
                    >
                      <label
                        className="mb-1"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <strong
                          style={{
                            fontWeight: 700,
                            fontSize: "17px",
                            color: "rgb(146, 146, 165)",
                            fontStyle: "normal",
                          }}
                        >
                          Select Sources
                        </strong>
                      </label>
                      {(values.selected_client ||
                        campaignFilterFields?.selectedClient) &&
                        !sourceLoader && (
                          <>
                            {sourceOption?.length &&
                            values.selected_values?.length ===
                              sourceOption?.length ? (
                              <span
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  alignContent: "center",
                                  height: "20px",
                                }}
                                className="ml-2"
                              >
                                <span
                                  className="mr-2"
                                  style={{
                                    color: "#828690",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    fontStyle: "normal",
                                  }}
                                >
                                  {" "}
                                  Clear All
                                </span>
                                <span aria-disabled>
                                  <Checkbox
                                    disabled={sourceLoader}
                                    checked={
                                      values?.selected_values?.length ==
                                      sourceOption?.length
                                        ? true
                                        : defaultIsSelectAll
                                    }
                                    onChange={(e) => {
                                      dispatch(
                                        updateSelectAllCampaigns(
                                          e?.target?.checked
                                        )
                                      );
                                      setFieldValue("selected_values", []);
                                    }}
                                  />
                                </span>
                              </span>
                            ) : (
                              <span
                                className="ml-2"
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  alignContent: "center",
                                  height: "20px",
                                }}
                              >
                                <span
                                  className="mr-2"
                                  style={{
                                    color: "#828690",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    fontStyle: "normal",
                                  }}
                                >
                                  Select All
                                </span>
                                <span
                                  aria-disabled
                                  className="source-checkbox-wrapper"
                                >
                                  <Checkbox
                                    disabled={sourceLoader}
                                    checked={
                                      sourceOption?.length > 0 &&
                                      values?.selected_values?.length ==
                                        sourceOption?.length
                                    }
                                    onChange={(e) => {
                                      setSelectAllData(e?.target?.checked);
                                      dispatch(
                                        updateSelectAllCampaigns(
                                          e?.target?.checked
                                        )
                                      );
                                      if (e.target.checked) {
                                        setFieldValue(
                                          "selected_values",
                                          sourceOption
                                        );
                                      }
                                    }}
                                  />
                                </span>
                              </span>
                            )}
                          </>
                        )}
                    </div>
                    <Tooltip>
                      <Select
                        mode="multiple"
                        prefixCls="inner-select2"
                        allowClear
                        loading={sourceLoader || sourceTypeLoading}
                        onSearch={(e) =>
                          handleCompanignList(e, 1, values.selected_client)
                        }
                        onBlur={(e) =>
                          scrollPage == 1
                            ? handleCompanignList(
                                null,
                                1,
                                values.selected_client
                              )
                            : setScrollPage(1)
                        }
                        maxTagCount={1}
                        style={{ width: "100%", height: "48px" }}
                        value={values.selected_values}
                        onChange={(_, data) => {
                          setFieldValue("selected_values", data);
                          setSelectAllData(false);
                        }}
                        placeholder="Please select"
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onClear={() => {
                          dispatch(updateSelectAllCampaigns(false));
                          setFieldValue("selected_values", []);
                        }}
                        options={sourceTypeLoading ? [] : sourceOption}
                        notFoundContent={
                          sourceLoader ? (
                            <NoDataDropdown text={"Processing..."} />
                          ) : (
                            <NoDataDropdown text={"No data"} />
                          )
                        }
                      />
                    </Tooltip>
                  </div>

                  <div style={{ marginTop: "20px" }} className="input-box">
                    <label>
                      <strong>Interval</strong>
                    </label>
                    <br />
                    <Select
                      size={"large"}
                      prefixCls="inner-select2"
                      allowClear
                      value={values?.interval}
                      onChange={(value) => setFieldValue("interval", value)}
                      style={{
                        width: "100%",
                        height: "48px",
                        marginTop: "6px",
                      }}
                      maxTagCount={1}
                      placeholder="Please select"
                      options={IntervalOption}
                    />

                    {(0 > values.interval || 2000 < values.interval) && (
                      <ErrorCustomMessage>
                        Please enter between 0 to 2000
                      </ErrorCustomMessage>
                    )}
                  </div>

                  <div
                    className="zero_dollar_job"
                    style={{ marginTop: "15px" }}
                  >
                    <Checkbox
                      style={{ color: "white" }}
                      onChange={(e) =>
                        setFieldValue(
                          "zero_dollar_job",
                          e.target.checked ? 1 : 0
                        )
                      }
                      checked={values?.zero_dollar_job == 0 ? false : true}
                    >
                      <strong style={{ color: "rgba(0, 0, 0, 1)" }}>
                        Zero Dollar Job
                      </strong>
                    </Checkbox>
                  </div>

                  <div className="button-wrap">
                    {filterLoading || sourceTypeLoading || sourceLoader ? (
                      <button type="button">
                        <b>Loading...</b>
                      </button>
                    ) : (
                      <button type="submit">
                        <b>Apply</b>
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            )}
          />
        </div>
      </div>
    );
  };

  const exportAllData = () => {
    setExportLoading(true);

    let requestPayload = {
      start_date: moment(campaignFilterFields?.date_start).format("YYYY-MM-DD"),
      end_date: moment(campaignFilterFields?.date_end).format("YYYY-MM-DD"),
      zero_dollar_job: campaignFilterFields?.zero_dollar_job ?? 1,
    };
    if (campaignFilterFields?.interval)
      requestPayload["interval"] = campaignFilterFields?.interval;
    if (
      campaignFilterFields?.selectSource?.length > 0 &&
      (campaignFilterFields?.selectSource?.length !== sourceOption?.length ||
        selectAllData !== true)
    ) {
      let s = campaignFilterFields?.selectSource?.map((el) => el?.label);
      requestPayload["source"] = s;
    }

    axios
      .post(
        `${process.env.REACT_APP_EXTERNAL_BASEURL}api/v1/custom/callrail/servicetitan-mapping/exporting?client_key=${campaignFilterFields?.selectedClient?.key}`,
        requestPayload
      )
      .then((response) => {
        const url = response.data?.data?.download_link;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "export.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setExportLoading(false);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
        setExportLoading(false);
      });
  };

  useEffect(() => {
    if (campaignFilterFields?.selectedClient != null) {
      handleCompanignList();
    }
  }, [campaignFilterFields?.selectedClient, scrollPage]);

  const defaultInterval = useSelector(
    (state) => state?.campaignSlice?.campaignData
  );

  useEffect(() => {
    handleListing(defaultInterval);
  }, [currentPage, pagesize]);

  useEffect(() => {
    getClientOption();
  }, []);

  return (
    <>
      <Header
        title="Attribution Analyzer"
        clientInfo={campaignFilterFields?.selectedClient}
        isFilter={true}
        openFilter={() => setFilterDrawer(true)}
        isExport={true}
        clickExport={exportAllData}
        isExportLoading={exportLoading}
        isNotification={true}
      />

      <TableWrapper selectedThemeColors={selectedThemeColors}>
        {openDrawer && (
          <Drawer
            className="filter-drawer"
            placement="right"
            closable={false}
            onClose={() => {
              setFilterDrawer(false);
            }}
            open={openDrawer}
            width={"400px"}
            key="bottom"
          >
            {filterComponent()}
          </Drawer>
        )}

        {loading ? (
          <LoadingBlock height={"calc(100vh - 104px)"} size={28} />
        ) : (
          <div>
            <>
              <Table
                prefixCls="custom-ui-table"
                columns={columns}
                dataSource={tableData}
                pagination={{
                  hideOnSinglePage: true,
                  current: currentPage,
                  pageSize: pagesize,
                  total: extraData,
                  onChange: onChangePagination,
                  showSizeChanger: true,
                  defaultPageSize: pagesize,
                  className: "custom-ui-pagination",
                }}
                scroll={{
                  x: "100%",
                  y: "calc(100vh - 260px)",
                }}
                summary={() => (
                  <Table.Summary fixed={"bottom"}>
                    <Table.Summary.Row>
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8]?.map((el, idx) => (
                        <Table.Summary.Cell index={idx} colSpan={1}>
                          {idx == 0 ? (
                            <strong>Total Amount:</strong>
                          ) : idx == 2 ? (
                            <strong>
                              {" "}
                              {valueFormatter(toatlRevenue, 2) ||
                              toatlRevenue == 0
                                ? "$" + valueFormatter(toatlRevenue, 2)
                                : "-"}
                            </strong>
                          ) : (
                            <strong>-</strong>
                          )}
                        </Table.Summary.Cell>
                      ))}
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
              />
            </>
          </div>
        )}
      </TableWrapper>
      <Watermark style={{ paddingRight: "10px" }} />
    </>
  );
};

export default CampaignTable;

const ErrorCustomMessage = styled.div`
  color: red;
  margin: 5px 0;
  font-size: 13px;
`;

const TableWrapper = styled.div`
  background: ${({ selectedThemeColors }) => selectedThemeColors?.bgMainColor};
  width: 100%;
  height: calc(100vh - 78px);
  overflow: hidden;
  padding: 20px 20px 0 20px;

  .filterBtn-Box {
    button {
      background: ${({ selectedThemeColors }) =>
        selectedThemeColors?.headerColor};
      height: 33px;
      font-size: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
`;
