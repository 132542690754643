import { useDispatch, useSelector } from "react-redux";
import { updateSeenStatus } from "../module/AIBooking/AiBookingSlice";
import useDayChangeEffect from "../customHook/useDayChange";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { ModalUI } from "./ModalUi";

const SubscriptionCheck = () => {
  const { lastSeen, token } = useSelector(({ aiBooking }) => ({
    ...aiBooking,
  }));
  const [companyList, setCompanyList] = useState([]);

  const dispatch = useDispatch();

  const onOk = () => {
    dispatch(
      updateSeenStatus({
        date: moment().format("DD-MMM,YYYY"),
        hasSeen: true,
      })
    );
  };

  useEffect(() => {
    if (
      !lastSeen?.date ||
      !lastSeen?.hasSeen ||
      lastSeen?.date !== moment().format("DD-MMM,YYYY")
    ) {
      dispatch(
        updateSeenStatus({
          date: moment().format("DD-MMM,YYYY"),
          hasSeen: false,
        })
      );
    }
  }, []);

  useDayChangeEffect(() => {
    console.log("The day has changed!");
    dispatch(
      updateSeenStatus({
        date: moment().format("DD-MMM,YYYY"),
        hasSeen: false,
      })
    );
  });

  if (!token) return null;
  return (
    <ModalUI
      open={
        moment().format("DD-MMM,YYYY") === lastSeen?.date && !lastSeen?.hasSeen
      }
      onOk={onOk}
    />
  );
};

export default memo(SubscriptionCheck);

const ModalBox = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 0;
  padding-bottom: 0;

  .title {
    font-family: "Poppins";
    font-size: 18px;
  }
`;
