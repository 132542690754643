import { Collapse, Table } from "antd";
import React from "react";
const { Panel } = Collapse;

const CollapseTable = ({
  header,
  activeKey,
  tableContent,
  clientName,
  setActiveCollapse,
  accordion,
  columns2,
}) => {
  return (
    <Collapse
      accordion={accordion}
      prefixCls="collapse-table"
      activeKey={activeKey}
      onChange={(v) => {
        setActiveCollapse(v);
      }}
      expandIconPosition="right"
    >
      <>
        <Panel showArrow={false} header={header} key={clientName}>
          {tableContent}
        </Panel>
      </>
    </Collapse>
  );
};

export default CollapseTable;
