import styled from "styled-components";

export const Line = styled.span`
  display: block;
  width: 100%;
  height: 20px;
  background: ${({ color }) => color};
`;

export const HorizontalBarWrapper = styled.div`
  width: 100%;
  background: #f2f2f2;
  display: flex;
  gap: 4px;

  .wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    background: white;

    label {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      text-align: center;
      color: #7a7b7a;
    }
    .new-customer {
      color: #05cd99;
      font-weight: 600;
      cursor:pointer;
    }
  }
`;

export const CapacityMarketingWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #f2f2f2;

  main {
    width: 100%;
    padding: 20px 20px 0 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .company-section {
    display: flex;
    gap: 7px;
    align-items: center;
    padding-bottom: 16px;

    img {
      height: 46px;
    }

    p {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      color: #111111;
      margin: 0;
    }
  }
  .trade-section {
    width: 100%;
    padding: 16px;
    border-radius: 12px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 8px 0;

    h2 {
      font-family: Inter;
      font-size: 24px;
      font-weight: 600;
      line-height: 29.05px;
      letter-spacing: 0.005em;
      text-align: left;
      color: #0f1011;
    }

    .inner {
      border: 4px solid #f2f2f2;
      padding: 16px;
      border-radius: 12px;

      p {
        font-family: Inter;
        font-size: 20px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: #0f1011;
        margin-bottom: 8px;
      }
    }
  }
`;
