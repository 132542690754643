import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Table, TableStyle, Summary } from "./TableGoal";
import { getGoals } from "../../../services/Collections";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { ModalLoader } from "../../../components/Loader";
import { CalendarDays } from "lucide-react";
import LoadingBlock from "../../../components/LoadingBlock";
import { updateGoalFilters } from "../../exceltable/ExcelStore";
import Watermark from "../../../components/Watermark";

export default function ExcelGoalSheet({
  year,
  currentData,
  setCurrentData,
  nextData,
  setNextData,
  budgetPercentageText,
  setBudgetPercentageText,
  revenueGoalText,
  setRevenueGoalText,
  showModal,
  submitLoading,
}) {
  const dispatch = useDispatch();
  const goalFilters = useSelector(
    ({ dynamicBudgetSheet }) => dynamicBudgetSheet?.goalFilters
  );

  const prefilledFilters = useSelector(
    (state) => state?.loginAuth?.summarySectionFilter
  );
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );
  const activeClient =
    prefilledFilters == null
      ? prefilledData?.selectedClient?.key
      : prefilledFilters?.selectedClient?.key;

  const [loading, setLoading] = useState(false);

  const modifyDataForTable = (data) => {
    const totalCurrentRevenue = data.reduce(
      (acc, item) => acc + item.current_revenue,
      0
    );
    const totalCurrentBudget = data.reduce(
      (acc, item) => acc + item.current_budget,
      0
    );

    let arr = data?.map((el) => ({
      month: el?.month,
      budget: el?.current_budget,
      revenue: el?.current_revenue,
      percentage:
        Number((el?.current_revenue / totalCurrentRevenue) * 100) || 0,
    }));
    arr.push({
      month: "TOTALS",
      budget: totalCurrentBudget,
      revenue: totalCurrentRevenue,
      percentage: 100,
    });
    return arr;
  };

  const modifyNextDataForTable = (data, revenueGoal, budgetPer) => {
    let arr = data
      .filter((el) => el.month !== "TOTALS")
      .map((el) => ({
        ...el,
        revenue: (revenueGoal * parseFloat(el.percentage)) / 100, // Correct percentage calculation
        budget:
          (((revenueGoal * parseFloat(el.percentage)) / 100) * budgetPer) / 100, // Correct budget calculation
      }));

    // Calculate total next revenue and total next budget
    const totalNextRevenue = arr.reduce((acc, item) => acc + item.revenue, 0);
    const totalNextBudget = arr.reduce((acc, item) => acc + item.budget, 0);

    // Add the totals row to the array
    arr.push({
      month: "TOTALS",
      budget: totalNextBudget, // Use toFixed to format the result
      revenue: totalNextRevenue, // Use toFixed to format the result
      percentage: 100,
    });
    return arr;
  };

  const fetchingGoals = async () => {
    setLoading(true);
    let params = new URLSearchParams();
    params.append("year", year);
    params.append("client_key", activeClient);
    let res = await getGoals(activeClient, params?.toString());
    if (res?.status === 200) {
      if (res?.data?.length > 0) {
        let currentGoal = modifyDataForTable(res?.data, "current");
        setCurrentData(currentGoal);
        dispatch(
          updateGoalFilters({
            year: year,
            currentData: currentGoal,
          })
        );
        setLoading(false);
        return;
      }
      setLoading("No Data Found");
    } else {
      setLoading(false);
      toast.error(res?.error || "Something went worng", { theme: "colored" });
      return [];
    }
  };

  useEffect(() => {
    if (currentData) {
      let nextGoal = modifyNextDataForTable(
        currentData,
        revenueGoalText,
        budgetPercentageText
      );
      setNextData(nextGoal);
    }
  }, [currentData, revenueGoalText, budgetPercentageText]);

  useEffect(() => {
    if (year && !goalFilters?.currentData) {
      fetchingGoals();
    }
  }, [year, activeClient]);

  if (loading || !year) {
    return (
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 200px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {typeof loading === "string" ? (
          <h2>{loading}</h2>
        ) : !year ? (
          <ApplyFilter />
        ) : (
          <LoadingBlock height={"calc(100vh - 216px)"} size={28} />
        )}
      </div>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <ExcelFortunesheetWrapper>
        <TableStyle>
          <div>
            {currentData?.length > 0 && nextData?.length > 0 && (
              <Summary
                currentData={currentData}
                nextData={nextData}
                budgetPercentageText={budgetPercentageText}
                setBudgetPercentageText={setBudgetPercentageText}
                revenueGoalText={revenueGoalText}
                setRevenueGoalText={setRevenueGoalText}
                showModal={showModal}
                submitLoading={submitLoading}
              />
            )}
            <div className="tables-container" style={{ width: "100%" }}>
              <Table data={currentData} title="Current" />
              <Table data={nextData} title="Goal" />
            </div>
          </div>
        </TableStyle>
        <Watermark />
      </ExcelFortunesheetWrapper>
    </div>
  );
}

const ExcelFortunesheetWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 199px);
`;

const ApplyFilter = () => {
  return (
    <MessageContainer>
      <StyledCalendarDays />
      <MessageTitle>Please select the year</MessageTitle>
      <MessageText>
        Use the fields above to specify your desired year.
      </MessageText>
    </MessageContainer>
  );
};

const MessageContainer = styled.div`
  height: calc(100vh - 280px);
  text-align: center;
  padding: 2rem;
  // background-color: #f9faf6;
  // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  // border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledCalendarDays = styled(CalendarDays)`
  width: 3rem;
  height: 3rem;
  color: rgb(216, 12, 12);
  margin: 0 auto 1rem;
`;

const MessageTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  color: #111827;
  margin-bottom: 0.5rem;
`;

const MessageText = styled.p`
  color: #6b7280;
  font-size: 1rem;
`;
