import React from "react";

export const NoDataDropdown = ({ text }) => {
  return (
    <div
      style={{
        width: "100%",
        height: 80,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p
        style={{
          margin: 0,
          fontSize: 14,
          color: "gray",
          fontFamily: "Poppins",
        }}
      >
        {text}
      </p>
    </div>
  );
};
