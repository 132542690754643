import { DatePicker, message, Table } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LoadingBlock from "../../../components/LoadingBlock";
import { numberWithCommas } from "../../../utils/common/commonFunction";
import { Select } from "antd";
import dayjs from "dayjs";
import {
  businessUnitCategories,
  businessUnitSubCategories,
  getbudgetPredictedApi,
  getTitleLogsApi,
  getTitleLogsMonthlyApi,
} from "../../../services/Collections";
import { useDispatch, useSelector } from "react-redux";
import { ApplyFilter } from "../YearSheet";
import {
  formatInteger,
  formatNumberDecimal,
  checkForInfinity,
} from "../Year/commonFuc";
import { finalReportFilter } from "../../exceltable/ExcelStore";
import { NoDataFoundIcon } from "../../../utils/Icons/SvgIcons";
import Watermark from "../../../components/Watermark";

export const months = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

const FinalReport = ({ filter }) => {
  const dispatch = useDispatch();
  const {
    data: loginData,
    summarySectionFilter: clientFilter,
    prefilledFilters,
  } = useSelector((state) => state?.loginAuth);

  const { yearSheetFilters, finalReport } = useSelector(
    (state) => state?.dynamicBudgetSheet
  );

  const activeClient =
    clientFilter == null
      ? prefilledFilters?.selectedClient?.key
      : clientFilter?.selectedClient?.key;

  const [tableData, setTableData] = useState(finalReport?.data || []);
  const [loader, setLoader] = useState(false);
  const [date, setDate] = useState(finalReport?.filters?.month || null);
  const [noDataFound, setNoDataFound] = useState(false);
  const currentYear = dayjs().year();
  const selectedYear = currentYear + 1;
  const uniqueSelectedMonths = yearSheetFilters?.uniqueMonths;
  const [categoriesListing, setCategoriesListing] = useState([]);
  const [subCategoriesListing, setSubCategoriesListing] = useState([]);
  const [catLoading, setCatLoading] = useState(false);
  const [subCatLoading, setSubCatLoading] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);

  const [titleOption, setTitleOption] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [titleLoading, setTitleLoading] = useState(false);

  const onMonthChange = (date) => {
    if (date == null || date == undefined) {
      setDate(null);
      return;
    }
    setDate(date);
  };

  const handleFinalBudget = async () => {
    try {
      setLoader(true);
      setNoDataFound(false);
      dispatch(finalReportFilter({ month: date }));

      let params = new URLSearchParams();
      params.append("client_key", activeClient);
      params.append("user_id", loginData?.id);
      params.append("year", selectedYear);
      date && params.append("month", date);
      // selectedCategories && params.append("bu_category", selectedCategories);
      // selectedSubCategories && params.append("bu_subcategory", selectedSubCategories);

      let res = await getbudgetPredictedApi(activeClient, params);

      if (res?.status === 200) {
        if (res.data?.length === 0) {
          // message.error("The prediction data for the specified period does not exist.");
          setNoDataFound(true);
          setLoader(false);
          setTableData([]);
          return;
        }
        dispatch(finalReportData(res?.data));
        setTableData(res?.data);
        setLoader(false);
      } else {
        message.error("Something went wrong");
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log("error in finalreport", error);
    }
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "category_name",
      width: 160,
      fixed: "left",
    },
    {
      title: "Budget",
      dataIndex: "budget",
      width: 120,
      render: (val) => <p style={{ margin: 0 }}>${formatInteger(val)}</p>,
    },
    {
      title: "Revenue",
      dataIndex: "sales",
      width: 120,
      render: (val) => <p style={{ margin: 0 }}>${formatInteger(val)}</p>,
    },
    {
      title: "ROAS",
      dataIndex: "roas",
      width: 80,
      render: (_, record) => (
        <p style={{ margin: 0 }}>
          {formatNumberDecimal(
            checkForInfinity(record?.sales / record?.budget)
          )}
        </p>
      ),
    },
    {
      title: "Leads",
      dataIndex: "leads",
      width: 70,
      render: (val) => <p style={{ margin: 0 }}>{formatInteger(val)}</p>,
    },
    {
      title: "CPL",
      dataIndex: "cpl",
      width: 100,
      render: (_, record) => (
        <p style={{ margin: 0 }}>
          ${formatInteger(checkForInfinity(record?.budget / record?.leads))}
        </p>
      ),
    },
    {
      title: "Appts",
      dataIndex: "appts",
      width: 80,
      render: (val) => <p style={{ margin: 0 }}>{formatInteger(val)}</p>,
    },
    {
      title: "CPA",
      dataIndex: "cpa",
      width: 120,
      render: (_, record) => (
        <p style={{ margin: 0 }}>
          ${formatInteger(checkForInfinity(record?.budget / record?.appts))}
        </p>
      ),
    },
    {
      title: "Conv %",
      dataIndex: "con_per",
      width: 80,
      render: (_, record) => (
        <p style={{ margin: 0 }}>
          {formatInteger(checkForInfinity(record?.appts / record?.leads) * 100)}
          %
        </p>
      ),
    },
    {
      title: "jobs",
      dataIndex: "jobs",
      width: 80,
      render: (val) => <p style={{ margin: 0 }}>{formatInteger(val)}</p>,
    },
    {
      title: "CPJ",
      dataIndex: "cpj",
      width: 100,
      render: (_, record) => (
        <p style={{ margin: 0 }}>
          ${formatInteger(checkForInfinity(record?.budget / record?.jobs))}
        </p>
      ),
    },
    {
      title: "Close %",
      dataIndex: "close_per",
      width: 80,
      render: (_, record) => (
        <p style={{ margin: 0 }}>
          {formatInteger(checkForInfinity(record?.jobs / record?.appts) * 100)}%
        </p>
      ),
    },
    {
      title: "Avg Ticket",
      dataIndex: "avg_ticket",
      width: 120,
      render: (_, record) => (
        <p style={{ margin: 0 }}>
          ${formatInteger(checkForInfinity(record?.sales / record?.jobs))}
        </p>
      ),
    },
  ];

  const getCategoriesListing = async () => {
    try {
      setCatLoading(true);
      let res = await businessUnitCategories(activeClient);
      if (res?.status === 200) {
        let updateData = res?.data.map((ele) => ({
          ...ele,
          value: ele?.type,
          label: ele?.type,
        }));
        setCategoriesListing(updateData);
        setCatLoading(false);
      } else {
        message.error("Something went wrong");
        setCatLoading(false);
      }
    } catch (error) {
      setCatLoading(false);
      console.log("error in businessUnitCategories", error);
    }
  };

  const getSubCategoriesListing = async () => {
    try {
      setSubCatLoading(true);
      let res = await businessUnitSubCategories(activeClient);
      if (res?.status === 200) {
        let updateData = res?.data.map((ele) => ({
          ...ele,
          value: ele?.subtype,
          label: ele?.subtype,
        }));
        setSubCategoriesListing(updateData);
        setSubCatLoading(false);
      } else {
        message.error("Something went wrong");
        setSubCatLoading(false);
      }
    } catch (error) {
      setSubCatLoading(false);
      console.log("error in businessUnitSubCategories", error);
    }
  };

  const getTitleListing = async () => {
    try {
      let params = new URLSearchParams();
      params.append("user_id", loginData?.id);

      setTitleLoading(true);
      let res = await getTitleLogsApi(activeClient, params);
      if (res?.status === 200) {
        if (res?.data?.length == 0) {
          // message.error(
          //   "Please generate prediction data from the year tab before proceeding with the final report."
          // );
          setTitleLoading(false);
          return;
        }
        let updateData = res?.data.map((ele) => ({
          ...ele,
          value: ele?.id,
          label: ele?.title,
        }));
        if (res?.data?.length === 1) {
          setSelectedTitle(updateData?.[0]?.value);
          getDataOFTitle(updateData?.[0]?.value);
        }
        setTitleOption(updateData);
        setTitleLoading(false);
      } else {
        // message.error("Something went wrong");
        setTitleOption([]);
        setTitleLoading(false);
      }
    } catch (error) {
      setTitleLoading(false);
      console.log("error in title", error);
    }
  };

  const getDataOFTitle = async (id) => {
    try {
      setLoader(true);
      let res = await getTitleLogsMonthlyApi(activeClient, id);

      if (res?.status === 200) {
        if (res.data?.length === 0) {
          // message.error("The prediction data for the specified period does not exist.");
          setNoDataFound(true);
          setLoader(false);
          setTableData([]);
          return;
        }
        let modified = res?.data.filter(
          (ele) => ele?.budget != 0 || ele?.sales != 0 || ele?.roas != 0
        );
        let Total = {
          category_name: "Total",
          budget: 0,
          sales: 0,
          roas: 0,
          leads: 0,
          cpl: 0,
          appts: 0,
          cpa: 0,
          con_per: 0,
          jobs: 0,
          cpj: 0,
          close_per: 0,
          avg_ticket: 0,
          new_customer: 0,
        };
        modified.forEach((ele) => {
          Total.budget += Number(ele?.budget);
          Total.sales += Number(ele?.sales);
          Total.roas += Number(ele?.roas);
          Total.leads += Number(ele?.leads);
          Total.cpl += Number(ele?.cpl);
          Total.appts += Number(ele?.appts);
          Total.cpa += Number(ele?.cpa);
          Total.con_per += Number(ele?.con_per);
          Total.jobs += Number(ele?.jobs);
          Total.cpj += Number(ele?.cpj);
          Total.close_per += Number(ele?.close_per);
          Total.avg_ticket += Number(ele?.avg_ticket);
          Total.new_customer += Number(ele?.new_customer);
        });
        setTableData([...modified, Total]);
        setLoader(false);
      } else {
        message.error("Something went wrong");
        setLoader(false);
        selectedTitle(null);
      }
    } catch (error) {
      setLoader(false);
      console.log("error in finalreport", error);
    }
  };

  const handleChangeCategory = (value) => {
    setSelectedCategories(value);
  };

  const handleChangeSubCategory = (value) => {
    setSelectedSubCategories(value);
  };

  const handleTitleOnChange = (value) => {
    setSelectedTitle(value);
    if (value) {
      getDataOFTitle(value);
    } else {
      setTableData([]);
    }
  };

  // useEffect(() => {
  //   setCategoriesListing([]);
  //   setSubCategoriesListing([]);

  //   if (activeClient) {
  //     getCategoriesListing();
  //     getSubCategoriesListing();
  //   }
  // }, [activeClient]);

  useEffect(() => {
    setTitleOption(null);
    setSelectedTitle(null);
    setTableData([]);

    if (activeClient) {
      getTitleListing();
    }

  }, [activeClient]);

  return (
    <FinalReportWrapper>
      <TitleWrapper>
        <section>
          <div className="label">Predicted Title</div>
          <Select
            // notFoundContent={
            //   titleLoading ? (
            //     <p style={{ color: "#000", textAlign: "center", margin: 0 }}>
            //       Loading...
            //     </p>
            //   ) : (
            //     <SelectNoData>
            //       <NoDataFoundIcon />
            //       Please generate prediction data from the year tab before
            //       proceeding with the final report.
            //     </SelectNoData>
            //   )
            // }
            placeholder="Please select"
            prefixCls="inner-select2"
            className="select"
            loading={titleLoading}
            onChange={handleTitleOnChange}
            options={titleOption}
            value={selectedTitle || null}
            size="middle"
            allowClear
          />
        </section>
      </TitleWrapper>
      {/* <FilterTabsWrapper position={filter}>
        {filter && (
          <div className="wrap">
            <section>
              <div className="label">Select Year & Month</div>
              <div className="flex">
                <DatePicker
                  allowClear={false}
                  picker="year"
                  value={dayjs().year(selectedYear)}
                  disabledDate={(date) =>
                    date.year() > selectedYear || date.year() < selectedYear
                  }
                />
                <Select
                  placeholder="Select month"
                  prefixCls="inner-select2"
                  className="select"
                  onChange={onMonthChange}
                  // options={months}
                  value={date}
                  size="middle"
                  allowClear
                >
                  {months?.map((item) => (
                    <Option key={item?.value} value={item?.value}>
                      <span
                        style={{
                          color:
                            uniqueSelectedMonths?.includes(item?.value) &&
                            item?.value != date &&
                            "green",
                        }}
                      >
                        {item?.label}
                      </span>
                    </Option>
                  ))}
                </Select>
              </div>
            </section>
          
            <section>
              <div className="label">Category</div>
              <Select
                placeholder="Please select"
                prefixCls="inner-select2"
                className="select"
                loading={catLoading}
                onChange={handleChangeCategory}
                options={categoriesListing}
                value={selectedCategories || []}
                mode="multiple"
                size="middle"
                maxTagCount={2}
              />
            </section>
            <section>
              <div className="label">Sub-Category</div>
              <Select
                placeholder="Please select"
                prefixCls="inner-select2"
                className="select"
                loading={subCatLoading}
                onChange={handleChangeSubCategory}
                options={subCategoriesListing}
                value={selectedSubCategories || []}
                mode="multiple"
                maxTagCount={2}
              />
            </section>
            <button
              className="saveBtn submit"
              onClick={() => !loader && handleFinalBudget()}
            >
              Submit
            </button>
          </div>
        )}
      </FilterTabsWrapper> */}
      {loader ? (
        <LoadingBlock
          // height={filter ? "calc(100vh - 291px)" : "calc(100vh - 125px)"}
          height={"calc(100vh - 210px)"}
          size={28}
        />
      ) : tableData?.length > 0 ? (
        <Table
          prefixCls="custom-ui-table"
          columns={columns}
          dataSource={tableData}
          pagination={false}
          scroll={{
            x: "100%",
            // y: filter ? "calc(100vh - 291px)" : "calc(100vh - 210px)",
            y: "calc(100vh - 210px)",
          }}
        />
      ) : (
        <ApplyFilter
          title="title"
          yearSheetFilter={true}
          message={
            noDataFound &&
            "The prediction data for the specified period does not exist. Please set up the prediction data for the given period."
          }
        />
      )}
      <Watermark 
      // style={{position:"absolute",bottom:"8px", right:"10px", background:"#fff", marginTop:"30px"}} 
      />
    </FinalReportWrapper>
  );
};

export default FinalReport;

const FinalReportWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 115px);
  overflow: auto;
`;

export const FilterTabsWrapper = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  justify-content: ${({ position }) => (position ? "space-between" : "right")};
  align-items: end;
  background: ${({ position }) => (position ? "#fff" : "transparent")};
  padding: ${({ position }) => (position ? "10px" : "0px")};
  border-radius: 9px;
  border: ${({ position }) => (position ? "1px solid rgb(210, 212, 222)" : "")};
  margin: ${({ position }) => (position ? "10px 0" : "0 0 10px 0")};

  .flex {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .label {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #7b7f91;
  }
  .wrap {
    display: flex;
    align-items: end;
    gap: 15px;
  }

  .saveBtn {
    background: rgb(36, 36, 36);
    color: rgb(255, 255, 255);
    border-style: none;
    height: 33px;
    font-size: 13px;
    padding-right: 12px;
    padding-left: 12px;
    cursor: pointer;
    font-family: Poppins;
    height: 34px;
    min-width: 68px;
    padding: 8px 12px 8px 12px;
    border-radius: 6px;
  }

  .submit {
    background: #d80c0c;
  }

  .select {
    .inner-select2-selector {
      min-width: 250px !important;
      background: white !important;
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: "start";
  margin: 7px 0 10px;
  .label {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #7b7f91;
  }

  .select {
    .inner-select2-selector {
      min-width: 250px !important;
      background: white !important;
    }
  }
`;

const SelectNoData = styled.div`
padding:10px;
color: rgb(107, 114, 128);
`;