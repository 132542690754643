import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { forgotpass, resetPasswordAdmin } from "../../../services/Collections";
import {
  LoginBg,
  LoginLogo,
  MainAppIcon,
  MainLogo,
} from "../../../utils/Images/Images";
import { toast } from "react-toastify";
import { ErrorMessage } from "formik";

const ForgotPassword = () => {
  const [auth, setAuth] = useState({ email: "" });
  const [loading, setLoading] = useState(false);
  const [showSendLinkBtn, setShowSendLinkBtn] = useState(true);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    if (auth?.email) {
      setLoading(true);
      let res = await resetPasswordAdmin(auth);
      if (res.status === 200) {
        setLoading(false);
        setShowSendLinkBtn(false);
        toast.success("Reset link has been sent to your email");
      } else {
        setLoading(false);
        toast.error(
          res.response.data.message ||
            res.error ||
            res.message ||
            "Something went wrong"
        );
      }
    } else {
      setError("Email is required");
    }
  };

  return (
    <LoginWrapper>
      <div className="flex-div">
        <div className="gradient-bg">
          <img src={LoginBg} alt="" />
        </div>
        <div className="inner-part">
          <div className="main-box">
            <div className="logo-div">
              <img src={MainAppIcon} alt="" />
              <h4>No worries, we’ll send you reset instructions</h4>
            </div>
            <form onSubmit={(e) => handleForgotPassword(e)}>
              <div className="content">
                {showSendLinkBtn ? (
                  <div>
                    <label>
                      <strong>Email</strong>
                    </label>
                    <input
                      font1="font1"
                      type="email"
                      placeholder="Enter your email"
                      onChange={(e) => {
                        setAuth({
                          email: e.target.value,
                        });
                        setError("");
                      }}
                    />
                    <div
                      style={{
                        color: "red",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                        fontWeight: 700,
                      }}
                    >
                      {error}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="btn-wrapper">
                  {showSendLinkBtn ? (
                    !loading ? (
                      <button style={{ fontFamily: "Poppins" }}>
                        Send link to the email
                      </button>
                    ) : (
                      <LoadingButton>Loading...</LoadingButton>
                    )
                  ) : (
                    ""
                  )}
                  <p
                    className="back-btn"
                    onClick={() => navigate(-1)}
                    style={{
                      border: !showSendLinkBtn ? "1px solid #fff" : "none",
                      borderRadius: !showSendLinkBtn ? "8px" : "0px",
                      padding: !showSendLinkBtn ? "5px 0px" : "0px",
                    }}
                  >
                    Back
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </LoginWrapper>
  );
};

export default ForgotPassword;

const LoginWrapper = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  width: 100%;
  height: 100vh;
  background: #fff;
  ${
    "" /* display: flex;
  justify-content: center;
  align-items: center; */
  }
  .btn-wrapper {
    display: flex;
    gap: 8px;
    flex-direction: row-reverse;

    p {
      width: 100%;
      border: 1px solid #000 !important;
      height: 50px;
      cursor: pointer;
      border-radius: 0.75rem !important;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000;
      font-family: "Poppins";
      text-align: center;
      margin-top: 10px;
    }
  }

  .flex-div {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .gradient-bg {
      position: relative;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .inner-part {
      background: #fff;
      width: 90%;
      max-width: 600px;
      border-radius: 24px;
      box-shadow: 0px 16px 32px 0px #0000001a;
      ${"" /* border-radius: 8px; */}
      position: absolute;

      .main-box {
        padding: 44px;
        .logo-div {
          text-align: center;
          img {
            width: 419.55px;
            height: 92.7px;

            @media (max-width: 1370px) {
              width: 382.55px;
            }
            @media (max-width: 530px) {
              width: 100%;
              object-fit: contain;
            }
          }
          h4 {
            font-size: 30px;
            font-weight: 700;
            line-height: 36px;
            letter-spacing: 0em;
            text-align: center;
            font-family: "Poppins";
            color: #242424;
            margin-top: 30px;
          }
        }
        .content {
          margin-top: 15px;
          div {
            ${"" /* margin-bottom: 1rem; */}
            label {
              color: #242424;
              margin-bottom: 0.25rem;
              display: block;
              font-family: "Poppins";
              font-size: 16px;
            }
            .password-div {
              display: flex;
              position: relative;
              input {
                background-color: #ffff;
                border: 1px solid #afafaf;
                color: #000;
                position: relative;
                padding: 0rem 0.75rem;
                width: 100%;
                ${"" /* height: 41px; */}
                height: 56px;
                border-radius: 12px;

                font-family: "Poppins" !important;
                font-size: 14px;

                :focus {
                  outline: none;
                }
                ::placeholder {
                  color: rgba(0, 0, 0, 0.39) !important;
                }
              }
              svg {
                position: absolute;
                right: 12px;
                top: 20px;
                cursor: pointer;
              }
              img {
                position: absolute;
                right: 12px;
                top: 15px;
                cursor: pointer;
              }
            }
            input {
              background-color: #ffff;
              border: 1px solid #afafaf;
              color: #000;
              position: relative;
              padding: 0rem 0.75rem;
              width: 100%;
              ${"" /* height: 41px; */}
              height: 56px;
              border-radius: 12px;

              font-family: "Poppins" !important;
              font-size: 14px;

              :focus {
                outline: none;
              }
              ::placeholder {
                color: rgba(0, 0, 0, 0.39) !important;
              }
            }
          }
          .forgt-password {
            h1 {
              color: #00a1e6;
              text-align: right;
              font-family: "Poppins";
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              letter-spacing: 0.2px;
              cursor: pointer;
              text-decoration-line: underline;
            }
          }
          button {
            font-weight: 500;
            height: 50px;
            cursor: pointer;
            border-radius: 0.75rem;
            width: 100%;
            background-color: #d80c0c;
            border: 1px solid #d80c0c;
            font-size: 14px;
            color: #fff;
            text-align: center;
            margin-top: 10px;

            ${
              "" /* :hover {
              background-color: #282348;
            } */
            }
          }
          span {
            font-weight: 700;
            height: 50px;
            cursor: pointer;
            border-radius: 0.75rem;
            width: 100%;
            background-color: #363062;
            font-size: 14px;
            border: 1px solid #363062;
            color: #fff;
            text-align: center;
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .inner-part {
      width: 95% !important;
    }
  }
`;

export const LoadingButton = styled.span`
  font-weight: 500 !important;
  height: 50px !important;
  cursor: pointer !important;
  border-radius: 0.75rem !important;
  width: 100% !important;
  background-color: #d80c0c !important;
  border: 1px solid #d80c0c !important;
  font-size: 14px !important;
  color: #fff !important;
  text-align: center !important;
  margin-top: 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: "Poppins" !important;
`;
