import React from "react";
import styled from "styled-components";
import { SyncOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import PropTypes from "prop-types";

export default function LoadingBlock({ height, size, color }) {
  const antIcon = (
    <SyncOutlined
      style={{
        fontSize: size || 46,
        color: color || "grey",
      }}
      spin
    />
  );

  return (
    <LoadingbuttonWrapper height={height}>
      <Spin style={{ margin: "0px" }} indicator={antIcon} />
    </LoadingbuttonWrapper>
  );
}

LoadingBlock.propTypes = {
  height: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
};

const LoadingbuttonWrapper = styled.div`
  width: 100%;
  height: ${({ height }) => height || "100%"};
  position: relative;
  display:flex;
  align-items:center;
  justify-content:center;
`;
