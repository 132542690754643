import styled from "styled-components";

export const MainWrap = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    p {
      font-family: Inter;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: #0f1011;
      margin: 0;
    }
  }
`;

export const SummaryReportWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 10px 0px;
  background: #f3f3f3;
`;

export const HeadingAndFilters = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    letter-spacing: 0.005em;
    text-align: left;
    color: #0f1011;
    margin: 0;
  }
`;

export const TabWrap = styled.div`
  width: 100%;
  padding: 10px 12px;
  gap: 8px;
  border-radius: 12px;
  margin: 24px 0;
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    gap: 6px;
    border-radius: 12px;
    background: #ffffff;
    border: 1px solid #d2d4de;

    .label {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 14.52px;
      text-align: center;
      color: #7b7f91;
      margin: 0;
    }
    .value {
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      line-height: 19.6px;
      text-align: center;
      color: #242424;
      margin: 0;
    }
  }
`;

export const COLORS = [
    "#FB6BB0",
    "#37ED6A",
    "#29C98A",
    "#DB25B9",
    "#CBF2A0 ",
    "#152F47",
    "#2EC9F0",
    "#AD6D5E",
    "#9F0ED7",
    "#BCD917",
    "#4C4FA0",
    "#294AC3",
    "#DF5D0D",
    "#4EADCE",
    "#F1CB42",
    "#AF0A24",
    "#C53E87",
    "#242AD6",
    "#9255F0",
    "#C6DD0C",
    "#474037",
    "#5ED022",
    "#C5D0C5",
    "#5FD195",
    "#E788C4",
    "#C86AAE",
    "#7E3A6B",
    "#137E0F",
    "#E98C23",
    "#E3A8AF",
    "#E17BFC",
    "#605449",
    "#363EBA",
    "#AC4D81",
    "#1B9731",
    "#747CC5",
    "#D4F838",
    "#A58BA1",
    "#0EDC22",
    "#61ED43",
    "#41E144",
    "#1FB162",
    "#D2A7BF",
    "#616C16",
    "#1A0EEC",
    "#A024B1",
    "#76924F",
    "#E3AFBE",
    "#FD74CA",
    "#837DE4",
    "#256481",
    "#446769",
    "#CBF49E",
    "#9902D3",
    "#29AF6E",
    "#8F40E5",
    "#171626",
    "#354875",
    "#A68D0B",
    "#D61C8B",
    "#78BEA1",
    "#BFEA89",
    "#1ADC94",
    "#99504C",
    "#A82EBF",
    "#435E20",
    "#FA19BD",
    "#DC0773",
    "#56A6E8",
    "#D3BCDC",
    "#C383EC",
    "#1D3A79",
    "#ED56D9",
    "#228EC0",
    "#722910",
    "#6D4544",
    "#604CC0",
    "#51F2AB",
    "#F3D5AF",
    "#F4F1CF",
    "#7102F0",
    "#5EF5BA",
    "#4C7F64",
    "#3263B2",
    "#5D42C4",
    "#01ED53",
  ];