import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_SOCKET_URL, {
  autoConnect: true,
  reconnection: true,
  reconnectionAttempts: 5,
  timeout: 10000,
});

socket.on('connect', () => console.log(`Connected to Socket.IO server: ${socket.id}`));
socket.on('disconnect', () => console.warn('Disconnected from Socket.IO server.'));
socket.on('connect_error', (error) => console.log('Socket.IO connection error:', error));

export default socket;
