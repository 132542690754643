import { Button, Checkbox, DatePicker, Input, Select, Tooltip } from "antd";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  addCampaignAndBusinessUnit,
  getBusinessUnitListFromST,
  getCampaignByPlatform,
  getCampaignDataFromST,
  getSourceListForSetting,
} from "../../services/Collections";
import { toast } from "react-toastify";
import { debounce } from "../../utils/common/commonFunction";
import { NoDataDropdown } from "../../components/common/NoDataDropdown";

const clientOptions = [
  { label: "Campaign", value: "campaign" },
  { label: "Business Unit", value: "businessunit" },
];

let cost_year = [];

const platformType = [
  {
    label: "Google",
    value: "google",
  },
  {
    label: "Facebook",
    value: "facebook",
  },
  {
    label: "LSA",
    value: "lsa",
  },
  {
    label: "Yelp",
    value: "yelp",
  },
  {
    label: "Bing",
    value: "bing",
  },
  {
    label: "Custom",
    value: "custom",
  },
];
const platformType2 = [
  {
    label: "Google",
    value: "google",
  },
  {
    label: "Facebook",
    value: "facebook",
  },
  {
    label: "LSA",
    value: "lsa",
  },
  {
    label: "Yelp",
    value: "yelp",
  },
  {
    label: "Bing",
    value: "bing",
  },
];

const AddSettings = ({ handleListing, setFilterDrawer }) => {
  let arr = [];
  let arr2 = [];
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [sourceLoading, setsourceLoading] = useState(false);
  // const [multiCompains, setMultiCompains] = useState(null);
  const [selectedValues, setSelectedValues] = useState(null);
  const [sourceOption, setSourceOption] = useState([]);
  const [costValue, setCostValue] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [isSelectAll, setIsSelectAll] = useState(false);
  console.log(isSelectAll, "isSelectAll");
  const [yearMonth, setYearMonth] = useState(null);
  const [sourceLoader, setsourceLoader] = useState(false);
  const [sourceOptionSetting, setSourceOptionSetting] = useState([]);
  const filterData = useSelector((state) => state?.loginAuth?.settingData);
  console.log(filterData, "filterDatafilterData");
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );
  const [selectData, setSelectData] = useState([]);
  const [selectData2, setSelectData2] = useState([]);
  const [platformLoading, setPlatformLoading] = useState(false);
  const [platformCampaignList, setPlatformCampaignList] = useState([]);
  const [platformTypeValue, setPlatFormType] = useState(null);
  const [selectedSource, setSelectedSource] = useState([]);
  const [scrollPage, setScrollPage] = useState(1);
  const [totalCountSource, setTotalCountSource] = useState(0);
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );
  const [selectedplatformByCampaign, setSelectedplatformByCampaign] =
    useState(null);
  const [
    selectedIgnoreplatformByCampaign,
    setSelectedIgnoreplatformByCampaign,
  ] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  //   prefilledData?.selectedClient?.db
  //   const prefilledData = null;
  const initialValues = {
    custom_costs: [
      {
        cost: "",
        month: "",
        year: "",
      },
    ],
  };

  const handleAllClear = () => {
    setIsSelectAll(false);
    setSelectedValues(null);
  };
  const handleAllClearSources = () => {
    setSelectedSource([]);
  };

  const handleAddValues = (val) => {
    if (!arr.includes(val)) {
      arr = [...arr, val];
      setSelectedplatformByCampaign(arr?.length > 0 ? arr : null);
    } else {
      let filteredArr = [];
      arr.map((values) => {
        if (values != val) {
          filteredArr.push(val);
        }
      });
      arr = filteredArr;
      setSelectedplatformByCampaign(arr?.length > 0 ? arr : null);
    }
  };

  const handleAddIgnoredValues = (val) => {
    if (!arr2.includes(val)) {
      arr2 = [...arr2, val];
      setSelectedIgnoreplatformByCampaign(arr2?.length > 0 ? arr2 : null);
    } else {
      let filteredArr = [];
      arr2.map((values) => {
        if (values != val) {
          filteredArr.push(val);
        }
      });
      arr2 = filteredArr;
      setSelectedIgnoreplatformByCampaign(arr2?.length > 0 ? arr2 : null);
    }
  };

  const getCampaignData = async (type) => {
    setSelectedValues(null);
    if (type == "campaign") {
      setsourceLoading(true);
      let res = await getCampaignDataFromST(
        filterData?.db ? filterData?.db : prefilledData?.selectedClient?.db
      );
      if (res.status === 200) {
        let arr = [];
        if (res.data.length > 0) {
          for (let i = 0; i < res?.data.length; i++) {
            let obj = {
              value: res?.data[i]?.id,
              label: res?.data[i]?.name,
            };
            arr.push(obj);
          }
        }
        const filteredByVal = Array.isArray(arr)
          ? [
              ...arr
                .reduce((a, c) => {
                  a?.set(c?.value, c);
                  return a;
                }, new Map())
                .values(),
            ]
          : [];
        setsourceLoading(false);
        setSourceOption(filteredByVal);
      } else {
        setsourceLoading(false);
        // toast.error(res?.message || res?.error || "Something went wrong");
      }
    } else {
      setsourceLoading(true);
      let res = await getBusinessUnitListFromST(
        filterData?.db ? filterData?.db : prefilledData?.selectedClient?.db
      );
      if (res.status === 200) {
        let arr = [];

        if (res.data.length > 0) {
          for (let i = 0; i < res?.data.length; i++) {
            let obj = {
              value: res?.data[i]?.id,
              label: res?.data[i]?.name,
            };
            arr.push(obj);
          }
        }
        const filteredByVal = Array.isArray(arr)
          ? [
              ...arr
                .reduce((a, c) => {
                  a?.set(c?.value, c);
                  return a;
                }, new Map())
                .values(),
            ]
          : [];
        setsourceLoading(false);
        setSourceOption(filteredByVal);
      } else {
        setsourceLoading(false);
        // toast.error(res?.message || res?.error || "Something went wrong");
      }
    }
  };

  const handlePlatformList = async (platformName) => {
    if (platformName === undefined) return;
    setPlatformLoading(true);
    setSelectedplatformByCampaign(null);
    setSelectedIgnoreplatformByCampaign(null);
    let params = new URLSearchParams();

    params.append(
      "db",
      filterData?.db ? filterData?.db : prefilledData?.selectedClient?.db
    );
    params.append("name", platformName);
    let res = await getCampaignByPlatform(params.toString());
    if (res?.status === 200) {
      let arr = [];

      if (res.data.length > 0) {
        for (let i = 0; i < res?.data.length; i++) {
          let obj = {
            value: res?.data[i]?.id,
            label: res?.data[i]?.name,
          };
          arr.push(obj);
        }
      }
      const filteredByVal = Array.isArray(arr)
        ? [
            ...arr
              .reduce((a, c) => {
                a?.set(c?.value, c);
                return a;
              }, new Map())
              .values(),
          ]
        : [];
      setPlatformLoading(false);
      setPlatformCampaignList(filteredByVal);
    } else {
      // toast.error(res.message || res?.error || "Something went wrong");
    }
  };

  const handleSourceList = async (search, page) => {
    setsourceLoader(true);
    let params = new URLSearchParams();
    params.append(
      "db",
      filterData?.db ? filterData?.db : prefilledData?.selectedClient?.db
    );
    params.append("page_size", 5000);
    params.append("page", page ?? scrollPage);
    params.append("interval", 90);
    search && params.append("search", search);
    let res = await getSourceListForSetting(params?.toString());
    if (res?.status == 200) {
      let arr = [];
      res?.data?.items?.length > 0 &&
        res?.data?.items?.map((el) => {
          arr.push({
            label: el,
            value: el,
          });
        });
      if (page == 1 || scrollPage == 1) {
        setSourceOptionSetting(arr);
      } else {
        setSourceOptionSetting([...sourceOptionSetting, ...arr]);
      }
      setsourceLoader(false);
    } else {
      setsourceLoader(false);
    }
  };

  const handleSearchSource = useCallback(debounce(handleSourceList), []);

  const handleSearchOptions = (e) => {
    handleSearchSource(e, 1);
  };

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight) {
      // User has scrolled to the bottom

      setScrollPage(scrollPage + 1);
    }
  };
  const submitHandler = async (val) => {
    if (
      selectedValues &&
      val?.custom_costs?.[0]?.cost !== "" &&
      val?.custom_costs?.[0]?.year !== "" &&
      val?.custom_costs?.[0]?.month !== "" &&
      platformTypeValue == "custom"
    ) {
      setLoading(true);
      let requestPayload;
      let arr = [];
      val?.custom_costs?.map((el, index) => {
        if (el?.cost) {
          arr.push(el);
        }
      });
      if (selectedClient == "campaign") {
        requestPayload = {
          st_type: selectedClient,
          campaignid: selectedValues ? selectedValues.toString() : [],
          platform_type: platformTypeValue,
          custom_costs: arr,
          ad_campaignids: selectedplatformByCampaign
            ? JSON.stringify(selectedplatformByCampaign)
            : null,
          ignored_ad_campaignids: selectedIgnoreplatformByCampaign
            ? JSON.stringify(selectedIgnoreplatformByCampaign)
            : null,

          campaign_source: JSON.stringify(selectedSource),
        };

        if (platformTypeValue == "custom") {
          delete requestPayload.ad_campaignids;
        }
      } else {
        requestPayload = {
          st_type: selectedClient,
          businessunitid: selectedValues.toString(),
          platform_type: platformTypeValue,

          ad_campaignids: selectedplatformByCampaign
            ? JSON.stringify(selectedplatformByCampaign)
            : null,
          ignored_ad_campaignids: selectedIgnoreplatformByCampaign
            ? JSON.stringify(selectedIgnoreplatformByCampaign)
            : null,

          campaign_source: JSON.stringify(selectedSource),
        };

        if (platformTypeValue == "custom") {
          delete requestPayload.ad_campaignids;
        }
      }
      let res = await addCampaignAndBusinessUnit(
        filterData?.db ? filterData?.db : prefilledData?.selectedClient?.db,
        requestPayload
      );
      if (res.status == 200 || res.status == 201) {
        toast.success("Added successfully");
        setLoading(false);
        handleListing();
        setFilterDrawer(false);
      } else {
        setLoading(false);
        // toast.error(
        //   res?.response?.data?.message ||
        //     res?.message ||
        //     res?.error ||
        //     "Something went wrong"
        // );
      }
    } else if (
      selectedClient &&
      selectedValues &&
      (selectedplatformByCampaign ||
        selectedIgnoreplatformByCampaign ||
        isSelectAll == true) &&
      (selectedplatformByCampaign?.length > 0 ||
        selectedIgnoreplatformByCampaign?.length > 0 ||
        selectData?.length > 0 ||
        selectData2?.length > 0) &&
      (platformTypeValue == "google" ||
        platformTypeValue == "facebook" ||
        platformTypeValue == "lsa" ||
        platformTypeValue == "yelp" ||
        platformTypeValue == "bing")
    ) {
      setLoading(true);
      let requestPayload;
      if (selectedClient == "campaign") {
        requestPayload = {
          st_type: selectedClient,
          campaignid: selectedValues ? selectedValues.toString() : [],
          platform_type: platformTypeValue,

          ad_campaignids: selectedplatformByCampaign
            ? JSON.stringify(selectedplatformByCampaign)
            : null,
          ignored_ad_campaignids: selectedIgnoreplatformByCampaign
            ? JSON.stringify(selectedIgnoreplatformByCampaign)
            : null,

          campaign_source: JSON.stringify(selectedSource),
        };

        if (platformTypeValue == "custom") {
          delete requestPayload.ad_campaignids;
        }
      } else {
        requestPayload = {
          st_type: selectedClient,
          businessunitid: selectedValues ? selectedValues.toString() : [],
          platform_type: platformTypeValue,

          ad_campaignids: selectedplatformByCampaign
            ? JSON.stringify(selectedplatformByCampaign)
            : null,
          ignored_ad_campaignids: selectedIgnoreplatformByCampaign
            ? JSON.stringify(selectedIgnoreplatformByCampaign)
            : null,

          // campaign_source: selectedSource,
          campaign_source: JSON.stringify(selectedSource),
        };

        if (platformTypeValue == "custom") {
          delete requestPayload.ad_campaignids;
        }
      }
      let res = await addCampaignAndBusinessUnit(
        filterData?.db ? filterData?.db : prefilledData?.selectedClient?.db,
        requestPayload
      );
      if (res.status == 200 || res.status == 201) {
        toast.success("Added successfully");
        setLoading(false);
        handleListing();
        setFilterDrawer(false);
      } else {
        setLoading(false);
        // toast.error(
        //   res?.response?.data?.message ||
        //     res?.message ||
        //     res?.error ||
        //     "Something went wrong"
        // );
      }
    } else {
      toast.warn("Please fill required fields");
    }
  };

  const handleSelectAll = (data) => {
    let arr = [];
    data?.length > 0 &&
      data?.map((el) => {
        arr.push(el?.value);
      });

    setSelectedplatformByCampaign(arr);
  };
  const handleIgnoredSelectAll = (data) => {
    let arr = [];
    data?.length > 0 &&
      data?.map((el) => {
        arr.push(el?.value);
      });

    setSelectedIgnoreplatformByCampaign(arr);
  };

  useEffect(() => {
    handleSourceList();
  }, [scrollPage]);

  return (
    <AddSectionWrapper selectedThemeColors={selectedThemeColors}>
      <div className="title">
        <h3 style={{ color: "rgba(25, 25, 25, 1)" }}>Add Campaigns</h3>
      </div>

      <div className="content">
        <Formik
          initialValues={initialValues}
          onSubmit={submitHandler}
          render={({ values, setFieldValue }) => (
            <Form>
              <div>
                <label>
                  <strong>Select Type</strong>
                </label>
                <div className="" style={{ marginTop: "4px" }}>
                  <Select
                    showSearch
                    size={"large"}
                    allowClear
                    // className="custom-select add-select"
                    prefixCls="inner-select2"
                    onChange={(val) => {
                      setPlatFormType(null);
                      getCampaignData(val);
                      setSelectedClient(val);
                      setSelectedValues(null);
                      setSourceOption([]);
                    }}
                    style={{
                      width: "100%",
                      height: "48px",
                      // backgroundColor: "#05031a",
                    }}
                    maxTagCount={1}
                    placeholder="Please select"
                    options={clientOptions}
                  />
                </div>
              </div>

              <div className="select-wrap">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "transparent",
                    height: "100%",
                    marginBottom: "0px",
                  }}
                >
                  <label
                    className="mb-1  "
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <strong>Campaign Search</strong>
                  </label>
                </div>
                <Tooltip>
                  <Select
                    showSearch
                    // mode="multiple"
                    // className="custom-select-2 inner-multi-select add-multi-select"
                    // className="custom-select add-select"
                    prefixCls="inner-select2"
                    allowClear
                    loading={sourceLoading}
                    style={{
                      width: "100%",
                      height: "48px",
                      // backgroundColor: "#05031a",
                    }}
                    maxTagCount={1}
                    value={selectedValues}
                    placeholder="Please select"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    onClear={handleAllClear}
                    onChange={setSelectedValues}
                    options={sourceOption}
                    notFoundContent={
                      sourceLoading ? (
                        <NoDataDropdown text={"Processing..."} />
                      ) : (
                        <NoDataDropdown text={"No data"} />
                      )
                    }
                  />
                </Tooltip>
              </div>

              <div className="input-box" style={{ marginTop: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "transparent",
                    height: "100%",
                    marginBottom: "0px",
                  }}
                >
                  <label
                    className="mb-1  "
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <strong
                      style={{
                        fontWeight: 700,
                        fontSize: "17px",
                        color: "rgb(146, 146, 165)",
                        fontStyle: "normal",
                      }}
                    >
                      Campaign Sources
                    </strong>
                  </label>
                </div>
                <Tooltip>
                  <Select
                    mode="multiple"
                    prefixCls="inner-select2"
                    allowClear
                    loading={sourceLoader}
                    onPopupScroll={handleScroll}
                    onSearch={(e) => handleSearchOptions(e)}
                    onBlur={(e) =>
                      scrollPage == 1 ? handleSourceList() : setScrollPage(1)
                    }
                    maxTagCount={1}
                    style={{
                      width: "100%",
                      height: "48px",
                    }}
                    value={selectedSource}
                    onChange={(el) => setSelectedSource(el)}
                    // onChange={(el) => console.log(el)}

                    placeholder="Please select"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    onClear={handleAllClearSources}
                    options={sourceOptionSetting}
                    notFoundContent={
                      sourceLoader ? (
                        <NoDataDropdown text={"Processing..."} />
                      ) : (
                        <NoDataDropdown text={"No data"} />
                      )
                    }
                  />
                </Tooltip>
              </div>

              <div className="select-wrap" style={{ marginTop: "20px" }}>
                <label>
                  <strong>Platform Type</strong>
                </label>
                <div className="" style={{ marginTop: "4px" }}>
                  <Select
                    showSearch
                    size={"large"}
                    value={platformTypeValue}
                    allowClear
                    // className="custom-select add-select"
                    prefixCls="inner-select2"
                    onChange={(val) => {
                      if (!platformLoading) {
                        val != "custom" && handlePlatformList(val);
                        setPlatFormType(val);
                        setPlatformCampaignList([]);
                      }
                    }}
                    style={{
                      width: "100%",
                      height: "48px",
                      // backgroundColor: "#05031a",
                    }}
                    maxTagCount={1}
                    placeholder="Please select"
                    options={
                      selectedClient == "businessunit"
                        ? platformType2
                        : platformType
                    }
                  />
                </div>
              </div>
              {platformTypeValue == "custom" && (
                <>
                  {/* <div className="select-wrap" style={{ marginTop: "20px" }}>
                    <label>
                      <strong>Cost</strong>
                    </label>
                    <div className="" style={{ marginTop: "4px" }}>
                      <input
                        // size={"large"}
                        onChange={(e) => setCostValue(e.target.value)}
                        className="custom-select add-select custom-input"
                        style={{
                          width: "100%",
                          height: "48px",
                          // backgroundColor: "#05031a",
                        }}
                        // maxTagCount={1}
                        type="number"
                        placeholder="Please Enter"
                        // options={platformType}
                        // popupClassName="source-select-popover"
                        // dropdownStyle={{
                        //   maxHeight: "300px",
                        //   background: "#161032",
                        // }}
                      />
                    </div>
                  </div>
                  <label style={{ marginTop: "20px", display: "block" }}>
                    <strong>Select Month/Year</strong>
                  </label>
                  <DatePicker
                    picker="month"
                    style={{ color: "#fff" }}
                    placeholder="Please Select"
                    className="form-control input custom-year-picker"
                    onChange={(e, fullDate) => {
                      setMonth(e.month());
                      setYear(e.year());
                      setYearMonth(fullDate);
                    }}
                  /> */}
                  <FieldArray name="custom_costs">
                    {({ insert, remove, push }) => (
                      <div>
                        {values.custom_costs.length > 0 &&
                          values.custom_costs.map((friend, index) => (
                            <div key={index}>
                              <div className="col">
                                <label
                                  htmlFor={`custom_costs.${index}.cost`}
                                  className="new-add-label-2"
                                >
                                  Cost
                                </label>
                                <Field
                                  name={`custom_costs.${index}.cost`}
                                  type="number"
                                  min="0"
                                  className="new-add-input"
                                  placeholder="Please Enter"
                                  // onChange={(e) => setCostValue(e.target.value)}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `custom_costs.${index}.cost`,
                                      e?.target?.value
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  name={`custom_costs.${index}.cost`}
                                  component="div"
                                  className="field-error"
                                />
                              </div>
                              <div className="col">
                                <label
                                  htmlFor={`custom_costs.${index}.month`}
                                  className="new-add-label-2"
                                >
                                  Select month/year
                                </label>
                                {/* <Field
                                  name={`custom_costs.${index}.monthyear`}
                                  placeholder="jane@acme.com"
                                  type="text"
                                /> */}
                                <DatePicker
                                  name={`custom_costs.${index}.month`}
                                  picker="month"
                                  allowClear={false}
                                  style={{ color: "#fff" }}
                                  placeholder="Please Select"
                                  className="form-control input custom-year-picker"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `custom_costs.${index}.month`,
                                      e.month() + 1
                                    );
                                    setFieldValue(
                                      `custom_costs.${index}.year`,
                                      e.year()
                                    );
                                  }}
                                  // onChange={(e, fullDate) => {
                                  //   setMonth(e.month());
                                  //   setYear(e.year());
                                  //   setYearMonth(fullDate);
                                  // }}
                                />
                                <ErrorMessage
                                  name={`custom_costs.${index}.month`}
                                  component="div"
                                  className="field-error"
                                />
                              </div>
                              <div className="add-more">
                                {index >= 1 ? (
                                  <button
                                    type="button"
                                    className="secondary"
                                    onClick={() => remove(index)}
                                  >
                                    Remove
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ))}
                        <div className="add-more" style={{ marginTop: "20px" }}>
                          <button
                            type="button"
                            className="secondary"
                            onClick={() =>
                              push({
                                cost: "",
                                month: "",
                                year: "",
                              })
                            }
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                  {/* <FieldArray
                    name="values"
                    render={({ push, remove }) => (
                      <div>
                        {cost_year?.map((value, index) => (
                          <div key={index}>
                            <label className="new-add-label-2">
                              <strong>Cost</strong>
                            </label>
                           
                            <Field
                              type="number"
                              name={values?.index}
                              className="new-add-input"
                              placeholder="Please Enter"
                              onChange={(e) => setCostValue(e.target.value)}
                            />
                            <label className="new-add-label">
                              <strong>Select month/year</strong>
                            </label>
                            <DatePicker
                              picker="month"
                              style={{ color: "#fff" }}
                              name={values?.index}
                              placeholder="Please Select"
                              className="form-control input custom-year-picker"
                              onChange={(e, fullDate) => {
                                setMonth(e.month());
                                setYear(e.year());
                                setYearMonth(fullDate);
                              }}
                            />
                            <div className="add-more">
                              <button
                                type="button"
                                onClick={() => {
                                  removeObj(
                                    index + 1,
                                    cost_year,
                                    setFieldValue
                                  );
                                }}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                        <div className="add-more" style={{ marginTop: "20px" }}>
                          <button
                            type="button"
                            onClick={() => {
                              push({
                                custom_cost: costValue,
                                custom_year: year,
                                custom_month: month,
                              }); 
                              cost_year.push({
                                custom_cost: costValue,
                                custom_year: year,
                                custom_month: month,
                              })
                            }} // Push an empty string to add another input field
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                    )}
                  /> */}
                  {/* <Form
                    name="dynamic_form_item"
                    {...formItemLayoutWithOutLabel}
                    onFinish={onFinish}
                    style={{
                      maxWidth: 600,
                    }}
                  >
                    <Form.List name="names">
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map((field, index) => (
                            <Form.Item
                              {...(index === 0
                                ? formItemLayout
                                : formItemLayoutWithOutLabel)}
                              required={false}
                              key={field.key}
                            >
                              <Form.Item
                                {...field}
                                validateTrigger={["onChange", "onBlur"]}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: true,
                                    message:
                                      "Please input passenger's name or delete this field.",
                                  },
                                ]}
                                noStyle
                              >
                                <label className="new-add-label">
                                  <strong>Cost</strong>
                                </label>
                                <input
                                  type="number"
                                  placeholder="Please Enter"
                                  className="new-add-input"
                                  onChange={(e) => setCostValue(e.target.value)}
                                />
                                <label className="new-add-label">
                                  <strong>Select month/year</strong>
                                </label>
                                <DatePicker
                                  picker="month"
                                  style={{ color: "#fff" }}
                                  placeholder="Please Select"
                                  className="form-control input custom-year-picker"
                                  onChange={(e, fullDate) => {
                                    setMonth(e.month());
                                    setYear(e.year());
                                    setYearMonth(fullDate);
                                  }}
                                />
                              </Form.Item>
                              {fields.length > 0 ? (
                                <div className="remove-icon">
                                  <MinusCircleOutlined
                                    className="dynamic-delete-button"
                                    onClick={(e) => {
                                      remove(field.name);
                                      removeObj(field.name);
                                    }}
                                  />
                                </div>
                              ) : null}
                            </Form.Item>
                          ))}
                          <Form.Item>
                            <div className="add-more">
                              <Button
                                type="dashed"
                                onClick={(e) => {
                                  add();
                                  addMore();
                                }}
                                icon={<PlusOutlined />}
                              >
                                Add More
                              </Button>
                            </div>
                            <Form.ErrorList errors={errors} />
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form>*/}
                  {/* <div className="add-more">
                    <button>
                      <b>Add More</b>
                    </button>
                  </div> */}
                </>
              )}

              {platformTypeValue != "custom" && (
                <>
                  <div className="select-wrap" style={{ marginTop: "20px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        background: "transparent",
                        height: "100%",
                        marginBottom: "0px",
                      }}
                    >
                      <label
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <strong>Campaigns by Platform</strong>
                      </label>
                      <>
                        {selectedplatformByCampaign?.length !==
                        platformCampaignList?.length ? (
                          <span
                            className="ml-2"
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                              height: "20px",
                            }}
                          >
                            <span className="mr-2" style={{ color: "#828690" }}>
                              Select All
                            </span>
                            <span aria-disabled>
                              <Checkbox
                                disabled={sourceLoading}
                                checked={
                                  selectedplatformByCampaign?.length ==
                                  platformCampaignList?.length
                                }
                                onChange={(e) => {
                                  setIsSelectAll(e?.target?.checked);
                                  setSelectData(platformCampaignList);
                                  if (platformCampaignList?.length > 0) {
                                    handleSelectAll(platformCampaignList);
                                  }
                                }}
                              />
                            </span>
                          </span>
                        ) : (
                          <span
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignContent: "center",
                              height: "20px",
                            }}
                            className="ml-2"
                          >
                            <span className="mr-2" style={{ color: "#828690" }}>
                              {" "}
                              Clear All
                            </span>
                            <span aria-disabled>
                              <Checkbox
                                disabled={sourceLoading}
                                checked={
                                  selectedplatformByCampaign?.length ==
                                  platformCampaignList?.length
                                }
                                onChange={(e) => {
                                  setIsSelectAll(e?.target?.checked);
                                  setSelectedplatformByCampaign([]);
                                  setSelectData([]);
                                }}
                              />
                            </span>
                          </span>
                        )}
                      </>
                    </div>
                    <div className="" style={{ marginTop: "4px" }}>
                      <Select
                        size={"large"}
                        allowClear
                        mode="multiple"
                        // prefixCls="multipleHeight"
                        loading={platformLoading}
                        // className="custom-select-2 inner-multi-select add-multi-select"
                        prefixCls="inner-select2"
                        // className="custom-select add-select"
                        searchValue={searchValue}
                        onBlur={() => setSearchValue("")}
                        onSearch={(e) => setSearchValue(e)}
                        onChange={(val) => {
                          handleAddValues(val);
                          setSelectedplatformByCampaign(val);
                        }}
                        style={{
                          width: "100%",
                          height: "48px",
                          // backgroundColor: "#05031a",
                        }}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={
                          selectedplatformByCampaign?.length > 0
                            ? selectedplatformByCampaign
                            : []
                        }
                        maxTagCount={1}
                        placeholder="Please select"
                        options={platformCampaignList}
                        notFoundContent={
                          platformLoading ? (
                            <NoDataDropdown text={"Processing..."} />
                          ) : (
                            <NoDataDropdown text={"No data"} />
                          )
                        }
                      />
                    </div>
                    <div></div>
                  </div>
                  <div className="select-wrap" style={{ marginTop: "20px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        background: "transparent",
                        height: "100%",
                        marginBottom: "0px",
                      }}
                    >
                      <label
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <strong>Ignored Campaign By Platform</strong>
                      </label>
                      <>
                        {selectedIgnoreplatformByCampaign?.length !==
                        platformCampaignList?.length ? (
                          <span
                            className="ml-2"
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                              height: "20px",
                            }}
                          >
                            <span className="mr-2" style={{ color: "#828690" }}>
                              Select All
                            </span>
                            <span aria-disabled>
                              <Checkbox
                                disabled={sourceLoading}
                                checked={
                                  selectedIgnoreplatformByCampaign?.length ==
                                  platformCampaignList?.length
                                }
                                onChange={(e) => {
                                  setIsSelectAll(e?.target?.checked);
                                  setSelectData2(platformCampaignList);
                                  if (platformCampaignList?.length > 0) {
                                    handleIgnoredSelectAll(
                                      platformCampaignList
                                    );
                                  }
                                }}
                              />
                            </span>
                          </span>
                        ) : (
                          <span
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignContent: "center",
                              height: "20px",
                            }}
                            className="ml-2"
                          >
                            <span className="mr-2" style={{ color: "#828690" }}>
                              {" "}
                              Clear All
                            </span>
                            <span aria-disabled>
                              <Checkbox
                                disabled={sourceLoading}
                                checked={
                                  selectedIgnoreplatformByCampaign?.length ==
                                  platformCampaignList?.length
                                }
                                onChange={(e) => {
                                  setIsSelectAll(e?.target?.checked);
                                  setSelectedIgnoreplatformByCampaign([]);
                                  setSelectData2([]);
                                }}
                              />
                            </span>
                          </span>
                        )}
                      </>
                    </div>
                    <div className="" style={{ marginTop: "4px" }}>
                      <Select
                        size={"large"}
                        mode="multiple"
                        allowClear
                        // prefixCls="multipleHeight"
                        loading={platformLoading}
                        // className="custom-select-2 inner-multi-select add-multi-select"
                        prefixCls="inner-select2"
                        // className="custom-select add-select"
                        onChange={(val) => {
                          handleAddIgnoredValues(val);
                          setSelectedIgnoreplatformByCampaign(val);
                        }}
                        style={{
                          width: "100%",
                          height: "48px",
                          // backgroundColor: "#05031a",
                        }}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={
                          selectedIgnoreplatformByCampaign?.length > 0
                            ? selectedIgnoreplatformByCampaign
                            : []
                        }
                        maxTagCount={1}
                        placeholder="Please select"
                        options={platformCampaignList}
                        notFoundContent={
                          platformLoading ? (
                            <NoDataDropdown text={"Processing..."} />
                          ) : (
                            <NoDataDropdown text={"No data"} />
                          )
                        }
                      />
                    </div>
                    <div></div>
                  </div>
                </>
              )}

              {!loading ? (
                <button
                  className="btn-wrap"
                  type="submit"
                  onClick={() => {
                    // addMore();
                    // submitHandler();
                  }}
                >
                  <b>Submit</b>
                </button>
              ) : (
                <div
                  style={{
                    marginTop: "17px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <LoadingButton selectedThemeColors={selectedThemeColors}>
                    <b>Loading...</b>
                  </LoadingButton>
                </div>
              )}
            </Form>
          )}
        ></Formik>
      </div>
    </AddSectionWrapper>
  );
};

export default AddSettings;

const AddSectionWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px 0;

  .btn-wrap {
    margin-top: 30px;
    width: 100%;
    border-style: none;
    background-color: ${({ selectedThemeColors }) =>
      selectedThemeColors?.buttonColor};
    border-radius: 7px;
    padding: 10px 15px;
    color: #fff;
  }

  .title {
    width: 100%;
    margin-bottom: 20px;

    h3 {
      font-size: 24px;
      color: #fff;
    }
  }
  .content {
    width: 100%;
    padding-bottom: 20px;

    label {
      color: ${({ selectedThemeColors }) => selectedThemeColors?.lightText};
      font-size: 15px;
    }
    .select-wrap {
      margin-top: 10px;
    }
  }
  .add-more {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 10px;

    button {
      border: none;
      padding: 3px 7px;
      border-radius: 4px;
      font-size: 12px;
      color: #fff;
      background-color: rgba(36, 36, 36, 1);
    }
  }
`;
const LoadingButton = styled.p`
  margin-top: 13px;
  width: 100%;
  border-style: none;
  background-color: ${({ selectedThemeColors }) =>
    selectedThemeColors?.buttonColor};
  border-radius: 7px;
  padding: 10px 15px;
  color: #fff;
`;
