import ExternalApi from "./Interceptor";

//get Api
export const getApiExternal = async (url, cancelPrev = {}) => {
  try {
    let result = await ExternalApi.get(url, cancelPrev);
    if (result.status === 200) {
      if (!result?.data?.status) return { data: result?.data, status: 200 }; // add this line if backend not return status
      if (result.data.status === 200 || result?.data?.message == "OK") {
        return result.data;
      } else {
        return result.data.message;
      }
    }

    if (result.status === 201) {
      if (result.data.status === 201 || result?.data?.message == "OK") {
        return result.data;
      } else {
        return result.data.message;
      }
    }
  } catch (e) {
    if (e) {
      return e;
    }
  }
};

//post Api
export const postApiExternal = async (url, data) => {
  try {
    let result = await ExternalApi.post(url, data);
    if (result.status === 200 || result.status === 201) {
      if (!result?.data?.status) return { ...result?.data, status: 200 }; // add this line if backend not return status
      if (
        result.data.status === 200 ||
        result.data.status === 201 ||
        result?.data?.message == "OK"
      ) {
        return result.data;
      } else {
        return result.data.message;
      }
    }
  } catch (e) {
    if (e) {
      return e;
    }
  }
};

//put Api
export const putApiExternal = async (url, data) => {
  try {
    let result = await ExternalApi.put(url, data);
    if (result.status === 200) {
      if (!result?.data?.status) return { ...result?.data, status: 200 }; // add this line if backend not return status
      if (result.data.status === 200) {
        return result.data;
      } else {
        return result.data.message;
      }
    }
  } catch (e) {
    if (e) {
      return e;
    }
  }
};

//delete Api
export const deleteApiExternal = async (url, data, type) => {
  let payload = type === "isBody" ? { data: data } : { params: data };
  try {
    let result = await ExternalApi.delete(url, payload);
    if (result.status === 200) {
      if (!result?.data?.status) return { ...result?.data, status: 200 }; // add this line if backend not return status
      if (result.data.status === 200) {
        return result.data;
      } else {
        return result.data.message;
      }
    }
  } catch (e) {
    if (e) {
      return e;
    }
  }
};

export const patchApiExternal = async (url, data) => {
  try {
    let result = await ExternalApi.patch(url, data);
    if (result.status === 200) {
      return { ...result.data, status: 200 };
    } else {
      return result.data.message;
    }
  } catch (e) {
    if (e) {
      return e;
    }
  }
};
