import { Button, ConfigProvider, DatePicker } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { useSelector } from "react-redux";
import styled from "styled-components";
const { RangePicker } = DatePicker;

const theme = {
  backgroundGray: "black",
  lightWhite: "#000",
  primaryColor: "#fff",
  lightText: "#000",
  greyText: "#a4a4a4",
};

const RangeSelectorConfirm = ({
  defaultDate = [],
  handleChange,
  disabled = false,
  bgColor,
  allowClear = false,
  handleClear = () => {},
}) => {
  const dateFormat = "YYYY-MM-DD";
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );
  const [open, setOpen] = useState(false);

  const [d, setDate] = useState([]);

  const handleDateChange = (e, value) => setDate(value);

  const handleConfirm = (e) => {
    setOpen(false);
    if (d?.length > 0) {
      handleChange(d);
    } else {
      handleChange(defaultDate);
    }
  };
  return (
    <ConfigProvider
      selectedThemeColors={selectedThemeColors}
      theme={{
        token: {
          colorBgElevated: "#fff", // container background
          colorSplit: theme.lightWhite,
          colorTextQuaternary: theme.lightText, // previous/next month date color
        },
        components: {
          DatePicker: {
            colorBgContainer: bgColor ?? theme.primaryColor, // used to change the bg color of datepicker-field
            // colorBgContainerDisabled: bgColor ?? theme.primaryColor, // used to change the bg color of datepicker-field
            colorText: theme.lightWhite,
            colorTextPlaceholder: theme.lightText,
            colorBorder: theme.primaryColor,
            cellActiveWithRangeBg: theme.primaryColor, //"#ebf4f8", // used to show the selected range background
            colorTextHeading: theme.greyText, // used to change the heading color in calender
            colorPrimary: "#000",
            colorPrimaryHover: "#000",
            colorIcon: theme.lightWhite,
            colorIconHover: theme.lightWhite,
          },
        },
      }}
    >
      <ClickAwayListener
        onClickAway={(e) => {
          if (open) {
            setOpen(false);
          }
        }}
      >
        <div>
          <DatePickerWrapper
            onClick={() => setOpen(true)}
            open={open}
            selectedThemeColors={selectedThemeColors}
            format={dateFormat}
            defaultValue={[
              defaultDate?.[0] ? dayjs(defaultDate[0], dateFormat) : null,
              defaultDate?.[1] ? dayjs(defaultDate[1], dateFormat) : null,
            ]}
            allowClear={allowClear}
            suffixIcon={null}
            onChange={(e) => e == null && handleClear(e)}
            onCalendarChange={handleDateChange}
            onClear={() => console.log("clear")}
            disabled={disabled}
            renderExtraFooter={() => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "12px",
                }}
              >
                <Button type="primary" onClick={handleConfirm}>
                  Apply
                </Button>
              </div>
            )}
            popupClassName="range-picker-popup"
          />
        </div>
      </ClickAwayListener>
    </ConfigProvider>
  );
};

export default RangeSelectorConfirm;

const DatePickerWrapper = styled(RangePicker)`
  width: 100%;
  height: 35px;
  padding: 8px !important;
  background: #fff !important;
  border: 1px solid rgba(206, 206, 206, 1) !important;
  ${
    "" /* display: flex !important;
  justify-content: space-evenly !important; */
  }

  .ant-empty-description {
    color: #000 !important;
  }
  .ant-picker-input {
    width: 40%;
    margin-bottom: 0px !important;
  }
  .ant-picker-range-separator {
    width: 20%;
    display: flex;
    justify-content: center;
    filter: invert(1) !important;
  }
  .ant-picker-input > input {
    color: #0f1011 !important;

    text-align: center;
  }
  .ant-picker .ant-picker-input > input-disabled:hover {
    border: none !important;
    background-color: none !important;
  }
  .ant-picker .ant-picker-input > input[disabled]:hover {
    border: none !important;
    background-color: none !important;
  }
`;
