import { Input, InputNumber, message, Modal } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Create_Phone_api, Edit_Phone_api } from "../../services/Collections";
import { Form, Button, Space } from "antd";

const CreatePhoneModal = ({ open, handleClose, handlePhoneListing, edit }) => {
  console.log(edit, "jkldkjd");
  const [form] = Form.useForm();
  const EditPhoneNumber = edit?.phone_number;
  const formattedNumber = EditPhoneNumber?.replace(/^\+1/, "");
  const initialValue = edit
    ? { phone_number: formattedNumber }
    : { phone_number: "" };

  const onFinish = (values) => {
    const arr = values?.phoneNumbers?.map((ele) => ({
      phone_number: "+1" + ele?.phone_number,
    }));

    if (edit) {
      handleSubmitEdit(arr?.[0]);
    } else {
      handleSubmit(arr);
    }
  };

  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(edit?.phone_number || "");
  const [phoneNumber, setPhoneNumber] = useState("");

  const client = useSelector(
    (state) => state?.loginAuth?.prefilledFilters?.selectedClient
  );

  const handleSubmit = async (phoneArr) => {
    setLoading(true);

    let res = await Create_Phone_api(client?.key, phoneArr);

    if (res.status === 201) {
      handlePhoneListing();
      handleClose();
      message.success(res?.message || "Created Successfully");
      setLoading(false);
    } else {
      setLoading(false);
      handleClose();
      message.error(
        res.response.data.message ||
          res.error ||
          res.message ||
          "Something went wrong"
      );
    }
  };

  const handleSubmitEdit = async (obj) => {
    setLoading(true);
    let res = await Edit_Phone_api(client?.key, edit?.id, obj);
    if (res.status === 200) {
      handlePhoneListing();
      handleClose();
      message.success(res?.message || "Edited Successfully");
      setLoading(false);
    } else {
      setLoading(false);
      handleClose();
      message.error(
        res.response.data.message ||
          res.error ||
          res.message ||
          "Something went wrong"
      );
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);

    let arr = form.getFieldValue()?.phoneNumbers;

    arr.map((ele, index) => (index == 0 ? { phone: inputValue } : ele));
    console.log("arrr", arr);
    form.setFieldValue("phoneNumbers", arr);
    console.log("arrr", form.getFieldValue());
    const formattedNumber = formatPhoneNumber(inputValue);
    setPhoneNumber(formattedNumber);
  };

  const formatPhoneNumber = (value) => {
    const digits = value.replace(/\D/g, ""); // Remove all non-numeric characters
    let formattedNumber = digits;

    if (digits.length > 1) formattedNumber = `(${digits.slice(0, 3)}`;
    if (digits.length >= 4) formattedNumber += `) ${digits.slice(3, 6)}`;
    if (digits.length >= 7) formattedNumber += `-${digits.slice(6, 10)}`;

    return formattedNumber;
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      const currentDigits = e?.target.value.replace(/\D/g, "");
      if (currentDigits.length > 0) {
        const newDigits = currentDigits.slice(0, -1);
        const formattedNumber = formatPhoneNumber(newDigits);
        console.log(
          newDigits,
          currentDigits,
          formattedNumber,
          "checking newDigits"
        );
        setPhoneNumber(formattedNumber);
        setInputValue(formattedNumber);
      }
    }
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      key="bottom"
      footer={null}
      destroyOnClose={true}
      prefixCls="default"
    >
      <CreatePhoneModalStyle>
        <h5>{edit?.phone_number ? "Edit" : "Add"} a Phone Number</h5>

        <Form form={form} onFinish={onFinish} layout="vertical">
          <p>Enter Phone Number :</p>
          <Form.List name="phoneNumbers" initialValue={[initialValue]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Space
                    prefixCls="AddInputSpace"
                    key={field.key}
                    style={{ width: "100%", alignItems: "center" }}
                  >
                    <Form.Item
                      style={{ margin: "5px 0" }}
                      {...field}
                      name={[field.name, "phone_number"]}
                      fieldKey={[field.fieldKey, "phone_number"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter a valid phone number",
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Enter phone number"
                        style={{
                          width: edit || fields.length <= 1 ? "472px" : "430px",
                        }}
                        maxLength={17}
                        formatter={formatPhoneNumber}
                        parser={(value) => value.replace(/\D/g, "")}
                        prefix="+1"
                      />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <div
                        className="addMore remove"
                        onClick={() => remove(field.name)}
                      >
                        -
                      </div>
                    ) : null}
                  </Space>
                ))}
                {!edit && (
                  <Form.Item
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      // paddingRight: "42px",
                      marginTop: "5px",
                    }}
                  >
                    <Button type="none" onClick={() => add()} block>
                      + Add more
                    </Button>
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
          <Form.Item
            style={{ margin: 0, display: "flex", justifyContent: "end" }}
          >
            <Button type="primary" htmlType="submit" loading={loading}>
              {loading ? "Loading..." : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </CreatePhoneModalStyle>
    </Modal>
  );
};

export default CreatePhoneModal;

const CreatePhoneModalStyle = styled.div`
  p {
    margin: 0;
    margin-bottom: 4px;
    margin-top: 20px;
  }
  .submitBtn {
    display: flex;
    justify-content: end;
    margin-top: 24px;
    button {
      width: fit-content;
      border-style: none;
      background-color: #ed0101;
      border-radius: 10px;
      padding: 10px 12px;
      color: #fff;
      font-size: 14px;
      font-weight: 500 !important;
      line-height: 18.2px;
      text-align: center;
    }
  }

  input {
    padding: 10px;
    font-family: Inter;
    font-weight: 400;
    line-height: 14.4px;
    text-align: left;
    color: #000;
  }
  input::placeholder {
    color: #b9b6b6c7 !important;
  }

  .addMore {
    background: green;
    border: 1px solid transparent;
    width: fit-content;
    color: white;
    font-size: 19px;
    padding: 0 10px;
    cursor: pointer;
    height: 32px;
    border-radius: 5px;
  }
  .remove {
    background: red;
  }

  .AddInputSpace-item {
    width: 100%;
  }
`;
