import { Input, InputNumber, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import { NoDataDropdown } from "../../components/common/NoDataDropdown";
import {
  addTrackerReason,
  editTrackerReason,
  getTrackerReasonListing,
} from "../../services/Collections";

export default function AddBudget({ title, initialData, handleSubmit }) {
  const { loginAuth, budgetTrcker } = useSelector((state) => state);

  const [client, setClient] = useState(initialData?.reason || null);
  const [amount, setAmount] = useState(initialData?.total || 0);
  const [reasons, setReasons] = useState(null);
  const [loader, setLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);

  const activeClient = budgetTrcker?.selectedClient
    ? budgetTrcker?.selectedClient
    : loginAuth?.prefilledFilters?.selectedClient;

  const addTracker = async () => {
    if (!client) {
      toast.warning("Please select reason");
      return;
    }
    setSubmitLoader(true);
    let payload = {
      user_id: loginAuth?.data?.id,
      reason: client,
      total: amount,
    };

    let res;

    if (initialData) {
      res = await editTrackerReason(activeClient?.key,initialData?.id, payload);
    } else {
      res = await addTrackerReason(activeClient?.key, payload);
    }

    if (res?.status == 200 || res?.status == 201) {
      toast.success(
        res?.message ||
          `Budget ${initialData ? "edited" : "added"} successfully`
      );
      setSubmitLoader(false);
      handleSubmit();
    } else {
      setSubmitLoader(false);
      toast.error(res?.message || "Something went wrong");
      handleSubmit();
    }
  };

  const reasonsListing = async (clinetKey) => {
    setLoader(true);

    let res = await getTrackerReasonListing(clinetKey);

    if (res?.status == 200) {
      let tableData = res?.data?.map((ele) => ({
        ...ele,
        lebel: ele?.reason,
        value: ele?.reason,
      }));
      setReasons(tableData);
      setLoader(false);
    } else {
      setLoader(false);
      toast.error(res?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    reasonsListing(activeClient?.key);
  }, [activeClient?.key]);

  return (
    <AddBudgetWrapper>
      <div>
        <div className="header">
          <p>{title}</p>
        </div>
        <div className="filedsWrappers">
          <p className="titles">Select Reason </p>
          <Select
            size={"large"}
            prefixCls="inner-select2"
            style={{ width: "100%", height: "48px" }}
            loading={loader}
            onChange={(e) => setClient(e)}
            value={client}
            placeholder="Please select"
            options={reasons}
            notFoundContent={
              true ? (
                <NoDataDropdown text={"Processing..."} />
              ) : (
                <NoDataDropdown text={"No Data"} />
              )
            }
          />
        </div>
        <div className="filedsWrappers">
          <p className="titles">Amount </p>
          <InputNumber
            prefixCls="inputnumber"
            className="inputnumber"
            min={0}
            value={amount}
            placeholder="Enter Budget Ammount"
            onChange={(e) => Number(e) && setAmount(e)}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          />
        </div>
      </div>
      <button onClick={addTracker}>
        {submitLoader ? "Loading..." : "Save"}
      </button>
    </AddBudgetWrapper>
  );
}

const AddBudgetWrapper = styled.div`
  height: 321px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  .header {
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    letter-spacing: 0.005em;
    text-align: center;
    color: #242424;
  }

  .filedsWrappers {
    margin-top: 24px;
  }

  .titles {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 11px;
  }

  .inputnumber {
    width: 100%;
    input {
      height: 42px;
    }
  }

  button {
    width: 100%;
    height: 42px;
    padding: 10.5px 12px;
    border-radius: 10px;
    background: #ed0101;
    color: white;
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const dummyData = [
  {
    label:
      "Volutpat ut lorem tellus sollicitudin cras lacus mi. A sit viverra enim at.",
    value:
      "Volutpat ut lorem tellus sollicitudin cras lacus mi. A sit viverra enim at.",
  },
  {
    label: "Nunc laoreet elit tincidunt fringilla. Blandit interdum.",
    value: "Nunc laoreet elit tincidunt fringilla. Blandit interdum.",
  },
  {
    label:
      "Nunc laoreet elit tincidunt fringilla. Blandit interdum sollicitudin cras lacus mi. A sit viverra enim at.",
    value:
      "Nunc laoreet elit tincidunt fringilla. Blandit interdum sollicitudin cras lacus mi. A sit viverra enim at.",
  },
  { label: "Volutpat ut lorem tellus .", value: "Volutpat ut lorem tellus ." },
];
