import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Workbook } from "@fortune-sheet/react";
import styled from "styled-components";
import {
  categoryArray,
  findCellValue,
  getIndex,
  transformData,
} from "./commonFun";
import { ModalLoader } from "../../components/Loader";
import "@fortune-sheet/react/dist/index.css"

const data1 = [
  {
    id: "1",
    name: "Sheet1",
    data: null,
    status: 1,
    celldata: [],
    calcChain: [],
    frozen: {
   type: 'rangeBoth',
   range: {row_focus: 0, column_focus: 0}
},
  },
];

function ExcelYearSheet({ yearsheetData, setSubmitData, selectedHistory }) {
  const workbookRef = useRef(null);
  const [workbookData, setWorkbookData] = useState([]);
  const [loading, setLoading] = useState(true);


  const handleChange = (newData) => {
    console.log("handleChange");

    let tempArray = [];
    if (newData[0]?.data) {
      for (let i = 0; i < newData[0]?.data?.length; i++) {
        let tempObject = {};
        const elementI = newData[0]?.data[i];
        for (let j = 0; j < elementI.length; j++) {
          const elementJ = elementI[j];
          tempObject = {
            r: i,
            c: j,
            v: elementJ,
          };
          tempArray.push(tempObject);
        }
      }
    }
    newData = [{ ...newData[0], celldata: tempArray, data: null }];
    setSubmitData(newData);
  };

  const manulipateData = (data) => {
    let finalCategoryArray = categoryArray?.map((el, i) => ({
      r: 0,
      c: `${i}`,
      v: {
        lo: 1,
        v: el,
        f: "",
        ff: "Poppins",
        bg: "rgb(216, 12, 12)",
        fc: "#ffffff",
        fs: "10",
        vt: 0,
        ht: 0,
        bl: 0,
      },
    }));

    let getAllKeys = transformData(data);
    let [celldata, calcChain] = findCellValue(getAllKeys);
    let a = data1?.map((e) => ({
      ...e,
      celldata: [...finalCategoryArray, ...celldata],
      calcChain: calcChain,
    }));
    setWorkbookData(a);
    setTimeout(()=>{
        setLoading(false);
    },300);
  };

  const handleCellUpdate = useCallback((row, column, oldValue, newValue) => {
    let colConv = getIndex(categoryArray, "Conv %");
    let colClose = getIndex(categoryArray, "Close %");

    if (column == colConv || column == colClose) {
      if (!workbookRef.current) return;
      newValue["ct"] = { fa: "0.00", t: "n" };
      workbookRef.current.setCellValue(row, column, newValue);
    }
  }, []);


  useEffect(() => {
    setLoading(true);

    if (selectedHistory?.length > 0) {
      setWorkbookData(selectedHistory);

      setTimeout(()=>{
          setLoading(false);
      },300);
    } else {
        manulipateData(yearsheetData);
    }
  }, [selectedHistory,yearsheetData]);

  if (loading) {
    return(
      <div
        style={{
          width: "100%",
          height: "500px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ModalLoader size={45} color={"#1a1b55"} />
      </div>
    );
  }

  return (
    <div style={{ width: "100%", paddingRight:"30px"}}>
      <ExcelFortunesheetWrapper>
        {workbookData && workbookData?.length > 0 && (
          <Workbook
            ref={workbookRef}
            data={workbookData}
            onChange={handleChange}
            showFormulaBar={false}
            showToolbar={false}
            showSheetTabs={false}
            defaultColWidth={180}
            defaultRowHeight={58}
            defaultFontSize={12}
            hooks={{
              afterUpdateCell: handleCellUpdate,
            }}
            row={0}
            column={0}
            allowEdit
            forceCalculation
            addRows={0}
          />
        )}
      </ExcelFortunesheetWrapper>
    </div>
  );
}

export default memo(ExcelYearSheet);

const ExcelFortunesheetWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 233px);
`;
