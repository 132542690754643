import styled from "styled-components";
import React, { useEffect, useState } from "react";
import ComposedGraphComponent from "../../components/ComposedGraph";
import { LoadingOutlined } from "@ant-design/icons";
import { Drawer, Spin } from "antd";
import VisualizationForm from "./VisualizationForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getBussinessUnitsListing,
  getVisualizationData,
} from "../../services/Collections";
import { toast } from "react-toastify";
import { updateVisualizationData } from "../../app/Auth/signIn/LogInSlice";
import VisualizationBusinessUnit from "./VisualizationBusinessUnit";
import { getSelectedClientLogo } from "../../utils/common/commonFunction";
import BarLineChart from "../../components/BarLineChart";
import dayjs from "dayjs";
import Header from "../../components/Header";
import TabComponent from "../../components/newComponent/TabComponent";
import LoadingBlock from "../../components/LoadingBlock";
import Watermark from "../../components/Watermark";

export const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 40,
    }}
    spin
  />
);

export const colrs = {
  skyBlue: "rgb(44, 175, 254)",
  navyBlue: "rgb(84, 79, 197)",
  green: "rgb(0, 226, 114)",
  orange: "rgb(254, 106, 53)",
  grey: "rgb(107, 138, 188)",
  pink: "rgb(213, 104, 251)",
  seaGreen: "rgb(46, 224, 202)",
  red: "rgb(250, 75, 66)",
  orangeYellow: "rgb(254, 181, 106)",
};

export const graphListing = [
  { name: "Existing Customer Rev", color: colrs.skyBlue }, //skyblue
  { name: "Existing Customer Job", color: colrs.navyBlue, disable: true },
  {
    name: "Existing Customer Avg Rev",
    type: "line",
    color: colrs.green,
    yAxis: "right",
  },

  // { name: "Existing Customer with Marketing Rev", color: colrs.orange },
  // { name: "Existing Customer with Marketing Job", color: colrs.grey },
  // {
  //   name: "Existing Customer with Marketing Avg Rev",
  //   color: colrs.pink,
  //   type: "line",
  //   yAxis: "right",
  // },

  { name: "New Customer Rev", color: colrs.seaGreen },
  { name: "New Customer Job", color: colrs.red, disable: true },
  {
    name: "New Customer Avg Rev",
    type: "line",
    color: colrs.orangeYellow,
    yAxis: "right",
  },
];

const Visualization = () => {
  const [loading, setLoading] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState({});
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );
  const [graphData, setGraphData] = useState([]);
  const dispatch = useDispatch();
  const { visualizationData } = useSelector((e) => e.loginAuth);
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );
  const [unitLoading, setUnitLoading] = useState(false);
  const [totalUnits, setTotalUnits] = useState([]);
  const AllClients = useSelector(
    (state) => state?.loginAuth?.clientsList?.clients
  );
  const [toggle, setToggle] = useState(true);

  const activeClient =
    visualizationData?.selectedClient || prefilledData?.selectedClient;

  const getInitData = async () => {
    setLoading(true);
    const init = visualizationData
      ? visualizationData
      : {
          date_start: prefilledData?.date_start,
          date_end: prefilledData?.date_end,
          selectedClient: prefilledData?.selectedClient,
          customer_type: "all",
          new_customer_nod: 30,
        };
    setDefaultValue(init);
    await getVisualizationDataListing(init);
    hanldeBussinessUnitsListing(init);
  };

  const getVisualizationDataListing = async (init, edit = false) => {
    const {
      selectedClient,
      date_start,
      date_end,
      customer_type = "all",
      job_types = [],
      business_units = [],
      new_customer_nod,
    } = init;
    let params = new URLSearchParams();
    params.append("date_start", date_start);
    params.append("date_end", date_end);
    params.append("customer_type", customer_type);
    params.append("state", "NY");
    params.append("new_customer_nod", new_customer_nod);
    job_types?.map((list) => params.append("job_types", list));
    business_units?.map((list) => params.append("business_units", list));

    const res = await getVisualizationData(selectedClient?.key, params);
    if (res?.status === 200) {
      const { categories, existing_cus, existing_cus_mkt, new_cus } =
        res?.data || {};
      let customize_data = categories?.map((element, index) => ({
        name: element,
        "Existing Customer Rev": existing_cus?.rev[index],
        "Existing Customer Job": existing_cus?.job[index],
        "Existing Customer Avg Rev": existing_cus?.avg_rev[index],

        "Existing Customer with Marketing Rev": existing_cus_mkt?.rev[index],
        "Existing Customer with Marketing Job": existing_cus_mkt?.job[index],
        "Existing Customer with Marketing Avg Rev":
          existing_cus_mkt?.avg_rev[index],

        "New Customer Rev": new_cus?.rev[index],
        "New Customer Job": new_cus?.job[index],
        "New Customer Avg Rev": new_cus?.avg_rev[index],
      }));

      setGraphData(customize_data);

      if (edit) {
        dispatch(updateVisualizationData({ ...init }));
        setDefaultValue({ ...init });
        setFilterLoading(false);
        setOpenDrawer(false); // used to close the drawer
      }
    } else {
      // toast.error(res?.message || res?.error || "Something went wrong");
      setFilterLoading(false);
    }
  };

  const hanldeBussinessUnitsListing = async (init) => {
    setTotalUnits([]);
    setUnitLoading(true);
    let clientKey =
      init?.selectedClient?.key ||
      visualizationData?.selectedClient?.key ||
      prefilledData?.selectedClient?.key;
    const res = await getBussinessUnitsListing(clientKey);
    if (res?.status === 200) {
      setTotalUnits(res?.data);
      setLoading(false);
      setUnitLoading(false);
    } else {
      // toast.error(res?.message || res?.error || "Something went wrong");
      setLoading(false);
      setUnitLoading(false);
    }
  };

  const handleFilter = async (init) => {
    setFilterLoading(true);
    hanldeBussinessUnitsListing(init);
    setDefaultValue(init);
    await getVisualizationDataListing(init, true);
  };

  const handleAction = (index) => {
    console.log("index", index);
    if (index === 0) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  useEffect(() => {
    getInitData();
  }, []);

  return (
    <>
      <Header
        title="Visualization"
        clientInfo={activeClient}
        isFilter={true}
        openFilter={() => setOpenDrawer(true)}
        isNotification={true}
      />
      <VisualizationWrapper selectedThemeColors={selectedThemeColors}>
        {openDrawer && (
          <Drawer
            className="filter-drawer"
            placement="right"
            closable={false}
            onClose={() => setOpenDrawer(false)}
            open={openDrawer}
            width={"400px"}
            key="bottom"
          >
            <VisualizationForm
              handleFilter={handleFilter}
              initValues={defaultValue}
              filterLoading={filterLoading}
              showFilter={toggle}
            />
          </Drawer>
        )}

        <TabComponent
          index={0}
          tabs={["Visualization", "ROAS / SPEND"]}
          selectedIndex={handleAction}
        />
        <SubHeader selectedThemeColors={selectedThemeColors}>
          {toggle && (
            <div className="filterBtn-Box">
              <h4>New v/s Existing Customer Analysis</h4>
            </div>
          )}
        </SubHeader>

        {!toggle ? (
          <BarLineChart />
        ) : (
          <>
            {loading ? (
              <LoadingBlock height={"calc(100vh - 243px)"} size={28} />
            ) : (
              <>
                <ComposedGraphComponent
                  data={graphData}
                  graphListing={graphListing}
                  yAxis_left_label="REV"
                  yAxis_right_label="AVG REV"
                  label_prefix="$"
                />
                {unitLoading
                  ? ""
                  : totalUnits?.map((ele, index) => {
                      return (
                        <VisualizationBusinessUnit
                          key={index}
                          businessunit={ele}
                          init={defaultValue}
                        />
                      );
                    })}
              </>
            )}
          </>
        )}
      </VisualizationWrapper>
      <Watermark style={{padding: "0 10px 6px"}}/>
    </>
  );
};

export default Visualization;

export const SubHeader = styled.div`
  width: 100%;
  height: 100%;
  justify-content: space-between;
  margin-bottom: 10px;

  .client-name {
    color: ${({ selectedThemeColors }) =>
      selectedThemeColors?.textPrimaryColor};
    margin-bottom: 15px !important;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      font-size: 13px;
      height: 33px;
      background: ${({ selectedThemeColors }) =>
        selectedThemeColors?.headerColor} !important;
    }
    .active {
      background: #cbcbcbab !important;
      color: #000 !important;
    }
  }
`;

const VisualizationWrapper = styled.div`
  background: #f3f3f3;
  width: 100%;
  min-height: calc(100vh - 80px);
  max-height: 100%;
  padding: 24px 22px;
  .filterBtn-Box {
    margin-bottom: 16px;
    margin-top: 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      margin: 0;
    }

    button {
      font-size: 13px;
      height: 33px;
      background: ${({ selectedThemeColors }) =>
        selectedThemeColors?.headerColor} !important;
    }
  }
`;

const MapsLoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
