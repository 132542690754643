import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  ResponsiveContainer,
} from "recharts";
import { ChannelPerfMonthlyApi } from "../services/Collections";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import moment from "moment";
import { toast } from "react-toastify";
import LoadingBlock from "./LoadingBlock";

export const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 40,
    }}
    spin
  />
);

const tabs = [
  { display: "Google", name: "google" },
  { display: "Facebook", name: "facebook" },
  { display: "Yelp", name: "yelp" },
  { display: "LSA", name: "lsa" },
  { display: "Bing", name: "bing" },
];

const BarLineChart = () => {
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );
  const { visualizationData } = useSelector((e) => e.loginAuth);
  const activeClient = visualizationData?.selectedClient
    ? visualizationData?.selectedClient
    : prefilledData?.selectedClient;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("google");
  const [graphData, setGraphData] = useState([]);
  const [graphDataCPL, setGraphDataCPL] = useState([]);
  const [activeLegends, setActiveLegends] = useState([]);
  const [activeLegendsCPL, setActiveLegendsCPL] = useState([]);

  const switchingTab = (data, tab) => {
    if (data?.roas?.length > 0) {
      const updatedData = data?.roas?.filter((d) => d?.name === tab);
      updatedData.sort((a, b) => {
        if (a.year === b.year) {
          return a.month - b.month;
        }
        return a.year - b.year;
      });
      let updatedDataWithMonth = updatedData.map((item) => ({
        ...item,
        monthYear: formatMonthYear(item.year, item.month),
      }));
      setGraphData(updatedDataWithMonth);
    }

    if (data?.cost_per_job?.length > 0) {
      const updatedData = data?.cost_per_job?.filter((d) => d?.name === tab);
      updatedData.sort((a, b) => {
        if (a.year === b.year) {
          return a.month - b.month;
        }
        return a.year - b.year;
      });
      let updatedDataWithMonth = updatedData.map((item) => ({
        ...item,
        monthYear: formatMonthYear(item.year, item.month),
      }));
      setGraphDataCPL(updatedDataWithMonth);
    }
  };

  const handleChannelPerfMonthly = async () => {
    setLoading(true);
    const today = moment();
    const todayMonth = today.format("MM");
    const todayYear = today.format("YYYY");

    const thirteenMonthsBack = today.subtract(13, "months");
    const thirteenBackMonth = thirteenMonthsBack.format("MM"); // Full month name
    const thirteenBackYear = thirteenMonthsBack.format("YYYY"); // Four-digit year

    let params = new URLSearchParams();

    params.append("client", activeClient?.key);
    params.append("start_month", Number(thirteenBackMonth));
    params.append("start_year", Number(thirteenBackYear));
    params.append("end_month", Number(todayMonth));
    params.append("end_year", Number(todayYear));

    const res = await ChannelPerfMonthlyApi(activeClient?.key, params);
    if (res?.status === 200) {
      setData(res?.data);
      switchingTab(res?.data, "google");
      setLoading(false);
    } else {
      toast.error(res?.message || res?.error || "Something went wrong");
      setLoading(false);
    }
  };

  const formatMonthYear = (year, month) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${monthNames[month - 1]} ${year}`;
  };

  const handleLegendCostPerLead = (e) => {
    if (activeLegendsCPL?.includes(e?.value)) {
      setActiveLegendsCPL((pre) => pre?.filter((ele) => ele !== e?.value));
    } else {
      setActiveLegendsCPL((pre) => [...pre, e?.value]);
    }
  };

  const handleLegend = (e) => {
    if (activeLegends?.includes(e?.value)) {
      setActiveLegends((pre) => pre?.filter((ele) => ele !== e?.value));
    } else {
      setActiveLegends((pre) => [...pre, e?.value]);
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      console.log(payload, "payloaddddddd");
      // let object = {
      //   fill: "#BCEE11",
      //   name: "",
      //   dataKey: "30_spend",
      //   color: "#BCEE11",
      //   value: 0,
      //   hide: false,
      // };

      // '' , 'close rate %', 'Avg ticket' , 'Booking %' , 'Cost per job'

      // useEffect(()=> {
      //   const arr = [{ ...object, name: "Lead cost", value: 0 }, { ...object, name: "Close rate %", value: 0 }];
      //   setPayload((prev)=> prev?.push(arr));
      // },[]);

      return (
        <div
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "8px",
            padding: "10px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <p style={{ margin: 0, fontWeight: "bold" }}>{label}</p>
          {payload?.map((item, index) => (
            <div key={index}>
              <p
                style={{
                  color: item.color,
                  margin: 0,
                  fontSize: "16px",
                }}
              >
                {item.name}: {item.value}
              </p>

              {item?.payload && (payload?.length - 1 === index) && (
                <>
                  {item?.payload?.avg_ticket !== undefined && (
                    <p style={{ color: "red", margin: 0, fontSize: "16px" }}>
                      Avg Ticket: {item?.payload?.avg_ticket}
                    </p>
                  )}
                  {item?.payload?.booking_perc !== undefined && (
                    <p style={{ color: "blue", margin: 0, fontSize: "16px" }}>
                      Booking %: {item?.payload?.booking_perc}
                    </p>
                  )}
                  {item?.payload?.close_rate_perc !== undefined && (
                    <p style={{ color: "orange", margin: 0, fontSize: "16px" }}>
                      Close Rate %: {item?.payload?.close_rate_perc}
                    </p>
                  )}
                  {item?.payload?.cost_per_job !== undefined && (
                    <p style={{ color: "green", margin: 0, fontSize: "16px" }}>
                      Cost per Job: {item?.payload?.cost_per_job}
                    </p>
                  )}
                  {item?.payload?.lead_cost !== undefined && (
                    <p style={{ color: "orange", margin: 0, fontSize: "16px" }}>
                      Lead Cost: {item?.payload?.lead_cost}
                    </p>
                  )}
                </>
              )}
            </div>
          ))}

          {/* <div>
            <p style={{ color: "red", margin: 0, fontSize: "16px" }}>
              {" "}
              Lead cost : {0}{" "}
            </p>
            <p style={{ color: "orange", margin: 0, fontSize: "16px" }}>
              {" "}
              Close rate % : {0}{" "}
            </p>
            <p style={{ color: "blue", margin: 0, fontSize: "16px" }}>
              {" "}
              Booking % : {0}{" "}
            </p>
            <p style={{ color: "green", margin: 0, fontSize: "16px" }}>
              {" "}
              Cost per job : {0}{" "}
            </p>
          </div> */}
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    handleChannelPerfMonthly();
  }, [activeClient?.key]);

  if (loading) {
    return <LoadingBlock height={"calc(100vh - 187px)"} size={28} />;
  }

  return (
    <BarLineChartStyle>
      <TabWrap>
        <h2>New v/s Existing Customer Analysis (ROAS)</h2>
        <div>
          {tabs?.map((t) => (
            <p
              onClick={() => {
                setActiveTab(t?.name);
                switchingTab(data, t?.name);
              }}
              className={t?.name === activeTab ? "active" : "non-active"}
            >
              {t?.display}
            </p>
          ))}
        </div>
      </TabWrap>

      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <ResponsiveContainer width="100%" height={500}>
          <ComposedChart
            data={graphData}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: "16px",
              padding: "16px 0",
            }}
          >
            <CartesianGrid stroke="#f5f5f5" strokeDasharray="5 5" />
            <XAxis dataKey="monthYear" />
            <YAxis
              yAxisId="left"
              orientation="left"
              label={{ value: "ROAS", angle: -90, position: "insideLeft" }}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              label={{ value: "Spend", angle: -90, position: "insideRight" }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend onClick={handleLegend} />

            {/* <Bar
              yAxisId="right"
              dataKey="1_spend"
              fill="#FAC222"
              name="1 Spend"
              barSize={30}
              hide={activeLegends?.includes("1 Spend")}
            /> */}

            {/* <Bar
              yAxisId="right"
              dataKey="7_spend"
              fill="#FADD22"
              name="7 Spend"
              barSize={30}
              hide={activeLegends?.includes("7 Spend")}
            /> */}

            {/* <Bar
              yAxisId="right"
              dataKey="14_spend"
              fill="#FAFF22"
              name="14 Spend"
              barSize={30}
              hide={activeLegends?.includes("14 Spend")}
            /> */}

            <Bar
              yAxisId="right"
              dataKey="30_spend"
              fill="#BCEE11"
              name="Spend"
              barSize={30}
              hide={activeLegends?.includes("Spend")}
            />

            {/* <Bar
              yAxisId="right"
              dataKey="60_spend"
              fill="#BCFF99"
              name="60 Spend"
              barSize={30}
              hide={activeLegends?.includes("60 Spend")}
            /> */}

            {/* <Bar
              yAxisId="right"
              dataKey="90_spend"
              fill="#CCFF22"
              name="90 Spend"
              barSize={30}
              hide={activeLegends?.includes("90 Spend")}
            /> */}

            {/* <Bar
              yAxisId="right"
              dataKey="180_spend"
              fill="#AABB34"
              name="180 Spend"
              barSize={30}
              hide={activeLegends?.includes("180 Spend")}
            /> */}

            <Line
              type="monotone"
              yAxisId="left"
              dataKey="1_roas"
              stroke="#FA4B42"
              name="1 Day"
              barSize={30}
              hide={activeLegends?.includes("1 Day")}
            />

            <Line
              type="monotone"
              yAxisId="left"
              dataKey="7_roas"
              stroke="#AD6D5E"
              name="7 Days"
              barSize={30}
              hide={activeLegends?.includes("7 Days")}
            />

            <Line
              type="monotone"
              yAxisId="left"
              dataKey="14_roas"
              stroke="#544FC5"
              name="14 Days"
              barSize={30}
              hide={activeLegends?.includes("14 Days")}
            />

            <Line
              type="monotone"
              yAxisId="left"
              dataKey="30_roas"
              stroke="#F00942"
              name="30 Days"
              barSize={30}
              hide={activeLegends?.includes("30 Days")}
            />

            <Line
              type="monotone"
              yAxisId="left"
              dataKey="60_roas"
              stroke="#FF8204"
              name="60 Days"
              barSize={30}
              hide={activeLegends?.includes("60 Days")}
            />

            <Line
              type="monotone"
              yAxisId="left"
              dataKey="90_roas"
              stroke="#FA4B99"
              name="90 Days"
              barSize={30}
              hide={activeLegends?.includes("90 Days")}
            />

            <Line
              type="monotone"
              yAxisId="left"
              dataKey="180_roas"
              stroke="#2EE0CA"
              name="180 Days"
              barSize={30}
              hide={activeLegends?.includes("180 Days")}
            />
          </ComposedChart>
        </ResponsiveContainer>

        <TabWrap style={{ margin: "24px 0 0 0" }}>
          {" "}
          <h2>New v/s Existing Customer Analysis (Cost per Job)</h2>{" "}
        </TabWrap>

        <ResponsiveContainer width="100%" height={500}>
          <ComposedChart
            data={graphDataCPL}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: "16px",
              padding: "16px 0",
            }}
          >
            <CartesianGrid stroke="#f5f5f5" strokeDasharray="5 5" />
            <XAxis dataKey="monthYear" />
            <YAxis
              yAxisId="left"
              orientation="left"
              label={{ value: "ROAS", angle: -90, position: "insideLeft" }}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              label={{ value: "Spend", angle: -90, position: "insideRight" }}
            />
            <Tooltip />
            <Legend onClick={handleLegendCostPerLead} />

            {/* <Bar
              yAxisId="right"
              dataKey="1_spend"
              fill="#f55c7a"
              name="1 Spend"
              barSize={30}
              hide={activeLegendsCPL?.includes("1 Spend")}
            /> */}

            {/* <Bar
              yAxisId="right"
              dataKey="7_spend"
              fill="#ffc2d1"
              name="7 Spend"
              barSize={30}
              hide={activeLegendsCPL?.includes("7 Spend")}
            /> */}

            {/* <Bar
              yAxisId="right"
              dataKey="14_spend"
              fill="#f57c73"
              name="14 Spend"
              barSize={30}
              hide={activeLegendsCPL?.includes("14 Spend")}
            /> */}

            <Bar
              yAxisId="right"
              dataKey="30_spend"
              fill="#FAC222"
              name="Spend"
              barSize={30}
              hide={activeLegendsCPL?.includes("Spend")}
            />

            {/* <Bar
              yAxisId="right"
              dataKey="60_spend"
              fill="#fb6f92"
              name="60 Spend"
              barSize={30}
              hide={activeLegendsCPL?.includes("60 Spend")}
            /> */}

            {/* <Bar
              yAxisId="right"
              dataKey="90_spend"
              fill="#ffc2d1"
              name="90 Spend"
              barSize={30}
              hide={activeLegendsCPL?.includes("90 Spend")}
            /> */}

            {/* <Bar
              yAxisId="right"
              dataKey="180_spend"
              fill="#ff8fab"
              name="180 Spend"
              barSize={30}
              hide={activeLegendsCPL?.includes("180 Spend")}
            /> */}

            <Line
              type="monotone"
              yAxisId="left"
              dataKey="1_cost_per_job"
              stroke="#FA4B42"
              name="1 Day"
              barSize={30}
              hide={activeLegendsCPL?.includes("1 Day")}
            />

            <Line
              type="monotone"
              yAxisId="left"
              dataKey="7_cost_per_job"
              stroke="#AD6D5E"
              name="7 Days"
              barSize={30}
              hide={activeLegendsCPL?.includes("7 Days")}
            />

            <Line
              type="monotone"
              yAxisId="left"
              dataKey="14_cost_per_job"
              stroke="#544FC5"
              name="14 Days"
              barSize={30}
              hide={activeLegendsCPL?.includes("14 Days")}
            />

            <Line
              type="monotone"
              yAxisId="left"
              dataKey="30_cost_per_job"
              stroke="#F00942"
              name="30 Days"
              barSize={30}
              hide={activeLegendsCPL?.includes("30 Days")}
            />

            <Line
              type="monotone"
              yAxisId="left"
              dataKey="60_cost_per_job"
              stroke="#FF8204"
              name="60 Days"
              barSize={30}
              hide={activeLegendsCPL?.includes("60 Days")}
            />

            <Line
              type="monotone"
              yAxisId="left"
              dataKey="90_cost_per_job"
              stroke="#FA4B99"
              name="90 Days"
              barSize={30}
              hide={activeLegendsCPL?.includes("90 Days")}
            />

            <Line
              type="monotone"
              yAxisId="left"
              dataKey="180_cost_per_job"
              stroke="#2EE0CA"
              name="180 Days"
              barSize={30}
              hide={activeLegendsCPL?.includes("180 Days")}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </BarLineChartStyle>
  );
};

export default BarLineChart;

const BarLineChartStyle = styled.div`
  .recharts-legend-wrapper {
    height: auto !important;
  }
  .interval {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 13px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    margin-bottom: 16px;
  }
`;

const TabWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0 24px 0;

  h2 {
    color: #0f1011;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin: 0;
  }

  div {
    width: fit-content;
    display: flex;
    align-items: center;
    border: 1px solid rgb(210, 212, 222);
    border-radius: 6px;
    padding: 2px;
    gap: 5px;
  }

  p {
    margin: 0;
    min-width: 80px;
    height: 28px;
    text-align: center;
    padding: 1px 11px;
    color: rgb(0, 0, 0);
    border: none;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .active {
    background: #ed0101;
    color: white;
  }

  .non-active {
    background: transparent;
    color: rgb(36, 36, 36);
  }
`;
