import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedClient: [],
  formattedDate: null,
  dateAftersevenDays: null,
  updatingSlice: null,
};

export const CapacitySlice = createSlice({
  name: "capacitySlice",
  initialState,

  reducers: {
    updateCapacityClient: (state, action) => {
      if (action) {
        state.selectedClient = action.payload;
      }
    },
    updateformattedDate: (state, action) => {
      if (action) {
        state.formattedDate = action.payload;
      }
    },
    updatedateAftersevenDays: (state, action) => {
      if (action) {
        state.dateAftersevenDays = action.payload;
      }
    },
    rendingForCallingAPI: (state, action) => {
        state.updatingSlice = action.payload;
    },
    resetCapacitySlice: () => initialState,
  },
});

export const {
  updateCapacityClient,
  resetCapacitySlice,
  updateformattedDate,
  updatedateAftersevenDays,
  rendingForCallingAPI
} = CapacitySlice.actions;
export default CapacitySlice.reducer;
