import { checkForInfinity } from "../Year/commonFuc";

export const getOptionValueForSource = (data) => {
    if (Array.isArray(data) && data?.length == 0) {
      return [];
    }
    let filteredSource =
      Array.isArray(data) &&
      data[0]?.data?.sheet?.persistYearData?.current?.map((cat) => ({
        label: cat?.category_name,
        value: cat?.category_name,
      }));
    return filteredSource;
  };
  
  export const calculateGraphValues = (data, option) => {
    if (!Array.isArray(data) || data?.length == 0) {
      return;
    }
    let GraphData = [];
  
    data?.forEach((element) => {
      let prediction = element?.data?.sheet?.persistYearData?.prediction?.filter(
        (c) => c?.category_name == option?.value
      );
      GraphData?.push({ ...prediction[0], month: element?.month });
    });
    return GraphData;
  };
  
  export const calculatePieValues = (data, categories) => {
    const salesByCategory = {};
    
    data.forEach((monthData) => {
        monthData.data.sheet.persistYearData.prediction.forEach((item) => {
          if (!salesByCategory[item.category_name]) {
            salesByCategory[item.category_name] = 0;
          }
          salesByCategory[item.category_name] += item.sales;
        });
      });
    
      const grandTotal = Object.values(salesByCategory).reduce((sum, sales) => sum + sales, 0);

      return Object.entries(salesByCategory).map(([name, salestotal]) => ({
        name,
        salestotal,
        percentage: ((salestotal / grandTotal) * 100).toFixed(2) // Keeping two decimal places
      }));
  
  };
  
  export const calculateCardValue = (data, option) => {
    if (!Array.isArray(data) || data?.length == 0) {
      return;
    }
  
    let currentRevenue = 0;
    let currentBudget = 0;
    let currentRoas = 0;
    let proposedRevenue = 0;
    let proposedBudget = 0;
    let proposedRoas = 0;
    let currentMarketingPerc = 0;
    let proposedMarketingPerc = 0;
    let newRevenue = 0;
    let revenueGrowth = 0;
  
    data?.forEach((element) => {
      let current = element?.data?.sheet?.persistYearData?.current?.filter(
        (c) => c?.category_name == option?.value
      );
  
      let prediction = element?.data?.sheet?.persistYearData?.prediction?.filter(
        (c) => c?.category_name == option?.value
      );
  
      currentRevenue = currentRevenue + current[0].sales;
      currentBudget = currentBudget + current[0].budget;
  
      proposedRevenue = proposedRevenue + prediction[0].sales;
      proposedBudget = proposedBudget + prediction[0].budget;
    });
  
    currentRoas = checkForInfinity(currentRevenue / currentBudget);
    proposedRoas = checkForInfinity(proposedRevenue / proposedBudget);
    currentMarketingPerc = checkForInfinity(currentBudget / currentRevenue) * 100;
    proposedMarketingPerc =
      checkForInfinity(proposedBudget / proposedRevenue) * 100;
    newRevenue = proposedRevenue - currentRevenue;
    revenueGrowth = checkForInfinity(currentRevenue / proposedRevenue) * 100;
  
    return [
      { label: "Current Revenue", value: currentRevenue, type: "currency" },
      { label: "Current Budget", value: currentBudget, type: "currency" },
      { label: "Current ROAS", value: currentRoas, type: "decimal" },
      { label: "Proposed Revenue", value: proposedRevenue, type: "currency" },
      { label: "Proposed Budget", value: proposedBudget, type: "currency" },
      { label: "Proposed ROAS", value: proposedRoas, type: "decimal" },
      { label: "Marketing %", value: currentMarketingPerc, type: "per" },
      {
        label: "Proposed Marketing %",
        value: proposedMarketingPerc,
        type: "per",
      },
      { label: "Required New Revenue", value: newRevenue, type: "currency" },
      { label: "Revenue Growth", value: revenueGrowth, type: "per" },
    ];
  };
  


//   [

//     {
//         month: 'Jan',
//         data: {
//             "sheet": {
//                 "persistYearData": {
//                     "prediction": [
//                         {
//                             "category_name": "Direct Mail",
//                             "budget": 47229.00216667167,
//                             "sales": 690,
//                         },
//                         {
//                             "category_name": "Email Marketing",
//                             "budget": 60060.410384376635,
//                             "sales": 660,
//                         },
//                         {
//                             "category_name": "Events",
//                             "budget": 66501.79116730776,
//                             "sales": 560,
//                         },
//                         {
//                             "category_name": "Google LSA",
//                             "budget": 62715.91867815904,
//                             "sales": 10,
//                         },
//                     ]
//                 }
//             },
//         }
//     },
//     {
//         month: 'Feb',
//         data: {
//             "sheet": {
//                 "persistYearData": {
//                     "prediction": [
//                         {
//                             "category_name": "Direct Mail",
//                             "budget": 47229.00216667167,
//                             "sales": 65690,
//                         },
//                         {
//                             "category_name": "Email Marketing",
//                             "budget": 60060.410384376635,
//                             "sales": 660,
//                         },
//                         {
//                             "category_name": "Events",
//                             "budget": 66501.79116730776,
//                             "sales": 70,
//                         },
//                         {
//                             "category_name": "Google LSA",
//                             "budget": 62715.91867815904,
//                             "sales": 90,
//                         },
//                     ]
//                 }
//             },
//         }
//     },
//   ]