import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

export const PublicLayout = () => {
  const googleOAuthClientId = "56432634994-ahf2u75uaqig4g3lb7ee5itdoneqvgso.apps.googleusercontent.com";
  return (
    <PublicLayoutWrapper>
      <GoogleOAuthProvider clientId={googleOAuthClientId}>
        <Outlet />
      </GoogleOAuthProvider>
    </PublicLayoutWrapper>
  );
};

const PublicLayoutWrapper = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: rgba(243, 243, 243, 1);
  display: flex;
  align-items: start;
  justify-content: center;

  .inner-part {
    width: 40%;
    border-radius: 8px;

    .main-box {
      padding: 50px;
      .logo-div {
        text-align: center;
        img {
          width: 150px;
        }
        h4 {
        }
      }
      .content {
        div {
          margin-bottom: 1rem;
          label {
            margin-bottom: 0.25rem;
            display: block;
          }
          input {
            padding: 0.375rem 0.75rem;
            width: 100%;
            height: 41px;
            border-radius: 12px;
          }
        }
        button {
          font-weight: 700;
          height: 50px;
          cursor: pointer;
          border-radius: 0.75rem;
          width: 100%;
          color: #fff;
          text-align: center;
        }
      }
    }
  }
`;
