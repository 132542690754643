import { DatePicker, Drawer, Select } from "antd";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { selectedAiCompany, updateDate } from "./AiBookingSlice";
import { aiCompanyList, aiCompanyStatistics } from "../../services/Collections";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import moment from "moment";
import RangeSelector from "../../components/RangePicker";

export default function AiFilter({
  setFilterDrawers,
  filterDrawers,
  setCStatsData,
  initialLoading,
  companyList,
  stopApi,
}) {
  const { Option } = Select;
  const dispatch = useDispatch();
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );
  const selectedCompany = useSelector(
    (state) => state?.aiBooking?.selectedCompany
  );
  const [loading, setLoading] = useState(false);
  const AiDate = useSelector((state) => state?.aiBooking);
  const companyId = useSelector((state) => state?.loginAuth?.data?.company_id);

  var enddate = new Date();
  var startdate = new Date(new Date().setDate(enddate.getDate() - 30));

  const initialValues = {
    company: selectedCompany,
    start_date: moment(AiDate?.startDate ?? startdate).format("YYYY-MM-DD"),
    end_date: moment(AiDate?.endDate ?? enddate).format("YYYY-MM-DD"),
  };

  const calculateTotalCost = (data) => {
    let total =
      (data?.total_leads - data?.total_booking) * 2 + data?.total_booking * 25;
    return total;
  };

  const handleSubmit = async (value) => {
    if (stopApi) {
      dispatch(selectedAiCompany(value?.company));
      dispatch(
        updateDate({ date_start: value?.start_date, date_end: value?.end_date })
      );
      setLoading(false);
      setFilterDrawers(false);
      return;
    }

    setLoading(true);
    const requestPayload = new URLSearchParams();
    requestPayload.append("start_time", value?.start_date);
    requestPayload.append("end_time", value?.end_date);
    let res = await aiCompanyStatistics(value?.company?.value, requestPayload);

    if (res?.status == 200) {
      let tempArray = [];
      for (let i = 0; i < res?.data.length; i++) {
        let element = res?.data[i];
        tempArray.push({
          ...element,
          total_booking_perc:
            (element?.total_booking / element?.total_leads) * 100,
          total_cost: calculateTotalCost(element),
        });
      }
      dispatch(selectedAiCompany(value?.company));
      dispatch(
        updateDate({ date_start: value?.start_date, date_end: value?.end_date })
      );

      setCStatsData && setCStatsData(tempArray);
      setLoading(false);
      setFilterDrawers(false);
    } else {
      dispatch(selectedAiCompany(null));
      setCStatsData && setCStatsData([]);
      setLoading(false);
      let message =
        res?.response?.data?.message ||
        res?.message ||
        res?.error ||
        "Something went wrong";
      // toast.error(message, { theme: "colored" });
    }
  };

  return (
    <Drawer
      className="filter-drawer"
      placement="right"
      closable={false}
      onClose={() => setFilterDrawers(false)}
      open={filterDrawers}
      width={"400px"}
      key="bottom"
    >
      <AiFilterWrapper selectedThemeColors={selectedThemeColors}>
        <div className="title">
          <h3>Filters</h3>
        </div>

        <div className="content">
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            render={({ values, setFieldValue }) => (
              <Form>
                {!companyId && (
                  <div style={{ margin: "20px 0" }} className="select-wrap">
                    <label>
                      <strong>Company</strong>
                    </label>
                    <br />
                    <Select
                      prefixCls="inner-select2"
                      value={values?.company}
                      // onChange={(val, data) => {setFieldValue("company", data);console.log(val,data)}}
                      onSelect={(_, option) =>
                        setFieldValue("company", option?.data)
                      }
                      style={{
                        width: "100%",
                        height: "48px",
                        marginTop: "6px",
                      }}
                      loading={loading}
                      maxTagCount={1}
                      placeholder="Choose Company"
                    >
                      {companyList?.map((opt) => (
                        <Option key={opt?.key} value={opt?.value} data={opt}>
                          {opt?.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                )}

                <div className="input-box">
                  <label>
                    <strong>Select Date</strong>
                  </label>{" "}
                  <RangeSelector
                    defaultDate={[
                      values?.start_date
                        ? moment(values?.start_date, "yyyy-MM-DD").format(
                            "yyyy-MM-DD"
                          )
                        : null,
                      values?.end_date
                        ? moment(values?.end_date, "yyyy-MM-DD").format(
                            "yyyy-MM-DD"
                          )
                        : null,
                    ]}
                    handleChange={(d) => {
                      const start = d[0];
                      const end = d[1];
                      setFieldValue("start_date", start);
                      setFieldValue("end_date", end);
                    }}
                  />
                  {/* <DatePicker
                    value={dayjs(values?.start_date)}
                    clearIcon={false}
                    prefixCls="customDatepicker"
                    onChange={(_, date) => setFieldValue("start_date", date)}
                  /> */}
                </div>

                {/* <div style={{ marginTop: "20px" }} className="input-box">
                  <label>
                    <strong>End Date</strong>
                  </label>{" "}
                  <DatePicker
                    clearIcon={false}
                    value={dayjs(values?.end_date)}
                    prefixCls="customDatepicker"
                    onChange={(_, date) => setFieldValue("end_date", date)}
                  />
                </div> */}

                {loading || initialLoading ? (
                  <LoadingButton selectedThemeColors={selectedThemeColors}>
                    <b>Loading...</b>
                  </LoadingButton>
                ) : (
                  <button className="btn-wrap" type="submit">
                    <b>Submit</b>
                  </button>
                )}
              </Form>
            )}
          ></Formik>
        </div>
      </AiFilterWrapper>
    </Drawer>
  );
}

const AiFilterWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px 0;

  .btn-wrap {
    margin-top: 30px;
    width: 100%;
    border-style: none;
    background-color: ${({ selectedThemeColors }) =>
      selectedThemeColors?.buttonColor};
    border-radius: 7px;
    padding: 10px 15px;
    color: #fff;
  }

  .title {
    width: 100%;
    margin-bottom: 20px;

    h3 {
      font-size: 24px;
      color: rgba(25, 25, 25, 1);
    }
  }
  .content {
    width: 100%;
    padding-bottom: 20px;

    label {
      color: ${({ selectedThemeColors }) => selectedThemeColors?.lightText};
      font-size: 15px;
    }
    .select-wrap {
      margin-top: 10px;
    }
  }
  .add-more {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 10px;

    button {
      border: none;
      padding: 3px 7px;
      border-radius: 4px;
      font-size: 12px;
      color: #fff;
      background-color: rgba(36, 36, 36, 1);
    }
  }
`;

const LoadingButton = styled.p`
  margin-top: 30px;
  width: 100%;
  border-style: none;
  background-color: ${({ selectedThemeColors }) =>
    selectedThemeColors?.buttonColor};
  border-radius: 7px;
  padding: 10px 15px;
  color: #fff;

  text-align: center;
`;
