import React, { useEffect } from "react";
import { BackgroundForNotFound } from "../utils/Images/Images";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import NotFoundAnime from "../assets/404-error-page.webp";
import { useSelector } from "react-redux";

export default function NotFound() {
  const token = useSelector((state) => state?.loginAuth?.dashboardData);
  const selectedCompany = useSelector(
    (state) => state?.aiBooking?.selectedCompany
  );
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (token == null && selectedCompany == null) {
  //     navigate("/");
  //   }
  // }, [token]);
  return (
    <NotFoundWrapper back={BackgroundForNotFound}>
      <img
        src={NotFoundAnime}
        alt="not_found"
        height="125px"
        width="auto"
        style={{ filter: "grayscale(1)" }}
      />
      <div className="subheader">Oops ! Page Not Found</div>
      <p>
        Sorry, the page you are looking for cannot be found or no longer exists.
      </p>
      <button
        className="button"
        onClick={() => {
          if (token == null && selectedCompany == null) {
            navigate("/");
          } else {
            navigate(-1);
          }
        }}
      >
        Go Home
      </button>
    </NotFoundWrapper>
  );
}

const NotFoundWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(3 3 3);
  button {
    width: 150px;
    border-style: none;
    background-color: red;
    border-radius: 7px;
    padding: 10px 15px;
    color: #fff;

    margin-top: 30px;
  }

  .subheader {
    color: rgb(188 188 188);

    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    padding: 10px;
  }
  p {
    color: rgb(188 188 188);

    text-align: center;
    padding: 0 10px;
  }
`;
