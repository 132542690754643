import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";

import createSagaMiddleware from "redux-saga";
import SignInSlice from "../Auth/signIn/SignInSlice";
import storage from "redux-persist/lib/storage";
import rootSaga from "../Auth/signIn/LoginSaga";
import LogInSlice from "../Auth/signIn/LogInSlice";
import { ColorsSlice } from "../Auth/signIn/Colors";
import DynamicBudgetModuleSlice from "../../module/exceltable/ExcelStore";
import LsaPerformanceSlice from "../../module/PaidSearch/LsaPerformanceStore";
import CampaignSlice from "../../module/campaignTable/CampaignSlice";
import AiBookingSlice from "../../module/AIBooking/AiBookingSlice";
import QualityAssurance from "../../module/qualityAssurance/QualityAssSlice";
import CapacitySlice from "../../module/capacityMarketing/CapacitySlice";
import NotificationSlice from "../../module/NotificationCenter/NotificationSlice";
import BudgetTrckerSlice from "../../module/BudgetTracker/BudgetTrckerSlice";

const reducers = combineReducers({
  LoginSlice: SignInSlice,
  loginAuth: LogInSlice,
  selectedColors: ColorsSlice,
  dynamicBudgetSheet: DynamicBudgetModuleSlice,
  lsaPerformance: LsaPerformanceSlice,
  campaignSlice: CampaignSlice,
  aiBooking: AiBookingSlice,
  qualityAssurance: QualityAssurance,
  capacityMarketing: CapacitySlice,
  notificationCenter: NotificationSlice,
  budgetTrcker: BudgetTrckerSlice,
});
// 11.0.02 - Major update, clean up 146 files
const persistConfig = {
  key: "adleverage-11.01.04",
  storage,
};

const saga = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(saga),
});
saga.run(rootSaga);

export const persistor = persistStore(store);
