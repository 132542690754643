import TableListing from "../module/Table/Table";
import CampaignTable from "../module/campaignTable/CampaignTable";
import Home2 from "../module/dashboard/Dashboard2";
import SettingsTable from "../module/settings/SettingsTable";
import ReportTable from "../module/reports/ReportTable";
import Visualization from "../module/visualization/Visualization";
import { CallClassification } from "../module/callClassification/CallClassification";
import DynamicBudgetSheet from "../module/dynamicBudgetSheet/DynamicBudgetSheet";
import { SettingDataModal } from "../module/dynamicBudgetSheet/SettingDataModal";
import PaidSearch from "../module/PaidSearch/PaidSearch";
import AiBooking from "../module/AIBooking/AiBooking";
import SelectModule from "../components/SelectModule";
import AdminList from "../module/admin/AdminList";
import BookingList from "../module/AIBooking/BookingList";
import Paidcategory from "../module/PaidSearch/Paidcategory";
import QualityAss from "../module/qualityAssurance/QualityAss";
import CapacityMarketing from "../module/capacityMarketing/CapacityMarketing";
import ConversationGround from "../module/AIBooking/ConversationGround";
import BudgetTracker from "../module/BudgetTracker/BudgetTracker";

export const privatePaths = [
  {
    path: "dashboard",
    component: <Home2 />,
    sectionName: "dashboard",
  },
  {
    path: "data-table",
    component: <TableListing />,
    sectionName: "data-table",
  },
  {
    path: "campaign-table",
    component: <CampaignTable />,
    sectionName: "campaign-table",
  },
  {
    path: "report-table",
    component: <ReportTable />,
    sectionName: "report-table",
  },
  {
    path: "visualization",
    component: <Visualization />,
    sectionName: "visualization",
  },
  {
    path: "settings",
    component: <SettingsTable />,
    sectionName: "settings",
  },
  {
    path: "call-classification",
    component: <CallClassification />,
    sectionName: "call-classification",
  },
  {
    path: "dynamic-budget-sheet",
    component: <DynamicBudgetSheet />,
    sectionName: "dynamic-budget-sheet",
  },
  {
    path: "paid-search",
    component: <PaidSearch />,
    sectionName: "paid-search",
  },
  {
    path: "paid-category",
    component: <Paidcategory />,
    sectionName: "paid-search",
  },
  {
    path: "budget-setting",
    component: <SettingDataModal />,
    sectionName: "dynamic-budget-sheet",
  },
  {
    path: "booking-buddy-performance",
    component: <AiBooking />,
    sectionName: "ai-booking-stats",
  },
  {
    path: "select-module",
    component: <SelectModule />,
    sectionName: "select-module",
  },
  {
    path: "admin-users",
    component: <AdminList />,
    sectionName: "admin-users",
  },
  {
    path: "booking",
    component: <BookingList />,
    sectionName: "ai-booking-stats",
  },
  {
    path: "conversations",
    component: <ConversationGround />,
    sectionName: "ai-booking-stats",
  },
  {
    path: "quality-assurance",
    component: <QualityAss />,
    sectionName: "quality-assurance",
  },
  {
    path: "capacity-marketing",
    component: <CapacityMarketing />,
    sectionName: "capacity-marketing",
  },
  {
    path: "budget-tracker",
    component: <BudgetTracker />,
    sectionName: "budget-tracker",
  },
];
