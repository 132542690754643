import React, { useEffect, useState } from "react";
import {
  ButtonComponent,
  SearchComponent,
} from "../../components/SearchComponent";
import { EditNewicon, PhoneIcon } from "../../utils/Icons/SvgIcons";
import { useSelector } from "react-redux";
import LoadingBlock from "../../components/LoadingBlock";
import { toast } from "react-toastify";
import {
  delete_qualityAssurance_modal_api,
  Get_Phone_api,
  getSearchApi,
} from "../../services/Collections";
import CreatePhoneModal from "./CreatePhoneModal";
import { DeleteModal } from "../../components/DeleteModal";
import QualityFilter from "./QualityFilter";
import { Checkbox, Drawer, Pagination } from "antd";
import {
  ButtonContainer,
  CardContent,
  CardWrapper,
  DeleteButton,
  EditButton,
  FlexContainer,
  IconWrapper,
  Label,
  PhoneNumber,
  QualityAssWrapper,
  TextContainer,
} from "./qualityAssuranceStyle";
import NoDataFound from "../../components/NoDataFound";
import Watermark from "../../components/Watermark";
import styled from "styled-components";

function ListingPhoneNumber({
  setPhoneListing,
  phoneListing,
  setSavedData,
  savedData,
  currentPage,
  setCurrentPage,
  pagesize,
  setPageSize,
  openDrawer,
  setOpenDrawer,
}) {
  const client = useSelector(
    (state) => state?.loginAuth?.prefilledFilters?.selectedClient
  );
  const selectedClient = useSelector(
    (state) => state?.qualityAssurance?.selectedClient
  );

  const activeClient = selectedClient ? selectedClient : client;

  const [openCreate, setOpenCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(null);
  const [deletemodal, setDeletemodal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const handleSearch = async (words) => {
    if (words && !Number(words)) {
      setPhoneListing([]);
      return;
    }
    if (!words) {
      setPhoneListing(savedData);
      return;
    }
    if (words) {
      let filterData = savedData?.filter((d) =>
        d?.phone_number?.replace(/\D/g, "")?.includes(words)
      );
      if (filterData?.length > 0) {
        setPhoneListing(filterData);
      } else {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("page", Number(currentPage));
        params.append("page_size", Number(pagesize));
        params.append("search", words);

        const res = await getSearchApi(activeClient?.key, params);
        if (res?.status == 200) {
          setPhoneListing([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }
  };

  const handleClicked = () => {
    setOpenCreate(true);
  };

  const handlePhoneListing = async (page, page_size) => {
    let pageNo = page || currentPage;
    let limit = page_size || pagesize;
    let key = activeClient?.key;
    setLoading(true);
    let params = new URLSearchParams();
    params.append("page", Number(pageNo));
    params.append("page_size", Number(limit));

    let res = await Get_Phone_api(key, params);

    if (res.status === 200) {
      setPhoneListing(res?.data);
      setSavedData(res?.data);
      setTotalCount(res?.total);
      setLoading(false);
      setOpenDrawer(false);
    } else {
      setLoading(false);
      toast.error(
        res.response.data.message ||
          res.error ||
          res.message ||
          "Something went wrong"
      );
    }
  };
  const handleDeleteCategory = async () => {
    console.log(selectedCheckboxes, "selectedCheckboxes");

    setDeleteLoading(true);
    let res = await delete_qualityAssurance_modal_api(
      activeClient?.key,
      selectedCheckboxes
    );
    if (res.status === 200) {
      setDeleteLoading(false);
      setDeletemodal(false);
      setSelectedCheckboxes([]);
      console.log(selectedCheckboxes, phoneListing, "selectedCheckboxes");
      if (selectedCheckboxes?.length === phoneListing?.length) {
        setCurrentPage(1);
        return;
      }
      handlePhoneListing();
    } else {
      setDeleteLoading(false);
      toast.error(
        res?.response?.data?.message ||
          res?.error ||
          res?.message ||
          "Something went wrong"
      );
    }
  };

  const showDeleteModal = (id) => {
    setDeletemodal(true);
    setDeleteId(id);
  };

  const handleClose = () => {
    setOpenCreate(false);
    setEdit(null);
  };

  const onPageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    handlePhoneListing(page, pageSize);
  };

  useEffect(() => {
    setCurrentPage(1);
    handlePhoneListing(1, pagesize);
  }, [activeClient]);

  return (
    <>
      {(openCreate || edit) && (
        <CreatePhoneModal
          open={openCreate || edit}
          handleClose={() => handleClose()}
          handlePhoneListing={handlePhoneListing}
          edit={edit}
        />
      )}

      {deletemodal && (
        <DeleteModal
          handleClose={() => setDeletemodal(false)}
          open={deletemodal}
          title="Are you sure you want to delete this phone number?"
          description="Phone number will be deleted permanently"
          button="Delete"
          btnColor="#ed2000"
          handleSubmit={handleDeleteCategory}
          isLoading={deleteLoading}
        />
      )}

      {openDrawer && (
        <Drawer
          className="filter-drawer"
          placement="right"
          closable={false}
          onClose={() => setOpenDrawer(false)}
          open={openDrawer}
          width={"400px"}
          key="bottom"
        >
          <QualityFilter paidLoading={loading} />
        </Drawer>
      )}

      <CoverStyle>
        <QualityAssWrapper>
          <main>
            <section className="sub-header">
              <SearchComponent onChange={handleSearch} />
              <div style={{ display: "flex", gap: "8px" }}>
                <ButtonComponent
                  title={"+ Add Phone Number"}
                  onClick={handleClicked}
                />
                {selectedCheckboxes.length > 0 && (
                  <ButtonComponent
                    title={"Delete"}
                    onClick={() => setDeletemodal(true)}
                  />
                )}
              </div>
            </section>
            {loading ? (
              <LoadingBlock height={"calc(100vh - 220px)"} size={28} />
            ) : (
              <>
                {phoneListing && phoneListing?.length > 0 ? (
                  <>
                    <section className="card-wrap">
                      {phoneListing?.map((d) => (
                        <PhoneNumberCard
                          data={d}
                          showDeleteModal={showDeleteModal}
                          setEdit={setEdit}
                          setSelectedCheckboxes={setSelectedCheckboxes}
                        />
                      ))}
                    </section>
                    <div className="pagination-container">
                      <Pagination
                        hideOnSinglePage
                        defaultCurrent={currentPage}
                        total={totalCount}
                        pageSize={pagesize}
                        onChange={onPageChange}
                        showSizeChanger={false}
                      />
                    </div>
                  </>
                ) : (
                  <NoDataFound
                    height={"calc(100vh - 195px)"}
                    message="NO SEARCH FOUND"
                  />
                )}
              </>
            )}
          </main>
        </QualityAssWrapper>
      </CoverStyle>
      <Watermark style={{padding: "0 10px"}} />
    </>
  );
}

export default ListingPhoneNumber;

const PhoneNumberCard = ({
  data,
  showDeleteModal,
  setEdit,
  setSelectedCheckboxes,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  console.log(selectedOptions, "selectedOptions");

  const handleCheckboxChange = (checkedValues) => {
    setSelectedOptions(checkedValues?.target?.id);
    console.log(checkedValues?.target?.checked, "checkedValues?.target?.value");
    if (checkedValues?.target?.checked) {
      setSelectedCheckboxes((pre) => [...pre, checkedValues?.target?.id]);
    } else {
      setSelectedCheckboxes((pre) =>
        pre?.filter((ele) => ele !== checkedValues?.target?.value)
      );
    }
  };

  function formatUSPhoneNumber(phoneNumber) {
    const cleaned = phoneNumber.replace(/\D/g, "");

    if (cleaned.length === 11 && cleaned.startsWith("1")) {
      return `+1 (${cleaned.slice(1, 4)}) ${cleaned.slice(
        4,
        7
      )}-${cleaned.slice(7)}`;
    }
    return phoneNumber;
  }

  return (
    <CardWrapper
      initial={{ scale: 0.95, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <CardContent
        whileHover={{ scale: 1.03 }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
      >
        <Checkbox
          options={selectedOptions}
          value={selectedOptions}
          id={data?.id}
          onChange={handleCheckboxChange}
        />
        <FlexContainer>
          <FlexContainer>
            <div style={{ display: "flex", gap: "8px" }}>
              <IconWrapper
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                }}
              >
                <PhoneIcon />
              </IconWrapper>
            </div>
            <TextContainer>
              {/* <Label>Phone Number</Label> */}
              <PhoneNumber
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                {formatUSPhoneNumber(data?.phone_number)}
              </PhoneNumber>
            </TextContainer>
          </FlexContainer>
          <ButtonContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            <EditButton
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setEdit(data)}
            >
              <i>
                <EditNewicon />
              </i>
            </EditButton>
            {/* <DeleteButton
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => showDeleteModal(data?.id)}
            >
              <i>
                <DeleteNewIcon />
              </i>
            </DeleteButton> */}
          </ButtonContainer>
        </FlexContainer>
      </CardContent>
    </CardWrapper>
  );
};

const CoverStyle = styled.div`
min-height: calc(100vh - 153px);
max-height: 100%;
`;
