import { Checkbox, Select, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { LoginBg, MainAppIcon } from "../utils/Images/Images";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import {
  getCampaignListing,
  getDataFromFilters,
} from "../services/Collections";
import {
  dashboardData,
  prefilledFilters,
  updateSelectAll,
} from "../app/Auth/signIn/LogInSlice";
import { ModalLoader } from "./Loader";
import RangeSelector from "./RangePicker";
import { updateCampaignData } from "../module/campaignTable/CampaignSlice";
import { updateDate } from "../module/AIBooking/AiBookingSlice";

const initialValues = {
  clientKey: "",
  Source: "",
};

const Filter = () => {
  const [isCompareDisabled, setIsCompareDisabled] = useState(true);
  const [selectedClient, setSelectedClient] = useState(null);

  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState({
    start: moment().format("yyyy-MM-DD"),
    end: moment().format("yyyy-MM-DD"),
  });
  const [compareDate, setCompareDate] = useState({
    start: moment().format("yyyy-MM-DD"),
    end: moment().format("yyyy-MM-DD"),
  });
  const [sourceOption, setSourceOption] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [clientOptions, setclientOptions] = useState([]);
  const clientOptionsData = useSelector(
    (state) => state?.loginAuth?.clientsList
  );
  const [sourceLoading, setsourceLoading] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );

  const x = sourceOption?.map((el) => ({
    title: el?.label,
    value: el?.value,
  }));
  const [selectedValues, setSelectedValues] = useState([]);

  const getClientOption = () => {
    let arr = [];
    clientOptionsData?.clients?.length > 0 &&
      clientOptionsData?.clients?.map((ele) => {
        let obj = {
          ...ele,
          label: ele?.name,
          value: ele?.name,
        };
        arr.push(obj);
      });
    setclientOptions(arr);
  };
  const submitHandler = async (e) => {
    if (currentDate && selectedValues.length > 0) {
      setLoading(true);
      let sourceArray = [];

      if (sourceOption.length > 0) {
        if (sourceOption.length !== selectedValues.length) {
          for (let i = 0; i < sourceOption.length; i++) {
            for (let j = 0; j < selectedValues.length; j++) {
              if (sourceOption[i].value === selectedValues[j]) {
                sourceArray.push(sourceOption[i].value);
              }
            }
          }
        }
      }

      console.log(currentDate?.start?._d, "currentDate?.start?._d");

      let requestPayload = {
        campaigns: sourceArray,
        date_start: currentDate?.start?._d
          ? formatDate(currentDate?.start?._d)
          : currentDate?.start,
        date_end: currentDate?.end?._d
          ? formatDate(currentDate?.end?._d)
          : currentDate?.end,
        compare_enable: isCompareDisabled ? 0 : 1,
        date_compare_start: compareDate
          ? compareDate?.start?._d
            ? formatDate(compareDate?.start?._d)
            : compareDate?.start
          : "",
        date_compare_end: compareDate
          ? compareDate?.end?._d
            ? formatDate(compareDate?.end?._d)
            : compareDate?.end
          : "",
      };
      let res = await getDataFromFilters(selectedClient?.key, requestPayload);
      if (res?.status === 200) {
        let sourceOptionValues = sourceOption?.map((el) => el?.value);
        let obj = {
          ...requestPayload,
          selectedClient: selectedClient,
        };
        if (selectedValues?.length == sourceOption?.length) {
          obj = {
            ...obj,
            campaignsIds: sourceOptionValues,
          };
        }
        let dateObject = {
          date_start: currentDate?.start?._d
            ? formatDate(currentDate?.start?._d)
            : currentDate?.start,
          date_end: currentDate?.end?._d
            ? formatDate(currentDate?.end?._d)
            : currentDate?.end,
        };
        dispatch(prefilledFilters(obj));
        let selectSource = [];
        if (sourceOption?.length > 0) {
          if (selectedValues?.length == sourceOption?.length) {
            sourceOption?.forEach((options) => {
              sourceOptionValues?.includes(options?.value) &&
                selectSource.push({
                  label: options?.label,
                  value: options?.label,
                });
            });
          } else {
            sourceOption?.forEach((options) => {
              selectedValues?.includes(options?.value) &&
                selectSource.push({
                  label: options?.label,
                  value: options?.label,
                });
            });
          }
        }

        dispatch(
          updateCampaignData({
            ...obj,
            interval: 90,
            selectSource: [],
            isCampaignShowTable: false,
          })
        );
        dispatch(updateDate(dateObject));
        if (res?.data?.current) {
          dispatch(dashboardData(res?.data));
          navigate("/");
          setLoading(false);
        } else {
          setLoading(false);
          toast.warning("No Data Found");
        }
      } else {
        setLoading(false);
        toast.error(res?.message || res?.error || "Something went wrong");
      }
    } else {
      toast.error("Please fill required fields");
    }
  };
  const handleCompanignList = async () => {
    setsourceLoading(true);
    setSourceOption(() => []);
    setSelectedValues([]);
    let res = await getCampaignListing(selectedClient?.key);
    if (res?.status == 200) {
      let arr = [];

      if (res.data.length > 0) {
        for (let i = 0; i < res?.data.length; i++) {
          let obj = {
            value: res?.data[i]?.campaignid,
            label: res?.data[i]?.campaignname,
          };
          arr.push(obj);
        }
      }
      const filteredByVal = Array.isArray(arr)
        ? [
            ...arr
              .reduce((a, c) => {
                a?.set(c?.value, c);
                return a;
              }, new Map())
              .values(),
          ]
        : [];
      setsourceLoading(false);
      setSourceOption(filteredByVal);
    } else {
      toast.error(res?.message || res?.error || "Something went wrong");
      setsourceLoading(false);
    }
  };

  const handleAllClear = () => {
    setIsSelectAll(false);
    dispatch(updateSelectAll(false));
    setSelectedValues([]);
  };

  useEffect(() => {
    if (selectedValues?.length && isSelectAll) {
      if (selectedValues?.length !== sourceOption?.length) {
        setIsSelectAll(false);
        dispatch(updateSelectAll(false));
      }
    }
  }, [selectedValues]);

  useEffect(() => {
    if (selectedClient != null) {
      setIsSelectAll(false);
      handleCompanignList();
    }
  }, [selectedClient]);

  useEffect(() => {
    getClientOption();
  }, []);

  const formatDate = (d) => {
    const date = new Date(d);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  return (
    <FilterWrapper selectedThemeColors={selectedThemeColors}>
      <div className="gradient-img">
        <img src={LoginBg} alt="" />
      </div>
      <div className="inner-part">
        <div className="content-box">
          {/* <div className="header">
            <img src={MainLogo2} alt="" />
            <h4>Ad Leverage Reporting</h4>
          </div> */}
          <div className="filters">
            <div className="main-logo-div">
              <img className="loginLogo" src={MainAppIcon} alt="" />
            </div>
            <h4>Filters</h4>
            <Formik initialValues={initialValues} onSubmit={submitHandler}>
              {({ setFieldValue }) => (
                <Form>
                  <div>
                    <label>
                      <strong>Client</strong>
                    </label>
                    <Select
                      size={"large"}
                      prefixCls="inner-select2"
                      onChange={(_, data) => setSelectedClient(data)}
                      style={{
                        width: "100%",
                        height: "48px",
                      }}
                      maxTagCount={1}
                      placeholder="Please select"
                      options={clientOptions}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        background: "transparent",
                        height: "100%",
                        marginBottom: "0px",
                      }}
                    >
                      <label
                        className="mb-1  "
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <strong>Select Sources</strong>
                      </label>
                      {selectedClient && !sourceLoading && (
                        <>
                          {selectedValues?.length !== sourceOption?.length ? (
                            <span
                              className="ml-2 select-all"
                              style={{
                                display: "flex",
                                gap: "5px",
                                alignContent: "center",
                                height: "20px",
                              }}
                            >
                              <span
                                className="mr-2"
                                style={{ color: "#828690" }}
                              >
                                Select All
                              </span>
                              {/* <span aria-disabled> */}
                              <Checkbox
                                disabled={sourceLoading}
                                checked={
                                  selectedValues?.length == sourceOption?.length
                                }
                                onChange={(e) => {
                                  setIsSelectAll(e?.target?.checked);
                                  dispatch(updateSelectAll(e?.target?.checked));
                                  if (e.target.checked) {
                                    setSelectedValues(sourceOption);
                                  }
                                }}
                              />
                              {/* </span> */}
                            </span>
                          ) : (
                            <span
                              style={{
                                display: "flex",
                                gap: "5px",
                                alignContent: "center",
                                height: "20px",
                              }}
                              className="ml-2"
                            >
                              <span
                                className="mr-2"
                                style={{ color: "#828690" }}
                              >
                                {" "}
                                Clear All
                              </span>
                              <span aria-disabled>
                                <Checkbox
                                  disabled={sourceLoading}
                                  checked={
                                    selectedValues?.length ==
                                    sourceOption?.length
                                  }
                                  onChange={(e) => {
                                    setIsSelectAll(e?.target?.checked);
                                    dispatch(
                                      updateSelectAll(e?.target?.checked)
                                    );
                                    setSelectedValues([]);
                                  }}
                                />
                              </span>
                            </span>
                          )}
                        </>
                      )}
                    </div>
                    <Tooltip>
                      <Select
                        mode="multiple"
                        prefixCls="inner-select2"
                        allowClear
                        loading={sourceLoading}
                        style={{
                          width: "100%",
                          height: "48px",
                        }}
                        maxTagCount={1}
                        value={selectedValues}
                        placeholder="Please select"
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onClear={handleAllClear}
                        onChange={setSelectedValues}
                        options={sourceOption}
                      />
                    </Tooltip>
                  </div>
                  <div>
                    <label>
                      <strong>Current</strong>
                    </label>
                    {/* <DateRangePicker
                      onCallback={(start, end) =>
                        setCurrentDate({ start: start, end: end })
                      }
                    >
                      <input type="text" className="form-control input" />
                    </DateRangePicker> */}
                    <RangeSelector
                      defaultDate={[
                        moment().format("yyyy-MM-DD"),
                        moment().format("yyyy-MM-DD"),
                      ]}
                      bgColor="#08051a"
                      handleChange={(d) => {
                        const start = moment(d[0]);
                        const end = moment(d[1]);
                        setCurrentDate({ start, end });
                      }}
                    />
                  </div>
                  <div>
                    <span>
                      <label>
                        <strong>Compare To</strong>
                      </label>
                      <input
                        style={{
                          accentColor: "rgba(36, 36, 36, 1)",
                          width: "16px",
                          height: "24px",
                        }}
                        type="checkbox"
                        onChange={(e) =>
                          setIsCompareDisabled(!e.target.checked)
                        }
                      />
                    </span>
                    {/* <DateRangePicker
                      onCallback={(start, end) =>
                        setCompareDate({ start: start, end: end })
                      }
                    >
                      <input
                        disabled={isCompareDisabled}
                        type="text"
                        className="form-control input"
                      />
                    </DateRangePicker> */}
                    <RangeSelector
                      defaultDate={[
                        moment().format("yyyy-MM-DD"),
                        moment().format("yyyy-MM-DD"),
                      ]}
                      bgColor="#08051a"
                      disabled={isCompareDisabled}
                      handleChange={(d) => {
                        const start = moment(d[0]);
                        const end = moment(d[1]);
                        setCompareDate({ start, end });
                      }}
                    />
                  </div>
                  {!loading ? (
                    <button type="submit">Apply</button>
                  ) : (
                    <div
                      style={{
                        marginTop: "17px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <LoadingButton>
                        <ModalLoader size={20} color="#000" />
                      </LoadingButton>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </FilterWrapper>
  );
};

export default Filter;

const FilterWrapper = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  .gradient-img {
    position: relative;
    height: 100vh;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .inner-part {
    position: absolute;
    width: 100% !important;
    border-radius: 24px !important;
    max-width: 600px !important;
    background: ${({ selectedThemeColors }) =>
      selectedThemeColors?.CardsColor} !important;
    .content-box {
      padding: 40px 50px 50px 50px;
      .header {
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;

        img {
          width: 50px;
          height: 50px;
        }
        h4 {
          color: #0f1011;

          font-size: 18px;
        }
      }
      .filters {
        .main-logo-div {
          display: flex;
          justify-content: center;
          .loginLogo {
            ${"" /* width: 100%; */}
            width: 419px;
            ${"" /* height: 100%; */}
            height: 90px;
            @media (max-width: 1370px) {
              width: 382.55px;
            }
            @media (max-width: 530px) {
              width: 100%;
              object-fit: contain;
            }
          }
        }
        h4 {
          margin-bottom: 1.5rem !important;
          color: #0f1011;

          font-size: 30px;
          text-align: center;
        }
        .inner-select2-arrow {
          filter: invert(1) !important;
        }
        div {
          margin-bottom: 6px;

          label {
            color: #7e7e7e;

            display: block;
            margin-bottom: 0.25rem;
          }
          span {
            display: flex;
            justify-content: space-between;

            span {
              color: #fff;
            }

            .ant-checkbox-inner {
              margin-top: 3px;
            }
            label {
              color: #7e7e7e;

              display: block;
              margin-bottom: 0.25rem;
              ${"" /* width: 100%; */}
            }
            .input {
              width: max-content;
              height: 20px;
              border-radius: 8px;
              border: none;
              padding: 0px 10px;
              margin-bottom: 10px;
              background-color: #05031a;
              color: #fff;
            }
          }
          .input {
            width: 100%;
            height: 53px;
            border-radius: 8px;
            border: none;
            padding: 0px 10px;
            margin-bottom: 10px;
            background-color: #05031a;
            color: #fff;
            ${
              "" /* border-color: #2a2833 !important;
            color: #3c2ac1 !important; */
            }
          }
          .ant-select {
            width: 100%;
            height: 53px;
            border-radius: 8px;
            border: none;

            .ant-select-selector {
              height: 100%;
              background-color: #05031a;
              border: none;
              .ant-select-selection-overflow {
                height: 100%;
                .ant-select-selection-overflow-item {
                  .ant-select-selection-item {
                    margin-bottom: 29px;
                    .ant-select-selection-item-content {
                      color: #fff;
                      cursor: context-menu;
                      maxwidth: 150px;
                      textoverflow: ellipsis;
                      overflow: hidden;
                      whitespace: nowrap;
                    }
                  }
                }
              }
            }
          }
        }
        button {
          background: ${({ selectedThemeColors }) =>
            selectedThemeColors?.buttonColor} !important;
          border-color: #0f1011;
          color: #fff;
          text-align: center;
          width: 100%;
          font-weight: 700;
          height: 50px;
          border-radius: 8px;
          border: none;
          margin-top: 7px;
          font-size: 13px;

          :hover {
            background: ${({ selectedThemeColors }) =>
              selectedThemeColors?.buttonColor} !important;
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .inner-part {
      width: 95% !important;
    }
  }
  .select-all .ant-checkbox-wrapper {
    display: flex !important;
    margin-bottom: 0px !important;
  }
`;
const LoadingButton = styled.p`
  width: 100%;
  font-weight: 700;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0.75rem;
  width: 100%;
  background-color: #d80c0c;
  border: 1px solid #d80c0c;
  color: #fff;
  text-align: center;
  margin-top: 7px;
  font-size: 13px;

  svg {
    color: #fff !important;
  }
`;
