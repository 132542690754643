import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";

const COLORS = [
  "#FB6BB0",
  "#29C98A",
  "#152F47",
  "#2EC9F0",
  "#AD6D5E",
  "#37ED6A",
  "#9F0ED7",
  "#BCD917",
  "#4C4FA0",
  "#294AC3",
  "#DF5D0D",
  "#979797",
  "#4EADCE",
  "#DB25B9",
  "#F1CB42",
  "#AF0A24",
  "#C53E87",
  "#242AD6",
  "#9255F0",
  "#C6DD0C",
  "#474037",
  "#5ED022",
  "#C5D0C5",
  "#5FD195",
  "#E788C4",
  "#C86AAE",
  "#7E3A6B",
  "#137E0F",
  "#E98C23",
  "#E3A8AF",
  "#E17BFC",
  "#605449",
  "#363EBA",
  "#AC4D81",
  "#1B9731",
  "#747CC5",
  "#D4F838",
  "#A58BA1",
  "#0EDC22",
  "#61ED43",
  "#41E144",
  "#1FB162",
  "#D2A7BF",
  "#616C16",
  "#1A0EEC",
  "#A024B1",
  "#76924F",
  "#E3AFBE",
  "#FD74CA",
  "#837DE4",
  "#256481",
  "#446769",
  "#CBF49E",
  "#9902D3",
  "#29AF6E",
  "#8F40E5",
  "#171626",
  "#354875",
  "#A68D0B",
  "#D61C8B",
  "#78BEA1",
  "#BFEA89",
  "#1ADC94",
  "#99504C",
  "#A82EBF",
  "#435E20",
  "#FA19BD",
  "#DC0773",
  "#56A6E8",
  "#D3BCDC",
  "#C383EC",
  "#1D3A79",
  "#ED56D9",
  "#228EC0",
  "#722910",
  "#6D4544",
  "#604CC0",
  "#51F2AB",
  "#F3D5AF",
  "#F4F1CF",
  "#7102F0",
  "#5EF5BA",
  "#4C7F64",
  "#3263B2",
  "#5D42C4",
  "#01ED53",
];

function LineChartComponent({ switchToggle, totalCallData }) {
  console.log(totalCallData, "totalCallDatatotalCallData");
  const [lastFourMonths, setLastFourMonths] = useState(null);
  console.log(lastFourMonths, "lastFourMonths");
  const data1 = [
    {
      // name: "Mar 7 - Mar 13",
      name: `${lastFourMonths?.[0]?.startDate} - ${lastFourMonths?.[0]?.endDate}`,
      ringless_voicemail: 4000,
      google_Ads: 1400,
      google_organic: 2400,
      facebook_organic: 1000,
      direct: 6200,
      facebook: 6500,
      yahoo_organic: 1500,
      bing_ads: 10,
    },
    {
      // name: "Feb 29 - Mar 6",
      name: `${lastFourMonths?.[1]?.startDate} - ${lastFourMonths?.[1]?.endDate}`,
      ringless_voicemail: 3000,
      google_Ads: 1398,
      google_organic: 2210,
      facebook_organic: 1800,
      direct: 900,
      facebook: 4400,
      yahoo_organic: 2900,
      bing_ads: 300,
    },
    {
      // name: "Feb 22 - Feb 28",
      name: `${lastFourMonths?.[2]?.startDate} - ${lastFourMonths?.[2]?.endDate}`,
      ringless_voicemail: 2000,
      google_Ads: 7800,
      google_organic: 2290,
      facebook_organic: 3200,
      direct: 5200,
      facebook: 9000,
      yahoo_organic: 4900,
      bing_ads: 3400,
    },
    {
      // name: "Feb 15 - Feb 21",
      name: `${lastFourMonths?.[3]?.startDate} - ${lastFourMonths?.[3]?.endDate}`,
      ringless_voicemail: 2780,
      google_Ads: 3908,
      google_organic: 2000,
      facebook_organic: 2600,
      direct: 200,
      facebook: 7700,
      yahoo_organic: 3900,
      bing_ads: 1900,
    },
  ];

  const data2 = [
    {
      // name: "Mar 7 - Mar 13",
      name: `${lastFourMonths?.[0]?.startDate} - ${lastFourMonths?.[0]?.endDate}`,
      ringless_voicemail: 2500,
      google_Ads: 1200,
      google_organic: 2200,
      facebook_organic: 800,
      direct: 5800,
      facebook: 6100,
      yahoo_organic: 1300,
      bing_ads: 20,
    },
    {
      // name: "Feb 29 - Mar 6",
      name: `${lastFourMonths?.[1]?.startDate} - ${lastFourMonths?.[1]?.endDate}`,
      ringless_voicemail: 3200,
      google_Ads: 1380,
      google_organic: 2100,
      facebook_organic: 1600,
      direct: 850,
      facebook: 4200,
      yahoo_organic: 2700,
      bing_ads: 270,
    },
    {
      // name: "Feb 22 - Feb 28",
      name: `${lastFourMonths?.[2]?.startDate} - ${lastFourMonths?.[2]?.endDate}`,
      ringless_voicemail: 1800,
      google_Ads: 7600,
      google_organic: 2390,
      facebook_organic: 3100,
      direct: 4800,
      facebook: 8500,
      yahoo_organic: 4700,
      bing_ads: 3100,
    },
    {
      // name: "Feb 15 - Feb 21",
      name: `${lastFourMonths?.[3]?.startDate} - ${lastFourMonths?.[3]?.endDate}`,
      ringless_voicemail: 3000,
      google_Ads: 3700,
      google_organic: 1900,
      facebook_organic: 2500,
      direct: 300,
      facebook: 7400,
      yahoo_organic: 3700,
      bing_ads: 1500,
    },
  ];

  // let data = switchToggle ? data2 : totalCallData;
  let data = totalCallData?.data;
  console.log(data, "bmddata");
  const hasPainted = useRef(false);
  const [opacity, setOpacity] = useState({});

  console.log(lastFourMonths, "lastFourMonths");
  const [activeKeys, setActiveKeys] = useState([]);

  const getLast4Weeks = () => {
    const last4Weeks = [];
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    // Get the start of the current week
    const startOfCurrentWeek = new Date(
      currentYear,
      currentMonth,
      currentDay - currentDate.getDay()
    );

    // Calculate the start and end dates for each of the last 4 weeks
    for (let i = 0; i < 4; i++) {
      const startOfWeek = new Date(startOfCurrentWeek);
      startOfWeek.setDate(startOfWeek.getDate() - i * 7); // Move back by i weeks

      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(endOfWeek.getDate() + 6); // End of the week

      last4Weeks.push({ start: startOfWeek, end: endOfWeek });
    }

    return last4Weeks;
  };

  const last4Weeks = getLast4Weeks();
  console.log(moment(last4Weeks?.[0]?.end).format("yyyy-MM-DD"), "last4Weeks");

  const FinalWeeka = () => {
    let arr = [];
    last4Weeks?.map((val) => {
      let obj = {
        startDate: moment(val?.start).format("MMM DD"),
        endDate: moment(val?.end).format("MMM DD"),
      };
      arr.push(obj);
      // setLastFourMonths(arr);
      console.log(arr, "newObj");
    });
  };

  /* Solution second start here */
  const getLastWeeks = () => {
    const last4Weeks = [];
    const currentDate = new Date();

    // Calculate the start date (4 weeks ago)
    const startDate = new Date(currentDate);
    startDate.setDate(startDate.getDate() - 27); // Subtract 27 days to go back 4 weeks

    // Calculate the end date (today)
    const endDate = new Date(currentDate);

    // Loop to create each week's start and end date
    for (let i = 0; i < 4; i++) {
      const startOfWeek = new Date(startDate);
      const endOfWeek = new Date(startOfWeek);

      // Set end of week (6 days from start)
      endOfWeek.setDate(endOfWeek.getDate() + 6);

      last4Weeks.push({ start: startOfWeek, end: endOfWeek });

      // Move to next week
      startDate.setDate(startDate.getDate() + 7);
    }

    return last4Weeks;
  };
  const last28Days = getLastWeeks();
  console.log(last28Days, "last28Days");

  const FinalAllWeek = () => {
    let arr = [];
    last28Days?.map((val) => {
      let obj = {
        startDate: moment(val?.start).format("MMM DD"),
        endDate: moment(val?.end).format("MMM DD"),
      };
      arr.push(obj);
      setLastFourMonths(arr);
      console.log(arr, "newObj");
    });
  };

  /* Solution second end here */

  const handleMouseEnter = (datalist) => {
    console.log("datalist", datalist);
    const { dataKey, inactive } = datalist;
    if (hasPainted?.current === dataKey) return;
    hasPainted.current = dataKey;
    if (!inactive) {
      let final = Object?.entries(opacity)?.reduce((op, [key]) => {
        if (key === dataKey) op[key] = 1;
        else op[key] = 0.1;
        return op;
      }, {});
      setOpacity(final);
    }
  };

  const handleMouseLeave = () => {
    if (!hasPainted?.current) return;
    hasPainted.current = false;
    let final = Object?.entries(opacity)?.reduce((op, [key]) => {
      op[key] = 1;
      return op;
    }, {});
    setOpacity(final);
  };

  const handleLegendClick = (e) => {
    const key = e?.dataKey;
    setActiveKeys((prev) =>
      prev?.includes(key) ? prev?.filter((k) => k !== key) : [...prev, key]
    );
  };

  const renderLegend = (props) => {
    console.log(props, "propsssssssssssss");
    const { payload } = props;

    console.log(payload, "payload");

    return (
      <LegendWrapper
        onMouseEnter={() => handleMouseLeave()}
        onMouseLeave={() => handleMouseLeave()}
        onMouseOver={(e) => {
          handleMouseLeave();
        }}
      >
        {payload &&
          payload?.length > 0 &&
          payload?.map((entry, index) => (
            <div
              key={`item-${index}`}
              className="flex-wraps"
              onMouseEnter={(e) => {
                if (e) {
                  e?.preventDefault();
                  e?.stopPropagation();
                }
                handleMouseEnter(entry);
              }}
              onMouseOver={(e) => {
                e?.preventDefault();
                e?.stopPropagation();
                handleMouseEnter(entry);
              }}
              onClick={() => handleLegendClick(entry)}
              // onMouseLeave={() => handleMouseLeave(entry)}
              style={{
                maxWidth: "max-content",
                margin: "10px 0px",
                height: "fit-content",
              }}
            >
              <div className="circle-wrapper">
                <Circle fill={entry?.color} size="12px" />
              </div>
              <Content className="content" inactive={entry?.inactive}>
                {entry?.value}
              </Content>
            </div>
          ))}
      </LegendWrapper>
    );
  };

  console.log(totalCallData, "totalCallData");

  useEffect(() => {
    if (totalCallData) {
      setActiveKeys(totalCallData?.activeKeys);
      setOpacity(totalCallData?.opacitySet);
    }
    FinalWeeka();
    FinalAllWeek();
  }, [totalCallData]);
  return (
    <LineOuterWrapper width="100%" height={400}>
      <LineChart
        // width={500}
        // height={300}
        className="line-section"
        data={data}
        margin={{
          top: 5,
          right: 35,
          left: 20,
          bottom: 45,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip cursor={false} />
        <Legend content={renderLegend} />
        {data &&
          data?.length > 0 &&
          Object?.keys(data[0]).map(
            (list, index) =>
              list !== "name" && (
                <>
                  {console.log(list, "list")}
                  <Line
                    type="monotone"
                    dataKey={list}
                    name={list}
                    stroke={
                      activeKeys?.includes(list)
                        ? COLORS[index % COLORS.length]
                        : "grey"
                    }
                    hide={!activeKeys?.includes(list)}
                    strokeOpacity={opacity?.[list]}
                    activeDot={{ r: 8 }}
                  />
                </>
              )
          )}
      </LineChart>
    </LineOuterWrapper>
  );
}

export default LineChartComponent;

const LineOuterWrapper = styled(ResponsiveContainer)`
  .recharts-legend-wrapper {
    height: auto !important;
    padding-left: 5vw !important;
  }
  .recharts-legend-wrapper {
    bottom: 0px !important;
  }
`;

const LegendWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  ${"" /* justify-content: center; */}
  ${"" /* gap: 10px; */}
  text-transform: Capitalize;
  ${"" /* padding: 15px 40px; */}

  .flex-wraps {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    color: white;
  }
  .circle-wrapper {
    margin-top: 5px;
  }
`;
const Circle = styled.div`
  background: ${({ fill }) => fill};
  height: ${(props) => props?.size};
  width: ${(props) => props?.size};
  border-radius: 10px;
`;
const Content = styled.div`
  // max-width: 80%;
  color: ${({ inactive }) => (inactive ? "grey" : "#000")};
  text-decoration: ${({ inactive }) => (inactive ? "line-through" : "none")};
`;
