import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedClient: [],
};

export const NotificationSlice = createSlice({
  name: "NotificationSlice",
  initialState,

  reducers: {
    updateNotificationClient: (state, action) => {
      if (action) {
        state.selectedClient = action.payload;
      }
    },
    resetNotificationSlice: () => initialState,
  },
});

export const { updateNotificationClient, resetNotificationSlice } =
  NotificationSlice.actions;
export default NotificationSlice.reducer;
