import { InputNumber } from "antd";
import styled from "styled-components";

export const DynamicSheetWrapper = styled.div`
  width: 100%;
  height: ${({ active }) =>
    active ? "calc(100vh - 254px)" : "calc(100vh - 175px)"};
  display: flex;
  gap: 10px;

  .right {
    width: 70%;
    height: ${({ active }) =>
      active ? "calc(100vh - 254px)" : "calc(100vh - 175px)"};
    overflow-y: scroll;
    scrollbar-color: #242424 #ffffff1f;
    scrollbar-width: thin;
  }

  .left {
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    min-width: 400px;
    height: ${({ active }) =>
      active ? "calc(100vh - 214px)" : "calc(100vh - 130px)"};
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    overflow-y: scroll;
    scrollbar-color: #242424 #ffffff1f;
    scrollbar-width: none;
  }
`;

export const Tag = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: fit-content;
  background: rgb(36, 36, 36);
  color: #ffffff;
  padding: 2px 12px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

export const ExpendTag = styled.div`
  position: absolute;
  top: 16px;
  right: 4px;
  width: fit-content;
  background: rgb(36, 36, 36);
  color: #ffffff;
  border-radius: 12px;
  cursor: pointer;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${({ rotation }) =>
    rotation ? `rotate(${rotation}deg)` : "rotate(0deg)"};
`;

export const PopoverWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  button {
    border: none;
    width: 100%;
    text-align: center;
    padding: 5px 20px;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-image: initial;
    border-left: 2px solid white;
    height: 33px;
    font-size: 13px;
    font-family: Poppins;
    font-style: normal;
    border-radius: 6px;
    background: rgb(36, 36, 36);
    color: white;
  }
`;

export const FormButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
`;

export const Container = styled.div`
  position: relative;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #24242421;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CategoryName = styled.h2`
  color: #242424;
  margin: 0;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  @media (max-width: 1388px) {
    font-size: 12px;
  }
`;

export const CategoryNameResponsive = styled.h2`
  width: 280px;
  color: #242424;
  margin: 0;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  @media (max-width: 1750px) {
    width: 180px;
  }

  @media (max-width: 1600px) {
    width: 150px;
  }
  @media (max-width: 1388px) {
    width: 120px;
    font-size: 12px;
  }
`;

export const MetricsContainer = styled.div`
  display: flex;
  gap: 16px;
  height: 46px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 9px;
  background-color: #f4f4f4;
`;

export const Label = styled.span`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #7b7f91;
  padding-right: 4px;
`;

export const AdditionalMetrics = styled.div`
  display: flex;
  gap: 10px;
  background-color: transparent;
  border-radius: 8px;
`;

export const Metric = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || "column"};
  align-items: start;
  border-radius: 12px;
  border: 1px solid #d2d4de;
  padding: 4px 6px;
  gap: 4px;
  background: rgb(222 220 220);
`;

export const MinMetric = styled.div`
  width: 100%;
  min-width: 200px;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || "column"};
  align-items: center;
  border-radius: 12px;
  border: 1px solid #d2d4de;
  padding: 4px 6px;
  gap: 4px;
  background: rgb(222 220 220);

  @media (max-width: 1570px) {
    min-width: 180px;
  }
  @media (max-width: 1442px) {
    min-width: 170px;
  }
`;

export const MetricLabel = styled.span`
  color: #7b7f91;
  margin-bottom: 5px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
`;

export const MetricValue = styled.span`
  color: #242424;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.8px;
  text-align: center;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledInputNumber = styled(InputNumber)`
  margin-bottom: 0;
`;

export const CategoryWrap = styled.div`
  display: flex;
  width: fit-content;
  gap: 8px;
  align-items: center;

  .expend {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 2px;
  background: red;
`;
