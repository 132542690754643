import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { SummaryJson } from "../../../utils/json/SummaryData";
import { Select } from "antd";
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { formatNumberDecimal } from "../Year/commonFuc";
import {
  calculateCardValue,
  calculateGraphValues,
  calculatePieValues,
  getOptionValueForSource,
} from "./commonFunc";
import {
  COLORS,
  HeadingAndFilters,
  MainWrap,
  SummaryReportWrapper,
  TabWrap,
} from "./summaryStyle";

export default function SummaryReport() {
  const [sourceOption, setSourceOption] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [graphData, setGraphData] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [pieChart, setPieChart] = useState([]);

  const handleSelectSource = (value, option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    if (selectedOption) {
      let result = calculateGraphValues(SummaryJson, selectedOption);
      setGraphData(result);
      let cardValues = calculateCardValue(SummaryJson, selectedOption);
      setCardData(cardValues);
      return;
    }

    if (sourceOption?.length > 0) {
      let result = calculateGraphValues(SummaryJson, sourceOption[0]);
      setGraphData(result);
      let cardValues = calculateCardValue(SummaryJson, sourceOption[0]);
      setCardData(cardValues);
      return;
    }
  }, [sourceOption, selectedOption]);

  useEffect(() => {
    if (sourceOption?.length > 0) {
      let filterPieValue = calculatePieValues(SummaryJson, sourceOption);
      console.log(filterPieValue, "filterPieValue");
      setPieChart(filterPieValue);
    }
  }, [sourceOption]);

  useEffect(() => {
    let filteredSource = getOptionValueForSource(SummaryJson);
    setSourceOption(filteredSource);
  }, []);

  return (
    <SummaryReportWrapper>
      <HeadingAndFilters>
        <h2>Budget Summary Report - 2025</h2>
        <div>
          <Select
            placeholder="Please select"
            prefixCls="inner-select2"
            className="select"
            onChange={handleSelectSource}
            options={sourceOption}
            value={selectedOption ? selectedOption : sourceOption[0]}
            size="middle"
          />
        </div>
      </HeadingAndFilters>

      <TabWrap>
        {cardData?.map((tabs) => (
          <div className="box">
            <p className="label">{tabs?.label}</p>
            <p className="value">
              {tabs?.type == "per"
                ? `${formatNumberDecimal(tabs?.value)}%`
                : tabs?.type == "currency"
                ? `$${formatNumberDecimal(tabs?.value)}`
                : formatNumberDecimal(tabs?.value)}
            </p>
          </div>
        ))}
      </TabWrap>

      <MainWrap>
        <section className="budget-revenue">
          <p>Projected Budget & Revenue</p>
          {graphData?.length > 0 && (
            <Graph type="Budget & Revenue" graphData={graphData} />
          )}
        </section>

        <section className="budget-revenue">
          <p>Projected Leads, Appts, Jobs</p>
          <Graph type="Leads, Appts, Jobs" graphData={graphData} />
        </section>

        <section className="budget-revenue">
          <p>Projected Category Revenue</p>
          <PieGraph data={pieChart} />
        </section>
      </MainWrap>
    </SummaryReportWrapper>
  );
}

const Graph = ({ type, graphData }) => {
  console.log(graphData, "graphsajds");

  const handleLegend = (e) => {
    // if (activeLegends?.includes(e?.value)) {
    //   setActiveLegends((pre) => pre?.filter((ele) => ele !== e?.value));
    // } else {
    //   setActiveLegends((pre) => [...pre, e?.value]);
    // }
  };

  return (
    <GraphWrapper>
      <ResponsiveContainer width="100%" height={500}>
        <ComposedChart
          data={graphData}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "16px",
            padding: "16px 0",
          }}
        >
          <CartesianGrid stroke="#f5f5f5" strokeDasharray="5 5" />
          <XAxis dataKey="month" />
          <YAxis yAxisId="left" orientation="left" />

          <Tooltip />
          <Legend onClick={handleLegend} />

          {type === "Budget & Revenue" ? (
            <>
              <Line
                type="monotone"
                yAxisId="left"
                dataKey="budget"
                stroke="#00e272"
                name="budget"
                // hide={activeLegends?.includes("1 Roas")}
              />

              <Line
                type="monotone"
                yAxisId="left"
                dataKey="sales"
                stroke="#FA4B99"
                name="sales"
                // hide={activeLegends?.includes("1 Roas")}
              />
            </>
          ) : (
            <>
              <Line
                type="monotone"
                yAxisId="left"
                dataKey="leads"
                stroke="#00e272"
                name="leads"
                // hide={activeLegends?.includes("1 Roas")}
              />

              <Line
                type="monotone"
                yAxisId="left"
                dataKey="appts"
                stroke="#FA4B99"
                name="appts"
                // hide={activeLegends?.includes("1 Roas")}
              />

              <Line
                type="monotone"
                yAxisId="left"
                dataKey="jobs"
                stroke="#feb56a"
                name="jobs"
                // hide={activeLegends?.includes("1 Roas")}
              />
            </>
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </GraphWrapper>
  );
};

const PieGraph = ({ data }) => {
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {percent * 100 > 10 ? `${(percent * 100).toFixed(0)}%` : ""}
      </text>
    );
  };

  const CustomTooltip = ({ payload }) => {
    return (
      <div
        className="tooltip-box"
        style={{ background: "#fff", padding: "20px" }}
      >
        <div className="ant-popover-arrow" />
        <div className="content">
          <h5>{payload?.[0]?.payload?.name}</h5>
          <p>
            <b>
              <span style={{ fontSize: "14px" }}>
                {payload?.[0]?.payload?.percentage}%
              </span>{" "}
              <span style={{ fontSize: "12px" }}>
                (${formatNumberDecimal(payload?.[0]?.payload?.salestotal)})
              </span>
            </b>
          </p>
        </div>
      </div>
    );
  };

  return (
    <PieGraphWrapper>
      <PieChart width={800} height={500}>
        <Legend
          layout="vertical"
          verticalAlign="middle"
          itemGap={20}
          align="right"
          iconType="circle"
          formatter={(value) => (
            <span style={{ color: "#7a7b7a" }}>{value}</span> // Gray color for legend text
          )}
          wrapperStyle={{
            position: "absolute",
            top: "40%",
            left: "60%",
            transform: "translate(10%, -30%)",
          }}
        />

        <Pie
          data={data}
          cx={195}
          cy={225}
          innerRadius={0}
          outerRadius={190}
          fill="#8884d8"
          legendType="circle"
          paddingAngle={0}
          dataKey="salestotal"
          label={renderCustomizedLabel}
          labelLine={false}
        >
          {data?.length > 0 &&
            data?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
        </Pie>
        <Tooltip content={<CustomTooltip data={data} />} />
      </PieChart>
    </PieGraphWrapper>
  );
};

const GraphWrapper = styled.div`
  width: 100%;
  height: 100%;
  .recharts-legend-wrapper {
    height: auto !important;
  }
`;

export const PieGraphWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 16px;
  padding: 16px 0px;
  .recharts-legend-wrapper {
    height: 200px !important;
  }
`;
