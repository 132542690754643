import { Checkbox, Select, Tooltip } from "antd";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import { updatepaidSearchClient } from "../../app/Auth/signIn/LogInSlice";
import {
  getCampaignByPlatform,
  getSourceListForSetting,
  getpaidsearchperformance,
  updatepaidsearch,
} from "../../services/Collections";
import {
  debounce,
  filterClientsBySection,
} from "../../utils/common/commonFunction";
import { updateInterval } from "./LsaPerformanceStore";
import { NoDataDropdown } from "../../components/common/NoDataDropdown";

const PaidSearchSettings = ({ paidLoading, setFilterDrawer, setActiveTab, activeTab }) => {
  let arr = [];
  let arr2 = [];
  let arr3 = [];
  const dispatch = useDispatch();
  const [campaignSearchData, setCampaignSearchData] = useState([]);
  const [campaignSearchDataIds, setCampaignSearchDataIds] = useState([]);
  const [selectedValues, setSelectedValues] = useState(null);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectData, setSelectData] = useState([]);
  const [platformCampaignList, setPlatformCampaignList] = useState([]);
  const [selectedplatformByCampaign, setSelectedplatformByCampaign] = useState(
    []
  );
  const [platformLoading, setPlatformLoading] = useState(false);
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );
  const [campaignSearchLoader, setCampaignSearchLoader] = useState(false);
  const [clientOptions, setclientOptions] = useState([]);
  const clientOptionsData = useSelector(
    (state) => state?.loginAuth?.clientsList
  );
  const filterData = useSelector(
    (state) => state?.lsaPerformance?.selectedClient
  );
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );
  const [defaultValue, setDefaultValue] = useState(
    filterData ? filterData : prefilledData?.selectedClient
  );
  const [sourceLoader, setsourceLoader] = useState(false);
  const [scrollPage, setScrollPage] = useState(1);
  const [sourceOptionSetting, setSourceOptionSetting] = useState([]);
  const [selectedSource, setSelectedSource] = useState([]);

  const intervalValue = useSelector((state) => state?.lsaPerformance?.interval);
  const [interval, setInterval] = useState(intervalValue);

  const initialValues = {
    custom_costs: [
      {
        cost: "",
        month: "",
        year: "",
      },
    ],
  };

  // const activeTab = useSelector((state) => state?.lsaPerformance?.activeTab);
  const [sourceType, setSocialType] = useState(activeTab);
  const socialOptions = [
    { label: "Paid Search", value: "paid" },
    { label: "LSA Performance", value: "lsa" },
    { label: "Paid Social", value: "social" },
    { label: "Yelp", value: "yelp" },
  ];
  const active = socialOptions?.filter((ele) => ele?.value == sourceType);
  const selectedSocial = sourceType ? [{ label: active?.label, value: sourceType }] : null;

  const getClientOption = () => {
    let arr = [];
    console.log(clientOptionsData?.clients, "clientOptionsData?.clients");

    filterClientsBySection(clientOptionsData?.clients, "paid-search")?.length >
      0 &&
      filterClientsBySection(clientOptionsData?.clients, "paid-search")?.map(
        (ele) => {
          let obj = {
            ...ele,
            label: ele?.name,
            value: ele?.name,
          };
          arr.push(obj);
        }
      );
    setclientOptions(arr);
  };

  const handleSelectAll = (data) => {
    let arr = [];
    data?.length > 0 &&
      data?.map((el) => {
        arr.push(el?.value);
      });

    setSelectedplatformByCampaign(arr);
  };
  const handleGetCampaignSearchData = async () => {
    setCampaignSearchLoader(true);
    let params = new URLSearchParams();
    params.append("client_key", defaultValue?.key);
    params.append("source_type", sourceType);
    let res = await getpaidsearchperformance(params.toString());
    if (res.status === 200) {
      setCampaignSearchLoader(false);
      if (res.data.items.length > 0) {
        console.log(res.data, "res.data");
        for (let i = 0; i < res?.data.items.length; i++) {
          let obj = {
            value: res?.data?.items[i]?.id,
            label: res?.data?.items[i]?.paid_search_name,
          };
          arr2.push(obj);
          console.log(arr2, "arr2");
        }
      }
      const filteredByVal = Array.isArray(arr2)
        ? [
            ...arr2
              ?.reduce((a, c) => {
                a?.set(c?.value, c);
                return a;
              }, new Map())
              ?.values(),
          ]
        : [];
      // st_campaign_id: res?.data?.items[i]?.st_campaign_id,
      if (res.data.items.length > 0) {
        for (let i = 0; i < res?.data.items.length; i++) {
          let obj = {
            value: res?.data?.items[i]?.campaign_list_names,
            label: res?.data?.items[i]?.paid_search_name,
            st_campaign_id: res?.data?.items[i]?.st_campaign_id,
          };
          arr3.push(obj);
        }
      }
      setCampaignSearchDataIds(arr3);
      setCampaignSearchData(filteredByVal);
    } else {
      setCampaignSearchLoader(false);
    }
  };

  const handleGetPerformanceData = async () => {
    setPlatformLoading(true);

    let nameValue = {
      paid: "google",
      lsa: "lsa",
      social: "facebook",
      yelp: "yelp",
    };
    console.log("defaultValue?.db", defaultValue?.db);
    //test commit
    let params = new URLSearchParams();
    params.append("db", defaultValue?.db);
    params.append("name", nameValue?.[sourceType]);
    params.append("source_type", sourceType);
    let res = await getCampaignByPlatform(params.toString());
    if (res.status == 200) {
      let arr = [];

      if (res.data.length > 0) {
        for (let i = 0; i < res?.data.length; i++) {
          let obj = {
            value: res?.data[i]?.id,
            label: res?.data[i]?.name,
          };
          arr.push(obj);
        }
      }
      const filteredByVal = Array.isArray(arr)
        ? [
            ...arr
              ?.reduce((a, c) => {
                a?.set(c?.value, c);
                return a;
              }, new Map())
              ?.values(),
          ]
        : [];
      setPlatformLoading(false);
      setPlatformCampaignList(filteredByVal);
    } else {
      setPlatformLoading(false);
    }
  };

  const handleUpdateSettings = async () => {
    if (selectedplatformByCampaign?.length > 0 && selectedValues) {
      setUpdateLoader(true);

      const comingArray = selectedplatformByCampaign?.[0]?.value
        ? selectedplatformByCampaign?.map((val) => val?.value)
        : selectedplatformByCampaign;

      const st_campaign = selectedSource?.[0]?.value
        ? selectedSource?.map((val) => val?.value)
        : selectedSource;

      console.log(comingArray, "campaign_list_names");
      console.log(selectedSource, "selectedSource");

      let req = {
        id: selectedValues?.value,
        campaign_list_names: JSON.stringify(comingArray),
        st_campaign_id: JSON.stringify(st_campaign),
        source_type: sourceType,
        // st_campaign_id:  typeof selectedSource?.[0] == 'string' ? selectedSource?.join(",") : selectedSource?.map((el)=> el.value)?.join(","),
      };
      let params = new URLSearchParams();
      params.append("client_key", defaultValue?.key);

      let res = await updatepaidsearch(req, params?.toString());
      if (res.status === 200) {
        setFilterDrawer(false);
        handleIntervalChanged(interval);
        setUpdateLoader(false);
        toast.success(res.ErrorMessage);
        dispatch(updatepaidSearchClient(defaultValue));
        // dispatch(updateActiveTab(sourceType));
        setActiveTab(sourceType);
      } else {
        setUpdateLoader(false);
        // toast.error(
        //   res.response.data.message ||
        //     res.error ||
        //     res.message ||
        //     "Something went wrong"
        // );
      }
    } else {
      toast.warning("Please fill required fields");
    }
  };

  const handleAddValues = (val) => {
    if (!arr?.includes(val)) {
      arr = [...arr, val];
      setSelectedplatformByCampaign(arr?.length > 0 ? arr : null);
    } else {
      let filteredArr = [];
      arr?.map((values) => {
        if (values != val) {
          filteredArr?.push(val);
        }
      });
      arr = filteredArr;
      setSelectedplatformByCampaign(arr?.length > 0 ? arr : null);
    }
  };

  const selectedData = campaignSearchDataIds?.find(
    (val) => val?.label == selectedValues?.label
  );

  const valuesArray =
    selectedData?.value &&
    JSON?.parse(selectedData?.value)?.map((value) => parseInt(value));

  const objectsArray =
    valuesArray?.map((value, index) => ({
      id: value,
    })) ?? [];
  console.log(valuesArray, "valuesArray", objectsArray);

  const defaultSourcesArray =
    selectedData?.st_campaign_id &&
    JSON?.parse(selectedData?.st_campaign_id)?.map((value) => value);
  const finalSelectedSources =
    defaultSourcesArray?.map((value, index) => ({
      id: value,
    })) ?? [];

  let myNewarr = [];
  const autoSelectedData = () => {
    objectsArray?.length > 0 &&
      objectsArray?.map((val) => {
        platformCampaignList?.map((el) => {
          if (val?.id == el?.value) {
            myNewarr?.push(el);
          }
        });
      });
    setSelectedplatformByCampaign(myNewarr);
  };

  let myNewSourceArray = [];

  const autoSelectedSourceData = () => {
    finalSelectedSources?.length > 0 &&
      finalSelectedSources?.map((val) => {
        sourceOptionSetting?.map((el) => {
          if (val?.id == el?.value) {
            myNewSourceArray?.push(el);
          }
        });
      });
    setSelectedSource(myNewSourceArray);
  };

  const handleSourceList = async (search, page) => {
    setsourceLoader(true);
    let params = new URLSearchParams();
    params.append(
      "db",
      filterData?.db ? filterData?.db : prefilledData?.selectedClient?.db
    );
    params.append("page_size", 500);
    params.append("page", page ?? scrollPage);
    params.append("source_type", sourceType);
    search && params.append("search", search);
    let res = await getSourceListForSetting(params?.toString());
    if (res?.status == 200) {
      let arr = [];
      console.log(res?.data?.items, "res?.data?.items");
      res?.data?.items?.length > 0 &&
        res?.data?.items?.map((el) => {
          arr?.push({
            label: el,
            value: el,
          });
        });
      if (page == 1 || scrollPage == 1) {
        setSourceOptionSetting(arr);
      } else {
        setSourceOptionSetting([...sourceOptionSetting, ...arr]);
      }
      setsourceLoader(false);
    } else {
      setsourceLoader(false);
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight) {
      // User has scrolled to the bottom

      setScrollPage(scrollPage + 1);
    }
  };

  const handleSearchSource = useCallback(debounce(handleSourceList), []);

  const handleSearchOptions = (e) => {
    handleSearchSource(e, 1);
  };
  const handleAllClearSources = () => {
    setSelectedSource([]);
  };

  const handleIntervalChanged = (value) => {
    if (value === undefined) {
      dispatch(updateInterval(null));
    }

    if (Number(value)) {
      dispatch(
        updateInterval({
          label: value?.toString(),
          value: value,
        })
      );
    }
    if (Object.keys(value).length) {
      dispatch(updateInterval(value));
    }
  };

  useEffect(() => {
    getClientOption();
  }, []);

  useEffect(() => {
    handleGetPerformanceData();
    handleGetCampaignSearchData();
  }, [defaultValue, sourceType]);

  useEffect(() => {
    if (objectsArray?.length === 0) setSelectedplatformByCampaign([]);
    if (finalSelectedSources?.length === 0) setSelectedSource([]);
    if (objectsArray?.length > 0 && platformCampaignList?.length > 0) {
      autoSelectedData();
      autoSelectedSourceData();
    }
  }, [selectedValues]);

  useEffect(() => {
    handleSourceList();
  }, [sourceType]);

  return (
    <AddSectionWrapper selectedThemeColors={selectedThemeColors}>
      <div className="title">
        <h3 style={{ color: "rgba(25, 25, 25, 1)" }}>Settings</h3>
      </div>

      <div className="content">
        <Formik
          initialValues={initialValues}
          onSubmit={handleUpdateSettings}
          render={({ values, setFieldValue }) => (
            <Form>
              <div className="select-wrap">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "transparent",
                    height: "100%",
                    marginBottom: "0px",
                  }}
                >
                  <label
                    className="mb-1  "
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <strong>
                      Social Type <span className="required">*</span>
                    </strong>
                  </label>
                </div>
                <Tooltip>
                  <Select
                    showSearch
                    prefixCls="inner-select2"
                    allowClear
                    // loading={campaignSearchLoader || platformLoading}
                    style={{
                      width: "100%",
                      height: "48px",
                    }}
                    maxTagCount={1}
                    value={selectedSocial}
                    placeholder="Please select"
                    onChange={(_, data) => {
                      setSocialType(data?.value);
                      setSelectedValues(null);
                      setSelectedSource([]);
                      setSelectedplatformByCampaign([]);
                      setSelectData([]);
                      setIsSelectAll(false);
                    }}
                    options={socialOptions}
                  />
                </Tooltip>
              </div>

              <div className="select-wrap">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "transparent",
                    height: "100%",
                    marginBottom: "0px",
                  }}
                >
                  <label
                    className="mb-1  "
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <strong>
                      Campaign Search <span className="required">*</span>
                    </strong>
                  </label>
                </div>
                <Tooltip>
                  <Select
                    showSearch
                    prefixCls="inner-select2"
                    allowClear
                    loading={campaignSearchLoader || platformLoading}
                    style={{
                      width: "100%",
                      height: "48px",
                    }}
                    maxTagCount={1}
                    value={selectedValues}
                    placeholder="Please select"
                    onChange={(_, data) => setSelectedValues(data)}
                    options={platformLoading ? [] : campaignSearchData}
                    notFoundContent={
                      campaignSearchLoader || platformLoading ? (
                        <NoDataDropdown text={"Processing..."} />
                      ) : (
                        <NoDataDropdown text={"No data"} />
                      )
                    }
                  />
                </Tooltip>
              </div>

              <div className="select-wrap">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "transparent",
                    height: "100%",
                    marginBottom: "0px",
                  }}
                >
                  <label
                    className="mb-1  "
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <strong>Campaign Sources</strong>
                  </label>
                </div>
                <Tooltip>
                  <Select
                    mode="multiple"
                    prefixCls="inner-select2"
                    allowClear
                    loading={sourceLoader}
                    onPopupScroll={handleScroll}
                    onSearch={(e) => handleSearchOptions(e)}
                    onBlur={(e) =>
                      scrollPage == 1 ? handleSourceList() : setScrollPage(1)
                    }
                    maxTagCount={1}
                    style={{
                      width: "100%",
                      height: "48px",
                    }}
                    value={selectedSource}
                    onChange={(el) => setSelectedSource(el)}
                    // onChange={(el) => console.log(el)}

                    placeholder="Please select"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    onClear={handleAllClearSources}
                    options={sourceOptionSetting}
                    notFoundContent={
                      sourceLoader ? (
                        <NoDataDropdown text={"Processing..."} />
                      ) : (
                        <NoDataDropdown text={"No data"} />
                      )
                    }
                  />
                </Tooltip>
              </div>

              <div className="select-wrap" style={{ marginTop: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "transparent",
                    height: "100%",
                    marginBottom: "0px",
                  }}
                >
                  <label
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <strong>
                      Campaigns by Platform <span className="required">*</span>{" "}
                    </strong>
                  </label>
                  <>
                    {selectedplatformByCampaign?.length !==
                    platformCampaignList?.length ? (
                      <span
                        className="ml-2"
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          height: "20px",
                        }}
                      >
                        <span className="mr-2" style={{ color: "#828690" }}>
                          Select All
                        </span>
                        <span aria-disabled>
                          <Checkbox
                            disabled={platformLoading}
                            checked={
                              selectedplatformByCampaign?.length ==
                              platformCampaignList?.length
                            }
                            onChange={(e) => {
                              setIsSelectAll(e?.target?.checked);
                              setSelectData(platformCampaignList);
                              if (platformCampaignList?.length > 0) {
                                handleSelectAll(platformCampaignList);
                              }
                            }}
                          />
                        </span>
                      </span>
                    ) : (
                      <span
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignContent: "center",
                          height: "20px",
                        }}
                        className="ml-2"
                      >
                        <span className="mr-2" style={{ color: "#828690" }}>
                          {" "}
                          Clear All
                        </span>
                        <span aria-disabled>
                          <Checkbox
                            disabled={platformLoading}
                            checked={
                              selectedplatformByCampaign?.length ==
                              platformCampaignList?.length
                            }
                            onChange={(e) => {
                              setIsSelectAll(e?.target?.checked);
                              setSelectedplatformByCampaign([]);
                              setSelectData([]);
                            }}
                          />
                        </span>
                      </span>
                    )}
                  </>
                </div>
                <div className="" style={{ marginTop: "4px" }}>
                  <Select
                    size={"large"}
                    allowClear
                    mode="multiple"
                    // prefixCls="multipleHeight"
                    loading={platformLoading}
                    // className="custom-select-2 inner-multi-select add-multi-select"
                    prefixCls="inner-select2"
                    // className="custom-select add-select"
                    onChange={(val) => {
                      handleAddValues(val);
                      setSelectedplatformByCampaign(val);
                    }}
                    style={{
                      width: "100%",
                      height: "48px",
                      // backgroundColor: "#05031a",
                    }}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    value={selectedplatformByCampaign}
                    maxTagCount={1}
                    placeholder="Please select"
                    options={platformCampaignList}
                    notFoundContent={
                      platformLoading ? (
                        <NoDataDropdown text={"Processing..."} />
                      ) : (
                        <NoDataDropdown text={"No data"} />
                      )
                    }
                  />
                </div>

                <div style={{ marginTop: "20px" }} className="input-box">
                  <label>
                    <strong>Interval</strong>
                  </label>
                  <br />
                  <Select
                    size={"large"}
                    // className="inner-select custom-Select-Campaign"
                    prefixCls="inner-select2"
                    allowClear
                    value={interval}
                    onChange={(value) => setInterval(value)}
                    style={{
                      width: "100%",
                      height: "48px",
                      marginTop: "6px",
                    }}
                    maxTagCount={1}
                    placeholder="Please select"
                    options={[
                      {
                        label: "90",
                        value: 90,
                      },
                    ]}
                  />
                </div>

                <div></div>
              </div>
              {updateLoader || paidLoading ? (
                <div
                  style={{
                    marginTop: "17px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <LoadingButton selectedThemeColors={selectedThemeColors}>
                    <b>Loading...</b>
                  </LoadingButton>
                </div>
              ) : (
                <button className="btn-wrap" type="submit">
                  <b>Submit</b>
                </button>
              )}
            </Form>
          )}
        ></Formik>
      </div>
    </AddSectionWrapper>
  );
};

export default PaidSearchSettings;

const AddSectionWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px 0;

  .btn-wrap {
    margin-top: 30px;
    width: 100%;
    border-style: none;
    background-color: ${({ selectedThemeColors }) =>
      selectedThemeColors?.buttonColor};
    border-radius: 7px;
    padding: 10px 15px;
    color: #fff;
  }

  .title {
    width: 100%;
    margin-bottom: 20px;

    h3 {
      font-size: 24px;
      color: #fff;
    }
  }
  .content {
    width: 100%;
    padding-bottom: 20px;

    label {
      color: ${({ selectedThemeColors }) => selectedThemeColors?.lightText};
      font-size: 15px;
    }
    .select-wrap {
      margin-top: 10px;
    }
  }
  .add-more {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 10px;

    button {
      border: none;
      padding: 3px 7px;
      border-radius: 4px;
      font-size: 12px;
      color: #fff;
      background-color: rgba(36, 36, 36, 1);
    }
  }

  .required {
    color: red;
  }
`;
const LoadingButton = styled.p`
  margin-top: 13px;
  width: 100%;
  border-style: none;
  background-color: ${({ selectedThemeColors }) =>
    selectedThemeColors?.buttonColor};
  border-radius: 7px;
  padding: 10px 15px;
  color: #fff;
`;
