import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import PropTypes from "prop-types";
import { Paths } from "./Paths";
import { PrivateLayout } from "../layouts/privateLayouts/PrivateLayout";
import { privatePaths } from "./PrivatePaths";
import { useSelector } from "react-redux";
import { PublicLayout } from "../layouts/publicLayouts/PublicLayout";
import NotFound from "../components/NotFound";
import SelectModule from "../components/SelectModule";

function PublicRoute({ isAuthenticated, selectedTab }) {
  if (isAuthenticated != null) {
    if (selectedTab) {
      return <Navigate to={`/private-layout/${selectedTab}`} replace />;
    }
    return <Navigate to="/private-layout/dashboard" replace />;
  }
  return <Outlet />;
}

function PrivateRoute({ isAuthenticated }) {
  if (!isAuthenticated) return <Navigate to="/" />;
  return <Outlet />;
}

export default function AllRoutes() {
  const token = useSelector((state) => state?.loginAuth?.dashboardData);
  const loginData = useSelector((state) => state?.loginAuth?.data) ?? {};
  const selectedModuleTabs = useSelector( (state) => state?.loginAuth?.selectedModuleTabs );
  const userDetails = useSelector( (state) => state?.loginAuth?.adleverageUserId );
  const filteredPrivateRoutes = privatePaths?.filter( (item) => userDetails?.permissions && userDetails?.permissions?.includes(item?.sectionName) );

  return (
    <Router>
      <Routes>
        <Route element={ <PublicRoute isAuthenticated={token} selectedTab={selectedModuleTabs} /> } >
          <Route path="/" element={<PublicLayout />}>
            {Paths.map((routes, index) => {
              if ( routes?.path === "/" || Object?.keys(loginData)?.length >= 0 ) {
                return ( <Route key={index} path={routes.path} element={routes.component} /> );
              }
            })}
          </Route>
        </Route>

        <Route element={<PrivateRoute isAuthenticated={token} />}>
          <Route path="/private-layout" element={<PrivateLayout />}>
            <Route path="select-module" element={<SelectModule />} />
            {filteredPrivateRoutes.map((routes, index) => {
              return ( <Route key={index} path={`${routes.path}`} element={routes.component} /> );
            })}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}

PublicRoute.propTypes = {
  isAuthenticated: PropTypes.any,
};

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.any,
};
