import moment from "moment";
import { Sort, SortAsc, SortDesc } from "../Images/Images";
import dayjs from "dayjs";

export const sortingFunction = (arr, prop, order) => {
  let sortedArray = arr?.sort((a, b) => {
    if (order === "asc") {
      return a[prop] - b[prop];
    } else if (order == "desc") {
      return b[prop] - a[prop];
    } else {
      // Default to ascending order if order is not recognized
      return a[prop] - b[prop];
    }
  });
  return sortedArray;
};

export const debounce = (func, timeOut = 400) => {
  let timer;
  return (...args) => {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, timeOut);
  };
};

export const srcSortImage = (Basis, sortParam) => {
  if (Basis === sortParam?.sortBasis) {
    if (sortParam?.sortType === "asc") {
      return SortAsc;
    }
    return SortDesc;
  }
  return Sort;
};

export function numberWithCommas(x) {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "0.00";
  }
}

function delayWithPromise(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}
export async function delay(milliseconds) {
  await delayWithPromise(milliseconds);
}

export const thirtyDayAgoDate = (d) => {
  const date = moment(d)?._d;
  date.setDate(date?.getDate() - 30);
  return date;
};

export const LeadNames = (name) => {
  if (name == "Yelp") {
    return "yelp";
  } else if (name == "Web Chat") {
    return "chatwidget";
  } else if (name == "LSA") {
    return "lsa";
  } else {
    return "sms";
  }
};

export const valueFormatter = (num, digits) => {
  if (num > 999) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        console.log(item, "valueFormatter if 1");
        return item ? num >= item.value : "0";
      });
    console.log(item, "valueFormatter if 2");
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  } else {
    console.log(num, "valueFormatter else");
    return num ? Math.floor(num) : "0";
  }
};

export const filterClientsBySection = (clients, section) => {
  return (clients ?? [])?.filter((client) =>
    client.allocated_boards?.includes(section)
  );
};

export function getRandomColor() {
  let letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const getSelectedClientLogo = (allClient, name) => {
  if (Array.isArray(allClient) && allClient?.length > 0) {
    let selected = allClient?.find((el) => el?.name === name);
    return selected?.logo;
  }
};

export const dateFunc = (d) => {
  const date = moment.utc(d).local().format("DD MMM, yyyy");
  return date;
};

export const timeFunc = (t) => {
  const time = moment.utc(t).local().format("HH:mm A");
  return time;
};

export function getMonthNumber(monthName) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthIndex = months.indexOf(monthName);

  if (monthIndex !== -1) {
    return monthIndex + 1;
  } else {
    return null;
  }
}

export function formatUSPhoneNumber(phoneNumber) {
  if (!phoneNumber) return "+1 (XXX) XXX-XXXX";

  // Remove all non-digit characters
  const cleaned = phoneNumber.replace(/\D/g, "");
  if (cleaned.length === 11 && cleaned.startsWith("1")) {
    return `+1 (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(
      7
    )}`;
  }

  // Format for 10-digit numbers
  if (cleaned.length === 10) {
    return `+1 (${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(
      6
    )}`;
  }

  // Return the original input if it's not valid
  return phoneNumber;
}

export function timeAgo(date) {
  const inputDate = dayjs(date);
  const today = dayjs();
  const yesterday = today.subtract(1, "day");

  if (inputDate.isSame(today, "day")) {
    return "Today";
  } else if (inputDate.isSame(yesterday, "day")) {
    return "Yesterday";
  } else {
    return inputDate.format("DD MMM, YYYY");
  }
}

export function getMonthOrYearDates(dateString, year = null) {
  console.log("dateObjdateObj",year);
  let firstDate, lastDate;

  if (year) {
    const startOfYear = dayjs(`${year}-01-01`);
    const endOfYear = dayjs(`${year}-12-31`);

    firstDate = startOfYear;
    lastDate = endOfYear;
  } else {
    const date = dayjs(dateString);

    firstDate = date.startOf("month");
    lastDate = date.endOf("month");
  }

  return [
    firstDate.toDate(),
    lastDate.toDate(),
    firstDate.format("YYYY-MM-DD"),
    lastDate.format("YYYY-MM-DD"),
  ];
}
