import React, { useEffect, useState } from "react";
import AllRoutes from "./routes/routes";
import "./style/global.css";
import "./style/dashboard.css";
import "./style/antdtable.css";
import { useOnlineStatus } from "./components/useOnlineStatus";
import NoInternetConnectionUI from "./components/NoInternetConnectionUI";

function App() {
  const isOnline = useOnlineStatus();
  console.log(isOnline, "isOnlineeeeeeeeeee")

  return <>{!isOnline ? <NoInternetConnectionUI /> : <AllRoutes />}</>;
}

//test commit on stage

export default App;
