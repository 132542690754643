/* eslint-disable react/jsx-key */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PrivateLayoutWrapper } from "../layouts/privateLayouts/PrivateLayout";
import AiBooking from "../module/AIBooking/AiBooking";
import Header from "./Header";
import { OpenSideNav } from "../app/Auth/signIn/SignInSlice";

export const AIBookingPublic = () => {
  const [click, setClick] = useState(false);
  const isSideBarOpen = useSelector((state) => state?.LoginSlice?.sideNav);
  const sidebar = useSelector((state) => state?.LoginSlice?.sideNav);
  const dispatch = useDispatch();
  const handleClickAway = () => {
    dispatch(OpenSideNav(false));
  };

  return (
    <PrivateLayoutWrapper sidebar={sidebar}>
      <div>
        <div className="header">
          <Header setClick={setClick} />
        </div>
        <div>
          <section className="outlet-section">
            <div className="outlet-section-inner">
              <AiBooking />
            </div>
          </section>
        </div>
      </div>
    </PrivateLayoutWrapper>
  );
};
