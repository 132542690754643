import moment from "moment";

export function calculateTotals(categories) {
  let totalBudget = 0,
    totalSales = 0,
    totalLeads = 0,
    totalAppts = 0,
    totalJobs = 0,
    totalClosePer = 0,
    totalAvgTicket = 0,
    totalCpl = 0,
    totalCpa = 0,
    totalCpj = 0,
    totalRoas = 0,
    totalCon_per = 0;

  // Iterate over each category and sum up the values
  categories.forEach((cat) => {
    if (cat.id !== "total") {
      totalBudget += cat.budget || 0;
      totalSales += cat.sales || 0;
      totalLeads += cat.leads || 0;
      totalAppts += cat.appts || 0;
      totalJobs += cat.jobs || 0;
      totalClosePer += cat.close_per || 0;
      totalAvgTicket += cat.avg_ticket || 0;
      totalCpl += cat.cpl || 0;
      totalCpa += cat.cpa || 0;
      totalCpj += cat.cpj || 0;
      totalRoas += cat.roas || 0;
      totalCon_per += cat.con_per || 0;
    }
  });
  // con_per
  return {
    id: "total",
    category_name: "Total",
    budget: totalBudget,
    sales: totalSales,
    leads: totalLeads,
    appts: totalAppts,
    jobs: totalJobs,
    close_per: totalClosePer,
    avg_ticket: totalAvgTicket,
    cpl: totalCpl,
    cpa: totalCpa,
    cpj: totalCpj,
    roas: totalRoas,
    con_per: totalCon_per
  };
}


export const checkForInfinity = (value) => {
  return isFinite(value) ? value : 0;
};


export function formatInteger(value) {
  const num = parseFloat(value);
  if (isNaN(num)) return "0";

  const roundedNum = Math.round(num);
  return new Intl.NumberFormat('en-US').format(roundedNum);
}

export function formatNumberDecimal(value) {
  const num = parseFloat(value);
  if (isNaN(num)) return "0";

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(num);
}

export const debounce = (func, delay) => {
  let timeoutId;

  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

// const seenCategories = new Set();
// const hasDuplicates = mainArr.some(item => {
//     if (seenCategories.has(item.category_name)) {
//         return true; // Duplicate found
//     }
//     seenCategories.add(item.category_name);
//     return false; // No duplicate
// });


export function getMonthNamesBetween(startDate, endDate) {
  console.log(startDate, endDate, "sadbashdvagd")
  const monthNames = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];

  const start = moment(startDate).startOf('month');
  const end = moment(endDate).endOf('month');
  const monthNamesInRange = [];

  // Loop through the months
  let current = start.clone();
  while (current.isSameOrBefore(end)) {
      monthNamesInRange.push(monthNames[current.month()]);
      current.add(1, 'months');
  }

  return monthNamesInRange;
}

export const predictionCalculator = (goalBudget, goalSales, categorySum, el) => {
  let newBudget =
    checkForInfinity(el?.budget / categorySum?.budget) * goalBudget;
  let newSales =
    checkForInfinity(el?.sales / categorySum?.sales) * goalSales;
  let newRoas = checkForInfinity(newSales / newBudget);
  let newJobs = checkForInfinity(newSales / el?.avg_ticket);
  let newAppts = checkForInfinity((newJobs / el?.close_per) * 100);
  let newLeads = checkForInfinity((newAppts / el?.con_per) * 100);
  let newCpa = checkForInfinity(newBudget / newAppts);
  let newCpj = checkForInfinity(newBudget / newJobs);
  let newCpl = checkForInfinity(newBudget / newLeads);

  return {
    ...el,
    budget: newBudget,
    sales: newSales,
    roas: newRoas,
    jobs: newJobs,
    appts: newAppts,
    leads: newLeads,
    cpa: newCpa,
    cpj: newCpj,
    cpl: newCpl,
  };
};