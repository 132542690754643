// This file will store the login credentials.
import { createSlice } from "@reduxjs/toolkit";

export const ColorsSlice = createSlice({
  name: "selectedColors",
  initialState: {
    themeColors: {
      headerCardsColor: "rgba(255, 255, 255, 0.06)",
      bgMainColor: "rgba(27, 27, 27, 1)",
      buttonColor: "rgba(216, 12, 12, 1)",
      graphFirstStrokeColor: "rgba(37, 208, 82, 1)",
      graphSecondStrokeColor: "rgba(44, 200, 252, 1)",
      textColor: "rgba(0, 0, 0, 1)",
      progressColor: "rgba(44, 200, 252, 1)",
      progressBgColor: "rgba(62, 61, 97, 1)",
      lightText: "rgba(146, 146, 165, 1)",
    },
  },
  //This is login reducers.
  reducers: {
    themeColors: (state, action) => {
      if (action) {
        state.themeColors = action.payload;
      }
    },
  },
});

//This is login actions.

export const { themeColors } = ColorsSlice.actions;
export default ColorsSlice.reducer;
