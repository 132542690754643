import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import PaidAddCategoryModal from "./PaidAddCategoryModal";
import { Button, Input, message, Modal, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { prefilledFilters } from "../../app/Auth/signIn/LogInSlice";
import {
  delete_categaries_api,
  edit_categaries_api,
  Get_categaries_api,
} from "../../services/Collections";
import { useSelector } from "react-redux";
import {
  DeleteNewIcon,
  EditNewicon,
  EditSendNewicon,
} from "../../utils/Icons/SvgIcons";
import { ModalWrapper } from "../dynamicBudgetSheet/Goals/GoalsHistory";
import Header from "../../components/Header";
import LoadingBlock from "../../components/LoadingBlock";
import Watermark from "../../components/Watermark";

function Paidcategory() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);

  const [editingKey, setEditingKey] = useState("");
  const [editedRowData, setEditedRowData] = useState({});
  const [defaultModal, setDefaultModal] = useState(null);

  const isEditing = (record) => record?.id === editingKey;

  const handleEdit = (record) => {
    if (editingKey !== "") {
      handleSave(editingKey);
    }
    setEditingKey(record?.id);
    setEditedRowData({ ...record });
  };

  const handleSave = (key) => {
    if (editedRowData?.paid_search_name?.trim()?.length <= 0) {
      message.error(
        "The category name cannot be left empty. Please fill out this field."
      );
      return;
    }
    const newData = [...categoryList];
    const index = newData.findIndex((item) => item.id === key);

    if (index > -1) {
      handleEditCategory(editedRowData);
      newData[index] = editedRowData;
      setCategoryList(newData);
      setEditingKey("");
      setEditedRowData({});
    }
  };

  const handleInputChange = (e, key, column) => {
    setEditedRowData((prev) => ({
      ...prev,
      [column]: e.target.value,
    }));
  };

  const prefilledFilters = useSelector((state) => state?.lsaPerformance);
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );
  const activeClient =
    prefilledFilters?.selectedClient || prefilledData?.selectedClient;

  const navigat = useNavigate();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleGetCategory = async () => {
    setLoading(true);
    const activeClient =
      prefilledFilters?.selectedClient == null
        ? prefilledData?.selectedClient?.key
        : prefilledFilters?.selectedClient?.key;
    const res = await Get_categaries_api(activeClient);
    if (res?.status == 200) {
      setCategoryList(res?.data);
      setLoading(false);
    } else {
      message.error("Something went wrong!");
      setLoading(false);
    }
  };

  const handleDeleteCategory = async () => {
    setDefaultModal(null);
    const activeClient =
      prefilledFilters?.selectedClient == null
        ? prefilledData?.selectedClient?.key
        : prefilledFilters?.selectedClient?.key;

    let param = new URLSearchParams();

    const data = categoryList?.filter((ele) => ele?.id !== defaultModal);
    setCategoryList(data);

    param.append("category", Number(defaultModal));
    param.append("client", activeClient);

    await delete_categaries_api(activeClient, defaultModal, param);
  };

  const handleEditCategory = async (data) => {
    setLoading(true);
    const activeClient =
      prefilledFilters?.selectedClient == null
        ? prefilledData?.selectedClient?.key
        : prefilledFilters?.selectedClient?.key;

    let param = new URLSearchParams();

    param.append("category", Number(data?.id));
    param.append("client", activeClient);

    let payload = {
      id: data?.id,
      paid_search_name: data?.paid_search_name,
    };

    const res = await edit_categaries_api(
      activeClient,
      data?.id,
      param,
      payload
    );
    if (res?.status == 200) {
      message.success(res?.message || "Category edited successfully");
      setLoading(false);
    } else {
      message.error("Something went wrong!");
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Category Name",
      dataIndex: "paid_search_name",
      key: "paid_search_name",
      align: "left",
      //   width: 100,
      render: (text, record) =>
        isEditing(record) ? (
          <Input
            value={editedRowData.paid_search_name}
            onChange={(e) =>
              handleInputChange(e, record.id, "paid_search_name")
            }
          />
        ) : (
          text
        ),
    },
    {
      title: "Sources",
      dataIndex: "source_type",
      key: "source_type",
      //   width: 100,
    },
    {
      title: "Action",
      dataIndex: "action",
      //   width: 100,
      render: (_, data) => {
        const editable = isEditing(data);
        return (
          <>
            {editable ? (
              <i
                style={{
                  color: "white",
                  marginRight: "10px",
                }}
                onClick={() => handleSave(data?.id)}
              >
                <EditSendNewicon />
              </i>
            ) : (
              <i
                onClick={() => handleEdit(data)}
                style={{ marginRight: "10px" }}
              >
                <EditNewicon />
              </i>
            )}

            <i onClick={() => setDefaultModal(data?.id)}>
              <DeleteNewIcon />
            </i>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    handleGetCategory();
  }, []);

  return (
    <>
      <Header
        title="Paid + LSA Perf. Categories"
        clientInfo={activeClient}
        // openFilter={() => setOpenDrawer(true)}
        isNotification={true}
      />

      <Wrapperpaidcategory>
        <div style={{ display: "flex", gap: "10px", marginBottom: "16px" }}>
          <MdKeyboardArrowLeft
            style={{ height: "25px", width: "25px" }}
            onClick={() => navigat("/private-layout/paid-search")}
          />
          <p className="back-to-paid">Back to Paid + LSA Perf.</p>
        </div>
        <div className="search-addCategory">
          <div>
            <button className="add-category" onClick={showModal}>
              + Add Category
            </button>
          </div>
        </div>

        <div>
          {loading ? (
            <LoadingBlock height={"calc(100vh - 200px)"} size={28} />
          ) : (
            <Table
              prefixCls="custom-ui-table"
              columns={columns}
              dataSource={categoryList}
              pagination={{
                hideOnSinglePage: true,
                showSizeChanger: false,
              }}
              // loading={loading}
            />
          )}
        </div>

        <Modal
          title=""
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Add"
          cancelText="Cancel"
          footer={false}
          destroyOnClose={true}
          prefixCls="paidAddCategory"
        >
          <PaidAddCategoryModal
            handleCancel={handleCancel}
            handleOk={handleOk}
            isModalOpen={isModalOpen}
            handleGetCategory={handleGetCategory}
          />
        </Modal>
        {defaultModal && (
          <Modal
            open={defaultModal !== null}
            footer={false}
            prefixCls="goalHistory"
            closable={false}
          >
            <ModalWrapper>
              <p>Are you sure you want to delete this category ?</p>
              <div className="btnWrapper">
                <button
                  className="cancel"
                  onClick={() => setDefaultModal(null)}
                >
                  No
                </button>
                <button className="submit" onClick={handleDeleteCategory}>
                  Yes
                </button>
              </div>
            </ModalWrapper>
          </Modal>
        )}
      </Wrapperpaidcategory>
      <Watermark style={{paddingRight:"10px"}} background="#f6f9fc"/>
    </>
  );
}

export default Paidcategory;

const Wrapperpaidcategory = styled.div`
  width: 100%;
  height: calc(100vh - 83px);
  padding: 24px 22px 0px 22px;

  .back-to-paid {
    width: 186px;
    height: 16px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
  }
  .search-input {
    width: 300px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: gray;
    padding: 10px;
    border-radius: 10px;
    border: 1px;
    justify: space-between;
    background: var(--Color-Primary-White, #ffffff);
    border: 1px solid #e1e1e1;
  }
  .add-category {
    height: 34px;
    padding: 4px 11px;
    font-weight: 400;
    font-family: "Poppins";
    font-size: 12px;
    display: flex;
    align-items: center;
    color: white;
    border-radius: 6px;
    background: #0f1011;
    border: none;
  }
  .search-addCategory {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    justify-content: end;
    margin-bottom: 18px;
  }
`;
