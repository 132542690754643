/* eslint-disable react/jsx-key */
import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { OpenSideNav } from "../../app/Auth/signIn/SignInSlice";
import SubscriptionCheck from "../SubscriptionCheck";
import { MainAppIcon } from "../../utils/Images/Images";

export const PrivateLayout = () => {
  const [click, setClick] = useState(false);
  const isSideBarOpen = useSelector((state) => state?.LoginSlice?.sideNav);
  const sidebar = useSelector((state) => state?.LoginSlice?.sideNav);

  const dispatch = useDispatch();
  const location = useLocation().pathname;
  const handleClickAway = () => {
    dispatch(OpenSideNav(false));
  };

  return (
    <PrivateLayoutWrapper sidebar={sidebar}>
      <SubscriptionCheck />
      <div>
        <div>
          <section className="outlet-section">
            <div className="outlet-section-inner">
              <Outlet />
            </div>
            {/* {!location?.includes('select-module') && !location?.includes('call-classification') &&
              <PoweredBy>Source: N^Sight
              </PoweredBy>
            }

            {location?.includes('call-classification') &&
              <PoweredBy>Source: CallRail
              </PoweredBy>
            } */}

          </section>
        </div>
      </div>
    </PrivateLayoutWrapper>
  );
};

export const PrivateLayoutWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  transition: all 0.3s;

  .header {
    width: 100%;
    height: 62px;
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .sidebar-section {
    width: 260px;
    height: 100vh;
    background: #0f1011;
    position: fixed;
    z-index: 99999999;
    transition: all 0.5s;
    @media (max-height: 600px) {
      overflow-y: scroll;
    }

    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
    }
    ::-webkit-scrollbar-thumb {
      background: #101010;
      border-radius: 10px;
    }

    ${"" /* @media (max-width: 1440px) {
      width: 200px;
    } */
  }
    @media (max-width: 992px) {
      display: ${({ sidebar }) => !sidebar && "none"};
    }
  }

  .outlet-section {
    width: calc(100% - 0px);
    background: #1a1b55;
    height: 100%;

    .outlet-section-inner {
      // position: relative;
      width: 100%;
      height: 100%;
      background: #f6f9fc;
    }

    @media (max-width: 992px) {
      width: 100%;
      margin: 0;
    }
  }
`;

export const PoweredBy = styled.div`
  width: 100%;
  background: #f3f3f3;
  padding: 0 20px 20px 20px;
  display: flex;
  justify-content: end;
  color: rgb(0 0 0 / 60%);
    font-size: 18px;
        gap: 20px;
    align-items: center;
    
    img {
      opacity:0.6;
    height:32px;
    }
`;
