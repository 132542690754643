import ForgotPassword from "../app/Auth/signIn/ForgotPassword";
import Login from "../app/Auth/signIn/Login";
import { AdminUserPublic } from "../components/AdminUserPublic";
import { AIBookingPublic } from "../components/AIBookingPublic";
import Filter from "../components/Filter";
import SelectModule from "../components/SelectModule";
import SetNewPassForget from "../components/SetNewPassForget";
import { BookingListPublic } from "../module/AIBooking/BookingListPublic";

export const Paths = [
  {
    path: "/",
    component: <Login />,
  },
  {
    path: "/select-module",
    component: <SelectModule />,
  },
  {
    path: "/filter",
    component: <Filter />,
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
  },
  {
    path: "/set-new-password",
    component: <SetNewPassForget />,
  },
  {
    path: "/booking-buddy-performance",
    component: <AIBookingPublic />,
  },
  {
    path: "admin-users",
    component: <AdminUserPublic />,
    sectionName: "settings",
  },
  { path: "/booking", component: <BookingListPublic /> },
];
