import React from "react";
import styled from "styled-components";
import { ModalLoader } from "../../../components/Loader";
import {
  formatToCurrency,
  formatToCurrencyWithDecimal,
} from "../../exceltable/commonFun";

const inputStyle = {
  width: "100%",
  height: "34px",
  borderRadius: "10px",
  border: "1px solid #00000033",
};

export const Summary = ({
  currentData,
  nextData,
  budgetPercentageText,
  setBudgetPercentageText,
  revenueGoalText,
  setRevenueGoalText,
  showModal,
  submitLoading
}) => {
  let { budget, revenue } = currentData[currentData?.length - 1];
  const nextBudget = nextData[nextData?.length - 1]?.budget;
  const nextRevenue = nextData[nextData?.length - 1]?.revenue;
  const revenueIncrease =
    nextRevenue > 0 ? (100 - (revenue / nextRevenue) * 100).toFixed(2) : 0;

  const handleBudgetPercentageChange = (e) => {
    const value = e.target.value;
    const regex = /^(100(\.0{1,2})?|[0-9]{1,2}(\.[0-9]{1,2})?)$/;
    if (value === "" || regex.test(value)) {
      setBudgetPercentageText(value);
    }
  };

  const handleRevenueGoalChange = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/[$,]/g, "");
    const isValidNumber = /^\d{0,12}$/.test(sanitizedValue); // Allow up to 12 digits

    if (isValidNumber) {
      const a = parseFloat(sanitizedValue) || 0;
      setRevenueGoalText(a);
    } else {
      e.target.value = formatToCurrency(revenueGoalText);
    }
  };

  return (
    <SummaryContainer>
      <InfoGrid>
        <InfoSection>
          <InfoRow>
            <Label>Current Budget %:</Label>
            <HighlightValue>
              {Number(((budget / revenue) * 100).toFixed(2)) || 0}%
            </HighlightValue>
          </InfoRow>
          <InfoRow>
            <Label>Current Budget:</Label>
            <HighlightValue>
              {formatToCurrencyWithDecimal(budget)}
            </HighlightValue>
          </InfoRow>
          <InfoRow>
            <Label>Current Revenue:</Label>
            <HighlightValue>
              {formatToCurrencyWithDecimal(revenue)}
            </HighlightValue>
          </InfoRow>
        </InfoSection>

        <InfoSection style={{ display: "flex" }}>
          <section>
            <InfoRow>
              <Label>Budget %:</Label>
              <InputWrapper>
                <Input
                  type="number"
                  value={budgetPercentageText}
                  onChange={handleBudgetPercentageChange}
                  min="0"
                  max="100"
                  step="0.01"
                  aria-label="Budget percentage"
                  style={inputStyle}
                />
                <PercentSign aria-hidden="true">%</PercentSign>
              </InputWrapper>
            </InfoRow>
            <InfoRow>
              <Label>Budget Goal:</Label>
              <HighlightValue>
                {formatToCurrencyWithDecimal(nextBudget)}
              </HighlightValue>
            </InfoRow>
          </section>
          <section>
            <InfoRow>
              <Label>Revenue Goal:</Label>
              <InputWrapper>
                {/* <DollarSign aria-hidden="true">$</DollarSign> */}
                <Input
                  type="text"
                  value={formatToCurrency(revenueGoalText)}
                  onChange={handleRevenueGoalChange}
                  min="0"
                  max="100"
                  step="0.01"
                  aria-label="Budget percentage"
                  style={inputStyle}
                />
              </InputWrapper>
            </InfoRow>
            <InfoRow>
              <Label>Revenue Increase:</Label>
              <HighlightValue>{revenueIncrease}%</HighlightValue>
            </InfoRow>

            <SaveButton >
              <button onClick={showModal} >
                {submitLoading ? (
                  <ModalLoader size={20} color={"#fff"} />
                ) : (
                  "Save changes"
                )}
              </button>
            </SaveButton>
          </section>
        </InfoSection>
      </InfoGrid>
    </SummaryContainer>
  );
};

export const Table = ({ data, title }) => {
  return (
    <table className="budget-table">
      <thead>
        <tr>
          <th>Month</th>
          <th>{title === "Current" ? "Current Budget" : "Budget"}</th>
          <th>{title === "Current" ? "Current Revenue" : "Revenue"}</th>
          <th>Revenue %</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index} className={data?.length - 1 == index && "totals-row"}>
            <td>{item.month}</td>
            <td>{formatToCurrencyWithDecimal(item.budget)}</td>
            <td>{formatToCurrencyWithDecimal(item.revenue)}</td>
            <td>{Number(item.percentage).toFixed(2)}%</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const TableStyle = styled.div`
  width: 100%;

  .App {
    padding: 20px;
  }

  .summary-container {
    margin-bottom: 20px;
  }

  .logo {
    width: 150px;
    height: auto;
  }

  .summary-details {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .right {
      width: 100%;
      display: grid;
      justify-content: right;
    }

    .logoDiv {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .tables-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .budget-table {
    width: 49%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  tr {
    width: 150px;
    height: 78px;
  }
  th {
    font-weight: 300;
  }

  .budget-table th,
  .budget-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: right;
  }

  .budget-table th {
    background-color: #00a1e6;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;
  }

  .budget-table .totals-row {
    background-color: #333;
    color: white;
    font-weight: bold;
  }

  .budget-table td,
  .budget-table th {
    text-align: center;
  }
`;

const SaveButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;

  button {
    margin-top: 8px;
    padding: 6px 12px;
    gap: 10px;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #ffffff;
    background: #00a1e6;
    border: none;
  }
`;

const SummaryContainer = styled.div`
  padding-bottom: 24px;
  max-width: 100%;
  margin: 0 auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
`;

const InfoSection = styled.div`
  width: 100%;
  justify-content: space-between;
  gap: 24px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  section {
    width: 100%;
    justify-content: space-between;
  }
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Label = styled.span`
  color: #495057;
  font-weight: 500;
`;

const Value = styled.span`
  color: #212529;
  font-weight: 600;
`;

const HighlightValue = styled(Value)`
  color: #00a1e6;
`;

const InputWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 120px;
`;

const Input = styled.input`
  width: 100%;
  padding: 4px 8px;
  padding-right: 20px; // Make room for the % sign
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 14px;
  color: #212529;
  text-align: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const PercentSign = styled.span`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: #212529;
  font-size: 14px;
  pointer-events: none; // Ensures the span doesn't interfere with input interactions
`;

const DollarSign = styled.span`
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: #212529;
  font-size: 14px;
  pointer-events: none; // Ensures the span doesn't interfere with input interactions
`;
