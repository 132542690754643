import React, { useCallback, useEffect, useState } from "react";
import { Pagination, Spin, Table } from "antd";
import { useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { SwitchField } from "../../components/SwitchFiled";
import { debounce, srcSortImage } from "../../utils/common/commonFunction";
import { getYelpUsersList, updateYelpUser } from "../../services/Collections";
import styled from "styled-components";
import { AiBookingWrapper } from "../AIBooking/AiBooking";
import YelpFilterModal from "./YelpFilterModal";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 40,
    }}
    spin
  />
);

export function ManageYelpUser({
  filterModal,
  setFilterModal,
  searchValue,
  setUserFilters,
}) {
  const [tableData, setTableData] = useState([]);
  const [sortParam, setSortParam] = useState({
    sortType: "",
    sortBasis: "",
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [extraData, setExtraData] = useState(0);
  const [loader, setLoader] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const [switchLoading, setSwitchLoading] = useState(null);
  // const [filterModal, setFilterModal] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [filterPayload, setFilterPayload] = useState([]);
  const userDetails = useSelector((state) => state?.loginAuth?.data);
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );

  const getSortParam = (sortBasis) => {
    if (sortParam?.sortBasis === sortBasis) {
      const filterObject = {
        sortBasis,
        sortType: sortParam?.sortType === "desc" ? "asc" : "desc",
      };
      setSortParam(filterObject);
    } else {
      const filterObject = {
        sortBasis,
        sortType: "desc",
      };
      setSortParam(filterObject);
    }
  };

  const onChangePagination = (e, limit) => {
    setPage(e);
    setPageSize(limit);
  };

  const handleUpdateYelpUser = async (id, requestPayload, name) => {
    setSwitchLoading({ id, name });
    const index = tableData.findIndex((obj) => obj.id === id);
    tableData[index] = { ...tableData[index], ...requestPayload };

    const res = await updateYelpUser(id, requestPayload);
    if (res?.id) {
      const tempData = [...(tableData ?? [])];
      const index = tempData.findIndex((obj) => obj.id === id);
      tempData[index] = {
        ...res,
        index: pageSize * (page - 1) + (1 + index),
      };
      setTableData(tempData);
      setSwitchLoading(null);
    } else {
      setSwitchLoading(null);
    }
  };

  const handleChangeSwitch = (val, name, data) => {
    const obj = {
      [name]: val,
    };
    handleUpdateYelpUser(data?.id, obj, name);
  };

  const columns = [
    {
      title: "Sr.No",
      dataIndex: "index",
      render: (val) => <p>{val}.</p>,
    },
    {
      title: (
        <TitleWrapper>
          <span>Name</span>
          <img
            style={{
              filter: sortParam?.sortBasis != "name" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("name", sortParam)}
            onClick={() => getSortParam("name")}
          />
        </TitleWrapper>
      ),
      dataIndex: "name",
    },
    {
      title: (
        <TitleWrapper>
          <span>Business Id</span>
          <img
            style={{
              filter: sortParam?.sortBasis != "business_id" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("business_id", sortParam)}
            onClick={() => getSortParam("business_id")}
          />
        </TitleWrapper>
      ),
      dataIndex: "business_id",
    },
    // {
    //   title: (
    //     <TitleWrapper>
    //       <span>Id</span>
    //       <img
    //         style={{
    //           filter: sortParam?.sortBasis != "lead_id" && "invert(1)",
    //         }}
    //         alt=""
    //         className="sortIcons"
    //         src={srcSortImage("lead_id", sortParam)}
    //         onClick={() => getSortParam("lead_id")}
    //       />
    //     </TitleWrapper>
    //   ),
    //   dataIndex: "lead_id",
    // },
    {
      title: (
        <TitleWrapper>
          <span>Stop Status</span>
          <img
            style={{
              filter: sortParam?.sortBasis != "stop_status" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("stop_status", sortParam)}
            onClick={() => getSortParam("stop_status")}
          />
        </TitleWrapper>
      ),
      dataIndex: "stop_status",
      render: (val, data) => (
        <div>
          {console.log(val, "valval")}
          <SwitchField
            value={val}
            data={data}
            handleChange={handleChangeSwitch}
            switchLoading={switchLoading}
            name="stop_status"
          />
        </div>
      ),
    },
    {
      title: (
        <TitleWrapper>
          <span>Stop Followup</span>
          <img
            style={{
              filter: sortParam?.sortBasis !== "stop_followup" && "invert(1)",
            }}
            alt=""
            className="sortIcons"
            src={srcSortImage("stop_followup", sortParam)}
            onClick={() => getSortParam("stop_followup")}
          />
        </TitleWrapper>
      ),
      dataIndex: "stop_followup",
      render: (val, data) => (
        <div>
          <SwitchField
            value={val}
            data={data}
            handleChange={handleChangeSwitch}
            switchLoading={switchLoading}
            name="stop_followup"
          />
        </div>
      ),
    },
  ];

  const handleYelpListing = async (search) => {
    setLoader(true);
    const params = new URLSearchParams();
    params.append("page_size", pageSize);
    params.append("page", page);
    sortParam?.sortBasis && params.append("sort_by", sortParam?.sortBasis);
    sortParam?.sortType && params.append("order_by", sortParam?.sortType);
    if (search !== null || search != "") {
      if (search === undefined) {
        if (searchWord) params.append("search", searchWord);
      } else {
        search && params.append("search", search);
      }
    }
    // Apply filter for company users
    let companyUserPayload = [];
    if (userDetails?.company_id) {
      companyUserPayload = [
        { field: "company_id", value: userDetails?.company_id },
      ];
    }
    const res = await getYelpUsersList(
      params,
      companyUserPayload?.length > 0
        ? companyUserPayload
        : filterPayload?.length > 0 && filterPayload
    );
    setExtraData(res?.total_count);
    if (res?.status === 200) {
      const array = res?.data?.map((el, idx) => ({
        ...el,
        index: pageSize * (page - 1) + (1 + idx),
      }));
      setTableData(array);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const handleSearchListing = useCallback(debounce(handleYelpListing), [
    filterData,
    pageSize,
  ]);

  const handleSearch = (value) => {
    setSearchWord(value);
    setPage(1);
    // setPageSize(10);
    handleSearchListing(value);
  };

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue]);

  useEffect(() => {
    handleYelpListing();
    setUserFilters(filterData);
  }, [page, pageSize, sortParam, filterData]);

  return (
    <AiBookingWrapper padding={"0"} selectedThemeColors={selectedThemeColors}>
      <TableWrapper>
        {filterModal && (
          <YelpFilterModal
            editDetails={filterData}
            handleClose={() => setFilterModal(false)}
            open={filterModal}
            setCurrentPage={setPage}
            setFilterData={setFilterData}
            setFilterPayload={setFilterPayload}
          />
        )}
        {/* <div className="create-btn">
          <div>
            <form autoComplete="off" onSubmit={(e) => e?.preventDefault()}>
              <input
                style={{ height: "33px" }}
                type="text"
                placeholder="Search"
                onChange={(e) => {
                  e?.preventDefault();
                  handleSearch(e?.target?.value);
                }}
                autoComplete="off"
              />
            </form>
          </div>
        </div> */}
        <div style={{ position: "relative", background: "#f6f9fc" }}>
          {loader ? (
            <div
              style={{
                width: "100%",
                height: "450px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin indicator={antIcon} />
            </div>
          ) : (
            <Table
              prefixCls="campaignTable"
              columns={columns}
              dataSource={tableData ?? []}
              pagination={false}
            />
          )}
          {!loader && (
            <div style={{ marginTop: "10px" }}>
              <Pagination
                hideOnSinglePage
                page={page}
                limit={pageSize}
                current={page}
                onChange={onChangePagination}
                total={extraData}
                defaultPageSize={pageSize}
                className="pagination"
                showSizeChanger={true}
              />
            </div>
          )}
        </div>
      </TableWrapper>
    </AiBookingWrapper>
  );
}

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 10px;
  img {
    cursor: pointer;
    height: 20px;
  }
`;

export const TableWrapper = styled.div`
  /* background: #f2f5fd; */
  ${"" /* width: 100%; */}
  ${"" /* min-height: calc(100vh - 82px); */}
  min-height: 100%;
  height: calc(100vh - 172px);

  /* padding: 30px; */
  /* padding-top: 0px; */

  .ant-select-selection-item {
    color: #000 !important;
  }

  .create-btn {
    display: flex;
    justify-content: end;
    padding: 0 0 20px 0;
    ${"" /* margin-bottom: 10px; */}
    gap: 7px;
    background: #f6f9fc;

    input {
      height: 35px;
      width: 100%;
      background: #ffff;
      border-radius: 7px;
      border: 1px solid #cecece;
      padding: 0 10px;
      color: #000;
      font-family: "Poppins", Arial, sans-serif;

      ::-webkit-input-placeholder {
        color: #000 !important;
      }

      &:focus {
        outline: none;
        border: 1px solid #000;
      }

      &::placeholder {
        color: #cecece !important;
      }
    }

    .btn {
      button {
        background-color: rgb(36, 36, 36);
        color: #fff;
        font-family: "Poppins", Arial, sans-serif;
        border-radius: 7px;
        font-weight: 500;
        font-size: 13px;
        border: none;
        padding: 0 10px;
        height: 43px;
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }
`;
