import React, { useMemo, useState } from "react";
import Header from "../../components/Header";
import { useSelector } from "react-redux";
import { QualityAssWrapper } from "./qualityAssuranceStyle";
import TabComponent from "../../components/newComponent/TabComponent";
import ListingPhoneNumber from "./ListingPhoneNumber";
import Report from "./Report";

export default function QualityAss() {
  const client = useSelector(
    (state) => state?.loginAuth?.prefilledFilters?.selectedClient
  );
  const selectedClient = useSelector(
    (state) => state?.qualityAssurance?.selectedClient
  );

  const activeClient = selectedClient ? selectedClient : client;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [activeTab, setActiveTab] = useState("Report");
  const [paidLoading, setPaidLoading] = useState(false);
  const [phoneListing, setPhoneListing] = useState([]);
  const [savedData, setSavedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesize, setPageSize] = useState(100);

  const handleQualitytab = (index) => {
    if (index === 0 && !paidLoading) {
      setActiveTab("Report");
    } else if (index === 1 && !paidLoading) {
      setActiveTab("List all Phone Numbers");
    }
  };

  const tabs = ["Report", "List all Phone Numbers"];

  const activeTabFunc = useMemo(() => {
    let activeIndex = 0;
    tabs?.forEach((ele, index) => {
      if (ele?.toLowerCase()?.includes(activeTab)) {
        activeIndex = index;
      }
    });

    return activeIndex;
  }, [activeTab]);

  return (
    <>
      <QualityAssWrapper height={"100%"}>
        <Header
          clientInfo={activeClient}
          title="Quality Assurance"
          openFilter={() => setOpenDrawer(true)}
          isFilter={true}
          isNotification={true}
        />
        <main className="main-wrapper">
          <TabComponent
            index={activeTabFunc}
            tabs={tabs}
            selectedIndex={handleQualitytab}
            // disableBtnsOnLoading={paidLoading}
          />
        </main>

        {activeTab === "Report" ? (
          <Report openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
        ) : (
          <ListingPhoneNumber
            setPhoneListing={setPhoneListing}
            phoneListing={phoneListing}
            setSavedData={setSavedData}
            savedData={savedData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pagesize={pagesize}
            setPageSize={setPageSize}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
          />
        )}
      </QualityAssWrapper>
    </>
  );
}
