import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexBar3 = ({ data, indicatorData }) => {
  const [chartOptions, setChartOptions] = useState({});
  const [chartData, setChartData] = useState([]);

  const findMaxNumber = (arr) => {
    let max = -Infinity;
    for (let i = 0; i < arr?.length; i++) {
      if (typeof arr[i] === "number") max = Math.max(max, arr[i]);
    }
    return max;
  };



  useEffect(() => {
    if (data && indicatorData) {
      const firstMax = findMaxNumber(data[0]?.data);
      const compareMax = findMaxNumber(data[1]?.data);
      const resultMax = Math.max(firstMax, compareMax);

      const columnWidth = data.length === 2 ?
        indicatorData.current.inbound_calls < 999 || indicatorData.compare.inbound_calls < 999 ?
          "50%" : "75%" : "15%";

      const strokeWidth = data.length === 2 ?
        indicatorData.current.inbound_calls < 999 || indicatorData.compare.inbound_calls < 999 ?
          12 : 22 : 2;

      const fillColors = data.length === 2 ?
        ["rgba(216, 12, 12, 1)", "#25D052"] : ["rgba(216, 12, 12, 1)"];

      setChartOptions({
        chart: { type: "bar", toolbar: { show: false } },
        plotOptions: { bar: { horizontal: false, columnWidth, endingShape: "rounded", dataLabels: { position: "top" } } },
        dataLabels: { enabled: true, offsetY: -20, style: { fontSize: "12px", colors: ["#0F1011"] } },
        legend: {
          show: true, fontSize: "12px", fontWeight: 300, labels: { colors: "black" }, position: "bottom",
          horizontalAlign: "center", markers: {
            width: 19, height: 19, strokeWidth: 0, radius: 19,
            strokeColor: "#0F1011", fillColors: ["#F85062", "#D45BFF"], offsetX: 0, offsetY: 0
          }
        },
        yaxis: {
          labels: {
            style: { colors: "#0F1011", fontSize: "12px", fontFamily: "Inter, Arial, sans-serif", fontWeight: 500 },
            formatter: (val) => Math.round(val)
          }, max: resultMax > 0 ? Math.ceil(resultMax) + Math.ceil(resultMax / 2) : 5,
          min: 0, tickAmount: 4
        },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        xaxis: {
          labels: {
            show: true,
            rotate: 0,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: true,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: "#9292A5",
              fontSize: "12px",
              fontFamily: "Inter, Arial, sans-serif",
              fontWeight: 500,
            },
            offsetX: 0,
            offsetY: 0
          },
          categories: ["FORMS", "INBOUND", "BOOKED", "CUSTOMERS"]
        },
        grid: { show: true, borderColor: "rgba(208, 208, 208, 0.4)", yaxis: { lines: { show: true } } },
        fill: { colors: fillColors, opacity: 1 },
        tooltip: { y: { formatter: (val) => val } },
      });

      setChartData(data);
    }
  }, [data, indicatorData]);

  return (
    <div className="chart-container">
      <ReactApexChart options={chartOptions} series={chartData} type="bar" height={"100%"} />
    </div>
  );
};

export default ApexBar3;
