import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { LoadingOutlined, SendOutlined } from "@ant-design/icons";
import { Spin, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import useWindowWidth from "../../customHook/GetWindowWidth";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getConversationDetails,
  getMessages,
  gettingCountOfTab,
  messagingDetails,
  messagingReadStatus,
  sendingMessage,
  updateUserStatus,
} from "../../services/Collections";
import {
  BackSVGIcon,
  LeftBackIcon,
  NotificationAlert,
  SenderIcon,
  ThreeDotsIcon,
} from "../../utils/Icons/SvgIcons";
import { useInfiniteScroll } from "../../customHook/InfiniteScroll";
import {
  debounce as customDebounce,
  formatUSPhoneNumber,
  timeAgo,
} from "../../utils/common/commonFunction";
import {
  AvatarBoy,
  AvatarGamer,
  AvatarMan,
  AvatarMan2,
  AvatarUser,
  AvatarWoman,
  chat,
  people,
  AvatarAssistant,
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4,
  Avatar5,
  Avatar6,
  Avatar7,
  Avatar8,
  Avatar9,
  Avatar10,
  Avatar11,
  Avatar12,
  Avatar13,
} from "../../utils/Images/Images";
import { updateMessageType, updateSelectedChat } from "./AiBookingSlice";
import moment from "moment";
import { SwitchField } from "../../components/SwitchFiled";
import ClickAwayListener from "react-click-away-listener";
import LoadingBlock from "../../components/LoadingBlock";
import { Input } from "antd";
import { DeleteModal as AlertModal } from "../../components/DeleteModal";
import { toast } from "react-toastify";
import TabComponent from "../../components/newComponent/TabComponent";
import axios from "axios";
import { SearchComponent } from "../../components/SearchComponent";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import socket from "../../app/socket/socketConnection";
import { debounce } from "lodash";

dayjs.extend(utc);
dayjs.extend(timezone);
const { TextArea } = Input;

const avatarArray = [
  AvatarBoy,
  AvatarGamer,
  AvatarWoman,
  AvatarMan,
  AvatarMan2,
  AvatarUser,
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4,
  Avatar5,
  Avatar6,
  Avatar7,
  Avatar8,
  Avatar9,
  Avatar10,
  Avatar11,
  Avatar12,
  Avatar13,
];

const tabIndicatorEnum = {
  widget: "Chat Widget",
  sms: "SMS Message",
  lsa: "LSA Message",
  yelp: "Yelp",
};

function AIBookingConversation({ cancelToken }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [messagesList, setMessagesList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchWord, setSearchWord] = useState("");
  const [responsiveChat, setResponsiveChat] = useState("user");
  const messageType = useSelector((state) => state?.aiBooking?.messageType);
  const selectedChat = useSelector((state) => state?.aiBooking?.selectedChat);
  const [paginationLoader, setPaginationLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [showActions, setShowActions] = useState(false);
  const [switchLoading, setSwitchLoading] = useState(null);
  const [message, setMessage] = useState("");
  const [trackSelectedChat, setTrackSelectedChat] = useState(false);
  const [isIndicatorTab, setIsIndicatorTab] = useState([]);
  const [isIndicatorMessage, setIsIndicatorMessage] = useState([]);

  const [loadMore] = useInfiniteScroll({
    elementId: "scrollable-container",
    height: window.innerHeight,
  });
  const [width] = useWindowWidth();
  // const searchRef = useRef("");
  const bottomRef = useRef(null);
  const navigate = useNavigate();
  const selectedCompany = useSelector(
    (state) => state?.aiBooking?.selectedCompany?.value
  );
  const AiStartDate = useSelector((state) => state?.aiBooking?.startDate);
  const AiEndDate = useSelector((state) => state?.aiBooking?.endDate);
  const activeTab = useSelector((state) => state?.aiBooking?.activeTab);

  var enddate = new Date();
  var startdate = new Date(new Date().setDate(enddate.getDate() - 30));
  const location = useLocation().pathname;

  const handleUpdateActions = (selectedChat) => {
    if (selectedChat) {
      if (messageType === "yelp") {
        return selectedChat?.yelp_user;
      } else if (messageType === "lsa") {
        return selectedChat?.lsa_user;
      } else if (messageType === "sms") {
        return selectedChat?.sms_user;
      } else if (messageType === "chatwidget") {
        return selectedChat;
      }
    }
  };

  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleMessageType = (el) => {
    dispatch(updateMessageType(el));
    dispatch(updateSelectedChat(null));
    setMessagesList([]);
    setTrackSelectedChat(!trackSelectedChat);
    setPage(1);
    setSearchWord("");
    if (messageType !== el) {
      setResponsiveChat("user");
      setMessagesList([]);
    }
  };

  const handleList = async (search, pageNumber, showLoader = true) => {
    console.log(cancelToken?.current, "cancel check");

    if (cancelToken.current) {
      cancelToken.current.cancel("Request cancelled by user");
    }
    cancelToken.current = axios.CancelToken.source();

    try {
      const currentPage = pageNumber || page;
      if (currentPage === 1) {
        setLoading(showLoader);
        setPaginationLoader(false);
      } else {
        setPaginationLoader(true);
      }

      const params = new URLSearchParams();
      params.append("page", currentPage);
      params.append("page_size", 20);
      params.append("sort_by", "modified_at");
      params.append("order_by", "desc");
      params.append("company_id", selectedCompany);
      params.append(
        "start_time",
        moment(AiStartDate ?? startdate).format("YYYY-MM-DD")
      );
      params.append(
        "end_time",
        moment(AiEndDate ?? enddate).format("YYYY-MM-DD")
      );

      if (search) {
        params.append("search", search);
      } else if (searchWord) {
        params.append("search", searchWord);
      }

      const res = await getMessages(
        messageType,
        params,
        [{ field: "company_id", value: selectedCompany }],
        { cancelToken: cancelToken.current.token }
      );
      if (res?.status === 200) {
        let formattedArray = [];
        if (messageType === "chatwidget") {
          formattedArray = res?.data?.data?.map((el, idx) => ({
            ...el,
            name: el?.title,
            displayName: el?.lead_name ? el?.lead_name : "Customer",
            phone: el?.lead_phone ? el?.lead_phone : "+1(XXX) XXX-XXXX",
            lastMessage:
              el?.content?.length > 0
                ? el?.content[el?.content?.length - 1]?.content
                : "No message",
            date: timeAgo(el?.content[el?.content?.length - 1]?.time),
            avatar: avatarArray[idx % 19],
          }));
        }

        if (messageType === "sms") {
          formattedArray = res?.data?.data?.map((el, idx) => ({
            ...el,
            name: el?.sms_user?.lead_first_name
              ? el?.sms_user?.lead_first_name +
                " " +
                el?.sms_user?.lead_last_name
              : "Customer",
            displayName: el?.sms_user?.lead_first_name
              ? el?.sms_user?.lead_first_name +
                " " +
                el?.sms_user?.lead_last_name
              : "Customer",
            phone: el?.sms_user?.lead_phone
              ? el?.sms_user?.lead_phone
              : "+1(XXX) XXX-XXXX",
            lastMessage:
              el?.content?.length > 0
                ? el?.content[el?.content?.length - 1]?.content
                : "No message",
                date: timeAgo(el?.content[el?.content?.length - 1]?.time),
            avatar: avatarArray[idx % 19],
          }));
        }

        if (messageType === "lsa") {
          formattedArray = res?.data?.data?.map((el, idx) => ({
            ...el,
            name: el?.lsa_user?.lead_name
              ? el?.lsa_user?.lead_name
              : "Customer",
            displayName: el?.lsa_user?.lead_name
              ? el?.lsa_user?.lead_name
              : "Customer",
            phone: el?.lsa_user?.lead_phone
              ? el?.lsa_user?.lead_phone
              : "+1(XXX) XXX-XXXX",

            lastMessage:
              el?.content?.length > 0
                ? el?.content[el?.content?.length - 1]?.content
                : "No message",
                date: timeAgo(el?.content[el?.content?.length - 1]?.time),
            avatar: avatarArray[idx % 19],
          }));
        }

        if (messageType === "yelp") {
          formattedArray = res?.data?.data?.map((el, idx) => ({
            ...el,
            name: el?.yelp_user?.name ? el?.yelp_user?.name : "Customer",
            displayName: el?.yelp_user?.name ? el?.yelp_user?.name : "Customer",
            phone: el?.yelp_user?.lead_phone
              ? el?.yelp_user?.lead_phone
              : "+1(XXX) XXX-XXXX",
            lastMessage:
              el?.content?.length > 0
                ? el?.content[el?.content?.length - 1]?.content
                : "No message",
                date: timeAgo(el?.content[el?.content?.length - 1]?.time),
            avatar: avatarArray[idx % 19],
          }));
        }

        let isUnreadIDs = res?.data?.data?.map(
          (el, idx) => !el?.is_read && el?.id
        );
        setIsIndicatorMessage((prev) => {
          const newIDs = isUnreadIDs.filter((id) => !prev.includes(id));
          return [...prev, ...newIDs];
        });

        if (currentPage === 1) {
          setMessagesList(formattedArray);
          setLoading(false);
        } else {
          setMessagesList((prevMessages) => [
            ...prevMessages,
            ...(formattedArray ?? []),
          ]);
          setPaginationLoader(false);
        }

        setTotalCount(res?.data?.total_count);
      } else {
        if (currentPage === 1) {
          setLoading(false);
        } else {
          setPaginationLoader(false);
        }
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        const currentPage = pageNumber || page;
        console.log("Request cancelled:", error.message, currentPage);
      } else {
        console.error("Request failed:", error);
        setLoading(false);
        setPaginationLoader(false);
      }
    }
  };

  const handleUpdateYelpUser = async (id, requestPayload, name, val) => {
    try {
      setSwitchLoading({ id, name });
      const res = await updateUserStatus(messageType, id, requestPayload);
      if (res?.id) {
        let object = { ...selectedChat };
        switch (messageType) {
          case "chatwidget":
            object = {
              ...object,
              [name]: val,
            };
            setMessagesList((prev) =>
              prev?.map((el) => (el?.id == id ? { ...object } : { ...el }))
            );
            break;

          case "yelp":
            object.yelp_user = {
              ...object.yelp_user,
              [name]: val,
            };
            setMessagesList((prev) =>
              prev?.map((el) =>
                el?.yelp_user_id == id ? { ...object } : { ...el }
              )
            );
            break;
          case "sms":
            object.sms_user = {
              ...object.sms_user,
              [name]: val,
            };
            setMessagesList((prev) =>
              prev?.map((el) =>
                el?.sms_user_id == id ? { ...object } : { ...el }
              )
            );
            break;
          case "lsa":
            object.lsa_user = {
              ...object.lsa_user,
              [name]: val,
            };
            setMessagesList((prev) =>
              prev?.map((el) =>
                el?.lsa_user_id == id ? { ...object } : { ...el }
              )
            );
            break;
          default:
            break;
        }

        dispatch(updateSelectedChat(object));

        // setMessagesList([]);
        // handleList(null, 1);
        setSwitchLoading(null);
      } else {
        setSwitchLoading(null);
      }
    } catch (error) {
      setSwitchLoading(null);
      console.error("Error updating user:", error);
      // Handle error case if needed
    }
  };

  const [sendMessageLoading, setSendMessageLoading] = useState(false);
  const sendMessage = async () => {
    setSendMessageLoading(false);
    setAlertMessageOpen(false);

    let type = messageType;
    let messageid = selectedChat?.id;
    let payload = {
      message: message,
    };

    let updatedMessage = {
      role: "assistant",
      time: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
      confirm: true,
      content: message,
    };
    let allMessages = selectedChat?.content;
    let newChatZone = {
      ...selectedChat,
      lastMessage: message,
      content: [...allMessages, updatedMessage],
    };
    setMessagesList((prev) =>
      prev?.map((el) =>
        el?.id == selectedChat.id ? { ...newChatZone } : { ...el }
      )
    );
    dispatch(updateSelectedChat(newChatZone));
    setMessage("");

    await sendingMessage(type, messageid, payload); // type,messageid, payload

    // if (res.status === 200) {

    // } else {
    //   toast.error(
    //     res?.error ||
    //       res?.response?.message?.error ||
    //       res?.response?.message ||
    //       "Something went worng!"
    //   );
    //   setSendMessageLoading(false);
    // }
  };

  const updateChatAutomatically = async () => {
    let type = messageType;
    let messageid = selectedChat?.id;
    let payload = [
      {
        field: "company_id",
        value: selectedCompany,
      },
    ];

    let res = await messagingDetails(type, messageid, payload); // type,messageid, payload
    if (
      res?.data?.content?.length > 0 &&
      selectedChat?.content?.length !== res?.data?.content?.length
    ) {
      let updatedChat = {
        ...selectedChat,
        content: res?.data?.content,
        modified_at: res?.data?.modified_at,
        lastMessage:
          res?.data?.content?.length > 0
            ? res?.data?.content[res?.data?.content?.length - 1]?.content
            : "No message",
      };
      dispatch(updateSelectedChat(updatedChat));
    }

    // if (res.status === 200) {
    //   let str = {
    //     role: "user",
    //     time: moment().format("YYYY-MM-DD HH:MM:SS"),
    //     content: `Hey Today is ${moment().format(
    //       "DD MMM YYYY"
    //     )} (THIS MESSAGE IS A DUMMY MESSAGE FOR TESTING)`,
    //   };
    //   let response = res?.data?.content;
    //   response?.push(str);
    //   dispatch(updateSelectedChat(res?.data));
    // }
  };

  const TextMessage = ({ data }) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: data
            .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
            .replace(/\n/g, "<br />"),
        }}
      />
    );
  };

  const handleChangeSwitch = (val, name, data) => {
    const obj = {
      [name]: val,
    };
    console.log(val, name, data, obj, "handleChangeSwitch");
    if (switchLoading == null) {
      handleUpdateYelpUser(data?.id, obj, name, val);
    }
  };

  const handleSearchListing = useCallback(customDebounce(handleList), [
    messageType,
    selectedCompany,
    AiEndDate,
    AiStartDate,
  ]);

  const handleSearch = (value) => {
    setSearchWord(value);
    setResponsiveChat("user");
    handleSearchListing(value, 1);
  };

  const getByTitle = (el, smsType) => {
    if (messageType === "chatwidget") {
      if (smsType) {
        return "Customer";
      }
      return el?.title;
    }
    if (messageType === "sms") {
      const title = `${el?.sms_user?.lead_phone}(${el?.sms_user?.lead_first_name} ${el?.sms_user?.lead_last_name})`;
      if (smsType) {
        return !el?.sms_user?.lead_first_name && !el?.sms_user?.lead_last_name
          ? el?.sms_user?.lead_phone
          : `${el?.sms_user?.lead_first_name}  ${el?.sms_user?.lead_last_name}`;
      }
      return title;
    }
    if (messageType === "yelp") {
      return el?.yelp_user?.name;
    }
    if (messageType === "lsa") {
      return el?.lsa_user?.lead_phone;
    }
  };

  const getMessageContent = (d) => {
    const f = [...d].reverse();

    return f;
  };

  // const handleSingleChat = async (type, id) => {
  //   let payload = [
  //     {
  //       field: "company_id",
  //       value: selectedCompany,
  //     },
  //   ];
  //   let res = await getConversationDetails(type, id, payload);
  //   if (res?.status === 200) {
  //     return res?.data;
  //   } else {
  //     return null;
  //     console.log(res?.data);
  //   }
  // };

  const tabs = [
    "Chat Widget",
    "SMS Message",
    "LSA Message",
    "Yelp",
    "Bookings",
  ];

  const handleAction = (val) => {
    if (val === 0 && messageType !== "chatwidget") {
      handleMessageType("chatwidget");
      let updatedIndicator = isIndicatorTab?.filter(
        (item) => item !== "Chat Widget"
      );
      setIsIndicatorTab(updatedIndicator);
    } else if (val === 1 && messageType !== "sms") {
      handleMessageType("sms");
      let updatedIndicator = isIndicatorTab?.filter(
        (item) => item !== "SMS Message"
      );
      setIsIndicatorTab(updatedIndicator);
    } else if (val === 2 && messageType !== "lsa") {
      handleMessageType("lsa");
      let updatedIndicator = isIndicatorTab?.filter(
        (item) => item !== "LSA Message"
      );
      setIsIndicatorTab(updatedIndicator);
    } else if (val === 3 && messageType !== "yelp") {
      handleMessageType("yelp");
      let updatedIndicator = isIndicatorTab?.filter((item) => item !== "Yelp");
      setIsIndicatorTab(updatedIndicator);
    } else if (val === 4) {
      location?.includes("private-layout")
        ? navigate("/private-layout/booking")
        : navigate("/booking");
    }
  };

  const changedStatusRead = async (messageid) => {
    let payload = { status: true };
    let type = messageType === "chatwidget" ? "widget" : messageType;
    await messagingReadStatus(messageid, type, payload);
  };

  const removeIndicatorMessage = (messageid) => {
    if (isIndicatorMessage?.includes(messageid)) {
      let filtered = isIndicatorMessage?.filter((el) => el !== messageid);
      setIsIndicatorMessage(filtered);
    }
    let tab = messageType === "chatwidget" ? "widget" : messageType;
    manageTabCount(tab, "decrease", messageid);
    changedStatusRead(messageid);
  };

  const [tabCount, setTabCount] = useState({
    widget: [],
    sms: [],
    lsa: [],
    "custom-sms": [],
    yelp: [],
    facebook: [],
  });

  const manageTabCount = (tab, type, messageid) => {
    console.log(tab, type, messageid, "tab, type, messageid", tabCount);
    if (tabCount[tab] !== undefined) {
      // Increase tab count
      if (type === "increase" && !tabCount[tab]?.includes(messageid)) {
        tabCount[tab]?.push(messageid);
      }
      // Decrease tab count but ensure it doesn't go below 0
      else if (type === "decrease" && tabCount[tab]?.includes(messageid)) {
        tabCount[tab] = tabCount[tab]?.filter((el) => el !== messageid);
      }
      console.log(tabCount, "tabCount in");
      setTabCount(tabCount);
    }
    for (const key in tabCount) {
      if (tabCount[key]?.length === 0) {
        setIsIndicatorTab((prev) =>
          prev?.filter((el) => el !== tabIndicatorEnum[key])
        );
      } else {
        setIsIndicatorTab((prev) => [...prev, tabIndicatorEnum[key]]);
      }
    }
  };

  console.log(tabCount, "tabCount out");

  const isUnreadMessageTab = async () => {
    let params = new URLSearchParams();
    params.append("company_id", selectedCompany);
    params.append(
      "start_time",
      moment(AiStartDate ?? startdate).format("YYYY-MM-DD")
    );
    params.append(
      "end_time",
      moment(AiEndDate ?? enddate).format("YYYY-MM-DD")
    );
    let res = await gettingCountOfTab(params?.toString());
    if (res?.status === 200) {
      let allTabCount = res?.data;
      setTabCount(allTabCount);
      for (const key in allTabCount) {
        if (allTabCount[key]?.length > 0) {
          setIsIndicatorTab((prev) => [...prev, tabIndicatorEnum[key]]);

          // if (messageType === 'chatwidget') {
          //   setIsIndicatorTab((prev) => [...prev, tabIndicatorEnum[key]]?.filter((el) => el !== 'Chat Widget'));
          // }
          // if (messageType === 'sms') {
          //   setIsIndicatorTab((prev) => [...prev, tabIndicatorEnum[key]]?.filter((el) => el !== 'SMS Message'));
          // }
          // if (messageType === 'lsa') {
          //   setIsIndicatorTab((prev) => [...prev, tabIndicatorEnum[key]]?.filter((el) => el !== 'LSA Message'));
          // }
          // if (messageType === 'yelp') {
          //   setIsIndicatorTab((prev) => [...prev, tabIndicatorEnum[key]]?.filter((el) => el !== 'Yelp'));
          // }
        }
      }
    }
  };

  const appendMessage = (selectedChat, message) => {
    console.log(selectedChat, message, "appendMessage");
    if (message?.message?.length > 0) {
      let updatedContent = [...selectedChat?.content, ...message?.message];
      let updatedChat = {
        ...selectedChat,
        content: updatedContent,
        modified_at: message?.message[message?.message?.length - 1]?.time,
        lastMessage:
          message?.message?.length > 0
            ? message?.message[message?.message?.length - 1]?.content
            : "No message",
      };
      setMessagesList((prev) => {
        // let findChat = prev?.find((el) => el?.id === message?.id);
        let filtered = prev?.filter((el) => el?.id !== message?.id);
        return [
          {
            ...updatedChat,
            // ...findChat,
            // lastMessage:
            //   message?.message[message?.message?.length - 1]?.content,
          },
          ...filtered,
        ];
      });
      dispatch(updateSelectedChat(updatedChat));
    }
  };

  const soundNotificationTigger = () => {
    const audio = new Audio(
      "https://audio-previews.elements.envatousercontent.com/files/472198703/preview.mp3"
    );
    audio.play();
  };

  useEffect(() => {
    selectedCompany && isUnreadMessageTab();
  }, [selectedCompany, messageType]);

  useEffect(() => {
    socket.on("admin", (message) => {
      console.log(message, "messagesocket", selectedChat);
      if (!socket.connected) {
        console.warn("Socket.IO is not connected. Message handling skipped.");
      }
      if (message?.company_id === selectedCompany) {
        console.log(
          message?.company_id,
          selectedCompany,
          "checkingCompanyID",
          selectedChat
        );
        // MANAGE TAB COUNTS
        manageTabCount(message?.type, "increase", message?.id);
        // ADDED A SOUND OF NOTIFICATION
        soundNotificationTigger();

        // CHATWIDEGT
        if (
          (message?.type === "widget" && messageType === "chatwidget") ||
          message?.type === messageType
        ) {
          console.log("chattype if", messageType, message?.type);
          if (selectedChat && selectedChat?.id === message?.id) {
            // remove the api that we are calling 30second
            appendMessage(selectedChat, message);
            setIsIndicatorMessage([message?.id]);
          } else {
            console.log(searchWord, "searchword");
            searchWord
              ? handleList(searchWord, 1, false)
              : handleList("", 1, false);
            if (
              isIndicatorMessage?.length > 0 &&
              !isIndicatorMessage?.every((el) => el === message?.id)
            ) {
              setIsIndicatorMessage((prev) => [...prev, message?.id]);
            } else {
              setIsIndicatorMessage([message?.id]);
            }
          }
        } else {
          console.log("chattype else", messageType, message?.type);
          setIsIndicatorTab((prev) => [
            ...prev,
            tabIndicatorEnum[message?.type],
          ]);
          setIsIndicatorMessage((prev) => [...prev, message?.id]);
        }
      }
    });

    return () => {
      socket.off("admin");
    };
  }, [selectedCompany, selectedChat, messageType, searchWord]);

  useEffect(() => {
    setIsIndicatorMessage([]);
    setIsIndicatorTab([]);
    dispatch(updateSelectedChat(null));
  }, [selectedCompany]);

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      if (searchWord) {
        handleSearch(searchWord);
      } else {
        setPage(1);
        dispatch(updateSelectedChat(null));
        handleList("", 1);
      }
    }, 500); // debounce time (500ms)

    debouncedSearch();
    return () => debouncedSearch.cancel();
  }, [messageType, selectedCompany, AiStartDate, AiEndDate, searchWord]);

  useEffect(() => {
    if (loadMore && messagesList?.length < totalCount && !paginationLoader && !loading) {
      setPage(page + 1);
      handleList("", page + 1);
    }
  }, [loadMore]);

  // useEffect(() => {
  //   let intervalRef = null;
  //   if (selectedChat && Object.keys(selectedChat)?.length && messageType) {
  //     intervalRef = setInterval(() => {
  //       updateChatAutomatically();
  //     }, 10000);
  //   }
  //   return () => clearInterval(intervalRef);
  // }, [trackSelectedChat]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get("type");
    if (type && type !== messageType) {
      dispatch(updateMessageType(type));
    }
  }, []);

  useEffect(() => {
    if (width <= 650) {
      dispatch(updateSelectedChat(null));
    }
  }, []);

  const findActiveTab = useMemo(() => {
    let tabTypes = ["chatwidget", "sms", "lsa", "yelp"];
    return tabTypes?.indexOf(messageType);
  }, [messageType]);

  const [alertMessageOpen, setAlertMessageOpen] = useState(false);

  return (
    <ConversationBox>
      <audio controls hidden>
        <source
          src="http://webaudioapi.com/samples/audio-tag/chrono.mp3"
          type="audio"
        />
      </audio>
      {alertMessageOpen && (
        <AlertModal
          handleClose={() => setAlertMessageOpen(false)}
          open={alertMessageOpen}
          title=""
          description=""
          button="Confirm to send"
          btnColor="rgb(44, 175, 254)"
          handleSubmit={sendMessage}
          isLoading={sendMessageLoading}
          width={255}
        />
      )}

      <TabComponent
        index={findActiveTab}
        tabs={tabs}
        selectedIndex={handleAction}
        disableBtnsOnLoading={switchLoading}
        isIndicator={isIndicatorTab}
      />

      <ChatSection
        id="scrollable-container"
        responsiveChat={responsiveChat}
        selectedChat={selectedChat}
      >
        <div className="sidenav">
          <SearchComponent width="100%" onChange={(e) => setSearchWord(e)} />
          <SpaceGap size={12} />
          {loading ? (
            <LoadingBlock height={"100%"} size={28} />
          ) : (
            <>
              {messagesList?.length > 0 ? (
                messagesList?.map((el, idx) => (
                  <div
                    key={idx}
                    className={`chat-users ${
                      el?.id === selectedChat?.id && "active"
                    } `}
                    onClick={() => {
                      dispatch(updateSelectedChat(el));
                      setResponsiveChat("playground");
                      scrollToBottom();
                      setTrackSelectedChat(!trackSelectedChat);
                      // setIsIndicatorMessage((prev) =>
                      //   prev?.filter((e) => e !== el?.id)
                      // );
                      removeIndicatorMessage(el?.id);
                    }}
                  >
                    {isIndicatorMessage?.includes(el?.id) && (
                      <IndicatorSpan>
                        <NotificationAlert
                          background={"#00A1E6"}
                          dotColor={"#ffffff"}
                        />
                      </IndicatorSpan>
                    )}
                    <img src={el?.avatar} alt="" className="sidenavAvatar" />
                    <SideMessageContainer>
                      <DarkLabel fontSize="16">{el?.displayName}</DarkLabel>
                      <LightLabel>
                        {formatUSPhoneNumber(el?.phone) ?? "+1(XXX) XXX-XXXX"}
                      </LightLabel>
                      <LightLabel height={"30px"}>{el?.lastMessage}</LightLabel>
                      <TimeDateLabel>{el?.date}</TimeDateLabel>

                      <Tags>
                        <BookedLabel
                          bgColor={el?.booked ? "#f9860433" : "#9047ff33"}
                          color={el?.booked ? "#f98604" : "#9047ff"}
                        >
                          {el?.booked ? "Booked" : "UnBooked"}
                        </BookedLabel>

                        <BookedLabel bgColor="#26ae6033" color="#26ae60">
                          {messageType == "chatwidget"
                            ? "Chat"
                            : messageType == "sms"
                            ? "SMS"
                            : messageType == "lsa"
                            ? "LSA"
                            : messageType == "yelp" && "Yelp"}
                        </BookedLabel>
                      </Tags>
                    </SideMessageContainer>
                  </div>
                ))
              ) : (
                <div className="no-chats">
                  <img src={people} alt="" />
                  <h2>No Users</h2>
                </div>
              )}
            </>
          )}
          {paginationLoader && (
            <div className="pagination-loader">
              <LoadingBox>
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 24,
                        color: "#296eff",
                      }}
                      spin
                    />
                  }
                />
              </LoadingBox>
            </div>
          )}
        </div>

        <div className="chat-playground" id="bottom" ref={bottomRef}>
          <div className="inner-inbox">
            {selectedChat == null ? (
              <div className="no-chats">
                <img src={chat} alt="" />
                <h2>No Chat Selected</h2>
              </div>
            ) : (
              <>
                {selectedChat?.content?.length > 0 ? (
                  getMessageContent(selectedChat?.content)?.map(
                    (el, idx, arr) => (
                      <section
                        style={{
                          flexDirection:
                            el?.role === "user" ? "row" : "row-reverse",
                        }}
                        className="messages-users"
                        key={idx}
                        onClick={() => removeIndicatorMessage(selectedChat?.id)}
                      >
                        <div className="sidenavAvatar">
                          <img
                            src={
                              el?.role === "user"
                                ? selectedChat?.avatar
                                : AvatarAssistant
                            }
                            alt=""
                          />
                        </div>

                        <div
                          style={{
                            alignItems: el?.role === "user" ? "start" : "end",
                          }}
                          className="user-title"
                        >
                          <div className="user-name">
                            {el?.role === "user"
                              ? selectedChat?.displayName
                              : "Assistant"}
                          </div>
                          <MessageTextContainer
                            key={idx}
                            isCustomer={el?.role === "user"}
                          >
                            <div className="content">
                              <TextMessage data={el?.content} />
                            </div>

                            <div
                              className="time"
                              style={{
                                textAlign: "end",
                                float: "right",
                                fontSize: 12,
                                color:
                                  el?.role === "user" ? "#978f8f" : "#666668",
                                marginTop: "5px",
                              }}
                            >
                              {timeAgo(el?.time) === "Today"
                                ? dayjs
                                    .utc(el?.time + "Z")
                                    // .local()
                                    .format("HH:mm A") // Treat as UTC
                                : dayjs
                                    .utc(el?.time + "Z")
                                    // .local()
                                    .format("DD MMM, YYYY HH:mm A")}
                            </div>
                          </MessageTextContainer>
                        </div>
                      </section>
                    )
                  )
                ) : (
                  <div className="no-chats">
                    <img src={chat} alt="" />
                    <h2>No Chats</h2>
                  </div>
                )}
              </>
            )}
          </div>

          {selectedChat && (
            <div className="sender-section">
              <TextArea
                prefixCls="chat-textarea"
                value={message}
                onClick={() => removeIndicatorMessage(selectedChat?.id)}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type a message"
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    setAlertMessageOpen(true);
                  }
                }}
              />
              <i
                style={{ background: message ? "#00a1e6" : "rgb(125 125 125)" }}
                onClick={() => message && setAlertMessageOpen(true)}
              >
                <SenderIcon />
              </i>
            </div>
          )}
        </div>

        {selectedChat && (
          <ChatInfoSection>
            <header>Contact Detail</header>
            <div className="info">
              <img src={selectedChat?.avatar} alt="profile" />
              <DarkLabel width="100%" fontSize="18" textAlign="center">
                {selectedChat?.displayName}
              </DarkLabel>
              <LightLabel width="100%" textAlign="center">
                {formatUSPhoneNumber(selectedChat?.phone) ?? "+1(XXX) XXX-XXXX"}
              </LightLabel>
            </div>

            {(messageType === "chatwidget" ||
              messageType === "sms" ||
              messageType === "yelp" ||
              messageType === "lsa") && (
              <div className="actions">
                <div className="content">
                  <div className="sub-actions">
                    <DarkLabel width="100%" fontSize="12" textAlign="start">
                      Follow up
                    </DarkLabel>
                    <div className="switch-button">
                      <SwitchField
                        value={
                          !handleUpdateActions(selectedChat)?.stop_followup
                        }
                        data={handleUpdateActions(selectedChat)}
                        handleChange={handleChangeSwitch}
                        switchLoading={switchLoading}
                        name="stop_followup"
                        disable={messageType === "chatwidget"}
                      />
                    </div>
                  </div>
                  <div className="sub-actions">
                    <DarkLabel width="100%" fontSize="12" textAlign="start">
                      BB AI
                    </DarkLabel>
                    <div className="switch-button">
                      <SwitchField
                        value={!handleUpdateActions(selectedChat)?.stop_status}
                        data={handleUpdateActions(selectedChat)}
                        handleChange={handleChangeSwitch}
                        switchLoading={switchLoading}
                        name="stop_status"
                        disable={messageType === "chatwidget"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </ChatInfoSection>
        )}
      </ChatSection>
    </ConversationBox>
  );
}

export default AIBookingConversation;

const ChatInfoSection = styled.div`
  width: 246px;
  height: calc(100vh - 171px);
  padding: 12px 16px;
  gap: 10px;
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 0px 9px 13px 0px #8b8b8b1a;
  transition: all 0.3s ease;

  header {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color: #0f1011;
    margin-bottom: 4px;
  }

  .info {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;

    img {
      width: 126px;
      height: 124px;
    }
  }

  .actions {
    gap: 16px;
    padding: 10px;
    background: #f3f5f7;
    border-radius: 16px;
    position: relative;

    .content {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  .sub-actions {
    display: flex;
    justify-content: space-between;
  }
`;

const IndicatorSpan = styled.span`
  position: absolute;
  top: 28px;
  right: 19px;
`;

const ConversationBox = styled.div`
  width: 100%;
  padding: 20px;
  height: calc(100vh - 56px);

  .search-box {
    display: flex;
    gap: 7px;
    background: #f6f9fc;

    button {
      padding: 8px 20px;
    }
  }

  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 650px) {
      flex-direction: column-reverse;
      align-items: start;
      gap: 10px;
    }

    form {
      @media (max-width: 445px) {
        width: 100%;
      }
    }

    input {
      height: 43px;
      width: 100%;
      background: #fff;
      border-radius: 7px;
      border: 1px solid #cecece;
      padding: 0 10px;
      font-family: "Poppins", Arial, sans-serif;
      font-size: "14px";
      border-style: none;
      outline: none;

      ::-webkit-input-placeholder {
        color: #000 !important;
      }

      &:focus {
        outline: none;
        border: 1px solid #000;
      }

      &::placeholder {
        color: #cecece !important;
      }

      @media (max-width: 445px) {
        width: 100%;
      }
    }
  }

  .tabs {
    display: flex;
    width: 412px;
    gap: 10px;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 18px;

    @media (max-width: 445px) {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    button {
      padding: 6px 8px;
      gap: 8px;
      border-radius: 4px;
      border: 1px solid #000000;
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      color: #242424;
      white-space: nowrap;
    }
    .chat-active {
      background-color: #ed0101;
      color: #fff;
      border: 1px solid transparent;
      border-radius: 4px;
    }
  }
`;

const ChatSection = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  gap: 16px;
  position: relative;
  background: #edf0f5;
  padding: 10px;
  border-radius: 15px;
  transition: all 0.3s ease;

  .messages-users {
    display: flex;
    gap: 5px;
  }

  .user-title {
    display: flex;
    flex-direction: column;
    /* gap: 5px; */
    width: 100%;

    p {
      margin: 0;
      font-size: 14px;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      color: #2c2c2c;
    }

    .user-name {
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-transform: capitalize;
      height: 40px;
      display: flex;
      align-items: center;
    }
  }

  .smsUserList {
    width: 75%;
    overflow: hidden;
  }

  .pagination-loader {
    width: 100%;
    height: 70px;
  }

  .sidenav {
    width: 310px;
    height: calc(100vh - 171px);
    overflow-y: scroll;
    border-radius: 12px;
    padding: 12px;
    background: #ffffff;

    &::-webkit-scrollbar {
      width: 0px;
    }
    &::-webkit-scrollbar-thumb {
      background: #c4bdbd;
      border-radius: 10px;
    }

    @media (max-width: 650px) {
      display: ${({ responsiveChat }) =>
        responsiveChat === "playground" && "none"};
      width: ${({ responsiveChat }) => responsiveChat === "user" && "100%"};
      height: calc(100vh - 275px);
    }

    .chat-users {
      position: relative;
      font-family: "Poppins", Arial, sans-serif;
      font-size: 16px;
      border: none;
      font-weight: 600;
      cursor: pointer;
      display: flex;
      gap: 10px;
      align-items: start;
      background: transparent;
      color: #0f1011;
      padding: 12px;
      border-radius: 12px;

      &:hover {
        background-color: #e2edee82;
      }

      p {
        margin: 0;
      }
    }

    .selectedBooked {
      width: 41px;
      margin-right: 6px;
    }

    .no-chats {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h2 {
        font-size: 20px;
        margin-top: 5px;
        color: #2c2c2c99;
        font-family: "Poppins", Arial, sans-serif;
      }
    }
    .active {
      background-color: #e2edee;
      color: #0f1011;
      border-radius: 12px;

      &:hover {
        background-color: #e2edee;
      }
      .smsUserList {
        width: 100%;
      }
    }

    .sidenavAvatar {
      width: 42px;
      height: 42px;
      border: 1px solid #fff;
      border-radius: 50%;
    }
  }

  .chat-playground {
    transition: all 0.3s ease;
    width: ${({ selectedChat }) =>
      selectedChat ? "calc(100% - 556px)" : "calc(100% - 310px)"};
    height: calc(100vh - 171px);
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;
    border-radius: 12px;

    @media (max-width: 650px) {
      display: ${({ responsiveChat }) => responsiveChat === "user" && "none"};
      width: ${({ responsiveChat }) =>
        responsiveChat === "playground" && "100%"};
      height: calc(100vh - 275px);
    }

    .header {
      position: sticky;
      top: 0;
      height: 40px !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      border-bottom: 1px solid #d9d9d9;

      .three-dot-icon {
        position: relative;

        .icon {
          cursor: pointer;
        }

        .content {
          width: 260px;
          background: white;
          padding: 4px;
          position: absolute;
          z-index: 9999 !important;
          top: 30px;
          left: -218px;
          border-radius: 9px;
          border: 1px solid #cecece;
          box-shadow: 0px 2px 8px rgba(61, 107, 192, 0.28);

          .actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
          }

          .switch-button {
            button {
              border-radius: 21px;
              border: unset;
              padding: unset;
              height: unset;
            }
          }
        }
      }

      .header-content {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      .chat-users {
        position: relative;
        color: #fff;
        font-family: "Poppins", Arial, sans-serif;
        font-size: 16px;
        border: none;
        font-weight: 600;
        height: 63px;
        cursor: pointer;
        display: flex;
        gap: 10px;
        align-items: center;
        padding-left: 5px;
      }
    }

    .message {
      background: #f0f0f0;
      padding: 10px;
      min-width: 25%;
      border: 1px solid #fff;
      font-size: 14px;
      font-weight: 400;
      font-style: normal;
      border-radius: 2px;
      color: #2c2c2c;
      max-width: 70%;
      font-family: "Poppins", Arial, sans-serif;
      &.left {
        margin-right: auto;
      }
      &.right {
        margin-left: auto;
      }
    }

    .inner-inbox {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column-reverse;
      gap: 10px;
      overflow-y: scroll;
      overflow-x: hidden;
      border-radius: 25px;

      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background: #edf0f5;
        border-radius: 10px;
      }
    }

    .no-chats {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #ffffff;

      h2 {
        font-size: 20px;
        margin-top: 5px;
        color: #2c2c2c99;
        font-family: "Poppins", Arial, sans-serif;
      }
    }

    .sidenavAvatar {
      width: 42px;
      height: 42px;
      border: 1px solid #fff;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .sender-section {
    width: 100%;
    height: auto;
    margin-top: 16px;
    background: #ffffff;
    display: flex;
    align-items: end;
    padding: 12px 16px;
    gap: 10px;
    border-radius: 15px;
    box-shadow: 0px 9px 13px 0px #8b8b8b1a;

    i {
      width: 44px;
      height: 44px;
      padding: 15px 14px;
      gap: 10px;
      border-radius: 10px;
      background: #00a1e6;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        width: 28px;
        height: 28px;
      }
    }
  }
`;

export const LoadingBox = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
`;

const SpaceGap = styled.div`
  width: 100%;
  height: ${({ size }) => `${size}px`};
`;

const DarkLabel = styled.h4`
  width: ${({ width }) => width ?? "135px"};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "14px")};
  font-weight: 500;
  line-height: 20px;
  text-align: ${({ textAlign }) => textAlign ?? "left"};
  color: #0f1011;
  text-transform: capitalize;
  margin: 0;
`;

const LightLabel = styled.p`
  width: ${({ width }) => width ?? "215px"};
  height: ${({ height }) => height ?? "auto"};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: ${({ textAlign }) => textAlign ?? "left"};
  color: #7e7e7e;
  text-transform: capitalize;
  margin: 0;
`;

const TimeDateLabel = styled.p`
  position: absolute;
  top: 0;
  right: 10px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #7e7e7e;
  text-transform: capitalize;
  margin: 0;
`;

const Tags = styled.div`
  width: 100%;
  display: flex;
  gap: 4px;
`;

const BookedLabel = styled.span`
  // position: absolute;
  bottom: 0;
  right: 0;
  width: fit-content;
  // height: 20px;
  padding: 2px 8px;
  gap: 8px;
  border-radius: 4px;
  background: ${({ bgColor }) => (bgColor ? bgColor : "#00a1e6")};
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  color: ${({ color }) => (color ? color : "#333333")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SideMessageContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2px;

  span {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
`;

const MessageTextContainer = styled.div`
  // isCustomer

  min-width: 25%;
  max-width: 70%;
  font-family: "Poppins", Arial, sans-serif;
  padding: 10px 16px;
  gap: 0px;
  border-radius: ${({ isCustomer }) =>
    isCustomer ? "0px 12px 12px 12px" : "12px 0px 12px 12px"};
  background: ${({ isCustomer }) => (isCustomer ? "#ffffff" : "#D0D3E3")};
  box-shadow: ${({ isCustomer }) => isCustomer && "0px 1px 3px 0px #00000026"};
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
  color: #3d3d3d;
`;
