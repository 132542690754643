import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { toast } from "react-toastify";
import {
  HideEye,
  LoginBg,
  MainAppIcon,
  MainLogo,
} from "../utils/Images/Images";
import { LoginEye } from "../utils/Icons/SvgIcons";
import { resetpassword, setNewPassword } from "../services/Collections";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";

const SetNewPassForget = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState("password");
  const [showPasswordConfirm, setShowPasswordConfirm] = useState("password");
  const [showPassword, setShowPassword] = useState("password");
  const [loading, setLoading] = useState(false);
  //   const dispatch = useDispatch();
  const token = useLocation()?.search;
  let apiToken = token?.split("=")?.[1];
  console.log(apiToken, "apiToken");

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*[\]{}()?"\\,><':;|_~`=+-])[a-zA-Z\d!@#$%^&*[\]{}()?"\\,><':;|_~`=+-]{8,99}$/,
        "Must contain at least 8 Characters, Example - Abcd@123"
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match"),
  });

  const showPassToggle = () => {
    if (showPassword == "password") {
      setShowPassword("text");
      return;
    }
    setShowPassword("password");
  };
  const showPassToggleNew = () => {
    if (showPasswordNew == "password") {
      setShowPasswordNew("text");
      return;
    }
    setShowPasswordNew("password");
  };
  const showPassToggleConfirm = () => {
    if (showPasswordConfirm == "password") {
      setShowPasswordConfirm("text");
      return;
    }
    setShowPasswordConfirm("password");
  };

  const handleChangePassword = async (e) => {
    setLoader(true);
    if (apiToken) {
      let req = {
        token: apiToken,
        new_password: e?.confirmPassword,
      };
      let res = await setNewPassword(req);
      if (res.status === 200) {
        setLoader(false);
        navigate("/");
        toast.success("Password changed successfully");
      } else {
        setLoader(false);
        toast.error(
          res.response.data.message ||
            res.error ||
            res.message ||
            "Something went wrong"
        );
      }
    }
  };

  return (
    <LoginWrapper>
      <div className="flex-div">
        <div className="gradient-bg">
          <img src={LoginBg} alt="" />
        </div>
        <div className="inner-part">
          <div className="main-box">
            <div className="logo-div">
              <img src={MainAppIcon} alt="" />
              <h4>New password must be different than previous password</h4>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleChangePassword}
            >
              <Form>
                <div className="content">
                  <div>
                    <label>
                      <strong>New Password</strong>
                    </label>
                    <div className="password-div">
                      <Field
                        font1="font1"
                        type={showPasswordNew}
                        name="password"
                        placeholder="Enter your password"
                        //   onChange={(e) =>
                        //     setAuth({
                        //       email: auth.email,
                        //       password: e.target.value,
                        //     })
                        //   }
                      />
                      {showPasswordNew == "password" ? (
                        <div onClick={showPassToggleNew}>
                          <img src={HideEye} alt="" />
                        </div>
                      ) : (
                        <div onClick={showPassToggleNew}>
                          <LoginEye />
                        </div>
                      )}
                      {/* )} */}
                    </div>
                    <span
                      style={{
                        color: "red",
                        background: "transparent",
                        border: "none",
                        margin: "0px",
                      }}
                    >
                      <ErrorMessage name="password" />
                    </span>
                  </div>
                  <div>
                    <label>
                      <strong>Confirm New Password</strong>
                    </label>
                    <div className="password-div">
                      <Field
                        font1="font1"
                        type={showPasswordConfirm}
                        name="confirmPassword"
                        placeholder="Enter your password"
                        //   onChange={(e) =>
                        //     setAuth({
                        //       email: auth.email,
                        //       password: e.target.value,
                        //     })
                        //   }
                      />
                      {showPasswordConfirm == "password" ? (
                        <div onClick={showPassToggleConfirm}>
                          <img src={HideEye} alt="" />
                        </div>
                      ) : (
                        <div onClick={showPassToggleConfirm}>
                          <LoginEye />
                        </div>
                      )}
                    </div>
                    <span
                      style={{
                        color: "red",
                        background: "transparent",
                        border: "none",
                      }}
                    >
                      <ErrorMessage name="confirmPassword" />
                    </span>
                  </div>

                  {/* {!loading ? ( */}
                  <button style={{ fontFamily: "Poppins" }}>
                    {loader ? "Loading..." : "Submit"}
                  </button>
                  {/* ) : (
                      <LoadingButton>Loading...</LoadingButton>
                    )} */}
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </LoginWrapper>
  );
};

export default SetNewPassForget;

const LoginWrapper = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  width: 100%;
  height: 100vh;
  background: #05031a;
  ${
    "" /* display: flex;
  justify-content: center;
  align-items: center; */
  }

  .flex-div {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .gradient-bg {
      position: relative;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .inner-part {
      background: rgb(255, 255, 255);
      width: 40%;
      ${"" /* border-radius: 8px; */}
      position: absolute;

      .main-box {
        padding: 50px;
        .logo-div {
          text-align: center;
          img {
            width: 300px;
            margin-bottom: 1rem;
          }
          h4 {
            margin-bottom: 1.5rem;
            color: rgb(36, 36, 36);
            font-size: 15px;
            font-family: "Poppins";
          }
        }
        .content {
          div {
            label {
              color: rgb(36, 36, 36);
              margin-bottom: 0.25rem;
              display: block;
              font-family: "Poppins";
              font-size: 16px;
            }
            .password-div {
              display: flex;
              position: relative;
              margin: 0px !important;
              input {
                background-color: #ffff;
                border: 1px solid #2a2833;
                color: #000;
                position: relative;
                padding: 0rem 0.75rem;
                width: 100%;
                ${"" /* height: 41px; */}
                height: 56px;
                border-radius: 12px;

                font-family: "Poppins" !important;
                font-size: 14px;

                :focus {
                  outline: none;
                }
                ::placeholder {
                  color: rgba(0, 0, 0, 0.39) !important;
                }
              }
              svg {
                position: absolute;
                right: 12px;
                top: 20px;
                cursor: pointer;
              }
              img {
                position: absolute;
                right: 12px;
                top: 15px;
                cursor: pointer;
              }
            }
            input {
              background-color: #ffff;
              border: 1px solid #2a2833;
              color: #000;
              position: relative;
              padding: 0rem 0.75rem;
              width: 100%;
              ${"" /* height: 41px; */}
              height: 56px;
              border-radius: 12px;

              font-family: "Poppins" !important;
              font-size: 14px;

              :focus {
                outline: none;
              }
              ::placeholder {
                color: rgba(0, 0, 0, 0.39) !important;
              }
            }
          }
          .forgt-password {
            h1 {
              color: #00a1e6;
              text-align: right;
              font-family: "Poppins";
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              letter-spacing: 0.2px;
              cursor: pointer;
              text-decoration-line: underline;
            }
          }
          button {
            font-weight: 500;
            height: 50px;
            cursor: pointer;
            border-radius: 0.75rem;
            width: 100%;
            background-color: #d80c0c;
            border: 1px solid #d80c0c;
            font-size: 14px;
            color: #fff;
            text-align: center;
            margin-top: 10px;

            ${
              "" /* :hover {
              background-color: #282348;
            } */
            }
          }
          span {
            font-weight: 700;
            height: 50px;
            cursor: pointer;
            border-radius: 0.75rem;
            width: 100%;
            background-color: #363062;
            font-size: 14px;
            border: 1px solid #363062;
            color: #fff;
            text-align: center;
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .inner-part {
      width: 95% !important;
    }
  }
`;

export const LoadingButton = styled.span`
  width: 100%;
  font-weight: 700;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0.75rem;
  width: 100%;
  background-color: #363062;
  border: 1px solid #363062;
  color: #fff;
  text-align: center;
`;
