import React, { useEffect, useRef, useState } from "react";
import {
  AvatarBoy,
  AvatarGamer,
  AvatarMan,
  AvatarMan2,
  AvatarUser,
  AvatarWoman,
  chat,
} from "../../utils/Images/Images";
import styled from "styled-components";
import {
  getConversationDetails,
  updateUserStatus,
} from "../../services/Collections";
import { BackSVGIcon, ThreeDotsIcon } from "../../utils/Icons/SvgIcons";
import ClickAwayListener from "react-click-away-listener";
import { SwitchField } from "../../components/SwitchFiled";
import { updateSelectedChat } from "./AiBookingSlice";
import { toast } from "react-toastify";
import { ModalLoader } from "../../components/Loader";
import LoadingBlock from "../../components/LoadingBlock";

const avatarArray = [
  AvatarBoy,
  AvatarGamer,
  AvatarWoman,
  AvatarMan,
  AvatarMan2,
  AvatarUser,
];

export const ChatComponent = ({
  messageType,
  messageId,
  companyId,
  onClose,
}) => {
  const [selectedChat, setSelectedChat] = useState(null);
  const bottomRef = useRef(null);
  const [showActions, setShowActions] = useState(false);
  const [switchLoading, setSwitchLoading] = useState(null);
  const [loading, setLoading] = useState(true);

  const TextMessage = ({ data }) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: data
            .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
            .replace(/\n/g, "<br />"),
        }}
      />
    );
  };

  const getByTitle = (el, smsType) => {
    if (messageType === "chatwidget") {
      if (smsType) {
        return "Customer";
      }
      return el?.title;
    }
    if (messageType === "sms") {
      const title = `${el?.sms_user?.lead_phone}(${el?.sms_user?.lead_first_name} ${el?.sms_user?.lead_last_name})`;
      if (smsType) {
        return !el?.sms_user?.lead_first_name && !el?.sms_user?.lead_last_name
          ? el?.sms_user?.lead_phone
          : `${el?.sms_user?.lead_first_name}  ${el?.sms_user?.lead_last_name}`;
      }
      return title;
    }
    if (messageType === "yelp") {
      return el?.yelp_user?.name;
    }
    if (messageType === "lsa") {
      return el?.lsa_user?.lead_phone;
    }
  };

  const getMessageContent = (d) => {
    const f = [...d].reverse();

    return f;
  };

  const handleUpdateActions = (selectedChat) => {
    if (selectedChat) {
      if (messageType === "yelp") {
        return selectedChat?.yelp_user;
      } else if (messageType === "lsa") {
        return selectedChat?.lsa_user;
      } else if (messageType === "sms") {
        return selectedChat?.sms_user;
      }
    }
  };

  const handleChatDetails = async () => {
    setLoading(true);
    let payload = [
      {
        field: "company_id",
        value: companyId,
      },
    ];
    let res = await getConversationDetails(messageType, messageId, payload);
    if (res?.status === 200) {
      setSelectedChat(res?.data);
      setLoading(false);
    } else {
      // toast.error(
      //   res?.response?.data?.message ||
      //     res?.message ||
      //     res?.error ||
      //     "Something went wrong"
      // );
      setLoading(false);
    }
  };

  const handleUpdateYelpUser = async (id, requestPayload, name, val) => {
    try {
      setSwitchLoading({ id, name });
      const res = await updateUserStatus(messageType, id, requestPayload);
      if (res?.id) {
        let object = { ...selectedChat };
        switch (messageType) {
          case "yelp":
            object.yelp_user = {
              ...object.yelp_user,
              [name]: val,
            };
            break;
          case "sms":
            object.sms_user = {
              ...object.sms_user,
              [name]: val,
            };
            break;
          case "lsa":
            object.lsa_user = {
              ...object.lsa_user,
              [name]: val,
            };
            break;
          default:
            break;
        }

        setSelectedChat(object);
        handleList(null, 1);
        setSwitchLoading(null);
      } else {
        setSwitchLoading(null);
      }
    } catch (error) {
      setSwitchLoading(null);
      console.error("Error updating user:", error);
      // Handle error case if needed
    }
  };

  const handleChangeSwitch = (val, name, data) => {
    const obj = {
      [name]: val,
    };
    if (switchLoading === null) {
      handleUpdateYelpUser(data?.id, obj, name, val);
    }
  };

  useEffect(() => {
    handleChatDetails();
  }, []);

  return (
    // <ChatComponentBox>
    <ChatComponentBox id="bottom" ref={bottomRef}>
      {selectedChat && (
        <div className="header">
          <div className="header-content">
            <div>
              <span
                style={{
                  cursor: "pointer",
                  display: "flex",
                  gap: "5px",
                  fontFamily: "Poppins",
                  color: "white",
                }}
                onClick={onClose}
              >
                <BackSVGIcon />
              </span>
            </div>
            <div style={{ height: "40px" }} className="chat-users">
              <img src={avatarArray[1]} alt="" />
              <p
                style={{
                  fontSize: "14px",
                  color: "#2c2c2c",
                  fontWeight: 500,
                  margin: 0,
                }}
              >
                {messageType === "sms"
                  ? getByTitle(selectedChat, true)
                  : getByTitle(selectedChat, false)}
              </p>
            </div>
          </div>
          {(messageType === "sms" ||
            messageType === "yelp" ||
            messageType === "lsa") && (
            <div className="three-dot-icon">
              <span className="icon" onClick={() => setShowActions(true)}>
                <ThreeDotsIcon />
              </span>

              {showActions && (
                <ClickAwayListener
                  onClickAway={() => {
                    setShowActions(false);
                  }}
                >
                  <div className="content">
                    <div className="actions">
                      <label>Phone:</label>
                      <div className="switch-button">
                        <p style={{ margin: "0px", paddingRight: "7px" }}>
                          {handleUpdateActions(selectedChat)
                            ?.formatted_lead_phone ?? "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="actions">
                      <label>Follow up</label>
                      <div className="switch-button" style={{ width: "60px" }}>
                        <SwitchField
                          value={
                            handleUpdateActions(selectedChat)?.stop_followup
                          }
                          data={handleUpdateActions(selectedChat)}
                          handleChange={handleChangeSwitch}
                          switchLoading={switchLoading}
                          name="stop_followup"
                        />
                      </div>
                    </div>
                    <div className="actions">
                      <label>
                       BB AI
                      </label>
                      <div className="switch-button" style={{ width: "60px" }}>
                        <SwitchField
                          value={handleUpdateActions(selectedChat)?.stop_status}
                          data={handleUpdateActions(selectedChat)}
                          handleChange={handleChangeSwitch}
                          switchLoading={switchLoading}
                          name="stop_status"
                        />
                      </div>
                    </div>
                  </div>
                </ClickAwayListener>
              )}
            </div>
          )}
        </div>
      )}
      <div className="inner-inbox">
        {selectedChat == null ? (
          loading ? (
            <div className="no-chats" style={{ gap: "15px" }}>
             <LoadingBlock height={"fit-content"} size={28} />
              <h2>Loading...</h2>
            </div>
          ) : (
            <div className="no-chats">
              <img src={chat} alt="" />
              <h2>No Chat Selected</h2>
            </div>
          )
        ) : (
          <>
            {selectedChat?.content?.length > 0 ? (
              getMessageContent(selectedChat?.content)?.map((el, idx) => (
                <section
                  style={{
                    flexDirection: el?.role === "user" ? "row" : "row-reverse",
                  }}
                  className="messages-users"
                  key={idx}
                >
                  <div>
                    <img
                      src={
                        el?.role === "user" ? avatarArray[1] : avatarArray[3]
                      }
                      alt=""
                    />
                  </div>

                  <div
                    style={{
                      alignItems: el?.role === "user" ? "start" : "end",
                    }}
                    className="user-title"
                  >
                    {el?.role === "user" ? (
                      <div>
                        <p>
                          {messageType === "sms" || messageType === "chatwidget"
                            ? getByTitle(selectedChat, true)
                            : getByTitle(selectedChat, false)}
                        </p>
                      </div>
                    ) : (
                      <div className="right-avatar">
                        <p>Assistant</p>
                      </div>
                    )}

                    <div
                      key={idx}
                      className={`message ${
                        el?.role === "user" ? "left" : "right"
                      } `}
                      style={{
                        alignItems: el?.role === "user" && "start",
                        background: el?.role !== "user" && "rgb(44, 44, 44)",
                        color: el?.role !== "user" && "#fff",
                      }}
                    >
                      <div className="content">
                        <TextMessage data={el?.content} />
                      </div>

                      <div
                        className="time"
                        style={{
                          textAlign: "end",
                          float: "right",
                          fontSize: 12,
                          color: el?.role !== "user" ? "#fff" : "#2c2c2c",
                          marginTop: "5px",
                        }}
                      >
                        {el?.time}
                      </div>
                    </div>
                  </div>
                </section>
              ))
            ) : (
              <div className="no-chats">
                <img src={chat} alt="" />
                <h2>No Chats</h2>
              </div>
            )}
          </>
        )}
      </div>
    </ChatComponentBox>
    // </ChatComponentBox>
  );
};

const ChatComponentBox = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
  background: #fff;

  @media (max-width: 650px) {
    display: ${({ responsiveChat }) => responsiveChat === "user" && "none"};
    width: ${({ responsiveChat }) => responsiveChat === "playground" && "100%"};
    height: calc(100vh - 275px);
  }

  .messages-users {
    display: flex;
    gap: 5px;

    img {
    width:42px;
    }
  }

  .user-title {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;

    p {
      font-size: 14px;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      color: #2c2c2c;
      margin: 0;
    }
  }

  .header {
    position: sticky;
    top: 0;
    height: 40px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${"" /* display: none; */}

    .three-dot-icon {
      position: relative;

      .icon {
        cursor: pointer;
      }

      .content {
        width: 250px;
        background: white;
        padding: 4px;
        position: absolute;
        z-index: 9999 !important;
        top: 30px;
        left: -218px;
        border-radius: 9px;
        border: 1px solid #cecece;
        box-shadow: 0px 2px 8px rgba(61, 107, 192, 0.28);

        .actions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
        }

        .switch-button {
          button {
            ${"" /* background: #242424; */}
            border-radius: 21px;
            border: unset;
            padding: unset;
            height: unset;
          }
        }
      }
    }

    .header-content {
      display: flex;
      align-items: center;
      gap: 5px;
    }
    .chat-users {
      color: #fff;
      font-family: "Poppins", Arial, sans-serif;
      font-size: 16px;
      border: none;
      font-weight: 600;
      height: 63px;
      cursor: pointer;
      display: flex;
      gap: 10px;
      align-items: center;
      padding-left: 20px;

      img {
      width:42px;
      }
    }
  }

  .message {
    background: #fff;
    padding: 10px;
    min-width: 25%;
    border: 1px solid #fff;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    border-radius: 7px;
    color: #2c2c2c;
    max-width: 70%;
    font-family: "Poppins", Arial, sans-serif;
    &.left {
      margin-right: auto;
    }
    &.right {
      margin-left: auto;
    }
  }

  .inner-inbox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #f8f8f8;
    border-radius: 8px;
    padding: 8px;
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #c4bdbd;
      border-radius: 10px;
    }
  }

  .no-chats {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 20px;
      margin-top: 5px;
      color: #2c2c2c99;
      font-family: "Poppins", Arial, sans-serif;
    }
  }
`;
