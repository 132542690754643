import React from "react";
import GaugeChart from "react-gauge-chart";

export default function Speedometer({ value }) {
  console.log(10 - parseInt(value) / 10, "valuevalue");
  return (
    <div>
      <GaugeChart
        id="gauge-chart5"
        nrOfLevels={420}
        arcsLength={[parseInt(value) / 10, 10 - parseInt(value) / 10]}
        colors={["#0F1011", "#ED0101"]}
        percent={`0.${parseInt(value)}`}
        arcPadding={0}
        style={{ height: "100px" }}
        hideText={true}
        cornerRadius={4}
      />
    </div>
  );
}
