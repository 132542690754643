import { Modal } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { LoginEye } from "../utils/Icons/SvgIcons";
import "../style/modal.css";
import { HideEye } from "../utils/Images/Images";
import { changepassword, changePasswordAdmin } from "../services/Collections";
import { toast } from "react-toastify";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
// import PropTypes from "prop-types";

export const SetNewPassword = ({
  showNewPasswordModal,
  handleCancelPasswordModal,
}) => {
  const [showPassword, setShowPassword] = useState("password");
  const [showPasswordNew, setShowPasswordNew] = useState("password");
  const [showPasswordConfirm, setShowPasswordConfirm] = useState("password");
  const [loader, setLoader] = useState(false);
  const userId = useSelector((state) => state?.loginAuth?.adleverageUserId?.id);
  const navigate = useNavigate();

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = yup.object().shape({
    oldPassword: yup.string().required("Password is required"),
    newPassword: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*[\]{}()?"\\,><':;|_~`=+-])[a-zA-Z\d!@#$%^&*[\]{}()?"\\,><':;|_~`=+-]{8,99}$/,
        "Must contain at least 8 Characters, Example - Abcd@123"
      ),
    confirmPassword: yup
      .string()
      .required("Password is required")
      .oneOf([yup.ref("newPassword")], "Passwords must match"),
  });

  const showPassToggle = () => {
    if (showPassword == "password") {
      setShowPassword("text");
      return;
    }
    setShowPassword("password");
  };
  const showPassToggleNew = () => {
    if (showPasswordNew == "password") {
      setShowPasswordNew("text");
      return;
    }
    setShowPasswordNew("password");
  };
  const showPassToggleConfirm = () => {
    if (showPasswordConfirm == "password") {
      setShowPasswordConfirm("text");
      return;
    }
    setShowPasswordConfirm("password");
  };

  const handleChangePassword = async (e) => {
    setLoader(true);
    console.log(e, "hjhdsfghjdata");
    let req = {
      id: userId,
      old_password: e?.oldPassword,
      new_password: e?.confirmPassword,
    };
    let res = await changePasswordAdmin(req);
    if (res.status === 200) {
      setLoader(false);
      toast.success("Password changed successfully");
      handleCancelPasswordModal();
    } else {
      if (res?.response) {
        setLoader(false);
        toast.error(
          res.response.data.message ||
            res.error ||
            res.message ||
            "Something went wrong"
        );
      } else {
        navigate("/");
      }
    }
  };

  return (
    <div>
      <Modal
        open={showNewPasswordModal}
        prefixCls="chnage-modal"
        centered
        width={448}
        footer={false}
        onCancel={handleCancelPasswordModal}
      >
        <SetNewPasswordModalWrapper>
          <div className="flex-div">
            {/* <div className="gradient-bg">
              <img src={LoginBg} alt="" />
            </div> */}
            <div className="inner-part">
              <div className="main-box">
                {/* <div className="logo-div">
                  <img src={MainLogo} alt="" />
                  <h4>Sign in your account</h4>
                </div> */}
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleChangePassword}
                >
                  <Form>
                    <div className="content">
                      <div>
                        <label>
                          <strong>Old Password</strong>
                        </label>
                        <div className="password-div">
                          <Field
                            font1="font1"
                            type={showPassword}
                            name="oldPassword"
                            placeholder="Enter your password"
                            //   onChange={(e) =>
                            //     setAuth({
                            //       email: auth.email,
                            //       password: e.target.value,
                            //     })
                            //   }
                          />
                          {showPassword == "password" ? (
                            <div onClick={showPassToggle}>
                              <img src={HideEye} alt="" />
                            </div>
                          ) : (
                            <div onClick={showPassToggle}>
                              <LoginEye />
                            </div>
                          )}
                        </div>
                        <span
                          style={{
                            color: "red",
                            background: "transparent",
                            border: "none",
                            margin: "0px",
                          }}
                        >
                          <ErrorMessage name="oldPassword" />
                        </span>
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <label>
                          <strong>New Password</strong>
                        </label>
                        <div className="password-div">
                          <Field
                            font1="font1"
                            type={showPasswordNew}
                            name="newPassword"
                            placeholder="Enter your password"
                            //   onChange={(e) =>
                            //     setAuth({
                            //       email: auth.email,
                            //       password: e.target.value,
                            //     })
                            //   }
                          />
                          {showPasswordNew == "password" ? (
                            <div onClick={showPassToggleNew}>
                              <img src={HideEye} alt="" />
                            </div>
                          ) : (
                            <div onClick={showPassToggleNew}>
                              <LoginEye />
                            </div>
                          )}
                          {/* )} */}
                        </div>
                        <span
                          style={{
                            color: "red",
                            background: "transparent",
                            border: "none",
                            margin: "0px",
                          }}
                        >
                          <ErrorMessage name="newPassword" />
                        </span>
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <label>
                          <strong>Confirm New Password</strong>
                        </label>
                        <div className="password-div">
                          <Field
                            font1="font1"
                            type={showPasswordConfirm}
                            name="confirmPassword"
                            placeholder="Enter your password"
                            //   onChange={(e) =>
                            //     setAuth({
                            //       email: auth.email,
                            //       password: e.target.value,
                            //     })
                            //   }
                          />
                          {showPasswordConfirm == "password" ? (
                            <div onClick={showPassToggleConfirm}>
                              <img src={HideEye} alt="" />
                            </div>
                          ) : (
                            <div onClick={showPassToggleConfirm}>
                              <LoginEye />
                            </div>
                          )}
                        </div>
                        <span
                          style={{
                            color: "red",
                            background: "transparent",
                            border: "none",
                            margin: "0px",
                          }}
                        >
                          <ErrorMessage name="confirmPassword" />
                        </span>
                      </div>
                      {/* {!loading ? ( */}
                      <button
                        style={{ fontFamily: "Poppins", marginTop: "20px" }}
                      >
                        {loader ? "Loading..." : "Submit"}
                      </button>
                      {/* ) : (
                      <LoadingButton>Loading...</LoadingButton>
                    )} */}
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </SetNewPasswordModalWrapper>
      </Modal>
    </div>
  );
};
// DeleteModal.propTypes = {
//   open: PropTypes.bool,
//   handleClose: PropTypes.func,
//   title: PropTypes.string,
//   description: PropTypes.string,
//   button: PropTypes.string,
//   btnColor: PropTypes.string,
//   handleSubmit: PropTypes.func,
//   isLoading: PropTypes.bool,
// };

const SetNewPasswordModalWrapper = styled.div`
  width: 100%;
  margin: 36px 0 12px 0;

  .flex-div {
    height: 100%;
    width: 100%;

    .gradient-bg {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .inner-part {
      background: transparent;
      ${"" /* border-radius: 8px; */}
      ${"" /* position: absolute; */}

      .main-box {
        .logo-div {
          text-align: center;
          img {
            width: 150px;
            margin-bottom: 1rem;
          }
          h4 {
            margin-bottom: 1.5rem;
            color: #fff;
            font-size: 18px;
            font-family: "Poppins";
          }
        }
        .content {
          div {
            label {
              color: #242424;
              margin-bottom: 0.25rem;
              display: block;
              font-family: "Poppins";
              font-size: 16px;
            }
            .password-div {
              display: flex;
              position: relative;
              input {
                background-color: #ffff;
                border: 1px solid #afafaf;
                color: #000;
                position: relative;
                padding: 0rem 0.75rem;
                width: 100%;
                ${"" /* height: 41px; */}
                height: 56px;
                border-radius: 12px;

                font-family: "Poppins" !important;
                font-size: 14px;

                :focus {
                  outline: none;
                }
                ::placeholder {
                  color: rgba(0, 0, 0, 0.39) !important;
                }
              }
              svg {
                position: absolute;
                right: 12px;
                top: 20px;
                cursor: pointer;
              }
              img {
                position: absolute;
                right: 12px;
                top: 15px;
                cursor: pointer;
              }
            }
            input {
              background-color: #ffff;
              border: 1px solid #afafaf;
              color: #000;
              position: relative;
              padding: 0rem 0.75rem;
              width: 100%;
              ${"" /* height: 41px; */}
              height: 56px;
              border-radius: 12px;

              font-family: "Poppins" !important;
              font-size: 14px;

              :focus {
                outline: none;
              }
              ::placeholder {
                color: rgba(0, 0, 0, 0.39) !important;
              }
            }
          }
          .forgt-password {
            h1 {
              color: #00a1e6;
              text-align: right;
              font-family: "Poppins";
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              letter-spacing: 0.2px;
              cursor: pointer;
              text-decoration-line: underline;
            }
          }
          button {
            font-weight: 500;
            height: 50px;
            cursor: pointer;
            border-radius: 0.75rem;
            width: 100%;
            background-color: #d80c0c;
            border: 1px solid #d80c0c;
            font-size: 14px;
            color: #fff;
            text-align: center;
            margin-top: 10px;

            ${
              "" /* :hover {
              background-color: #282348;
            } */
            }
          }
          span {
            font-weight: 700;
            height: 50px;
            cursor: pointer;
            border-radius: 0.75rem;
            width: 100%;
            background-color: #363062;
            font-size: 14px;
            border: 1px solid #363062;
            color: #fff;
            text-align: center;
          }
        }
      }
    }
  }
`;
