/* eslint-disable react/jsx-key */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import BookingList from "./BookingList";
import { PrivateLayoutWrapper } from "../../layouts/privateLayouts/PrivateLayout";
import Header from "../../components/Header";

export const BookingListPublic = () => {
  const [click, setClick] = useState(false);
  const isSideBarOpen = useSelector((state) => state?.LoginSlice?.sideNav);
  const sidebar = useSelector((state) => state?.LoginSlice?.sideNav);

  return (
    <PrivateLayoutWrapper sidebar={sidebar}>
      <div>
        <div>
          <section className="outlet-section">
            <div className="outlet-section-inner">
              <BookingList/>
            </div>
          </section>
        </div>
      </div>
    </PrivateLayoutWrapper>
  );
};
