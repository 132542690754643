import React from "react";
import styled from "styled-components";
import { formatNumberDecimal } from "./Year/commonFuc";
import { COLORS } from "./SummaryReport/summaryStyle";
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { PieGraphWrapper } from "./SummaryReport/SummaryReport";

const RADIAN = Math.PI / 180;
const renderActiveShape2 = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + outerRadius * cos;
  const sy = cy + outerRadius * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
        style={{ fontSize: "12px" }}
      >{`${payload?.name} (${(percent * 100).toFixed(2)}%)`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
        style={{ fontSize: "10px" }}
      >
        ${formatNumberDecimal(value)}
      </text>
    </g>
  );
};

const BudgetSalesGraph = ({ budgetData, revenueData }) => {
  const indexArr = budgetData?.map((_, index) => {
    return index;
  });
  const indexArr2 = revenueData?.map((_, index) => {
    return index;
  });

  return (
    <PieWrapper>
      <div>
        <h4>BUDGET</h4>
        {/* <PieChart width={800} height={600} style={{ margin: "auto" }}>
          <Pie
            activeIndex={indexArr}
            activeShape={renderActiveShape2}
            data={budgetData}
            cx="50%"
            cy="50%"
            innerRadius={0}
            outerRadius={130}
            fill="#8884d8"
          />
        </PieChart> */}

        {budgetData && <PieGraph data={budgetData} />}
      </div>
      <div>
        <h4>REVENUE</h4>
        {/* <PieChart width={600} height={400} style={{ margin: "auto" }}>
          <Pie
            activeIndex={indexArr2}
            activeShape={renderActiveShape2}
            data={revenueData}
            cx="50%"
            cy="50%"
            innerRadius={0}
            outerRadius={130}
            fill="#8884d8"
          />
        </PieChart> */}
      </div>
    </PieWrapper>
  );
};
export default BudgetSalesGraph;

const PieGraph = ({ data }) => {
  const totalValue = data.reduce((acc, item) => acc + item.value, 0);
  const RADIAN = Math.PI / 180;
  
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {percent * 100 > 10 ? `${(percent * 100).toFixed(0)}%` : ""}
      </text>
    );
  };

  const CustomTooltip = ({ payload }) => {
    return (
      <div
        className="tooltip-box"
        style={{ background: "#fff", padding: "20px" }}
      >
        <div className="ant-popover-arrow" />
        <div className="content">
          <h5>{payload?.[0]?.payload?.name}</h5>
          <p>
            <b>
              <span style={{ fontSize: "14px" }}>
                {((payload?.[0]?.payload?.value / totalValue) * 100).toFixed(2)}
                %
              </span>{" "}
              <span style={{ fontSize: "12px" }}>
                (${formatNumberDecimal(payload?.[0]?.payload?.value)})
              </span>
            </b>
          </p>
        </div>
      </div>
    );
  };

  return (
    <PieGraphWrapper>
      <PieChart width={800} height={500}>
        <Legend
          layout="vertical"
          verticalAlign="middle"
          itemGap={20}
          align="right"
          iconType="circle"
          formatter={(value) => (
            <span style={{ color: "#7a7b7a" }}>{value}</span> // Gray color for legend text
          )}
          wrapperStyle={{
            position: "absolute",
            top: "40%",
            left: "60%",
            transform: "translate(10%, -30%)",
          }}
        />

        <Pie
          data={data}
          cx={195}
          cy={225}
          innerRadius={0}
          outerRadius={190}
          fill="#8884d8"
          legendType="circle"
          paddingAngle={0}
          dataKey="value"
          label={renderCustomizedLabel}
          labelLine={false}
        >
          {data?.length > 0 &&
            data?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
        </Pie>
        <Tooltip content={<CustomTooltip data={data} />} />
      </PieChart>
    </PieGraphWrapper>
  );
};

const PieWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 70px;

  div {
    h4 {
      text-align: center;
      font-weight: 700;
    }
  }
  @media (max-width: 600px) {
    overflow-x: scroll;
  }
`;
