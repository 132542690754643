export let SummaryJson = [
    {
        month: 'Jan',
        data: {
            "save_date": "2024-11-06T10:21:00.863Z",
            "user_id": 85,
            "year": "2024",
            "month": "11",
            "business_unit": [],
            "service_type": [],
            "sheet": {
                "historyTitle": "dfsfs",
                "yearSheetFilters": {
                    "date": {
                        "month": 1,
                        "year": 2024,
                        "date": "2024-01-01",
                        "dateObj": "2023-12-31T18:30:00.000Z"
                    },
                    "endDate": {
                        "month": 12,
                        "year": 2024,
                        "date": "2024-12-31",
                        "dateObj": "2024-12-31T18:29:59.999Z"
                    }
                },
                "goalPrediction": {
                    "budget": 1232235.2999999996,
                    "sales": 12322352.999999996,
                    "year": 2024
                },
                "persistYearData": {
                    "current": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 710191.43,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 1,
                            "cpa": 710191.43,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 903139.74,
                            "sales": 1000,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 943071.12,
                            "sales": 440,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 903016.77,
                            "sales": 7470,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 924869.25,
                            "sales": 660,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 908978.81,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 917993.41,
                            "sales": 740,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 1000000,
                            "sales": 6960,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 1588989,
                            "sales": 406.39,
                            "roas": 5.707029,
                            "leads": 2620,
                            "cpl": 606.484351,
                            "appts": 5766,
                            "cpa": 275.579084,
                            "con_per": 220.0763,
                            "jobs": 3358,
                            "cpj": 473.195057,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 1000010,
                            "sales": 7770,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 909514.19,
                            "sales": 580,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 915095.58,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 1000000,
                            "sales": 6960,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 904485.75,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ],
                    "prediction": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 429.00216667167,
                            "sales": 5690,
                            "roas": 0,
                            "leads": 4584,
                            "cpl": 0,
                            "appts": 5600,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 43270,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 60060.410384376635,
                            "sales": 20,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 66501.79116730776,
                            "sales": 6960,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 62715.91867815904,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 66501.79116730776,
                            "sales": 3630,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 60052.23265911679,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 66501.79116730776,
                            "sales": 60,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 61505.461720564555,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 60448.71899812793,
                            "sales": 3630,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 61048.20604478474,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 66501.79116730776,
                            "sales": 5250,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 105670.6146451492,
                            "sales": 2352.999999996,
                            "roas": 116.61097118985721,
                            "leads": 3560.1179040519255,
                            "cpl": 29.681773888690838,
                            "appts": 7834.975758875028,
                            "cpa": 13.487037854003741,
                            "con_per": 220.0763,
                            "jobs": 4562.92534747788,
                            "cpj": 23.158523665866632,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 66502.45618521945,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 66501.79116730776,
                            "sales": 4540,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 60484.32272708307,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 60855.49515928637,
                            "sales": 3630,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 60149.92246030574,
                            "sales": 4420,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ]
                }
            },
            "total": {},
            "budget_goal": 0,
            "revenue_goal": 0,
            "budget_per": 0,
            "budget_goal_per": 0,
            "budget_variance": 0
        }
    },
    {
        month: 'Feb',
        data: {
            "save_date": "2024-11-06T10:21:00.863Z",
            "user_id": 85,
            "year": "2024",
            "month": "11",
            "business_unit": [],
            "service_type": [],
            "sheet": {
                "historyTitle": "dfsfs",
                "yearSheetFilters": {
                    "date": {
                        "month": 1,
                        "year": 2024,
                        "date": "2024-01-01",
                        "dateObj": "2023-12-31T18:30:00.000Z"
                    },
                    "endDate": {
                        "month": 12,
                        "year": 2024,
                        "date": "2024-12-31",
                        "dateObj": "2024-12-31T18:29:59.999Z"
                    }
                },
                "goalPrediction": {
                    "budget": 1232235.2999999996,
                    "sales": 12322352.999999996,
                    "year": 2024
                },
                "persistYearData": {
                    "current": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 710191.43,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 1,
                            "cpa": 710191.43,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 903139.74,
                            "sales": 360,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 943071.12,
                            "sales": 6360,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 903016.77,
                            "sales": 6330,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 924869.25,
                            "sales": 650,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 908978.81,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 917993.41,
                            "sales": 550,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 1000000,
                            "sales": 55,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 1588989,
                            "sales": 9068406.39,
                            "roas": 5.707029,
                            "leads": 2620,
                            "cpl": 606.484351,
                            "appts": 5766,
                            "cpa": 275.579084,
                            "con_per": 220.0763,
                            "jobs": 3358,
                            "cpj": 473.195057,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 1000010,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 909514.19,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 915095.58,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 904485.75,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ],
                    "prediction": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 429.00216667167,
                            "sales": 0,
                            "roas": 0,
                            "leads": 29315,
                            "cpl": 0,
                            "appts": 5073,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 2168,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 60060.410384376635,
                            "sales": 660,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 62715.91867815904,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 60052.23265911679,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 61505.461720564555,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 60448.71899812793,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 61048.20604478474,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 105670.6146451492,
                            "sales": 1352.999999996,
                            "roas": 116.61097118985721,
                            "leads": 3560.1179040519255,
                            "cpl": 29.681773888690838,
                            "appts": 7834.975758875028,
                            "cpa": 13.487037854003741,
                            "con_per": 220.0763,
                            "jobs": 4562.92534747788,
                            "cpj": 23.158523665866632,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 66502.45618521945,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 60484.32272708307,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 60855.49515928637,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 60149.92246030574,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ]
                }
            },
            "total": {},
            "budget_goal": 0,
            "revenue_goal": 0,
            "budget_per": 0,
            "budget_goal_per": 0,
            "budget_variance": 0
        }
    },
    {
        month: 'Mar',
        data: {
            "save_date": "2024-11-06T10:21:00.863Z",
            "user_id": 85,
            "year": "2024",
            "month": "11",
            "business_unit": [],
            "service_type": [],
            "sheet": {
                "historyTitle": "dfsfs",
                "yearSheetFilters": {
                    "date": {
                        "month": 1,
                        "year": 2024,
                        "date": "2024-01-01",
                        "dateObj": "2023-12-31T18:30:00.000Z"
                    },
                    "endDate": {
                        "month": 12,
                        "year": 2024,
                        "date": "2024-12-31",
                        "dateObj": "2024-12-31T18:29:59.999Z"
                    }
                },
                "goalPrediction": {
                    "budget": 1232235.2999999996,
                    "sales": 12322352.999999996,
                    "year": 2024
                },
                "persistYearData": {
                    "current": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 710191.43,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 1,
                            "cpa": 710191.43,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 903139.74,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 943071.12,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 903016.77,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 924869.25,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 908978.81,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 917993.41,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 1588989,
                            "sales": 8406.39,
                            "roas": 5.707029,
                            "leads": 2620,
                            "cpl": 606.484351,
                            "appts": 5766,
                            "cpa": 275.579084,
                            "con_per": 220.0763,
                            "jobs": 3358,
                            "cpj": 473.195057,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 1000010,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 909514.19,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 915095.58,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 904485.75,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ],
                    "prediction": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 479.00216667167,
                            "sales": 0,
                            "roas": 0,
                            "leads": 223445,
                            "cpl": 0,
                            "appts": 68,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 6,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 60060.410384376635,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 62715.91867815904,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 60052.23265911679,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 61505.461720564555,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 60448.71899812793,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 61048.20604478474,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 105670.6146451492,
                            "sales": 2352.999999996,
                            "roas": 116.61097118985721,
                            "leads": 3560.1179040519255,
                            "cpl": 29.681773888690838,
                            "appts": 7834.975758875028,
                            "cpa": 13.487037854003741,
                            "con_per": 220.0763,
                            "jobs": 4562.92534747788,
                            "cpj": 23.158523665866632,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 66502.45618521945,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 60484.32272708307,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 60855.49515928637,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 60149.92246030574,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ]
                }
            },
            "total": {},
            "budget_goal": 0,
            "revenue_goal": 0,
            "budget_per": 0,
            "budget_goal_per": 0,
            "budget_variance": 0
        }
    },

    {
        month: 'Apr',
        data: {
            "save_date": "2024-11-06T10:21:00.863Z",
            "user_id": 85,
            "year": "2024",
            "month": "11",
            "business_unit": [],
            "service_type": [],
            "sheet": {
                "historyTitle": "dfsfs",
                "yearSheetFilters": {
                    "date": {
                        "month": 1,
                        "year": 2024,
                        "date": "2024-01-01",
                        "dateObj": "2023-12-31T18:30:00.000Z"
                    },
                    "endDate": {
                        "month": 12,
                        "year": 2024,
                        "date": "2024-12-31",
                        "dateObj": "2024-12-31T18:29:59.999Z"
                    }
                },
                "goalPrediction": {
                    "budget": 1232235.2999999996,
                    "sales": 12322352.999999996,
                    "year": 2024
                },
                "persistYearData": {
                    "current": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 710191.43,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 1,
                            "cpa": 710191.43,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 903139.74,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 943071.12,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 903016.77,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 924869.25,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 908978.81,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 917993.41,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 1588989,
                            "sales": 68406.39,
                            "roas": 5.707029,
                            "leads": 2620,
                            "cpl": 606.484351,
                            "appts": 5766,
                            "cpa": 275.579084,
                            "con_per": 220.0763,
                            "jobs": 3358,
                            "cpj": 473.195057,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 1000010,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 909514.19,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 915095.58,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 904485.75,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ],
                    "prediction": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 4229.00216667167,
                            "sales": 8888,
                            "roas": 0,
                            "leads": 30495,
                            "cpl": 0,
                            "appts": 2360,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 42201,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 60060.410384376635,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 62715.91867815904,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 60052.23265911679,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 61505.461720564555,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 60448.71899812793,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 61048.20604478474,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 105670.6146451492,
                            "sales": 352.999999996,
                            "roas": 116.61097118985721,
                            "leads": 3560.1179040519255,
                            "cpl": 29.681773888690838,
                            "appts": 7834.975758875028,
                            "cpa": 13.487037854003741,
                            "con_per": 220.0763,
                            "jobs": 4562.92534747788,
                            "cpj": 23.158523665866632,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 66502.45618521945,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 60484.32272708307,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 60855.49515928637,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 60149.92246030574,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ]
                }
            },
            "total": {},
            "budget_goal": 0,
            "revenue_goal": 0,
            "budget_per": 0,
            "budget_goal_per": 0,
            "budget_variance": 0
        }
    },
    {
        month: 'May',
        data: {
            "save_date": "2024-11-06T10:21:00.863Z",
            "user_id": 85,
            "year": "2024",
            "month": "11",
            "business_unit": [],
            "service_type": [],
            "sheet": {
                "historyTitle": "dfsfs",
                "yearSheetFilters": {
                    "date": {
                        "month": 1,
                        "year": 2024,
                        "date": "2024-01-01",
                        "dateObj": "2023-12-31T18:30:00.000Z"
                    },
                    "endDate": {
                        "month": 12,
                        "year": 2024,
                        "date": "2024-12-31",
                        "dateObj": "2024-12-31T18:29:59.999Z"
                    }
                },
                "goalPrediction": {
                    "budget": 1232235.2999999996,
                    "sales": 12322352.999999996,
                    "year": 2024
                },
                "persistYearData": {
                    "current": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 710191.43,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 1,
                            "cpa": 710191.43,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 903139.74,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 943071.12,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 903016.77,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 924869.25,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 908978.81,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 917993.41,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 1588989,
                            "sales": 2.39,
                            "roas": 5.707029,
                            "leads": 2620,
                            "cpl": 606.484351,
                            "appts": 5766,
                            "cpa": 275.579084,
                            "con_per": 220.0763,
                            "jobs": 3358,
                            "cpj": 473.195057,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 1000010,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 909514.19,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 915095.58,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 904485.75,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ],
                    "prediction": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 129.00216667167,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 60060.410384376635,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 62715.91867815904,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 60052.23265911679,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 61505.461720564555,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 60448.71899812793,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 61048.20604478474,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 105670.6146451492,
                            "sales": 2.999999996,
                            "roas": 116.61097118985721,
                            "leads": 3560.1179040519255,
                            "cpl": 29.681773888690838,
                            "appts": 7834.975758875028,
                            "cpa": 13.487037854003741,
                            "con_per": 220.0763,
                            "jobs": 4562.92534747788,
                            "cpj": 23.158523665866632,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 66502.45618521945,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 60484.32272708307,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 60855.49515928637,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 60149.92246030574,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ]
                }
            },
            "total": {},
            "budget_goal": 0,
            "revenue_goal": 0,
            "budget_per": 0,
            "budget_goal_per": 0,
            "budget_variance": 0
        }
    },
    {
        month: 'Jun',
        data: {
            "save_date": "2024-11-06T10:21:00.863Z",
            "user_id": 85,
            "year": "2024",
            "month": "11",
            "business_unit": [],
            "service_type": [],
            "sheet": {
                "historyTitle": "dfsfs",
                "yearSheetFilters": {
                    "date": {
                        "month": 1,
                        "year": 2024,
                        "date": "2024-01-01",
                        "dateObj": "2023-12-31T18:30:00.000Z"
                    },
                    "endDate": {
                        "month": 12,
                        "year": 2024,
                        "date": "2024-12-31",
                        "dateObj": "2024-12-31T18:29:59.999Z"
                    }
                },
                "goalPrediction": {
                    "budget": 1232235.2999999996,
                    "sales": 12322352.999999996,
                    "year": 2024
                },
                "persistYearData": {
                    "current": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 710191.43,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 1,
                            "cpa": 710191.43,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 903139.74,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 943071.12,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 903016.77,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 924869.25,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 908978.81,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 917993.41,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 1588989,
                            "sales": 9068406.39,
                            "roas": 5.707029,
                            "leads": 2620,
                            "cpl": 606.484351,
                            "appts": 5766,
                            "cpa": 275.579084,
                            "con_per": 220.0763,
                            "jobs": 3358,
                            "cpj": 473.195057,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 1000010,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 909514.19,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 915095.58,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 904485.75,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ],
                    "prediction": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 49.00216667167,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 60060.410384376635,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 62715.91867815904,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 60052.23265911679,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 61505.461720564555,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 60448.71899812793,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 61048.20604478474,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 105670.6146451492,
                            "sales": 232.999999996,
                            "roas": 116.61097118985721,
                            "leads": 3560.1179040519255,
                            "cpl": 29.681773888690838,
                            "appts": 7834.975758875028,
                            "cpa": 13.487037854003741,
                            "con_per": 220.0763,
                            "jobs": 4562.92534747788,
                            "cpj": 23.158523665866632,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 66502.45618521945,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 60484.32272708307,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 60855.49515928637,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 60149.92246030574,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ]
                }
            },
            "total": {},
            "budget_goal": 0,
            "revenue_goal": 0,
            "budget_per": 0,
            "budget_goal_per": 0,
            "budget_variance": 0
        }
    },

    {
        month: 'Jul',
        data: {
            "save_date": "2024-11-06T10:21:00.863Z",
            "user_id": 85,
            "year": "2024",
            "month": "11",
            "business_unit": [],
            "service_type": [],
            "sheet": {
                "historyTitle": "dfsfs",
                "yearSheetFilters": {
                    "date": {
                        "month": 1,
                        "year": 2024,
                        "date": "2024-01-01",
                        "dateObj": "2023-12-31T18:30:00.000Z"
                    },
                    "endDate": {
                        "month": 12,
                        "year": 2024,
                        "date": "2024-12-31",
                        "dateObj": "2024-12-31T18:29:59.999Z"
                    }
                },
                "goalPrediction": {
                    "budget": 1232235.2999999996,
                    "sales": 12322352.999999996,
                    "year": 2024
                },
                "persistYearData": {
                    "current": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 710191.43,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 1,
                            "cpa": 710191.43,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 903139.74,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 943071.12,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 903016.77,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 924869.25,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 908978.81,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 917993.41,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 1588989,
                            "sales": 406.39,
                            "roas": 5.707029,
                            "leads": 2620,
                            "cpl": 606.484351,
                            "appts": 5766,
                            "cpa": 275.579084,
                            "con_per": 220.0763,
                            "jobs": 3358,
                            "cpj": 473.195057,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 1000010,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 909514.19,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 915095.58,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 904485.75,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ],
                    "prediction": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 49.00216667167,
                            "sales": 0,
                            "roas": 0,
                            "leads": 95775,
                            "cpl": 0,
                            "appts": 27,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 28431,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 60060.410384376635,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 62715.91867815904,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 60052.23265911679,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 61505.461720564555,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 60448.71899812793,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 61048.20604478474,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 105670.6146451492,
                            "sales": 152.999999996,
                            "roas": 116.61097118985721,
                            "leads": 3560.1179040519255,
                            "cpl": 29.681773888690838,
                            "appts": 7834.975758875028,
                            "cpa": 13.487037854003741,
                            "con_per": 220.0763,
                            "jobs": 4562.92534747788,
                            "cpj": 23.158523665866632,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 66502.45618521945,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 60484.32272708307,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 60855.49515928637,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 60149.92246030574,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ]
                }
            },
            "total": {},
            "budget_goal": 0,
            "revenue_goal": 0,
            "budget_per": 0,
            "budget_goal_per": 0,
            "budget_variance": 0
        }
    },
    {
        month: 'Aug',
        data: {
            "save_date": "2024-11-06T10:21:00.863Z",
            "user_id": 85,
            "year": "2024",
            "month": "11",
            "business_unit": [],
            "service_type": [],
            "sheet": {
                "historyTitle": "dfsfs",
                "yearSheetFilters": {
                    "date": {
                        "month": 1,
                        "year": 2024,
                        "date": "2024-01-01",
                        "dateObj": "2023-12-31T18:30:00.000Z"
                    },
                    "endDate": {
                        "month": 12,
                        "year": 2024,
                        "date": "2024-12-31",
                        "dateObj": "2024-12-31T18:29:59.999Z"
                    }
                },
                "goalPrediction": {
                    "budget": 1232235.2999999996,
                    "sales": 12322352.999999996,
                    "year": 2024
                },
                "persistYearData": {
                    "current": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 710191.43,
                            "sales":10191.43,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 1,
                            "cpa": 710191.43,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 903139.74,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 943071.12,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 903016.77,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 924869.25,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 908978.81,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 917993.41,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 1588989,
                            "sales": 9406.39,
                            "roas": 5.707029,
                            "leads": 2620,
                            "cpl": 606.484351,
                            "appts": 5766,
                            "cpa": 275.579084,
                            "con_per": 220.0763,
                            "jobs": 3358,
                            "cpj": 473.195057,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 1000010,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 909514.19,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 915095.58,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 904485.75,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ],
                    "prediction": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 47229.00216667167,
                            "sales":  29.00216667167,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 60060.410384376635,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 62715.91867815904,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 60052.23265911679,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 61505.461720564555,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 60448.71899812793,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 61048.20604478474,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 105670.6146451492,
                            "sales": 352.999999996,
                            "roas": 116.61097118985721,
                            "leads": 3560.1179040519255,
                            "cpl": 29.681773888690838,
                            "appts": 7834.975758875028,
                            "cpa": 13.487037854003741,
                            "con_per": 220.0763,
                            "jobs": 4562.92534747788,
                            "cpj": 23.158523665866632,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 66502.45618521945,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 60484.32272708307,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 60855.49515928637,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 60149.92246030574,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ]
                }
            },
            "total": {},
            "budget_goal": 0,
            "revenue_goal": 0,
            "budget_per": 0,
            "budget_goal_per": 0,
            "budget_variance": 0
        }
    },
    {
        month: 'Sep',
        data: {
            "save_date": "2024-11-06T10:21:00.863Z",
            "user_id": 85,
            "year": "2024",
            "month": "11",
            "business_unit": [],
            "service_type": [],
            "sheet": {
                "historyTitle": "dfsfs",
                "yearSheetFilters": {
                    "date": {
                        "month": 1,
                        "year": 2024,
                        "date": "2024-01-01",
                        "dateObj": "2023-12-31T18:30:00.000Z"
                    },
                    "endDate": {
                        "month": 12,
                        "year": 2024,
                        "date": "2024-12-31",
                        "dateObj": "2024-12-31T18:29:59.999Z"
                    }
                },
                "goalPrediction": {
                    "budget": 1232235.2999999996,
                    "sales": 12322352.999999996,
                    "year": 2024
                },
                "persistYearData": {
                    "current": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 710191.43,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 1,
                            "cpa": 710191.43,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 903139.74,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 943071.12,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 903016.77,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 924869.25,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 908978.81,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 917993.41,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 1588989,
                            "sales": 8406.39,
                            "roas": 5.707029,
                            "leads": 2620,
                            "cpl": 606.484351,
                            "appts": 5766,
                            "cpa": 275.579084,
                            "con_per": 220.0763,
                            "jobs": 3358,
                            "cpj": 473.195057,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 1000010,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 909514.19,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 915095.58,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 904485.75,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ],
                    "prediction": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 29.00216667167,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 60060.410384376635,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 62715.91867815904,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 60052.23265911679,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 61505.461720564555,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 60448.71899812793,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 61048.20604478474,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 105670.6146451492,
                            "sales": 12.999999996,
                            "roas": 116.61097118985721,
                            "leads": 3560.1179040519255,
                            "cpl": 29.681773888690838,
                            "appts": 7834.975758875028,
                            "cpa": 13.487037854003741,
                            "con_per": 220.0763,
                            "jobs": 4562.92534747788,
                            "cpj": 23.158523665866632,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 66502.45618521945,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 60484.32272708307,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 60855.49515928637,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 60149.92246030574,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ]
                }
            },
            "total": {},
            "budget_goal": 0,
            "revenue_goal": 0,
            "budget_per": 0,
            "budget_goal_per": 0,
            "budget_variance": 0
        }
    },

    {
        month: 'Oct',
        data: {
            "save_date": "2024-11-06T10:21:00.863Z",
            "user_id": 85,
            "year": "2024",
            "month": "11",
            "business_unit": [],
            "service_type": [],
            "sheet": {
                "historyTitle": "dfsfs",
                "yearSheetFilters": {
                    "date": {
                        "month": 1,
                        "year": 2024,
                        "date": "2024-01-01",
                        "dateObj": "2023-12-31T18:30:00.000Z"
                    },
                    "endDate": {
                        "month": 12,
                        "year": 2024,
                        "date": "2024-12-31",
                        "dateObj": "2024-12-31T18:29:59.999Z"
                    }
                },
                "goalPrediction": {
                    "budget": 1232235.2999999996,
                    "sales": 12322352.999999996,
                    "year": 2024
                },
                "persistYearData": {
                    "current": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 710191.43,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 1,
                            "cpa": 710191.43,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 903139.74,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 943071.12,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 903016.77,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 924869.25,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 908978.81,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 917993.41,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 1588989,
                            "sales": 9068406.39,
                            "roas": 5.707029,
                            "leads": 2620,
                            "cpl": 606.484351,
                            "appts": 5766,
                            "cpa": 275.579084,
                            "con_per": 220.0763,
                            "jobs": 3358,
                            "cpj": 473.195057,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 1000010,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 909514.19,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 915095.58,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 904485.75,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ],
                    "prediction": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 47229.00216667167,
                            "sales": 0,
                            "roas": 0,
                            "leads": 1245,
                            "cpl": 0,
                            "appts": 9529,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 6530,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 60060.410384376635,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 62715.91867815904,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 60052.23265911679,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 61505.461720564555,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 60448.71899812793,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 61048.20604478474,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 105670.6146451492,
                            "sales": 152.999999996,
                            "roas": 116.61097118985721,
                            "leads": 3560.1179040519255,
                            "cpl": 29.681773888690838,
                            "appts": 7834.975758875028,
                            "cpa": 13.487037854003741,
                            "con_per": 220.0763,
                            "jobs": 4562.92534747788,
                            "cpj": 23.158523665866632,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 66502.45618521945,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 60484.32272708307,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 60855.49515928637,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 60149.92246030574,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ]
                }
            },
            "total": {},
            "budget_goal": 0,
            "revenue_goal": 0,
            "budget_per": 0,
            "budget_goal_per": 0,
            "budget_variance": 0
        }
    },
    {
        month: 'Nov',
        data: {
            "save_date": "2024-11-06T10:21:00.863Z",
            "user_id": 85,
            "year": "2024",
            "month": "11",
            "business_unit": [],
            "service_type": [],
            "sheet": {
                "historyTitle": "dfsfs",
                "yearSheetFilters": {
                    "date": {
                        "month": 1,
                        "year": 2024,
                        "date": "2024-01-01",
                        "dateObj": "2023-12-31T18:30:00.000Z"
                    },
                    "endDate": {
                        "month": 12,
                        "year": 2024,
                        "date": "2024-12-31",
                        "dateObj": "2024-12-31T18:29:59.999Z"
                    }
                },
                "goalPrediction": {
                    "budget": 1232235.2999999996,
                    "sales": 12322352.999999996,
                    "year": 2024
                },
                "persistYearData": {
                    "current": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 710191.43,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 1,
                            "cpa": 710191.43,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 903139.74,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 943071.12,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 903016.77,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 924869.25,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 908978.81,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 917993.41,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 1588989,
                            "sales": 9068406.39,
                            "roas": 5.707029,
                            "leads": 2620,
                            "cpl": 606.484351,
                            "appts": 5766,
                            "cpa": 275.579084,
                            "con_per": 220.0763,
                            "jobs": 3358,
                            "cpj": 473.195057,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 1000010,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 909514.19,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 915095.58,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 904485.75,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ],
                    "prediction": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 47229.00216667167,
                            "sales": 0,
                            "roas": 0,
                            "leads": 43572,
                            "cpl": 0,
                            "appts": 350,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 10,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 60060.410384376635,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 62715.91867815904,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 60052.23265911679,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 61505.461720564555,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 60448.71899812793,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 61048.20604478474,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 105670.6146451492,
                            "sales": 152.999999996,
                            "roas": 116.61097118985721,
                            "leads": 3560.1179040519255,
                            "cpl": 29.681773888690838,
                            "appts": 7834.975758875028,
                            "cpa": 13.487037854003741,
                            "con_per": 220.0763,
                            "jobs": 4562.92534747788,
                            "cpj": 23.158523665866632,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 66502.45618521945,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 60484.32272708307,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 60855.49515928637,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 60149.92246030574,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ]
                }
            },
            "total": {},
            "budget_goal": 0,
            "revenue_goal": 0,
            "budget_per": 0,
            "budget_goal_per": 0,
            "budget_variance": 0
        }
    },
    {
        month: 'Dec',
        data: {
            "save_date": "2024-11-06T10:21:00.863Z",
            "user_id": 85,
            "year": "2024",
            "month": "11",
            "business_unit": [],
            "service_type": [],
            "sheet": {
                "historyTitle": "dfsfs",
                "yearSheetFilters": {
                    "date": {
                        "month": 1,
                        "year": 2024,
                        "date": "2024-01-01",
                        "dateObj": "2023-12-31T18:30:00.000Z"
                    },
                    "endDate": {
                        "month": 12,
                        "year": 2024,
                        "date": "2024-12-31",
                        "dateObj": "2024-12-31T18:29:59.999Z"
                    }
                },
                "goalPrediction": {
                    "budget": 1232235.2999999996,
                    "sales": 12322352.999999996,
                    "year": 2024
                },
                "persistYearData": {
                    "current": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 710191.43,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 1,
                            "cpa": 710191.43,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 903139.74,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 943071.12,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 903016.77,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 924869.25,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 908978.81,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 917993.41,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 1588989,
                            "sales": 9068406.39,
                            "roas": 5.707029,
                            "leads": 2620,
                            "cpl": 606.484351,
                            "appts": 5766,
                            "cpa": 275.579084,
                            "con_per": 220.0763,
                            "jobs": 3358,
                            "cpj": 473.195057,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 1000010,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 909514.19,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 915095.58,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 1000000,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 904485.75,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ],
                    "prediction": [
                        {
                            "category_name": "Direct Mail",
                            "budget": 229.00216667167,
                            "sales": 0,
                            "roas": 0,
                            "leads": 43327,
                            "cpl": 0,
                            "appts": 6229,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 2820,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Email Marketing",
                            "budget": 60060.410384376635,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Events",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Google LSA",
                            "budget": 62715.91867815904,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Misc Marketing",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Organic Social",
                            "budget": 60052.23265911679,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Outdoor",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Search",
                            "budget": 61505.461720564555,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Paid Social",
                            "budget": 60448.71899812793,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Pay For Performance",
                            "budget": 61048.20604478474,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Print",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Production",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Radio",
                            "budget": 105670.6146451492,
                            "sales": 122.999999996,
                            "roas": 116.61097118985721,
                            "leads": 3560.1179040519255,
                            "cpl": 29.681773888690838,
                            "appts": 7834.975758875028,
                            "cpa": 13.487037854003741,
                            "con_per": 220.0763,
                            "jobs": 4562.92534747788,
                            "cpj": 23.158523665866632,
                            "close_per": 58.2379,
                            "avg_ticket": 2700.537936,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Referral",
                            "budget": 66502.45618521945,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Repeat",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "SEO",
                            "budget": 60484.32272708307,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Television",
                            "budget": 60855.49515928637,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Web",
                            "budget": 66501.79116730776,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        },
                        {
                            "category_name": "Yelp",
                            "budget": 60149.92246030574,
                            "sales": 0,
                            "roas": 0,
                            "leads": 0,
                            "cpl": 0,
                            "appts": 0,
                            "cpa": 0,
                            "con_per": 0,
                            "jobs": 0,
                            "cpj": 0,
                            "close_per": 0,
                            "avg_ticket": 0,
                            "isChecked": false,
                            "isParent": true
                        }
                    ]
                }
            },
            "total": {},
            "budget_goal": 0,
            "revenue_goal": 0,
            "budget_per": 0,
            "budget_goal_per": 0,
            "budget_variance": 0
        }
    }
];