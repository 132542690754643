/* eslint-disable react/jsx-key */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PrivateLayoutWrapper } from "../layouts/privateLayouts/PrivateLayout";
import Header from "./Header";
import { OpenSideNav } from "../app/Auth/signIn/SignInSlice";
import AdminList from "../module/admin/AdminList";
import NotFound from "./NotFound";

export const AdminUserPublic = () => {
  const [click, setClick] = useState(false);
  const isSideBarOpen = useSelector((state) => state?.LoginSlice?.sideNav);
  const sidebar = useSelector((state) => state?.LoginSlice?.sideNav);
  const userDetails = useSelector(
    (state) => state?.loginAuth?.adleverageUserId
  );
  const dispatch = useDispatch();
  const handleClickAway = () => {
    dispatch(OpenSideNav(false));
  };

  return userDetails?.permissions?.includes("admin-users") ? (
    <PrivateLayoutWrapper sidebar={sidebar}>
      <div>
        <div>
          <section className="outlet-section">
            <div className="outlet-section-inner">
              <AdminList />
            </div>
          </section>
        </div>
      </div>
    </PrivateLayoutWrapper>
  ) : (
    <NotFound />
  );
};
