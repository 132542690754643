import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  campaignData: null,
  isSelectAllCampaign: false,
};

export const CampaignSlice = createSlice({
  name: "campaignSlice",
  initialState,

  reducers: {
    updateCampaignData: (state, action) => {
      if (action) {
        state.campaignData = action.payload;
      }
    },
    updateSelectAllCampaigns: (state, action) => {
      if (action) {
        state.isSelectAllCampaign = action.payload;
      }
    },
    resetCampaignslice: () => initialState,
  },
});

export const {
  updateCampaignData,
  updateSelectAllCampaigns,
  resetCampaignslice,
} = CampaignSlice.actions;
export default CampaignSlice.reducer;
