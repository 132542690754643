import React, { useEffect } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const handleExport = (data) => {
  let newArray = [];
  for (let index = 0; index < data?.length; index++) {
    const element = data[index];
    console.log(element, "element");
    let obj = {
      ["Source"]: element?.name,
      ["Total Leads"]: element?.total_leads,
      ["Total Bookings"]: element?.total_booking,
      ["Total Cost"]: "$" + element?.total_cost,
    };
    newArray.push(obj);
  }

  return newArray;
};

export const handleExcelExport = (jsonData, invoiceData) => {
  const wb = XLSX.utils.book_new();
  const invoiceFile = XLSX.utils.json_to_sheet(handleExport(invoiceData));
  XLSX.utils.book_append_sheet(wb, invoiceFile, "Invoice");

  jsonData.forEach((item) => {
    const sheetData = item?.leads?.map((lead) => ({
      Name: lead?.name
        ? lead?.name?.trim() === ""
          ? "N/A"
          : lead.name
        : "N/A",
      Phone: lead?.phone
        ? lead?.phone?.trim() === ""
          ? "N/A"
          : lead.phone
        : "N/A",
    }));
    const file = XLSX.utils.json_to_sheet(sheetData);

    // Set column widths
    file["!cols"] = [{ wch: 80 }, { wch: 20 }];

    XLSX.utils.book_append_sheet(wb, file, item.name ? item.name : "Sheet");
  });

  // Generate Excel file
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  // Create a Blob object
  const data = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  });

  // Save the Excel file
  saveAs(data, "leads.xlsx");
};
