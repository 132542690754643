import React from "react";
import styled from "styled-components";
import { NODATA } from "../utils/Images/Images";

export default function NoDataFound({ height,imageSize, message }) {
  return (
    <NoDataFoundWrapper height={height} imageSize={imageSize}>
      <img src={NODATA} alt="No DATA FOUND" />
      <h1>{message}</h1>
      <h2> We couldn't find the data you're looking for. Please check your search criteria or try again later. </h2>
    </NoDataFoundWrapper>
  );
}

const NoDataFoundWrapper = styled.div`
  width: 100%;
  height: ${({ height }) => height};
  background: #f1f1f1f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  h1 {
    font-family: Poppins;
    font-size: 42px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #4f4f4f;
  }

  h2 {
    max-width: 500px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #7a7b7a;
  }
    img{
    height: ${({ imageSize }) => imageSize ? `${imageSize}px` : '100%'};
    }
`;
