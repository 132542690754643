import { memo, useEffect, useRef, useState } from "react";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Rectangle,
  CartesianGrid,
} from "recharts";
import styled from "styled-components";

function StackedBarChart({ data }) {
  const hasPainted = useRef(false);
  const [opacity, setOpacity] = useState({});
  const [activeKeys, setActiveKeys] = useState([]);

  const handleMouseEnter = (datalist) => {
    console.log("datalist", datalist);
    const { dataKey, inactive } = datalist;
    if (hasPainted?.current === dataKey) return;
    hasPainted.current = dataKey;
    if (!inactive) {
      let final = Object?.entries(opacity)?.reduce((op, [key]) => {
        if (key === dataKey) op[key] = 1;
        else op[key] = 0.1;
        return op;
      }, {});
      setOpacity(final);
    }
  };

  const handleMouseLeave = () => {
    if (!hasPainted?.current) return;
    hasPainted.current = false;
    let final = Object?.entries(opacity)?.reduce((op, [key]) => {
      op[key] = 1;
      return op;
    }, {});
    setOpacity(final);
  };

  const handleLegendClick = (e) => {
    const key = e?.dataKey;
    setActiveKeys((prev) =>
      prev?.includes(key) ? prev?.filter((k) => k !== key) : [...prev, key]
    );
  };

  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <LegendWrapper
        onMouseEnter={() => handleMouseLeave()}
        onMouseLeave={() => handleMouseLeave()}
        onMouseOver={(e) => {
          handleMouseLeave();
        }}
      >
        {payload?.length > 0 &&
          payload?.map((entry, index) => (
            <div
              key={`item-${index}`}
              className="flex-wraps"
              onMouseEnter={(e) => {
                if (e) {
                  e?.preventDefault();
                  e?.stopPropagation();
                }
                handleMouseEnter(entry);
              }}
              onMouseOver={(e) => {
                e?.preventDefault();
                e?.stopPropagation();
                handleMouseEnter(entry);
              }}
              onClick={() => handleLegendClick(entry)}
              // onMouseLeave={() => handleMouseLeave(entry)}
              style={{
                maxWidth: "max-content",
                margin: "10px 0px",
                height: "fit-content",
              }}
            >
              <div className="circle-wrapper">
                <Circle fill={entry?.color} size="15px" />
              </div>
              <Content className="content" inactive={entry?.inactive}>
                {entry?.value}
              </Content>
            </div>
          ))}
      </LegendWrapper>
    );
  };

  console.log(data, "datadata");
  useEffect(() => {
    setActiveKeys([
      "calls",
      "leads",
      "not_interested",
      "spam",
      "undefined",
      "forms",
    ]);
    setOpacity({
      calls: 1,
      leads: 1,
      not_interested: 1,
      spam: 1,
      undefined: 1,
      forms: 1,
    });
  }, []);

  return (
    <GraphWrapper width="100%" height={380}>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="source" />
        <YAxis />
        <Tooltip />
        <Legend content={renderLegend} />
        <Bar
          dataKey="calls"
          name="Total Calls"
          barSize={20}
          fill={activeKeys?.includes("calls") ? "#2caffe" : "grey"}
          opacity={opacity["calls"]}
          hide={!activeKeys?.includes("calls")}
          style={{ fontFamily: "Poppins" }}
        />
        <Bar
          dataKey="leads"
          name="Call Leads"
          barSize={20}
          style={{ fontFamily: "Poppins" }}
          fill={activeKeys?.includes("leads") ? "#2ee0ca" : "grey"}
          opacity={opacity["leads"]}
          hide={!activeKeys?.includes("leads")}
        />
        <Bar
          dataKey="not_interested"
          name="Not Interested"
          barSize={20}
          style={{ fontFamily: "Poppins" }}
          fill={activeKeys?.includes("not_interested") ? "#544fc5" : "grey"}
          opacity={opacity["not_interested"]}
          hide={!activeKeys?.includes("not_interested")}
        />
        <Bar
          dataKey="spam"
          name="Spam"
          barSize={20}
          style={{ fontFamily: "Poppins" }}
          fill={activeKeys?.includes("spam") ? "#fa4b42" : "grey"}
          opacity={opacity["spam"]}
          hide={!activeKeys?.includes("spam")}
        />
        <Bar
          dataKey="undefined"
          name="Undefined"
          barSize={20}
          style={{ fontFamily: "Poppins" }}
          fill={activeKeys?.includes("undefined") ? "#9a4fc5" : "grey"}
          opacity={opacity["undefined"]}
          hide={!activeKeys?.includes("undefined")}
        />
        {/* <Bar
          dataKey=""
          name="Total Bookings"
          barSize={20}
          style={{ fontFamily: "Poppins" }}
          fill={"rgb(21, 47, 71)"}
          opacity={opacity["total_undefined"]}
          // hide={!activeKeys?.includes("total_undefined")}
        /> */}
        <Bar
          dataKey="forms"
          name="Forms"
          barSize={20}
          fill={activeKeys?.includes("forms") ? "#00e272" : "grey"}
          opacity={opacity["forms"]}
          hide={!activeKeys?.includes("forms")}
          style={{ fontFamily: "Poppins" }}
        />

        {/* <Bar
          dataKey="15"
          name="Unbooked"
          barSize={20}
          style={{ fontFamily: "Poppins" }}
          fill={"#FF6C00"}
          // fill={activeKeys?.includes("total_undefined") ? "#FF6C00" : "grey"}
          opacity={opacity["total_undefined"]}
          // hide={!activeKeys?.includes("total_undefined")}
        /> */}
      </BarChart>
    </GraphWrapper>
  );
}
export default memo(StackedBarChart);

const GraphWrapper = styled(ResponsiveContainer)`
  .recharts-legend-wrapper {
    height: auto !important;
  }
`;

const LegendWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  text-transform: Capitalize;
  padding: 15px 40px;

  .flex-wraps {
    display: flex;
    gap: 10px;
    align-items: center;
    color: white;
  }
  .circle-wrapper {
    ${"" /* margin-top: 5px; */}
  }
`;
const Circle = styled.div`
  background: ${({ fill }) => fill};
  height: ${(props) => props?.size};
  width: ${(props) => props?.size};
  border-radius: 10px;
`;
const Content = styled.div`
  // max-width: 80%;
  color: ${({ inactive }) => (inactive ? "grey" : "#000")};
  text-decoration: ${({ inactive }) => (inactive ? "line-through" : "none")};
`;
