import { Drawer, Popover } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { updateDynamicSheetTab } from "../app/Auth/signIn/LogInSlice";
import GoalsHistory from "../module/dynamicBudgetSheet/Goals/GoalsHistory";
import SummaryFilter from "../module/dynamicBudgetSheet/SummaryFilter";
import YearHistory from "../module/dynamicBudgetSheet/YearHistory";
import {
  activeHistorySheet,
  activeHistorySheetData,
} from "../module/exceltable/ExcelStore";
import { FiltersOfSheetIcon } from "../utils/Icons/SvgIcons";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineCalendarMonth } from "react-icons/md";

const BudgetSheetNavbar = ({
  setActive,
  active,
  setYearSheetFilter,
  yearSheetFilter,
  openPop,
  setOpenPop,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDrawer, setFilterDrawer] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);

  const content = (
    <ContentPOP>
      {active == "goals" && (
        <p
          onClick={() => {
            setOpenPop(false);
            setOpenHistory(true);
          }}
          style={{ borderBottom: "1px solid #F1F1F1" }}
        >
          <i>
            <MdOutlineCalendarMonth />
          </i>
          History
        </p>
      )}
      <p
        onClick={() => {
          setOpenPop(false);
          navigate("/private-layout/budget-setting");
        }}
      >
        <i>
          <IoSettingsOutline />
        </i>
        Settings{" "}
      </p>
    </ContentPOP>
  );

  return (
    <NavStyle>
      {openDrawer && (
        <Drawer
          className="filter-drawer"
          placement="right"
          closable={false}
          onClose={() => setFilterDrawer(false)}
          open={openDrawer}
          width={"500px"}
          key="bottom"
        >
          <SummaryFilter drawer={true} setFilterDrawer={setFilterDrawer} />
        </Drawer>
      )}
      {openHistory && (
        <Drawer
          className="filter-drawer"
          prefixCls="historyDrawer"
          placement="right"
          closable={false}
          onClose={() => setOpenHistory(false)}
          open={openHistory}
          width={"450px"}
          key="bottom"
        >
          {active == "goals" && <GoalsHistory closeDrawer={setOpenHistory} />}
          {/* {active == "goals" ? (
            <GoalsHistory closeDrawer={setOpenHistory} />
          ) : (
            <YearHistory closeDrawer={setOpenHistory} />
          )} */}
        </Drawer>
      )}
      <div className="main-content">
        <div className="all-tabs">
          <button
            className={active == "goals" && "active"}
            onClick={() => {
              setActive("goals");
              dispatch(updateDynamicSheetTab("goals"));
            }}
          >
            Goals
          </button>

          <button
            className={active == "yearsheet" && "active"}
            onClick={() => {
              setActive("yearsheet");
              dispatch(updateDynamicSheetTab("yearsheet"));
            }}
          >
            Year Sheet
          </button>

          {/* <button
            className={active == "summary" && "active"}
            onClick={() => {
              setActive("summary");
              dispatch(updateDynamicSheetTab("summary"));
            }}
          >
            Summary Report
          </button> */}
          <button
            className={active == "report" && "active"}
            onClick={() => {
              setActive("report");
              dispatch(updateDynamicSheetTab("report"));
            }}
          >
            Final Report
          </button>
        </div>
        <div className="filter-btn">
          {active == "yearsheet" && (
            <p
              style={{ position: "relative" }}
              className="settingBtn filter-btns"
              onClick={() => setYearSheetFilter(!yearSheetFilter)}
            >
              <FiltersOfSheetIcon /> Filters
              {yearSheetFilter && <FilterAbsolute />}
            </p>
          )}

          <p className="more">
            <Popover
              content={content}
              arrow={false}
              open={openPop}
              onOpenChange={setOpenPop}
              trigger="click"
              prefixCls="dynamic-popover"
              placement="left"
            ></Popover>
          </p>
        </div>
      </div>
    </NavStyle>
  );
};

export default BudgetSheetNavbar;

const FilterAbsolute = styled.div`
  position: absolute;
  width: 100px;
  height: 18px;
  background: #fff;
  bottom: -14px;
  right: 0;
  left: -1px;
  border-left: 1px solid rgb(210, 212, 222);
  border-right: 1px solid rgb(210, 212, 222);
  z-index: 9;
`;

const NavStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .main-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .all-tabs {
      border: 1px solid #d2d4de;
      border-radius: 6px;
      padding: 2px;
      button {
        background: transparent;
        width: fit-content;
        text-align: center;
        padding: 5px 20px;
        color: #000;
        border: none;
        border-left: 2px solid white;
        height: 33px;
        font-size: 13px;
        font-family: Poppins;
        font-style: normal;
        border-radius: 6px;
      }

      .active {
        background: #00a1e6;
        color: white;
      }
    }
    .filter-btn {
      display: flex;
      align-items: center;
      gap: 20px;
      button {
        background: rgb(36, 36, 36);
        color: rgb(255, 255, 255);
        border-style: none;
        height: 33px;
        font-size: 13px;
        padding: 10px 12px;
        border-radius: 10px;
        cursor: pointer;
        width: 100px;
        font-family: Poppins;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
      .settingBtn {
        background: rgb(36, 36, 36);
        color: rgb(255, 255, 255);
        border-style: none;
        font-size: 13px;
        padding: 10px 12px;
        cursor: pointer;
        font-family: Poppins;
        margin-top: 20px;
        width: 100px;
        text-align: center;
        margin: 0px;
        display: flex;
        align-items: center;
        justify-content: center;

        height: 34px;
        min-width: 68px;
        padding: 8px 12px 8px 12px;
        border-radius: 6px;
      }
      .filter-btns {
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        color: black;
        border: 1px solid #d2d4de;
        /* border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; */
      }
      .more {
        border-style: none;
        font-size: 13px;
        cursor: pointer;
        font-family: Poppins;
        margin-top: 20px;
        text-align: center;
        margin: 0px;
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 10px;

        height: 34px;
        padding: 8px 0px;
        border-radius: 6px;
      }
    }
  }
`;

const ContentPOP = styled.div`
  // position: fixed;
  // top: 46px;
  // right: 20px;
  // background: #fff;
  // border: 1px solid rgba(0, 0, 0, 0.2);
  // border-radius: 12px;
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px;
  padding: 4px;
  p {
    width: 128px;
    height: 32px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    cursor: pointer;
    padding: 0 10px;
  }

  i {
    margin: 0 5px 0 0;
  }
`;
