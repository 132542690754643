import { ConfigProvider, Switch } from "antd";
import React from "react";
import PropTypes from "prop-types";

export function SwitchField({
  value,
  handleChange,
  name,
  data,
  switchLoading,
  disable
}) {
  return (
    <div style={{ width: "100%" }} className="fields">
      {/* <label>After Hours </label> */}
      <ConfigProvider
        theme={{
          components: {
            Switch: {
              colorTextQuaternary: "#fff",
              colorPrimary: "#FF0000",
              colorPrimaryHover: "#FF0000",
            },
          },
        }}
      >
        <Switch
          // prefixCls="custom-switch"
          checkedChildren="ON"
          unCheckedChildren="OFF"
          value={value}
          style={{
            backgroundColor: value ? "#00E272" : "#e2000d",
          }}
          checked={value}
          loading={
            switchLoading?.id === data?.id && switchLoading?.name === name
          }
          onChange={(e) => handleChange(!e, name, data)}
          disabled={disable}
        />
      </ConfigProvider>
    </div>
  );
}

SwitchField.propTypes = {
  value: PropTypes.bool,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  data: PropTypes.any,
  switchLoading: PropTypes.bool,
};
