import { Select } from "antd";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { summaryFilterDetails } from "../../app/Auth/signIn/LogInSlice";
import { filterClientsBySection } from "../../utils/common/commonFunction";

const SummaryFilter = ({ setFilterDrawer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newFilters = useSelector(
    (state) => state?.loginAuth?.summarySectionFilter
  );
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );
  const [clientOptions, setclientOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );
  const clientOptionsData = useSelector(
    (state) => state?.loginAuth?.clientsList
  );
  const getClientOption = () => {
    let arr = [];
    filterClientsBySection(clientOptionsData?.clients, "dynamic-budget-sheet")
      ?.length > 0 &&
      // eslint-disable-next-line array-callback-return
      filterClientsBySection(
        clientOptionsData?.clients,
        "dynamic-budget-sheet"
      )?.map((ele) => {
        let obj = {
          ...ele,
          label: ele?.name,
          value: ele?.name,
        };
        arr.push(obj);
      });
    setclientOptions(arr);
  };
  const initialValues = {
    clientKey: "",
  };
  const submitHandler = () => {
    console.log("heyamtriggered");
    let reauestedPayload = {
      selectedClient: selectedClient,
    };
    dispatch(summaryFilterDetails(reauestedPayload));
    setFilterDrawer(false);
  };

  useEffect(() => {
    getClientOption();
  }, []);

  return (
    <SummaryFilterWrapper selectedThemeColors={selectedThemeColors}>
      <div className="inner-part">
        <div className="content-box">
          <div className="filters">
            <h4>Filters</h4>
            <Formik initialValues={initialValues} onSubmit={submitHandler}>
              {({ setFieldValue }) => (
                <Form>
                  <div>
                    <label>
                      <strong>Client</strong>
                    </label>
                    <Select
                      size={"large"}
                      // className="inner-select"
                      name="clientKey"
                      prefixCls="inner-select2"
                      defaultValue={
                        newFilters?.selectedClient
                          ? newFilters?.selectedClient
                          : prefilledData?.selectedClient ?? selectedClient
                      }
                      onChange={(_, data) => setSelectedClient(data)}
                      style={{
                        width: "100%",
                        height: "48px",
                        // backgroundColor: "#05031a",
                      }}
                      maxTagCount={1}
                      placeholder="Please select"
                      options={clientOptions}
                      // popupClassName="source-select-popover"
                      dropdownStyle={{
                        maxHeight: "300px",
                      }}
                    />
                  </div>

                  <button type="submit" className="summaryApplyBtn">
                    Apply
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </SummaryFilterWrapper>
  );
};

export default SummaryFilter;

const SummaryFilterWrapper = styled.div`
  .inner-part {
    .content-box {
      .filters {
        label {
          color: ${({ selectedThemeColors }) => selectedThemeColors?.lightText};

          display: block;
          margin-bottom: 0.25rem;
        }
        .inner-select2 {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          appearance: none;
          height: 50px;
          width: 100%;
          margin-top: 4px;
          padding: 0px 0px;
          border: 1px solid rgba(206, 206, 206, 1);
          border-radius: 10px;
          color: #000;
          background: #fff;

          .anticon-close {
            color: #000 !important;
          }

          .inner-select2-selector {
            border: none !important;
          }
          .inner-select2-selection-placeholder {
            color: ${({ selectedThemeColors }) =>
              selectedThemeColors?.inputPlaceHolder} !important;
          }
          .inner-select2-suffix {
            color: rgba(146, 146, 165, 1) !important;
          }
        }
        .settingBtn {
          background: rgb(36, 36, 36);
          color: rgb(255, 255, 255);
          border-style: none;
          padding: 10px 15px;
          border-radius: 10px;
          cursor: pointer;
          width: 100px;
          font-family: Poppins;
          margin-top: 20px;
          width: 100%;
          margin-bottom: 10px;
          text-align: center;
        }
        .summaryApplyBtn {
          font-family: Poppins;
          background-color: rgb(216, 12, 12);
          color: rgb(255, 255, 255);
          text-align: center;
          width: 100%;
          font-weight: 700;
          height: 40px;
          border-radius: 8px;
          border: none;
          margin-top: 10px;
          font-size: 13px;
        }
      }
    }
  }
`;
