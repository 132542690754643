import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { NotificationAlert } from "../../utils/Icons/SvgIcons";

const TabContainer = styled.div`
  display: flex;
  background-color: transparent;
  border-radius: 6px;
  padding: 4px;
  position: relative;
  border: 1px solid #d2d4de;
  width: fit-content;
`;

const TabButton = styled.button`
  position: relative;
  width: 100%;
  padding: 4px 24px 4px 16px;
  border: none;
  background-color: transparent;
  color: ${(props) =>
    props.color ? props.color : props.active ? "white" : "#333"};
  border-radius: 6px;
  cursor: pointer;
  transition: color 0.3s ease;
  position: relative;
  z-index: 1;
  white-space: nowrap;
  font-size: 14px;

  span {
    position: absolute;
    right: 4px;
  }

  &:hover {
    color: ${(props) => (props.active ? "white" : "#00A1E6")};
  }
`;

const ActiveBackground = styled.div`
  position: absolute;
  background-color: ${({ bg }) => (bg ? "#00a1e6" : "transparent")};
  border-radius: 6px;
  transition: all 0.3s ease;
  z-index: 0;
`;

export default function Component({
  index = null,
  tabs,
  selectedIndex,
  disableBtnsOnLoading = false,
  color,
  isIndicator = [],
}) {
  const [activeTab, setActiveTab] = useState(index);
  const [activeBackgroundStyle, setActiveBackgroundStyle] = useState({});
  const tabRefs = useRef([]);

  useEffect(() => {
    const updateActiveTabStyle = () => {
      const activeTabElement = tabRefs.current[activeTab];

      if (activeTabElement) {
        setActiveBackgroundStyle({
          width: `${activeTabElement.offsetWidth}px`,
          height: `${activeTabElement.offsetHeight}px`,
          left: `${activeTabElement.offsetLeft}px`,
          top: `${activeTabElement.offsetTop}px`,
        });
      }
    };
    requestAnimationFrame(updateActiveTabStyle);
    window.addEventListener("load", updateActiveTabStyle);
    return () => window.removeEventListener("load", updateActiveTabStyle);
  }, [activeTab]);

  useEffect(() => {
    setActiveTab(index);
  }, [index]);

  return (
    <TabContainer>
      {tabs.map((tab, index) => (
        <>
          <ActiveBackground
            style={activeBackgroundStyle}
            bg={activeTab == index}
          />
          <TabButton
            color={color}
            key={tab}
            ref={(el) => (tabRefs.current[index] = el)}
            active={activeTab === index}
            disabled={activeTab === index ? false : disableBtnsOnLoading}
            onClick={() => {
              setActiveTab(index);
              selectedIndex(index);
            }}
          >
            {tab}

            {/* It is for Chat Indicator  in booking buddy module */}
            {isIndicator?.includes(tab) && (
              <span>
                <NotificationAlert
                  background={activeTab == index ? "#ffffff" : "#00A1E6"}
                  dotColor={activeTab == index ? "#00A1E6" : "#ffffff"}
                />
              </span>
            )}
          </TabButton>
        </>
      ))}
    </TabContainer>
  );
}
