import React, { useEffect, useRef, useState } from "react";
import AIBookingConversation from "./AIBookingConversation";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import AiFilter from "./AiFilter";
import Watermark from "../../components/Watermark";

export default function ConversationGround() {
  const selectedCompany = useSelector(
    (state) => state?.aiBooking?.selectedCompany
  );
  const [filterDrawers, setFilterDrawers] = useState(false);
  const cancelToken = useRef(null);
  const [companyList, setCompanyList] = useState([]);
  const AiDate = useSelector((state) => state?.aiBooking);
  const clientOptionsData = useSelector(
    (state) => state?.loginAuth?.clientsList
  );

  const getClientOption = () => {
    let arr = [];
    clientOptionsData?.clients?.length > 0 &&
      clientOptionsData?.clients?.map((ele) => {
        if (ele?.company?.id) {
          let obj = {
            label: ele?.name,
            value: ele?.company?.id,
            logo: ele?.logo,
            name: ele?.name,
            key: ele?.key,
          };
          arr.push(obj);
        }
      });
    setCompanyList(arr);
  };

  useEffect(() => {
    clientOptionsData?.clients?.length > 0 && getClientOption();
  }, [selectedCompany, AiDate?.startDate, AiDate?.endDate]);

  return (
    <>
      <ConversationGroundWrapper>
        <Header
          title="Conversations"
          clientInfo={selectedCompany}
          isFilter={true}
          openFilter={() => setFilterDrawers(true)}
          isNotification={true}
        />
        <AIBookingConversation cancelToken={cancelToken} />
        {filterDrawers && (
          <AiFilter
            filterDrawers={filterDrawers}
            setFilterDrawers={setFilterDrawers}
            setCStatsData={null}
            companyList={companyList}
            initialLoading={false}
            stopApi={true}
          />
        )}
      </ConversationGroundWrapper>
      <Watermark style={{ position:'absolute',bottom:"4px", right:"30px",background:"transparent"}} background="rgb(255 255 255)"/>
    </>
  );
}

const ConversationGroundWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 19px);
  background: #ffffff;
`;
