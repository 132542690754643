import React from "react";
import styled, { keyframes } from "styled-components";

// Animation for dots
const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

// Loader container
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

// Individual dot
const Dot = styled.div`
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: ${({ color }) => color || "#3498db"};
  border-radius: 50%;
  animation: ${bounce} 1.4s infinite;
  animation-delay: ${({ delay }) => delay}s;
`;

// Loader component
const ThreeDotLoader = ({ color }) => {
  return (
    <LoaderContainer>
      <Dot color={color} delay={0} />
      <Dot color={color} delay={0.2} />
      <Dot color={color} delay={0.4} />
    </LoaderContainer>
  );
};

export default ThreeDotLoader;
