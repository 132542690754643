import { Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { getDataFromFilters } from "../../services/Collections";
import { toast } from "react-toastify";
import { dashboardData } from "../../app/Auth/signIn/LogInSlice";
import Header from "../../components/Header";
import Watermark from "../../components/Watermark";
import LoadingBlock from "../../components/LoadingBlock";

const TableListing = () => {
  const [table, setTable] = useState([]);
  const dispatch = useDispatch();
  const selectedThemeColors = useSelector(
    (state) => state?.loginAuth?.themeColors
  );
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );
  const databoardTables = useSelector(
    (state) => state?.loginAuth?.dashboardData
  );
  const [loading, setLoading] = useState(true);
  const { Column, ColumnGroup } = Table;
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 40,
      }}
      spin
    />
  );
  const manupilateTableData = (data) => {
    let currentArray = [];

    let compairArray = [];
    if (data?.current?.datatable) {
      let tempObj = {};
      for (let index = 0; index < data?.current?.datatable.length; index++) {
        let el = data?.current?.datatable[index];
        tempObj = {
          name: el?.lead_source,
          current_cost: el?.cost,
          current_cost_per_lead: el?.cost_per_lead,
          current_cost_per_job: el?.cost_per_job,
          current_online_forms: el?.online_forms,
          current_inbound_calls: el?.inbound_calls,
          current_lead_calls: el?.lead_calls,
          current_lead_calls_perc: el?.lead_calls_perc,
          current_new_customer: el?.unique_inbound_new_customer,
          current_Inbound_booked_calls: el?.booked_calls,
          current_excused_rate: el?.excused_calls,
          current_excused_rate_perc: el?.excused_rate,
          current_abandoned_rate: el?.abandoned_calls,
          current_abandoned_rate_perc: el?.abandoned_rate,
          current_unbooked_calls: el?.unbooked_calls,
          current_unbooked_calls_perc: el?.unbooked_rate,
          current_toatl_booking: el?.total_jobs_booked,
          current_toatl_booking_perc: el?.unique_call_booking_perc,
          current_cancelled_calls: el?.cancelled_calls,
          current_cancelled_calls_perc: el?.cancellation_rate,
          current_number_closed: el?.number_closed,
          current_number_closed_perc: el?.closing_perc,
        };
        currentArray.push(tempObj);
      }
    }

    if (data?.compare?.datatable) {
      let tempObj = {};
      for (let index = 0; index < data?.compare?.datatable.length; index++) {
        let el = data?.compare?.datatable[index];
        tempObj = {
          compare_cost: el?.cost,
          compare_cost_per_lead: el?.cost_per_lead,
          compare_cost_per_job: el?.cost_per_job,
          compare_online_forms: el?.online_forms,
          compare_inbound_calls: el?.inbound_calls,
          compare_lead_calls: el?.lead_calls,
          compare_lead_calls_perc: el?.lead_calls_perc,
          compare_new_customer: el?.unique_inbound_new_customer,
          compare_Inbound_booked_calls: el?.booked_calls,

          compare_excused_rate: el?.excused_calls,
          compare_excused_rate_perc: el?.excused_rate,

          compare_abandoned_rate: el?.abandoned_calls,
          compare_abandoned_rate_perc: el?.abandoned_rate,

          compare_unbooked_calls: el?.unbooked_calls,
          compare_unbooked_calls_perc: el?.unbooked_rate,

          compare_toatl_booking: el?.total_jobs_booked,
          compare_toatl_booking_perc: el?.unique_call_booking_perc,

          compare_cancelled_calls: el?.cancelled_calls,
          compare_cancelled_calls_perc: el?.cancellation_rate,

          compare_number_closed: el?.number_closed,
          compare_number_closed_perc: el?.closing_perc,
        };
        compairArray.push(tempObj);
      }
    }

    if (compairArray.length > 0) {
      let finalArray = [];
      for (let i = 0; i < currentArray.length; i++) {
        for (let j = 0; j < compairArray.length; j++) {
          if (i === j) {
            finalArray.push({ ...currentArray[i], ...compairArray[j] });
          }
        }
      }
      setTable(finalArray);
    } else {
      setTable(currentArray);
    }
  };

  const handleNewListig = async (id, payload) => {
    setLoading(true);
    let res = await getDataFromFilters(id, payload);
    if (res?.status === 200) {
      setLoading(false);
      if (res?.data?.current) {
        dispatch(dashboardData(res?.data));
        setLoading(false);
      } else {
        setLoading(false);
        toast.warning("No Data Found");
      }
    } else {
      setLoading(false);
      // toast.error(res?.message || res?.error || "Something went wrong");
    }
  };

  console.log(prefilledData, "prefilledData");

  useEffect(() => {
    let requestPayload = {
      campaigns: prefilledData?.campaigns,
      compare_enable: prefilledData?.compare_enable,
      date_compare_end: prefilledData?.date_compare_end,
      date_compare_start: prefilledData?.date_compare_start,
      date_end: prefilledData?.date_end,
      date_start: prefilledData?.date_start,
    };
    handleNewListig(prefilledData?.selectedClient?.key, requestPayload);
  }, []);

  useEffect(() => {
    manupilateTableData(databoardTables);
  }, [databoardTables]);

  return (
    <>
      <Header
        title="Marketing Tracker"
        clientInfo={prefilledData?.selectedClient}
        isNotification={true}
      />

      <TableWrapper selectedThemeColors={selectedThemeColors}>
        {loading ? (
          <LoadingBlock height={"calc(100vh - 122px)"} size={28} />
        ) : (
          <>
            {databoardTables &&
            databoardTables?.current &&
            databoardTables?.compare ? (
              <Table
                prefixCls="custom-double-ui-table"
                dataSource={table}
                pagination={false}
                scroll={{
                  x: "100%",
                  y: "calc(100vh - 225px)",
                }}
              >
                <Column
                  title="SOURCE"
                  dataIndex="name"
                  key="name"
                  width="150px"
                  fixed="left"
                  render={(item) => <span key={item}>{item}</span>}
                />

                <ColumnGroup title="COST">
                  <Column
                    title="CURRENT"
                    dataIndex="current_cost"
                    key="current_cost"
                    width="100px"
                  />
                  <Column
                    title="COMPARED"
                    dataIndex="compare_cost"
                    key="compare_cost"
                    width="120px"
                  />
                </ColumnGroup>

                <ColumnGroup title="COST PER LEAD">
                  <Column
                    title="CURRENT"
                    dataIndex="current_cost_per_lead"
                    key="current_cost_per_lead"
                    width="100px"
                  />
                  <Column
                    title="COMPARED"
                    dataIndex="compare_cost_per_lead"
                    key="compare_cost_per_lead"
                    width="120px"
                  />
                </ColumnGroup>

                <ColumnGroup title="COST PER JOB">
                  <Column
                    title="CURRENT"
                    dataIndex="current_cost_per_job"
                    key="current_cost_per_job"
                    width="100px"
                  />
                  <Column
                    title="COMPARED"
                    dataIndex="compare_cost_per_job"
                    key="compare_cost_per_job"
                    width="120px"
                  />
                </ColumnGroup>

                <ColumnGroup title="FORMS">
                  <Column
                    title="CURRENT"
                    dataIndex="current_online_forms"
                    key="current_online_forms"
                    width="100px"
                  />
                  <Column
                    title="COMPARED"
                    dataIndex="compare_online_forms"
                    key="compare_online_forms"
                    width="120px"
                  />
                </ColumnGroup>

                <ColumnGroup title="INBOUND CALLS">
                  <Column
                    title="CURRENT"
                    dataIndex="current_inbound_calls"
                    key="current_inbound_calls"
                    width="100px"
                  />
                  <Column
                    title="COMPARED"
                    dataIndex="compare_inbound_calls"
                    key="compare_inbound_calls"
                    width="120px"
                  />
                </ColumnGroup>

                <ColumnGroup title="LEAD CALLS %">
                  <Column
                    title="CURRENT"
                    dataIndex="current_lead_calls"
                    key="current_lead_calls"
                    width="100px"
                    render={(_, data) => (
                      <>
                        <span key={data?.current_lead_calls_perc}>
                          {data?.current_lead_calls_perc}%
                        </span>
                        <p
                          style={{ fontSize: "11px" }}
                          key={data?.current_lead_calls}
                        >
                          ({data?.current_lead_calls})
                        </p>
                      </>
                    )}
                  />
                  <Column
                    title="COMPARED"
                    dataIndex="compare_lead_calls"
                    key="compare_lead_calls"
                    width="120px"
                    render={(_, data) => (
                      <>
                        <span key={data?.compare_lead_calls_perc}>
                          {data?.compare_lead_calls_perc}%
                        </span>
                        <p
                          style={{ fontSize: "11px" }}
                          key={data?.compare_lead_calls}
                        >
                          ({data?.compare_lead_calls})
                        </p>
                      </>
                    )}
                  />
                </ColumnGroup>

                <ColumnGroup title="NEW CUSTOMERS">
                  <Column
                    title="CURRENT"
                    dataIndex="current_new_customer"
                    key="current_new_customer"
                    width="100px"
                  />
                  <Column
                    title="COMPARED"
                    dataIndex="compare_new_customer"
                    key="compare_new_customer"
                    width="120px"
                  />
                </ColumnGroup>

                <ColumnGroup title="INBOUND CALL BOOKED">
                  <Column
                    title="CURRENT"
                    dataIndex="current_Inbound_booked_calls"
                    key="current_Inbound_booked_calls"
                    width="100px"
                  />
                  <Column
                    title="COMPARED"
                    dataIndex="compare_Inbound_booked_calls"
                    key="compare_Inbound_booked_calls"
                    width="120px"
                  />
                </ColumnGroup>

                <ColumnGroup title="EXCUSED RATE">
                  <Column
                    title="CURRENT"
                    dataIndex="current_excused_rate"
                    key="current_excused_rate"
                    width="100px"
                    render={(_, data) => (
                      <>
                        <span key={data?.current_excused_rate_perc}>
                          {data?.current_excused_rate_perc}%
                        </span>
                        <p
                          style={{ fontSize: "11px" }}
                          key={data?.current_excused_rate}
                        >
                          ({data?.current_excused_rate})
                        </p>
                      </>
                    )}
                  />
                  <Column
                    title="COMPARED"
                    dataIndex="compare_excused_rate"
                    key="compare_excused_rate"
                    width="120px"
                    render={(_, data) => (
                      <>
                        <span key={data?.compare_excused_rate_perc}>
                          {data?.compare_excused_rate_perc}%
                        </span>
                        <p
                          style={{ fontSize: "11px" }}
                          key={data?.compare_excused_rate}
                        >
                          ({data?.compare_excused_rate})
                        </p>
                      </>
                    )}
                  />
                </ColumnGroup>

                <ColumnGroup title="ABANDONED RATE">
                  <Column
                    title="CURRENT"
                    dataIndex="current_abandoned_rate"
                    key="current_abandoned_rate"
                    width="100px"
                    render={(_, data) => (
                      <>
                        <span key={data?.current_abandoned_rate_perc}>
                          {data?.current_abandoned_rate_perc}%
                        </span>
                        <p
                          style={{ fontSize: "11px" }}
                          key={data?.current_abandoned_rate}
                        >
                          ({data?.current_abandoned_rate})
                        </p>
                      </>
                    )}
                  />
                  <Column
                    title="COMPARED"
                    dataIndex="compare_abandoned_rate"
                    key="compare_abandoned_rate"
                    width="120px"
                    render={(_, data) => (
                      <>
                        <span key={data?.compare_abandoned_rate_perc}>
                          {data?.compare_abandoned_rate_perc}%
                        </span>
                        <p
                          style={{ fontSize: "11px" }}
                          key={data?.compare_abandoned_rate}
                        >
                          ({data?.compare_abandoned_rate})
                        </p>
                      </>
                    )}
                  />
                </ColumnGroup>

                <ColumnGroup title="UNBOOKED">
                  <Column
                    title="CURRENT"
                    dataIndex="current_unbooked_calls"
                    key="current_unbooked_calls"
                    width="100px"
                    render={(_, data) => (
                      <>
                        <span key={data?.current_unbooked_calls_perc}>
                          {data?.current_unbooked_calls_perc}%
                        </span>
                        <p
                          style={{ fontSize: "11px" }}
                          key={data?.current_unbooked_calls}
                        >
                          ({data?.current_unbooked_calls})
                        </p>
                      </>
                    )}
                  />
                  <Column
                    title="COMPARED"
                    dataIndex="compare_current_unbooked_calls_calls"
                    key="compare_current_unbooked_calls_calls"
                    width="120px"
                    render={(_, data) => (
                      <>
                        <span key={data?.compare_unbooked_calls_perc}>
                          {data?.compare_unbooked_calls_perc}%
                        </span>
                        <p
                          style={{ fontSize: "11px" }}
                          key={data?.compare_unbooked_calls}
                        >
                          ({data?.compare_unbooked_calls})
                        </p>
                      </>
                    )}
                  />
                </ColumnGroup>

                <ColumnGroup title="TOTAL BOOKING">
                  <Column
                    title="CURRENT"
                    dataIndex="current_toatl_booking"
                    key="current_toatl_booking"
                    width="100px"
                    render={(_, data) => (
                      <>
                        <span key={data?.current_toatl_booking_perc}>
                          {data?.current_toatl_booking_perc}%
                        </span>
                        <p
                          style={{ fontSize: "11px" }}
                          key={data?.current_toatl_booking}
                        >
                          ({data?.current_toatl_booking})
                        </p>
                      </>
                    )}
                  />
                  <Column
                    title="COMPARED"
                    dataIndex="compare_toatl_booking"
                    key="compare_toatl_booking"
                    width="120px"
                    render={(_, data) => (
                      <>
                        <span key={data?.compare_toatl_booking_perc}>
                          {data?.compare_toatl_booking_perc}%
                        </span>
                        <p
                          style={{ fontSize: "11px" }}
                          key={data?.compare_toatl_booking}
                        >
                          ({data?.compare_toatl_booking})
                        </p>
                      </>
                    )}
                  />
                </ColumnGroup>

                <ColumnGroup title="CANCELLATION %">
                  <Column
                    title="CURRENT"
                    dataIndex="current_cancelled_calls"
                    key="current_cancelled_calls"
                    width="100px"
                    render={(_, data) => (
                      <>
                        <span key={data?.current_cancelled_calls_perc}>
                          {data?.current_cancelled_calls_perc}%
                        </span>
                        <p
                          style={{ fontSize: "11px" }}
                          key={data?.current_cancelled_calls}
                        >
                          ({data?.current_cancelled_calls})
                        </p>
                      </>
                    )}
                  />
                  <Column
                    title="COMPARED"
                    dataIndex="compare_cancelled_calls"
                    key="compare_cancelled_calls"
                    width="120px"
                    render={(_, data) => (
                      <>
                        <span key={data?.compare_cancelled_calls_perc}>
                          {data?.compare_cancelled_calls_perc}%
                        </span>
                        <p
                          style={{ fontSize: "11px" }}
                          key={data?.compare_cancelled_calls}
                        >
                          ({data?.compare_cancelled_calls})
                        </p>
                      </>
                    )}
                  />
                </ColumnGroup>

                <ColumnGroup title="CLOSING">
                  <Column
                    title="CURRENT"
                    dataIndex="current_number_closed"
                    key="current_number_closed"
                    width="100px"
                    render={(_, data) => (
                      <>
                        <span key={data?.current_number_closed_perc}>
                          {data?.current_number_closed_perc}%
                        </span>
                        <p
                          style={{ fontSize: "11px" }}
                          key={data?.current_number_closed}
                        >
                          ({data?.current_number_closed})
                        </p>
                      </>
                    )}
                  />

                  <Column
                    title="COMPARED"
                    dataIndex="compare_number_closed"
                    key="compare_number_closed"
                    width="120px"
                    render={(_, data) => (
                      <>
                        <span key={data?.compare_number_closed_perc}>
                          {data?.compare_number_closed_perc}%
                        </span>
                        <p
                          style={{ fontSize: "11px" }}
                          key={data?.compare_number_closed}
                        >
                          ({data?.compare_number_closed})
                        </p>
                      </>
                    )}
                  />
                </ColumnGroup>
              </Table>
            ) : (
              <Table
                prefixCls="custom-ui-table"
                dataSource={table}
                pagination={false}
                scroll={{
                  x: "100%",
                  y: "calc(100vh - 176px)",
                }}
              >
                <Column
                  title="SOURCE"
                  dataIndex="name"
                  key="name"
                  width="150px"
                  fixed="left"
                  render={(item) => (
                    <span style={{ display: "block" }} key={item}>
                      {item}
                    </span>
                  )}
                />
                <Column
                  title="COST"
                  dataIndex="current_cost"
                  key="current_cost"
                  width="100px"
                />
                <Column
                  title="COST PER LEAD"
                  dataIndex="current_cost_per_lead"
                  key="current_cost_per_lead"
                  width="150px"
                />
                <Column
                  title="COST PER JOB"
                  dataIndex="current_cost_per_job"
                  key="current_cost_per_job"
                  width="200px"
                />
                <Column
                  title="FORMS"
                  dataIndex="current_online_forms"
                  key="current_online_forms"
                  width="200px"
                />
                <Column
                  title="INBOUND CALLS"
                  dataIndex="current_inbound_calls"
                  key="current_inbound_calls"
                  width="200px"
                />
                <Column
                  title="LEAD CALLS %"
                  dataIndex="current_lead_calls"
                  key="current_lead_calls"
                  width="200px"
                  render={(_, data) => (
                    <>
                      <span key={data?.current_lead_calls_perc}>
                        {data?.current_lead_calls_perc}%
                      </span>
                      <p
                        style={{ fontSize: "11px" }}
                        key={data?.current_lead_calls}
                      >
                        ({data?.current_lead_calls})
                      </p>
                    </>
                  )}
                />
                <Column
                  title="NEW CUSTOMERS"
                  dataIndex="current_new_customer"
                  key="current_new_customer"
                  width="200px"
                />
                <Column
                  title="INBOUND CALL BOOKED"
                  dataIndex="current_Inbound_booked_calls"
                  key="current_Inbound_booked_calls"
                  width="250px"
                />
                <Column
                  title="EXCUSED RATE"
                  dataIndex="current_excused_rate"
                  key="current_excused_rate"
                  width="200px"
                  render={(_, data) => (
                    <>
                      <span key={data?.current_excused_rate_perc}>
                        {data?.current_excused_rate_perc}%
                      </span>
                      <p
                        style={{ fontSize: "11px" }}
                        key={data?.current_excused_rate}
                      >
                        ({data?.current_excused_rate})
                      </p>
                    </>
                  )}
                />
                <Column
                  title="ABANDONED RATE"
                  dataIndex="current_abandoned_rate"
                  key="current_abandoned_rate"
                  width="200px"
                  render={(_, data) => (
                    <>
                      <span key={data?.current_abandoned_rate_perc}>
                        {data?.current_abandoned_rate_perc}%
                      </span>
                      <p
                        style={{ fontSize: "11px" }}
                        key={data?.current_abandoned_rate}
                      >
                        ({data?.current_abandoned_rate})
                      </p>
                    </>
                  )}
                />
                <Column
                  title="UNBOOKED"
                  dataIndex="current_unbooked_calls"
                  key="current_unbooked_calls"
                  width="200px"
                  render={(_, data) => (
                    <>
                      <span key={data?.current_unbooked_calls_perc}>
                        {data?.current_unbooked_calls_perc}%
                      </span>
                      <p
                        style={{ fontSize: "11px" }}
                        key={data?.current_unbooked_calls}
                      >
                        ({data?.current_unbooked_calls})
                      </p>
                    </>
                  )}
                />
                <Column
                  title="TOTAL BOOKING"
                  dataIndex="current_toatl_booking"
                  key="current_toatl_booking"
                  width="200px"
                  render={(_, data) => (
                    <>
                      <span key={data?.current_toatl_booking_perc}>
                        {data?.current_toatl_booking_perc}%
                      </span>
                      <p
                        style={{ fontSize: "11px" }}
                        key={data?.current_toatl_booking}
                      >
                        ({data?.current_toatl_booking})
                      </p>
                    </>
                  )}
                />
                <Column
                  title="CANCELLATION %"
                  dataIndex="current_cancelled_calls"
                  key="current_cancelled_calls"
                  width="200px"
                  render={(_, data) => (
                    <>
                      <span key={data?.current_cancelled_calls_perc}>
                        {data?.current_cancelled_calls_perc}%
                      </span>
                      <p
                        style={{ fontSize: "11px" }}
                        key={data?.current_cancelled_calls}
                      >
                        ({data?.current_cancelled_calls})
                      </p>
                    </>
                  )}
                />
                <Column
                  title="CLOSING"
                  dataIndex="current_number_closed"
                  key="current_number_closed"
                  width="200px"
                  render={(_, data) => (
                    <>
                      <span key={data?.current_number_closed_perc}>
                        {data?.current_number_closed_perc || "0"}%
                      </span>
                      <p
                        style={{ fontSize: "11px" }}
                        key={data?.current_number_closed}
                      >
                        ({data?.current_number_closed || "0"})
                      </p>
                    </>
                  )}
                />
              </Table>
            )}
          </>
        )}
      </TableWrapper>
      <Watermark style={{ paddingRight: "10px" }} />
    </>
  );
};

export default TableListing;

const TableWrapper = styled.div`
  background: #f3f3f3;
  width: 100%;
  height: calc(100vh - 80px);
  padding: 20px;
`;
