import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListingProposalsHistoryApi } from "../../services/Collections";
import {
  Box,
  Container,
  DefaultButton,
  Email,
  HistoryStyle,
  UserName,
} from "./Goals/GoalsHistory";
import { ModalLoader } from "../../components/Loader";
import styled from "styled-components";
import { Calendar, Clock } from "lucide-react";
import {
  activeHistorySheet,
  activeHistorySheetData,
  updateGoalPrediction,
  updateYearData,
  updateYearSheetFilters,
} from "../exceltable/ExcelStore";
import { dateFunc, timeFunc } from "../../utils/common/commonFunction";
import { GoalHistoryCrossIcon } from "../../utils/Icons/SvgIcons";
import LoadingBlock from "../../components/LoadingBlock";

const YearHistory = ({ closeDrawer }) => {
  const dispatch = useDispatch();
  const prefilledFilters = useSelector(
    (state) => state?.loginAuth?.summarySectionFilter
  );
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );

  const activeClient =
    prefilledFilters == null
      ? prefilledData?.selectedClient?.key
      : prefilledFilters?.selectedClient?.key;
  const activeHistory = useSelector((state) => state?.dynamicBudgetSheet);
  const loginAuth = useSelector((state) => state?.loginAuth);

  const [sheetHistory, setSheetHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedBox, setSelectedBox] = useState(
    activeHistory?.selectedHistory
  );

  const handleBoxClick = (id, data) => {
    if (id === selectedBox) {
      setSelectedBox(null);
      dispatch(activeHistorySheet(null));
      dispatch(updateGoalPrediction(null));
      dispatch(updateYearData(null));
    } else {
      setSelectedBox(id);
      dispatch(activeHistorySheet(id));
      // closeDrawer(false);
    }
  };

  const handleSheetListing = async () => {
    try {
      setLoading(true);
      let params = new URLSearchParams();
      params.append("user_id", loginAuth?.data?.id);
      params.append("client_key", activeClient);
      let res = await getListingProposalsHistoryApi(activeClient, params);
      if (res?.status === 200) { 
        setSheetHistory(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
        message.error("Something went wrong");
      }
    } catch (error) {
      setLoading(false);
      console.log("error in businessUnitSubCategories", error);
    }
  };

  const handleYearSheetFilter = (user) => {
    console.log(user, "handleYearSheetFilter");
    if (user?.sheet) {
      let sheet = user?.sheet;
      if (sheet?.yearSheetFilters)
        dispatch(updateYearSheetFilters(sheet?.yearSheetFilters));
      if (sheet?.goalPrediction)
        dispatch(updateGoalPrediction(sheet?.goalPrediction));
      if (sheet?.persistYearData)
        dispatch(updateYearData(sheet?.persistYearData));
    }

    //updateGoalPrediction --------  state.GoalPrediction
    //updateYearData    -------------- state.yearData.current
    // updateYearSheetFilters --------- state.yearSheetFilters
  };

  useEffect(() => {
    handleSheetListing();
  }, []);

  return (
    <HistoryStyle>
      <div className="history">
        <div className="header">
          <div className="title">Year History</div>
          <i style={{ cursor: "pointer" }} onClick={() => closeDrawer(false)}>
            <GoalHistoryCrossIcon />
          </i>
        </div>
        {loading ? (
          <div className="loadingWrapper">
            <LoadingBlock height={"fit-content"} size={28} />
          </div>
        ) : (
          <Container>
            {sheetHistory?.length > 0 ? (
              sheetHistory?.map((user, index) => (
                <>
                  {index == 0 && <hr></hr>}
                  <Box key={index}>
                    <UserName>{user?.sheet?.historyTitle}</UserName>
                    <Email>{user?.user?.email}</Email>
                    <div className="defaultSection">
                      <div className="dateTimeWrap gap">
                        <div className="dateTimeWrap">
                          <IconWrapper>
                            <Calendar color="#7A7B7A" size={18} />
                          </IconWrapper>
                          <Email style={{ color: "#7A7B7A" }}>
                            {dateFunc(user.save_date)}
                          </Email>
                        </div>
                        <div className="dateTimeWrap">
                          <IconWrapper>
                            <Clock color="#7A7B7A" size={18} />
                          </IconWrapper>
                          <Email style={{ color: "#7A7B7A" }}>
                            {timeFunc(user.save_date)}
                          </Email>
                        </div>
                      </div>
                      <DefaultButton
                        active={index == selectedBox ? true : false}
                        onClick={() => {
                          handleYearSheetFilter(user);
                          handleBoxClick(index, user?.sheet);
                        }}
                      >
                        {index == selectedBox ? "Active" : "Set as Active"}{" "}
                      </DefaultButton>
                    </div>
                  </Box>
                  <hr></hr>
                </>
              ))
            ) : (
              <div className="noData">No Data Found</div>
            )}
          </Container>
        )}
      </div>
    </HistoryStyle>
  );
};

export default YearHistory;

export const Circle = styled.div`
  width: 48px;
  height: 48px;
  background-color: black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 18px;
  margin-right: 16px;
  text-transform: uppercase;
`;

export const Name = styled.h2`
  font-size: 20px;
  font-weight: 600;
  color: black;
`;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  color: black;
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

export const InfoText = styled.span`
  color: #333;
  margin-right: 16px;
`;
