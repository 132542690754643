import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ExcelYearSheet from "../exceltable/ExcelYearSheet";
import BudgetSalesGraph from "./BudgetSalesGraph";
import { Button, DatePicker, message, Select } from "antd";
import {
  businessUnitCategories,
  businessUnitSubCategories,
  getListingGoalPredictionApi,
  predictionDefaultValue,
  proposalsHistoryApi,
  proposalsListingApi,
} from "../../services/Collections";
import { useDispatch, useSelector } from "react-redux";
import { getRandomColor } from "../../utils/common/commonFunction";
import { ModalLoader } from "../../components/Loader";
import moment from "moment";
import { CalendarDays } from "lucide-react";
import YearComponent from "./Year/YearComponent";
import TotalComponent from "./Year/TotalComponent";
import DifferenceComponent from "./Year/DifferenceComponent";
import {
  calculateTotals,
  checkForInfinity,
  formatInteger,
  formatNumberDecimal,
  getMonthNamesBetween,
  predictionCalculator,
} from "./Year/commonFuc";
import {
  activeHistorySheet,
  activeHistorySheetData,
  updateGoalPrediction,
  updatePredicationLabelValue,
  updateYearData,
  updateYearSheetFilters,
} from "../exceltable/ExcelStore";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import LoadingBlock from "../../components/LoadingBlock";
import { months } from "./finalReport/FinalReport";
const { Option } = Select;
export const PickerWithType = ({ type, onChange, value }) => {
  return <DatePicker picker={type} value={value} onChange={onChange} />;
};
const tObject = {
  category_name: "",
  budget: 0,
  sales: 0,
  roas: 0,
  leads: 0,
  cpl: 0,
  appts: 0,
  cpa: 0,
  con_per: 0,
  jobs: 0,
  cpj: 0,
  close_per: 0,
  avg_ticket: 0,
};

const rangePresets = [
  {
    label: "This Month",
    value: [dayjs().startOf("month"), dayjs().endOf("month")],
  },
  {
    label: "Last Month",
    value: [
      dayjs().subtract(1, "month").startOf("month"),
      dayjs().subtract(1, "month").endOf("month"),
    ],
  },
  {
    label: "This Year",
    value: [dayjs().startOf("year"), dayjs().endOf("year")],
  },
  {
    label: "Last Year",
    value: [
      dayjs().subtract(1, "year").startOf("year"),
      dayjs().subtract(1, "year").endOf("year"),
    ],
  },
];

const sortByFilter = [
  // {
  //   label: "Budget  A-Z",
  //   value: "budget-asc",
  //   option: { name: "budget", type: "asc" },
  // },
  {
    label: "Budget",
    value: "budget-desc",
    option: { name: "budget", type: "desc" },
  },

  // {
  //   label: "Sales  A-Z",
  //   value: "sales-asc",
  //   option: { name: "sales", type: "asc" },
  // },
  {
    label: "Revenue",
    value: "sales-desc",
    option: { name: "sales", type: "desc" },
  },

  // {
  //   label: "Roas  A-Z",
  //   value: "roas-asc",
  //   option: { name: "roas", type: "asc" },
  // },
  {
    label: "Roas",
    value: "roas-desc",
    option: { name: "roas", type: "desc" },
  },

  // {
  //   label: "Leads  A-Z",
  //   value: "leads-asc",
  //   option: { name: "leads", type: "asc" },
  // },
  {
    label: "Leads",
    value: "leads-desc",
    option: { name: "leads", type: "desc" },
  },

  // {
  //   label: "Appts  A-Z",
  //   value: "appts-asc",
  //   option: { name: "appts", type: "asc" },
  // },
  {
    label: "Appts",
    value: "appts-desc",
    option: { name: "appts", type: "desc" },
  },

  {
    label: "Cost per Appts",
    value: "costappts-asc",
    option: { name: "cpa", type: "asc" },
  },
  // {
  //   label: "Cost per Appts",
  //   value: "costappts-desc",
  //   option: { name: "cpa", type: "desc" },
  // },

  // {
  //   label: "Sold Jobs  A-Z",
  //   value: "soldjobs-asc",
  //   option: { name: "jobs", type: "asc" },
  // },
  {
    label: "Sold Jobs",
    value: "soldjobs-desc",
    option: { name: "jobs", type: "desc" },
  },

  {
    label: "Cost per Sold Jobs",
    value: "costsoldjob-asc",
    option: { name: "cpj", type: "asc" },
  },
  // {
  //   label: "Cost per Sold Jobs",
  //   value: "costsoldjob-desc",
  //   option: { name: "cpj", type: "desc" },
  // },

  {
    label: "CPL",
    value: "cpl-asc",
    option: { name: "cpl", type: "asc" },
  },
  // {
  //   label: "CPL",
  //   value: "cpl-desc",
  //   option: { name: "cpl", type: "desc" },
  // },

  // {
  //   label: "Conv%  A-Z",
  //   value: "conv-asc",
  //   option: { name: "con_per", type: "asc" },
  // },
  {
    label: "Conv%",
    value: "conv-desc",
    option: { name: "con_per", type: "desc" },
  },

  // {
  //   label: "Close%  A-Z",
  //   value: "close-asc",
  //   option: { name: "close_per", type: "asc" },
  // },
  {
    label: "Close%",
    value: "close-desc",
    option: { name: "close_per", type: "desc" },
  },

  // {
  //   label: "Avg Ticket  A-Z",
  //   value: "avgticket-asc",
  //   option: { name: "avg_ticket", type: "asc" },
  // },
  {
    label: "Avg Ticket",
    value: "avgticket-desc",
    option: { name: "avg_ticket", type: "desc" },
  },
];

export default function YearSheet({
  handleUnique,
  selectedHistory,
  yearsheetData,
  setYearsheetData,
  yearSheetFilter,
}) {
  const formRef = useRef(null);
  const prefilledFilters = useSelector(
    (state) => state?.loginAuth?.summarySectionFilter
  );
  const prefilledData = useSelector(
    (state) => state?.loginAuth?.prefilledFilters
  );
  const yearSheetFilters = useSelector(
    (state) => state?.dynamicBudgetSheet?.yearSheetFilters
  );
  const activeClient =
    prefilledFilters == null
      ? prefilledData?.selectedClient?.key
      : prefilledFilters?.selectedClient?.key;

  const loginAuth = useSelector((state) => state?.loginAuth);
  const currentDateTime = moment().toISOString();
  const [categoriesListing, setCategoriesListing] = useState([]);
  const [subCategoriesListing, setSubCategoriesListing] = useState([]);
  const [catLoading, setCatLoading] = useState(false);
  const [subCatLoading, setSubCatLoading] = useState(false);
  const [graphsLoading, setGraphsLoading] = useState(false);
  const [graphsData, setGraphsData] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  const [activeBudgetSheet, setActiveBudgetSheet] = useState("current");
  const [submitData, setSubmitData] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [yearTotal, setYearTotal] = useState([]);
  const [falseLoading, setFalseLoading] = useState(false);
  const [goalsCal, setGoalsCal] = useState({ budget: 0, sales: 0 });
  const [sortBy, setSortBy] = useState(null);
  const dispatch = useDispatch();
  const persistYearData = useSelector(
    (state) => state?.dynamicBudgetSheet?.yearData
  );
  const currentYear = dayjs().year();
  const selectedYear = yearSheetFilters?.year || currentYear;
  const uniqueSelectedMonths = yearSheetFilters?.uniqueMonths;
  const [noDataFound, setNoDataFound] = useState(false);


  const onMonthChange = (date, dateString) => {
    if (date == null || date == undefined) {
      dispatch(
        updateYearSheetFilters({
          ...yearSheetFilters,
          month: null,
        })
      );
      return;
    }

    dispatch(
      updateYearSheetFilters({
        ...yearSheetFilters,
        month: date,
      })
    );
    dispatch(updateYearData());
  };

  const handleChangeCategory = (value) => {
    dispatch(
      updateYearSheetFilters({
        ...yearSheetFilters,
        category: value,
      })
    );
    dispatch(updateYearData());
  };

  const handleChangeSubCategory = (value) => {
    dispatch(
      updateYearSheetFilters({
        ...yearSheetFilters,
        subCategory: value,
      })
    );
    dispatch(updateYearData());
  };

  const getCategoriesListing = async () => {
    try {
      setCatLoading(true);
      let res = await businessUnitCategories(activeClient);
      if (res?.status === 200) {
        let updateData = res?.data.map((ele) => ({
          ...ele,
          value: ele?.type,
          label: ele?.type,
        }));
        setCategoriesListing(updateData);
        setCatLoading(false);
      } else {
        message.error("Something went wrong");
        setCatLoading(false);
      }
    } catch (error) {
      setCatLoading(false);
      console.log("error in businessUnitCategories", error);
    }
  };

  const getSubCategoriesListing = async () => {
    try {
      setSubCatLoading(true);
      let res = await businessUnitSubCategories(activeClient);
      if (res?.status === 200) {
        let updateData = res?.data.map((ele) => ({
          ...ele,
          value: ele?.subtype,
          label: ele?.subtype,
        }));
        setSubCategoriesListing(updateData);
        setSubCatLoading(false);
      } else {
        message.error("Something went wrong");
        setSubCatLoading(false);
      }
    } catch (error) {
      setSubCatLoading(false);
      console.log("error in businessUnitSubCategories", error);
    }
  };

  const handleBudgetSheetData = async (goalBudget, goalSales, staticValues) => {
    try {
      setGraphsLoading(true);
      setNoDataFound(false);
      let params = new URLSearchParams();
      yearSheetFilters?.category &&
        yearSheetFilters?.category?.length > 0 &&
        params.append("bu_category", yearSheetFilters?.category);
      yearSheetFilters?.subCategory &&
        yearSheetFilters?.subCategory?.length > 0 &&
        params.append("bu_subcategory", yearSheetFilters?.subCategory);
      yearSheetFilters?.month &&
        params.append("month", yearSheetFilters?.month);
      params.append("year", selectedYear);
      params.append("client_key", activeClient);

      let res = await proposalsListingApi(activeClient, params);
      if (res?.status === 200) {
        if (res.data?.length === 0) {
          // message.error("No data found!");
          setNoDataFound(true);
          setGraphsLoading(false);
          return;
        }

        let budgetArr = [];
        let revenueArr = [];

        res?.data.map((ele) => {
          let color = getRandomColor();
          budgetArr.push({
            name: ele?.category_name,
            value: ele?.budget,
            fill: color,
          });
          revenueArr.push({
            name: ele?.category_name,
            value: ele?.sales,
            fill: color,
          });
        });

        setGraphsData({ budget: budgetArr, revenue: revenueArr });
        let willAddedKey = res?.data?.map((cat) => {
          const baseValues = {
            isChecked: false,
            isParent: true,
            showUi: false,
            // Update the static keys
            // cpl: 150,
            // con_per: 50,
            // close_per: 50,
            // avg_ticket: 1000,
          };
          return {
            ...cat,
            ...baseValues,
          };
        });

        // Add predication dynamic default data
        const categorySum = calculateTotals(willAddedKey);
        let arr = [];

        for (let i = 0; i < willAddedKey.length; i++) {
          const currentObject = willAddedKey[i];
          let item = {};

          // let result = predictionCalculator(
          //   goalBudget,
          //   goalSales,
          //   categorySum,
          //   currentObject
          // );
          // item.budget = result.budget;
          // item.sales = result.sales;
          // item.roas = result.roas;
          // item.jobs = result.jobs;
          // item.appts = result.appts;
          // item.leads = result.leads;
          // item.cpa = result.cpa;
          // item.cpa = result.cpa;
          // item.cpj = result.cpj;
          // item.cpl = result.cpl;
          item.isChecked = true;
          item.isParent = false;
          item.isDynaicBudget = true;

          arr.push(currentObject);
          arr.push({ ...currentObject, ...item });
        }

        dispatch(updateYearData({ categories: arr, name: "current" }));
        setYearsheetData(arr);
        // setYearsheetData(willAddedKey);
        setGraphsLoading(false);
      } else {
        message.error("Something went wrong");
        setGraphsLoading(false);
      }
    } catch (error) {
      setGraphsLoading(false);
      console.log("error in businessUnitSubCategories", error);
    }
  };

  const handleLabelValueOfPredictionTab = async () => {
    try {
      setGraphsLoading(true);
      let res = await predictionDefaultValue(activeClient);
      console.log(res.data, "updatePredicationLabelValue");
      if (res?.status === 200) {
        dispatch(updatePredicationLabelValue(res?.data));
        return res?.data;
      } else {
        dispatch(updatePredicationLabelValue([]));
        message.error("Something went wrong");
        setGraphsLoading(false);
        return [];
      }
    } catch (error) {
      setGraphsLoading(false);
      console.log("error in businessUnitSubCategories", error);
    }
  };

  const handleSaveSheet = async () => {
    try {
      setSubmitLoading(true);
      let payload = {
        save_date: currentDateTime,
        user_id: loginAuth?.data?.id,
        year: moment(new Date()).format("YYYY"),
        month: moment(new Date()).format("MM"),
        business_unit: yearSheetFilters?.category || [],
        service_type: yearSheetFilters?.subCategory || [],
        sheet: submitData?.[0],
      };
      let params = new URLSearchParams();
      params.append("client_key", activeClient);
      let res = await proposalsHistoryApi(activeClient, params, payload);
      if (res?.status === 200) {
        message.success("Saved successfully");
        setSubmitLoading(false);
      } else {
        message.error("Something went wrong");
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
      console.log("error in businessUnitSubCategories", error);
    }
  };

  const applyFilters = async () => {
    dispatch(updateGoalPrediction(null));
    dispatch(updateYearData(null));

    setYearsheetData([]);
    let result = false;
    let totalBudget = 0;
    let totalSales = 0;

    if (selectedYear) {
      setGraphsLoading(true);
      let params = new URLSearchParams();
      params.append("user_id", loginAuth?.data?.id);
      params.append("client_key", activeClient);
      params.append("year", selectedYear);
      let res = await getListingGoalPredictionApi(activeClient, params);

      if (res?.status === 200) {
        if (res?.data?.length) {
          let months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          let monthKeys = yearSheetFilters?.month
            ? months?.filter(
              (el, index) => index == yearSheetFilters?.month - 1
            )
            : months;

          totalBudget = res?.data[0]?.expected_budget_sheet?.nextData?.reduce(
            (acc, el) => {
              return monthKeys?.includes(el?.month) ? acc + el?.budget : acc;
            },
            0
          );

          totalSales = res?.data[0]?.expected_budget_sheet?.nextData?.reduce(
            (acc, el) => {
              return monthKeys?.includes(el?.month) ? acc + el?.revenue : acc;
            },
            0
          );

          let payload = {
            budget: totalBudget,
            sales: totalSales,
            year: selectedYear,
          };
          dispatch(updateGoalPrediction(payload));
          result = true;
        } else {
          message.error(
            "Please define the goal before proceeding with the dynamic budget sheet."
          );
          setGraphsLoading(false);
        }
      } else {
        setGraphsLoading(false);
        message.error("Something went wrong");
      }
    }

    if (result) {
      // let staticValues = handleLabelValueOfPredictionTab(); // fecthing label values of CPA | COV_PER | CLOSE_PER | AVG_TICKET
      handleBudgetSheetData(totalBudget, totalSales); // fetching data for dynamic table
      dispatch(activeHistorySheetData(null));
      dispatch(activeHistorySheet(null));
    }
  };

  const handleButtonClick = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  useEffect(() => {
    setFalseLoading(true);
    setTimeout(() => {
      setFalseLoading(false);
    }, 10);

    if (yearsheetData && yearsheetData?.length > 0) {
      setYearTotal(yearsheetData);
      setGoalsCal({
        budget: 0,
        sales: 0,
      });
    }
    if (selectedHistory && selectedHistory?.length > 0) {
      setYearTotal(selectedHistory?.[0]?.categories);
      setGoalsCal(selectedHistory?.[0]?.goals);
    }
  }, [yearsheetData, selectedHistory]);

  useEffect(() => {
    setCategoriesListing([]);
    setSubCategoriesListing([]);

    if (activeClient) {
      // setYearsheetData([]);
      getCategoriesListing();
      getSubCategoriesListing();
    }

    if (!yearSheetFilters?.year || yearSheetFilters?.year != currentYear) {
      dispatch(
        updateYearSheetFilters({
          ...yearSheetFilters,
          year: dayjs().year(),
        })
      );
    }
  }, [activeClient]);

  const [highlightedMonths, setHighlightedMonths] = useState([]);

  const cellRender = (current, info) => {
    if (info.type !== "month") {
      return info.originNode;
    }
    const monthIndex = current.month();
    const style = {
      color: "#6ee16e",
      borderRadius: "50%",
    };
    return (
      <div
        className="ant-picker-cell-inner"
        style={highlightedMonths?.includes(monthIndex) ? style : {}}
      >
        {current.format("MMM")}
      </div>
    );
  };

  return (
    <YearSheetWrapper>
      <FilterTabsWrapper position={yearSheetFilter}>
        {yearSheetFilter && (
          <div className="wrap">
            <section>
              <div className="label">Select Year & Month</div>
              <div className="flex">
                <DatePicker
                  picker="year"
                  value={dayjs().year(selectedYear)}
                  disabledDate={(date) =>
                    date.year() > currentYear || date.year() < currentYear
                  }
                />
                <Select
                  placeholder="Select month"
                  prefixCls="inner-select2"
                  className="select"
                  onChange={onMonthChange}
                  // options={months}
                  value={yearSheetFilters?.month || null}
                  size="middle"
                  allowClear
                >
                  {months?.map((item, i) => (
                    <Option key={item?.value} value={item?.value}>
                      <span
                        style={{
                          color:
                            uniqueSelectedMonths?.includes(item?.value) &&
                            item?.value != yearSheetFilters?.month &&
                            "green",
                        }}
                      >
                        {item?.label}
                      </span>
                    </Option>
                  ))}
                </Select>
              </div>
            </section>
            <section>
              <div className="label">Category</div>
              <Select
                placeholder="Please select"
                prefixCls="inner-select2"
                className="select"
                loading={catLoading}
                onChange={handleChangeCategory}
                options={categoriesListing}
                value={yearSheetFilters?.category || []}
                mode="multiple"
                size="middle"
                maxTagCount={2}
              />
            </section>
            <section>
              <div className="label">Sub-Category</div>
              <Select
                placeholder="Please select"
                prefixCls="inner-select2"
                className="select"
                loading={subCatLoading}
                onChange={handleChangeSubCategory}
                options={subCategoriesListing}
                value={yearSheetFilters?.subCategory || []}
                mode="multiple"
                maxTagCount={2}
              />
            </section>
            <button
              className="saveBtn submit"
              onClick={() => !graphsLoading && applyFilters()}
            >
              {graphsLoading ? "Loading..." : "Submit"}
            </button>
          </div>
        )}
      </FilterTabsWrapper>
      {graphsLoading ? (
        <LoadingBlock
          height={
            yearSheetFilter ? "calc(100vh - 216px)" : "calc(100vh - 130px)"
          }
          size={28}
        />
      ) : (
        <>
          <div className="historyWrapper">
            {(persistYearData?.current?.length > 0 &&
              persistYearData?.prediction?.length > 0) ||
            (persistYearData?.current?.length > 0 &&
              yearsheetData &&
              yearsheetData?.length > 0) ? (
              <>
                <MainWrapperYear>
                  <section className="left">
                    <div className="all-tabs">
                      <button
                        className={activeBudgetSheet == "current" && "active"}
                        onClick={() => {
                          setActiveBudgetSheet("current");
                          setSortBy(null);
                        }}
                      >
                        Budget Planning
                      </button>
                      {/* <button
                      className={activeBudgetSheet == "prediction" && "active"}
                      onClick={() => {setActiveBudgetSheet("prediction");setSortBy(null)}}
                    >Predicted Budget</button> */}
                    </div>

                    <div style={{ display: "flex", gap: "10px" }} >

                      <div className="orderby-wrap">
                        <span className="title">Order By </span>
                        <Select
                          prefixCls="inner-select2"
                          placeholder="Select"
                          size={"large"}
                          allowClear
                          style={{ minWidth: "180px" }}
                          options={sortByFilter}
                          onChange={(_, data) => setSortBy(data)}
                        />
                      </div>

                      <div className="all-tabs" style={{ marginRight: "10px" }}>
                        <button
                          className="active"
                          onClick={!formLoading && handleButtonClick}
                        >
                          {formLoading ? "Loading..." : "Save Projection"}
                        </button>
                      </div>

                    </div>

                  </section>

                  {/* <section className="right">
                    <div className="all-tabs" style={{ marginRight: "10px" }}>
                      <button
                        className="active"
                        onClick={!formLoading && handleButtonClick}
                      >
                        {formLoading ? "Loading..." : "Save Projection"}
                      </button>
                    </div>
                  </section> */}
                </MainWrapperYear>

                <YearComponent
                  name={activeBudgetSheet}
                  ref={formRef}
                  setLoading={setFormLoading}
                  data={
                    selectedHistory?.length > 0
                      ? selectedHistory?.[0]?.categories
                      : yearsheetData
                  }
                  // data={temp}
                  setYearTotal={setYearTotal}
                  category={yearSheetFilters?.category || []}
                  subCategory={yearSheetFilters?.subCategory || []}
                  goalsCal={goalsCal}
                  setGraphsData={setGraphsData}
                  graphsData={graphsData}
                  yearSheetFilter={yearSheetFilter}
                  sortBy={sortBy}
                  handleUnique={handleUnique}
                />
              </>
            ) : (
              <>
                {!graphsLoading && (
                  <ApplyFilter
                    yearSheetFilter={yearSheetFilter}
                    message={noDataFound && "No data found!"}
                  />
                )}
              </>
            )}
          </div>
        </>
      )}
    </YearSheetWrapper>
  );
}

export const ApplyFilter = ({ title = null, yearSheetFilter, message }) => {
  return (
    <MessageContainer yearSheetFilter={yearSheetFilter}>
      <StyledCalendarDays />
      {message ? (
        <>
          <MessageTitle>Missing Data for Specified Period!</MessageTitle>
          <MessageText>{message}</MessageText>
        </>
      ) : (
        <>
          <MessageTitle>
            {" "}
            Please select the {title || "date range and categories"}{" "}
          </MessageTitle>
          <MessageText>
            {" "}
            Use the fields above to specify your desired{" "}
            {title || "data range and categories"}.{" "}
          </MessageText>
        </>
      )}
    </MessageContainer>
  );
};

const MainWrapperYear = styled.div`
  width: 100%;
  display: flex;
  // margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .left {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .right {
    width: 30%;
    min-width: 400px;
    display: flex;
    justify-content: end;
  }

  .orderby-wrap {
    .title {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: rgb(123, 127, 145);
      padding-right: 10px;
    }
  }

  .all-tabs {
    border: 1px solid #d2d4de;
    border-radius: 6px;
    padding: 2px;
    button {
      background: #f6f9fc;
      width: fit-content;
      text-align: center;
      padding: 5px 20px;
      color: #000;
      border: none;
      border-left: 2px solid white;
      height: 33px;
      font-size: 13px;
      font-family: Poppins;
      font-style: normal;
      border-radius: 6px;
    }

    .active {
      background: #242424;
      color: white;
    }
  }
`;

const YearSheetWrapper = styled.div`
  .historyWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .saveBtn {
    background: rgb(36, 36, 36);
    color: rgb(255, 255, 255);
    border-style: none;
    height: 33px;
    font-size: 13px;
    padding-right: 12px;
    padding-left: 12px;
    cursor: pointer;
    font-family: Poppins;
    height: 34px;
    min-width: 68px;
    padding: 8px 12px 8px 12px;
    border-radius: 6px;
  }

  .submit {
    background: #d80c0c;
  }

  .select {
    .inner-select2-selector {
      min-width: 250px !important;
      background: white !important;
    }
  }
`;

const FilterTabsWrapper = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  justify-content: ${({ position }) => (position ? "space-between" : "right")};
  align-items: end;
  background: ${({ position }) => (position ? "#fff" : "transparent")};
  padding: ${({ position }) => (position ? "10px" : "0px")};
  border-radius: 9px;
  border: ${({ position }) => (position ? "1px solid rgb(210, 212, 222)" : "")};
  margin: ${({ position }) => (position ? "10px 0" : "0 0 10px 0")};

  .flex {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .label {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #7b7f91;
  }
  .wrap {
    display: flex;
    align-items: end;
    gap: 15px;
  }
`;

const MessageContainer = styled.div`
  height: ${({ yearSheetFilter }) =>
    yearSheetFilter ? "calc(100vh - 210px)" : "calc(100vh - 130px)"};
  text-align: center;
  padding: 2rem;
  background-color: #f9faf6;
  border-radius: 0.5rem;
  // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledCalendarDays = styled(CalendarDays)`
  width: 3rem;
  height: 3rem;
  color: rgb(216, 12, 12);
  margin: 0 auto 1rem;
`;

const MessageTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  color: #111827;
  margin-bottom: 0.5rem;
`;

const MessageText = styled.p`
  color: #6b7280;
  font-size: 1rem;
`;
